<!-- <p-messages></p-messages> -->

<div [ngClass]="{'mobile': app.isMobile}">
    <router-outlet></router-outlet>
</div>

<ngx-spinner name="app-main"></ngx-spinner>

<p-confirmDialog></p-confirmDialog>

<p-toast position="top-center" [baseZIndex]="1000"></p-toast>