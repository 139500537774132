import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PaymentMethod } from "./payment.service";

@Injectable({
  providedIn: 'root',
})
export class DataCapService {
  newPaymentMethod = new BehaviorSubject<PaymentMethod>(null);
  tokenKey: string

  constructor() {}
}