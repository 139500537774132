import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Amplify from '@aws-amplify/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './app/extensions/string.extensions';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const aws_exports = require("./aws-exports").default;
Amplify.configure(aws_exports);
