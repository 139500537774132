import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderLink } from 'src/app/services/API.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-tagged-menus',
  templateUrl: './tagged-menus.component.html',
  styleUrls: ['./tagged-menus.component.scss']
})
export class TaggedMenusComponent implements OnInit, OnChanges {
  items: Item[] = []
  @Input() orderLink: Partial<OrderLink>
  @Input() tags: any[]

  constructor(
    private menuSvc: MenuService,
    public bp: BreakpointService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderLink?.currentValue || changes.tags) {
      this.items = []
      const tagNames = this.tags.map(t => t.name.toLowerCase())
      const options = this.menuSvc.menuEntryOptions.filter(m => m.active)
        .filter(o => o.tags.some(t => tagNames.includes(t.name.toLowerCase())))
      for (let option of options) {
        const item = {
          menuID: option.menuID,
          name: option.name,
          tags: option.tags.map(t => t.name).join(', '),
          imageS3s: [],
          sortOrder: option.sortOrder
        }
        for (let imageS3 of option.imageS3Featured) {
          item.imageS3s.push(imageS3)
        }
        this.items.push(item)
      }
    }
  }

  ngOnInit(): void {
  }

  onClickItem(item) {
    this.menuSvc.selectMenu(item.menuID)
  }
}

class Item {
  menuID: string
  name: string
  tags: string
  imageS3s: string[]
  sortOrder: number

  constructor(init?: Partial<Item>) {
    Object.assign(this, init)
  }
}