<div class="wrapper" [ngClass]="{'menu-selected': menuSvc.menu}" *ngIf="orderSvc.orderLink">
    <div class="notice" *ngIf="showNotice && !menuSvc.menu">
        <div class="left">

        </div>
        <div class="center">
            {{orderSvc.orderLink?.notice}}
        </div>
        <div class="right" (click)="showNotice = false">
            <fa-icon size="lg" [icon]="['far', 'times-circle']" style="cursor: pointer;"></fa-icon>
        </div>
    </div>

    <!-- ENTRY -->
    <ng-container *ngIf="!menuSvc.menu">
        <!-- DESKTOP -->
        <div class="top-bar" *ngIf="app.isDesktop" [ngClass]="{'menu-selected': menuSvc.menu}">
            <div class="row row1">
                <div class="left">
                </div>
                <div class="center">
                    <div class="business-name">{{orderSvc.orderLink?.originName}}</div>
                </div>
                <div class="right">
                    <div class="order-types">
                        <div *ngFor="let orderType of orderSvc.orderTypes"
                            [class.active]="orderSvc.orderTypeID === orderType.id"
                            (click)="orderSvc.selectOrderType(orderType)">{{orderType.displayName || orderType.name}}</div>
                    </div>
                    <div style="margin-right: 1rem;">|</div>
                    <div class="order-timing">
                        <div [class.active]="this.orderSvc.orderQueueMode === 'ASAP'" *ngIf="orderSvc.orderType.asap"
                            (click)="orderSvc.selectOrderQueueMode('ASAP')">ASAP</div>
                        <div [class.active]="this.orderSvc.orderQueueMode === 'SCHEDULE'"
                            *ngIf="orderSvc.orderType.schedule" (click)="orderSvc.selectOrderQueueMode('SCHEDULE')">
                            Later<span *ngIf="this.orderSvc.orderQueueMode === 'SCHEDULE'"> ({{orderSvc.dueTime | amLocal | amDateFormat: 'MMM D'}})</span></div>
                        <div style="margin-right: 1rem;" *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'"
                            class="to">to</div>
                        <div class="zip" style="margin-right: 1rem;"
                            *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && orderSvc.customer.address?.zip"
                            (click)="editAddress()">
                            {{orderSvc.customer.address.zip}}</div>
                        <div class="zip" style="margin-right: 1rem;"
                            *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && !orderSvc.customer.address?.zip"
                            (click)="editAddress()">Edit
                            Address</div>
                    </div>
                    <div style="margin-right: 1rem;">|</div>
                    <div class="icons">
                        <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDOUT'">
                            <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDIN'">
                            <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()" style="color: #0085FF;">
                            </fa-icon>
                        </ng-container>
                        <fa-icon class="ml-2" [icon]="['fal', 'shopping-bag']" (click)="orderSvc.showCheck = true">
                        </fa-icon>
                        <span class="ml-1 count"
                            *ngIf="checkSvc.checkDTO?.items?.length > 0">{{checkSvc.checkDTO?.items?.length}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- MOBILE -->
        <div class="top-bar mobile" *ngIf="app.isMobile" [ngClass]="{'menu-selected': menuSvc.menu}">
            <div class="row row1">
                <div class="left">
                    <!-- <fa-icon [icon]="['fal', 'stream']"></fa-icon> -->
                </div>
                <div class="center business-name">{{orderSvc.orderLink?.originName}}</div>
                <div class="right icons">
                    <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDOUT'">
                        <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDIN'">
                        <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()" style="color: #0085FF;">
                        </fa-icon>
                    </ng-container>
                    <fa-icon class="ml-2" [icon]="['fal', 'shopping-bag']" (click)="orderSvc.showCheck = true">
                    </fa-icon>
                    <span class="ml-1 count"
                        *ngIf="checkSvc.checkDTO?.items?.length > 0">{{checkSvc.checkDTO?.items?.length}}</span>
                </div>
            </div>
            <div class="row row2">
                <div class="left order-types">
                    <div *ngFor="let orderType of orderSvc.orderTypes"
                        [class.active]="orderSvc.orderTypeID === orderType.id"
                        (click)="orderSvc.selectOrderType(orderType)">{{orderType.displayName || orderType.name}}</div>
                </div>
                <div class="right order-timing">
                    <div [class.active]="this.orderSvc.orderQueueMode === 'ASAP'" *ngIf="orderSvc.orderType.asap"
                        (click)="orderSvc.selectOrderQueueMode('ASAP')">ASAP</div>
                    <div [class.active]="this.orderSvc.orderQueueMode === 'SCHEDULE'"
                        *ngIf="orderSvc.orderType.schedule" (click)="orderSvc.selectOrderQueueMode('SCHEDULE')">
                        Later<span *ngIf="this.orderSvc.orderQueueMode === 'SCHEDULE'"> ({{orderSvc.dueTime | amLocal | amDateFormat: 'MMM D'}})</span></div>
                        <div style="margin-right: 1rem;" *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'" class="to">
                        to</div>
                    <div class="zip" style="margin-right: 1rem;"
                        *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && orderSvc.customer.address?.zip"
                        (click)="editAddress()">
                        {{orderSvc.customer.address.zip}}</div>
                    <div class="zip" style="margin-right: 1rem;"
                        *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && !orderSvc.customer.address?.zip"
                        (click)="editAddress()">Edit
                        Address</div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- MENU -->
    <ng-container *ngIf="menuSvc.menu">
        <!-- DESKTOP -->
        <div class="top-bar" *ngIf="app.isDesktop" [ngClass]="{'menu-selected': menuSvc.menu}">
            <div class="row row1">
                <div class="left">
                    <!-- <div class="menu-selector mr-3">
                            <p-dropdown [options]="menuSvc.menuOptions" optionLabel="name" optionValue="menuID"
                                [autoDisplayFirst]="false" (onChange)="menuSvc.selectMenu($event.value)"
                                [(ngModel)]="menuSvc.menuID">
                            </p-dropdown>
                        </div> -->
                    <div class="business-name" (click)="menuSvc.reset()">{{orderSvc.orderLink?.originName}}</div>
                </div>
                <div class="center">
                    <div class="search">
                        <app-menu-item-search [menus]="menuSvc.menus" class="mx-3"></app-menu-item-search>
                    </div>
                </div>
                <div class="right">
                    <div class="order-types">
                        <div *ngFor="let orderType of orderSvc.orderTypes"
                            [class.active]="orderSvc.orderTypeID === orderType.id"
                            (click)="orderSvc.selectOrderType(orderType)">{{orderType.displayName || orderType.name}}</div>
                    </div>
                    <div style="margin-right: 1rem;">|</div>
                    <div class="order-timing">
                        <div [class.active]="this.orderSvc.orderQueueMode === 'ASAP'" *ngIf="orderSvc.orderType.asap"
                            (click)="orderSvc.selectOrderQueueMode('ASAP')">ASAP</div>
                        <div [class.active]="this.orderSvc.orderQueueMode === 'SCHEDULE'"
                            *ngIf="orderSvc.orderType.schedule" (click)="orderSvc.selectOrderQueueMode('SCHEDULE')">
                            Later<span *ngIf="this.orderSvc.orderQueueMode === 'SCHEDULE'"> ({{orderSvc.dueTime | amLocal | amDateFormat: 'MMM D'}})</span></div>
                        <div style="margin-right: 1rem;" *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'"
                            class="to">to</div>
                        <div class="zip" style="margin-right: 1rem;"
                            *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && orderSvc.customer.address?.zip"
                            (click)="editAddress()">
                            {{orderSvc.customer.address.zip}}</div>
                        <div class="zip" style="margin-right: 1rem;"
                            *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && !orderSvc.customer.address?.zip"
                            (click)="editAddress()">Edit
                            Address</div>
                    </div>
                    <div style="margin-right: 1rem;">|</div>
                    <div class="icons">
                        <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDOUT'">
                            <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDIN'">
                            <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()" style="color: #0085FF;">
                            </fa-icon>
                        </ng-container>
                        <fa-icon class="ml-2" [icon]="['fal', 'shopping-bag']" (click)="orderSvc.showCheck = true">
                        </fa-icon>
                        <span class="ml-1 count"
                            *ngIf="checkSvc.checkDTO?.items?.length > 0">{{checkSvc.checkDTO?.items?.length}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- MOBILE -->
        <div class="top-bar mobile" *ngIf="app.isMobile" [ngClass]="{'menu-selected': menuSvc.menu}">
            <div class="row row1">
                <div class="left">
                    <div class="business-name" (click)="menuSvc.reset()">{{orderSvc.orderLink?.originName}}</div>
                    <app-menu-item-search [menus]="menuSvc.menus" class="mx-3"></app-menu-item-search>
                </div>
                <div class="icons">
                    <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDOUT'">
                        <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="customerSvc.authState.value === 'SIGNEDIN'">
                        <fa-icon [icon]="['fal', 'user']" (click)="openCustomerDetails()" style="color: #0085FF;">
                        </fa-icon>
                    </ng-container>
                    <fa-icon class="ml-2" [icon]="['fal', 'shopping-bag']" (click)="orderSvc.showCheck = true">
                    </fa-icon>
                    <span class="ml-1 count"
                        *ngIf="checkSvc.checkDTO?.items?.length > 0">{{checkSvc.checkDTO?.items?.length}}</span>
                </div>
            </div>
            <div class="row row2">
                <div class="left order-types">
                    <div *ngFor="let orderType of orderSvc.orderTypes"
                        [class.active]="orderSvc.orderTypeID === orderType.id"
                        (click)="orderSvc.selectOrderType(orderType)">{{orderType.displayName || orderType.name}}</div>
                </div>
                <div class="right order-timing">
                    <div [class.active]="this.orderSvc.orderQueueMode === 'ASAP'" *ngIf="orderSvc.orderType.asap"
                        (click)="orderSvc.selectOrderQueueMode('ASAP')">ASAP</div>
                    <div [class.active]="this.orderSvc.orderQueueMode === 'SCHEDULE'"
                        *ngIf="orderSvc.orderType.schedule" (click)="orderSvc.selectOrderQueueMode('SCHEDULE')">
                        Later<span *ngIf="this.orderSvc.orderQueueMode === 'SCHEDULE'"> ({{orderSvc.dueTime | amLocal | amDateFormat: 'MMM D'}})</span></div>
                        <div style="margin-right: 1rem;" *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'" class="to">
                        to</div>
                    <div class="zip" style="margin-right: 1rem;"
                        *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && orderSvc.customer.address?.zip"
                        (click)="editAddress()">
                        {{orderSvc.customer.address.zip}}</div>
                    <div class="zip" style="margin-right: 1rem;"
                        *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY' && !orderSvc.customer.address?.zip"
                        (click)="editAddress()">Edit
                        Address</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>