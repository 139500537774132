import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomerService } from 'src/app/services/customer.service';
import { GMapsService } from 'src/app/services/gmaps.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-customer-address',
  templateUrl: './customer-address.component.html',
  styleUrls: ['./customer-address.component.scss']
})
export class CustomerAddressComponent implements OnInit {
  loading: boolean
  model: any = {}

  constructor(
    public orderSvc: OrderService,
    public ref: DynamicDialogRef,
    private gmapsSvc: GMapsService,
    private dConfig: DynamicDialogConfig,
    private messageSvc: MessageService,
    public customerSvc: CustomerService) { }

  ngOnInit(): void {
    this.dConfig.header = 'Delivery Address'
    this.model.address = this.orderSvc.customer.address.street
    this.model.city = this.orderSvc.customer.address.city
    this.model.state = this.orderSvc.customer.address.state
    this.model.zip = this.orderSvc.customer.address.zip
  }

  async save() {
    this.loading = true
    try {
      this.orderSvc.customer.address.street = this.model.address
      this.orderSvc.customer.address.city = this.model.city
      this.orderSvc.customer.address.state = this.model.state
      this.orderSvc.customer.address.zip = this.model.zip
      if (this.orderSvc.order) {
        await this.gmapsSvc.gMapsDirections({
          street: this.orderSvc.orderLink.location.street,
          city: this.orderSvc.orderLink.location.city,
          state: this.orderSvc.orderLink.location.state,
          zip: this.orderSvc.orderLink.location.zip
        },
          this.orderSvc.customer.address,
          this.orderSvc.orderLink.location,
          this.orderSvc.customer
        )
      }

      await this.orderSvc.getDeliveryFee()

      this.ref.close(this.orderSvc.customer)
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  selectAddress(event) {
    this.model.address = event.value.street
    this.model.city = event.value.city
    this.model.state = event.value.state
    this.model.zip = event.value.zip
  }
}
