import { Injectable } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import * as moment from 'moment-timezone';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CheckItemDTO } from "src/models/DTO/check.dto";
import { MenuItemDTO } from "src/models/DTO/menu.dto";
import { MenuItemComponent } from "../shared/components/menu-item/menu-item.component";
import { Menu, MenuCategory, MenuItem, Tag, TimeRange } from "./API.service";
import { ImageService } from "./image.service";
import { ScheduleService } from "./schedule.service";

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    activeCategoryOption
    bannerImage: SafeUrl
    bannerImageMobile: SafeUrl
    changingCategory: boolean
    dRef: DynamicDialogRef
    menu: Menu
    menuID: string
    menuOptionSelected: MenuEntryOption
    menus: Menu[] = []
    menuCategory: MenuCategory
    menuCategoryId: string
    menuCategoryOptions: MenuCategoryOption[] = []
    menuEntryOptions: MenuEntryOption[] = []
    menuItems: MenuItem[] = []
    menuOptions: MenuOption[] = []
    menuTagOptions: MenuTagOption[] = []
    menuTagOptionsSelected: MenuTagOption[] = []

    constructor(
        private dialogSvc: DialogService,
        private imgSvc: ImageService,
        private scheduleSvc: ScheduleService) { }

    editMenuItem(checkItemDTO: CheckItemDTO) {
        if (this.dRef) this.dRef.close()
        this.dialogSvc.open(MenuItemComponent, {
            styleClass: 'dialog-menu-item',
            data: {
                checkItemDTO: checkItemDTO,
            }
        })
    }

    async generateMenuEntryOptions(menus: Menu[], timezone: string, ISOStringLocal?: string, sortOrder?: string[]) {
        this.menuEntryOptions = []
        for (let menu of menus) {
            let timeRanges = menu.schedules.items.map(i => i.schedule.timeRanges).flat()
            let open = this.scheduleSvc.isOpen(timeRanges, timezone, ISOStringLocal)
            if (menu.active) {
                let option = {
                    menuID: menu.id,
                    venueID: menu.venueID,
                    name: menu.displayName || menu.name,
                    logoS3: menu.imageS3LogoSquare || menu.venue.imageS3LogoSquare,
                    timeRanges: timeRanges,
                    timeBlocks: this.scheduleSvc.getTimeBlocks(timeRanges, moment().day()).join(', '),
                    open: open,
                    featured: menu.featured,
                    active: menu.active,
                    popular: menu.popular,
                    imageS3Featured: menu.imageS3Featured,
                    tags: menu.tags?.items.map(i => i.tag) || [],
                    sortOrder: sortOrder?.indexOf(menu.id)
                }
                this.menuEntryOptions.push(option)
            }
        }

        const open = this.menuEntryOptions.filter(o => o.open)
        if (open[0] && open[0].sortOrder >= 0) {
            open.sort((a, b) => a.sortOrder - b.sortOrder)
        } else {
            open.sort((a, b) => a.name.localeCompare(b.name))
        }

        const closed = this.menuEntryOptions.filter(o => !o.open)
        if (closed[0] && closed[0].sortOrder >= 0) {
            closed.sort((a, b) => a.sortOrder - b.sortOrder)
        } else {
            closed.sort((a, b) => a.name.localeCompare(b.name))
        }

        this.menuEntryOptions = open.concat(closed)

        return this.menuEntryOptions
    }

    generateMenuTags() {
        this.menuTagOptions = []
        const menus = this.menus.filter(m => m.active)
        for (let menu of menus) {
            const tags = menu.tags.items.map(i => i.tag)
            for (let tag of tags) {
                if (!this.menuTagOptions.some(t => t.name.toLowerCase() === tag.name.toLowerCase())) {
                    const option = new MenuTagOption()
                    option.id = tag.id
                    option.name = tag.name
                    option.imageS3 = tag.imageS3Square
                    this.menuTagOptions.push(option)
                }
            }
        }
        this.menuTagOptions.sort((a, b) => a.name.localeCompare(b.name))
        this.selectAllMenuTagOptions()
    }

    getMenuItem(id) {
        return this.menuItems.find(mi => mi.id === id)
    }

    async getMenuItemOptions(menuCategory: MenuCategory, menu: Menu) {
        let options = []
        if (menuCategory?.menuItems) {
            options = []

            const items = menuCategory.menuItems.items
            if (items[0] && items[0].sortOrder >= 0) {
                items.sort((a, b) => a.sortOrder - b.sortOrder)
            } else {
                items.sort((a, b) => a.menuItem.name.localeCompare(b.menuItem.name))
            }

            const menuItems = items.filter(i => i.menuItem.active).map(i => i.menuItem)
            for (let menuItem of menuItems) {
                options.push({
                    menuItem: await MenuItemDTO.fromQuery(menuItem, menuCategory.priceTypeID),
                    venueID: menu.venueID,
                    menuID: menu.id,
                    menuName: menu.name,
                })
            }
            // if (options) {
            //     for (let o of options) {
            //         await this.imgSvc.getSafeURL(o.menuItem.imageS3)
            //     }
            // }
        }

        return options
    }

    generateMenuOptions(menus: Menu[]) {
        this.menus = menus
        this.menuOptions = []
        for (let menu of menus) {
            if (menu.active) {
                this.menuOptions.push({
                    name: menu.displayName || menu.name,
                    menuID: menu.id,
                    venueID: menu.venueID
                })
            }
        }
        return this.menuOptions
    }

    isOpen(menuID: string) {
        return this.menuEntryOptions.find(o => o.menuID === menuID)?.open
    }

    async loadMenus(menus: Menu[], menuID?: string, timezone?: string, ISOStringLocal?: string, sortOrder?: string[]) {
        this.menus = menus
        this.generateMenuOptions(this.menus)
        await this.generateMenuEntryOptions(this.menus, timezone, ISOStringLocal, sortOrder)

        this.menuItems = []
        for (let menu of this.menus) {
            for (let category of menu.categories.items.map(i => i.menuCategory)) {
                for (let menuItem of category.menuItems.items.map(i => i.menuItem)) {
                    if (!this.menuItems.some(mi => mi.id === menuItem.id)) {
                        this.menuItems.push(menuItem)
                    }
                }
            }
        }

        if (menuID) {
            const option = this.menuOptions.find(o => o.menuID === menuID)
            this.selectMenu(option.menuID)
        }

        this.generateMenuTags()
    }

    reset() {
        this.selectAllMenuTagOptions()
        this.menuOptionSelected = null
        this.menu = null
    }

    async selectMenu(id: string) {
        this.menuOptionSelected = this.menuEntryOptions.find(o => o.menuID === id)
        this.menuID = id
        this.menu = this.menus.find(m => m.id === this.menuID)
        this.menu.categories.items.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
        this.menuCategoryOptions = this.menu.categories.items.filter(i => i.menuCategory.active).map(i => ({
            menuCategory: i.menuCategory,
            venueID: this.menu.venueID,
            menuID: this.menu.id,
            menuName: this.menu.name,
            items: []
        }))

        for (let option of this.menuCategoryOptions) {
            option.items = await this.getMenuItemOptions(option.menuCategory, this.menu)
        }

        this.bannerImage = null
        this.bannerImageMobile = null
        if (this.menu?.imageS3Headers?.length > 0) {
            let random = this.shuffle(this.menu.imageS3Headers)
            this.bannerImage = await this.imgSvc.getURL(random[0])
            random = this.shuffle(this.menu.imageS3HeadersMobile)
            this.bannerImageMobile = await this.imgSvc.getURL(random[0])
        }
    }

    selectMenuItem(menuItemID: string, venueID: string, menuID: string, priceTypeID?: string, checkItemID?: string) {
        if (this.dRef) this.dRef.close()
        this.dialogSvc.open(MenuItemComponent, {
            styleClass: 'dialog-menu-item',
            data: {
                menuItemID,
                venueID,
                menuID,
                priceTypeID,
                checkItemID
            }
        })
    }

    selectAllMenuTagOptions() {
        this.menuTagOptions.filter(t => !t.selected).forEach(t => {
            this.selectMenuTagOption(t)
        })
    }

    selectMenuTagOption(option: MenuTagOption) {
        if (this.menuTagOptionsSelected.length === this.menuTagOptions.length) {
            this.menuTagOptionsSelected.forEach(t => {
                t.selected = false
            })
            this.menuTagOptionsSelected = []
        }
        const existing = this.menuTagOptionsSelected.find(t => t.id === option.id)
        if (!existing && !option.selected) {
            this.menuTagOptionsSelected.push(option)
            option.selected = true
        }
        if (existing && option.selected) {
            const idx = this.menuTagOptionsSelected.findIndex(t => t.id === option.id)
            this.menuTagOptionsSelected.splice(idx, 1)
            option.selected = false
        }
        this.menuTagOptionsSelected = [...this.menuTagOptionsSelected]
    }

    selectNoneMenuTagOptions() {
        this.menuTagOptions.filter(t => t.selected).forEach(t => {
            this.selectMenuTagOption(t)
        })
    }

    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }
}

class MenuEntryOption {
    venueID: string
    menuID: string
    name: string
    logoS3: string
    timeRanges: TimeRange[]
    timeBlocks: string
    open: boolean
    active: boolean
    featured: boolean
    popular: boolean
    sortOrder: number
    imageS3Featured: string[]
    tags: Tag[]
}

class MenuOption {
    venueID: string
    menuID: string
    name: string
}

class MenuCategoryOption {
    venueID: string
    menuID: string
    menuName: string
    items?: MenuItemOption[]
    menuCategory: MenuCategory
}

class MenuItemOption {
    venueID: string
    menuID: string
    menuName: string
    menuItem: MenuItem
}

class MenuTagOption {
    id: string
    name: string
    imageS3?: string
    selected?: boolean
    menus?: {
        menuID: string
        name: string
        imageS3s: string[]
        tags?: string
    }[] = []
}
