<div class="close">
    <fa-icon [icon]="'times-circle'" (click)="dRef.close()" size="2x"></fa-icon>
</div>
<div class="mt-3">
    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Login">
            <ng-container *ngIf="!confirmPassword">
                <div class="field">
                    <label for="email">Email</label>
                    <input pInputText id="email" type="email" class="inputfield w-full" [(ngModel)]="email" />
                </div>
                <div class="field">
                    <label for="password">Password</label>
                    <input pPassword id="password" type="password" class="inputfield w-full" [(ngModel)]="password" />
                </div>
                <button type="button" pButton class="btn-primary-lg w-full mt-3" [disabled]="!email || !password"
                    label="Login" (click)="signIn(email, password)" [ladda]="loading"></button>
                <div class="text-center w-full mt-3" style="cursor: pointer;" (click)="forgotPassword(email)">
                    Forgot Password
                </div>
            </ng-container>
            <ng-container *ngIf="confirmPassword">
                <div class="field">
                    <label for="email">Email</label>
                    <input pInputText id="email" type="email" class="inputfield w-full" [(ngModel)]="email" />
                </div>
                <div class="field">
                    <label for="email">Code</label>
                    <input pInputText id="code" type="text" class="inputfield w-full" [(ngModel)]="code" />
                </div>
                <div class="field">
                    <label for="password">New Password</label>
                    <input pPassword id="password" type="password" class="inputfield w-full" [(ngModel)]="password" />
                </div>
                <button type="button" pButton class="btn-primary-lg w-full mt-3"
                    [disabled]="!email || !code || !password" label="Change Password"
                    (click)="forgotPasswordSubmit(email, code, password)" [ladda]="loading"></button>
                <div class="text-center w-full mt-3" style="cursor: pointer;" (click)="confirmPassword = false">
                    Cancel
                </div>
            </ng-container>
        </p-tabPanel>

        <p-tabPanel header="Sign Up">
            <ng-container *ngIf="!confirmAccount">
                <div class="field">
                    <label for="email">Email</label>
                    <input pInputText id="email" type="email" class="inputfield w-full" [(ngModel)]="email" />
                </div>
                <div class="field">
                    <label for="password">Password</label>
                    <input pPassword id="password" type="password" class="inputfield w-full" [(ngModel)]="password" />
                </div>
                <button type="button" pButton class="btn-primary-lg w-full mt-3" [disabled]="!email || !password"
                    label="Sign Up" (click)="signUp(email, password)" [ladda]="loading"></button>
                <div class="text-center w-full mt-3" style="cursor: pointer;" (click)="confirmAccount = true">
                    Confirm Signup Code
                </div>
            </ng-container>

            <ng-container *ngIf="confirmAccount">
                <div class="field">
                    <label for="email">Email</label>
                    <input pInputText id="email" type="email" class="inputfield w-full" [(ngModel)]="email" />
                </div>
                <div class="field">
                    <label for="email">Code</label>
                    <input pInputText id="code" type="text" class="inputfield w-full" [(ngModel)]="code" />
                </div>
                <button type="button" pButton class="btn-primary-lg w-full mt-3" [disabled]="!email || !code"
                    label="Submit" (click)="signUpConfirm(email, code)" [ladda]="loading"></button>
                <div class="text-center w-full mt-3" style="cursor: pointer;" (click)="resendSignUp(email)">
                    Resend Code
                </div>
                <div class="text-center w-full mt-3" style="cursor: pointer;" (click)="confirmAccount = false">
                    Cancel
                </div>
            </ng-container>
        </p-tabPanel>
    </p-tabView>
</div>