import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppStateService } from './app-state.service';

@Injectable({
    providedIn: 'root'
})
export class BreakpointService {
    isDesktop = new BehaviorSubject<boolean>(null)
    isMobile = new BehaviorSubject<boolean>(null)
    size = new BehaviorSubject<string>(null)

    constructor(
        private breakpointObserver: BreakpointObserver,
        private app: AppStateService
    ) {
        this.breakpointObserver
            .observe(['(min-width: 768px)'])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.app.isDesktop = true
                    this.isDesktop.next(true)
                    this.app.isMobile = false
                    this.isMobile.next(true)
                } else {
                    this.app.isDesktop = false
                    this.isDesktop.next(false)
                    this.app.isMobile = true
                    this.isMobile.next(true)
                }
                console.log('isMobile:' + this.app.isMobile)
                console.log('isDesktop:' + this.app.isDesktop)
            })

        this.breakpointObserver
            .observe([Breakpoints.XLarge])
            .subscribe((state: BreakpointState) => {
                state.matches && (this.size.next('xl'))
            })

        this.breakpointObserver
            .observe([Breakpoints.Large])
            .subscribe((state: BreakpointState) => {
                state.matches && (this.size.next('l'))
            })

        this.breakpointObserver
            .observe([Breakpoints.Medium])
            .subscribe((state: BreakpointState) => {
                state.matches && (this.size.next('m'))
            })

        this.breakpointObserver
            .observe([Breakpoints.Small])
            .subscribe((state: BreakpointState) => {
                state.matches && (this.size.next('s'))
            })

        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                state.matches && (this.size.next('xs'))
            })
    }

    isSize(value: string | string[]) {
        if (!(value instanceof Array)) {
            value = [value]
        }
        return value.includes(this.size.value)
    }

    gteSize(value: string) {
        if (value === 'xs') {
            if (this.size.value === 'xs') return true
            if (this.size.value === 's') return true
            if (this.size.value === 'm') return true
            if (this.size.value === 'l') return true
            if (this.size.value === 'xl') return true
        }
        if (value === 's') {
            if (this.size.value === 's') return true
            if (this.size.value === 'm') return true
            if (this.size.value === 'l') return true
            if (this.size.value === 'xl') return true
        }
        if (value === 'm') {
            if (this.size.value === 'm') return true
            if (this.size.value === 'l') return true
            if (this.size.value === 'xl') return true
        }
        if (value === 'l') {
            if (this.size.value === 'l') return true
            if (this.size.value === 'xl') return true
        }
        if (value === 'xl') {
            if (this.size.value === 'xl') return true
        }
        return false
    }

    lteSize(value: string) {
        if (value === 'xs') {
            if (this.size.value === 'xs') return true
        }
        if (value === 's') {
            if (this.size.value === 'xs') return true
            if (this.size.value === 's') return true
        }
        if (value === 'm') {
            if (this.size.value === 'xs') return true
            if (this.size.value === 's') return true
            if (this.size.value === 'm') return true
        }
        if (value === 'l') {
            if (this.size.value === 'xs') return true
            if (this.size.value === 's') return true
            if (this.size.value === 'm') return true
            if (this.size.value === 'l') return true
        }
        if (value === 'xl') {
            if (this.size.value === 'xs') return true
            if (this.size.value === 's') return true
            if (this.size.value === 'm') return true
            if (this.size.value === 'l') return true
            if (this.size.value === 'xl') return true
        }
        return false
    }
}