import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-customer-wallet',
  templateUrl: './customer-wallet.component.html',
  styleUrls: ['./customer-wallet.component.scss']
})
export class CustomerWalletComponent implements OnInit {
@Input() paymentMethods

  constructor(public customerSvc: CustomerService, public paymentSvc: PaymentService, public orderSvc: OrderService) { }

  getCardTypeDisplayName(type: string) {
    switch (type) {
      case 'VS':
        return 'VISA'
      default:
        return type
    }
  }

  ngOnInit(): void {
  }

}
