import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { OrderLink } from 'src/app/services/API.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-featured-menus',
  templateUrl: './featured-menus.component.html',
  styleUrls: ['./featured-menus.component.scss']
})
export class FeaturedMenusComponent implements OnInit {
  items: Item[] = []
  @Input() orderLink: Partial<OrderLink>

  constructor(private menuSvc: MenuService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderLink?.currentValue) {
      const options = this.menuSvc.menuEntryOptions.filter(m => m.active && m.featured)
      for (let option of options) {
        if (!this.items.some(i => i.menuID === option.menuID)) {
          this.items.push(new Item({
            menuID: option.menuID,
            venueID: option.venueID,
            name: option.name,
            imageS3: option.imageS3Featured[0],
            sortOrder: option.sortOrder
          }))
        }
      }
    }
  }

  ngOnInit(): void {
  }

  onClickItem(item) {
    this.menuSvc.selectMenu(item.menuID)
  }
}

class Item {
  venueID: string
  menuID: string
  name: string
  imageS3: string
  sortOrder: number

  constructor(init?: Partial<Item>) {
    Object.assign(this, init)
  }
}