import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { OrderMode } from 'src/app/services/API.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { ImageService } from 'src/app/services/image.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.scss']
})
export class OrderEntryComponent implements OnInit {
  bannerImage: SafeUrl
  bannerImageMobile: SafeUrl
  footerImage: SafeUrl
  @Input() orderMode: OrderMode
  orderModeKiosk = OrderMode.KIOSK
  orderModeOnlineOrder = OrderMode.ONLINE_ORDER

  constructor(
    public app: AppStateService,
    public menuSvc: MenuService,
    public orderSvc: OrderService,
    private imgSvc: ImageService
  ) {
  }

  async init() {
    if (this.orderSvc.orderLink?.imageS3EntryHeaders?.length > 0) {
      let random = this.shuffle(this.orderSvc.orderLink.imageS3EntryHeaders)
      this.bannerImage = await this.imgSvc.getURL(random[0])
      random = this.shuffle(this.orderSvc.orderLink.imageS3EntryHeadersMobile)
      this.bannerImageMobile = await this.imgSvc.getURL(random[0])
    }
    if (this.orderSvc.orderLink?.imageS3Footer) {
      this.footerImage = await this.imgSvc.getSafeURL(this.orderSvc.orderLink.imageS3Footer)
    }
  }

  ngOnInit() {
    this.init()
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}
