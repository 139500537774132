import { Pipe, PipeTransform } from '@angular/core';
import * as short from 'short-uuid'

@Pipe({
  name: 'toShortUUID'
})
export class ToShortUUIDPipe implements PipeTransform {

  transform(value: string): any {
    try {
      const translator = short();
      return translator.fromUUID(value)
    } catch (error) {
      return value;
    }
  }

}