<form ngForm #f="ngForm">
    <div class="formgrid grid" *ngIf="showName || showPhone">
        <div class="field col-12 md:col-6" *ngIf="showName">
            <label for="address">Name</label>
            <input class="inputfield w-full" id="address" name="name" type="text" pInputText placeholder="Name"
                [(ngModel)]="model.name" [required]="true">
        </div>
        <div class="field col-12 md:col-6" *ngIf="showPhone">
            <label for="phone">Phone Number</label><br>
            <p-inputMask class="inputfield w-full" mask="999-999-9999" name="phone" [(ngModel)]="model.phone" placeholder="###-###-####"
                [required]="true" slotChar=" ">
            </p-inputMask>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="address">Street</label>
            <input class="inputfield w-full" id="address" name="address" type="text" pInputText placeholder="Street"
                [(ngModel)]="model.street" [required]="true">
        </div>
        <div class="field col-12 md:col-6">
            <label for="city">City</label>
            <input class="inputfield w-full" id="city" name="city" type="text" pInputText placeholder="City"
                [(ngModel)]="model.city" [required]="true">
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="state">State</label>
            <input class="inputfield w-full" id="state" name="state" type="text" pInputText placeholder="State"
                [(ngModel)]="model.state" [required]="true">
        </div>
        <div class="field col-12 md:col-6">
            <label for="zip">Postal Code</label>
            <input class="inputfield w-full" id="zip" name="zip" type="text" pInputText placeholder="Postal Code"
                [(ngModel)]="model.zip" [required]="true">
        </div>
    </div>

    <div class="formgrid grid" *ngIf="showInstructions">
        <div class="field col-12">
            <label for="instructions">Delivery Instructions</label>
            <textarea class="inputfield w-full" id="instructions" name="instructions" type="text" pInputTextArea [(ngModel)]="model.instructions" [required]="false" [rows]="5"></textarea>
        </div>
    </div>

    <div class="flex" style="width: 100%;">
        <button type="button" pButton class="btn-primary ml-auto" [disabled]="f.invalid" (click)="save(model)"
            label="Save"></button>
    </div>
</form>