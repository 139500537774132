<div class="sub-heading">Profile Info</div>
<form ngForm #f="ngForm">
    <div class="formgrid grid">
        <div class="field col">
            <label for="firstName">First Name</label>
            <input required id="firstName" name="firstName" type="text" class="inputfield w-full" pInputText
                [(ngModel)]="customerSvc.customer.firstName">
        </div>
        <div class="field col">
            <label for="lastName">Last Name</label>
            <input required id="lastName" name="lastName" type="text" class="inputfield w-full" pInputText
                [(ngModel)]="customerSvc.customer.lastName">
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label for="phone">Phone</label>
            <input required id="phone" name="phone" type="phone" class="inputfield w-full" pInputText
                [(ngModel)]="customerSvc.customer.phone">
        </div>
        <div class="field col">
            <label>Email</label>
            <input required id="email" name="email" type="email" class="inputfield w-full" pInputText [disabled]="true"
                [(ngModel)]="customerSvc.customer.email">
        </div>
    </div>
<div class="w-full flex">
    <button [disabled]="f.invalid" class="btn-primary mt-2 mr-2" pButton label="Save Changes"
        (click)="updateCustomer(this.customerSvc.customer)" [ladda]="loading"></button>

    <button *ngIf="!confirmPassword" class="btn-primary ml-auto" pButton label="Change Password"
        (click)="changePassword()" [ladda]="loadingPassword"></button>
</div>
</form>


<ng-container *ngIf="confirmPassword">
    <hr class="my-5">

    <div class="sub-heading">Change Password</div>

    <div class="formgrid grid">
        <div class="field col">
            <label for="email">Verification Code</label>
            <input pInputText id="code" type="text" class="inputfield w-full" [(ngModel)]="code" />
        </div>
        <div class="field col"></div>
    </div>

    <div class="formgrid grid">
        <div class="field col">
            <label for="password">New Password</label>
            <input pPassword id="password" type="password" class="inputfield w-full" [(ngModel)]="password" />
        </div>
        <div class="field col"></div>
    </div>

    <button type="button" pButton class="btn-primary mt-2" [disabled]="!code || !password" label="Change Password"
        (click)="changePasswordSubmit(code, password)" [ladda]="loading"></button>
</ng-container>