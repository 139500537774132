import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CheckPaymentDTO } from "src/models/DTO/check.dto";
import { environment } from '../../environments/environment';
import { APIService, CCProcessor, PaymentMethodType } from "./API.service";
import { CheckService } from "./check.service";
import { PaymentMethod } from "./payment.service";

@Injectable({
    providedIn: 'root'
})
export class I4GoService {
    accessBlock
    api = new APIService()
    server
    newPaymentMethod = new BehaviorSubject<PaymentMethod>(null)
    walletEnabled

    constructor(
        private checkSvc: CheckService,
        private http: HttpClient) { }

    async preAuth(orderLinkID: string, orderID: string, amountWithTip: number, customerDetails: any) {
        const data: any = await this.http.get("https://api.ipify.org/?format=json").toPromise();
        const clientIp = data.ip

        this.server = null
        this.accessBlock = null

        console.log('i4GoPreAuth', { orderLinkID: orderLinkID, orderID: orderID, clientIp: clientIp, amount: amountWithTip, customerDetails: customerDetails })

        const res = await this.api.I4GoPreAuth({ orderLinkID: orderLinkID, orderID: orderID, clientIp: clientIp, amount: amountWithTip, customerDetails: customerDetails })
        this.accessBlock = res.i4go_accessblock;
        this.server = res.i4go_server;
        this.walletEnabled = {
            applePay: false,
            googlePay: false,
        };
    }

    loadIFrame(iFrameRef, self, amount, tip: number = 0) {
        iFrameRef.i4goTrueToken({
            server: this.server,
            accessBlock: this.accessBlock,
            self: self,
            template: "bootstrap3-horizontal",
            url: environment.production ? "https://i4m.i4go.com" : "https://i4m.shift4test.com",
            walletsEnabled: true,
            debug: false,
            submitButton: {
                label: "Submit",
            },
            acceptedPayments: "AX,JC,MC,NS,VS",
            // formPostalCode: this.check.billing.postalCode || undefined,
            payments: [
                { type: "VS", name: "Visa" },
                { type: "MC", name: "MasterCard" },
                { type: "AX", name: "American Express" },
                { type: "NS", name: "Discover" },
                { type: "JC", name: "JCB" },
            ],
            postalCode: {
                label: "Zip Code",
                visible: true,
                required: true
            },
            onSuccess: async (form, data) => {
                if (this.checkSvc.checkDTO) {
                    this.checkSvc.checkDTO.payments = []
                    const payment: CheckPaymentDTO = {
                        amount: amount - tip,
                        tip: tip,
                        token: data.i4go_uniqueid,
                        displayName: data.otn.cardnumber
                    }
                    this.checkSvc.checkDTO.payments.push(payment)
                }

                console.log('i4Go', data)
                const paymentMethod = new PaymentMethod({
                    token: data.i4go_uniqueid,
                    name: data.otn.cardnumber,
                    zipcode: data.i4go_postalcode,
                    type: PaymentMethodType.CREDIT,
                    cardType: data.i4go_cardtype,
                    cardExpiration: `${data.i4go_expirationmonth}/${data.i4go_expirationyear}`,
                    amount: amount,
                    tip: tip,
                    processor: CCProcessor.SHIFT4
                })
                this.newPaymentMethod.next(paymentMethod)
                this.newPaymentMethod.next(null)
            },
            onFailure: (form, data) => {
                console.error('onFailure', data);
            },
            onComplete: (form, data) => {
                console.log('onComplete', data);
            },
            onWalletInit: (wallet, enabled, reason) => {
                console.log("Wallet is initialized");
            },
            onWalletEnabled: (wallet, enabled, reason) => {
                console.log("Wallet is enabled");
                if (wallet === "google-pay") {
                    this.walletEnabled.googlePay = true;
                }
                if (wallet === "apple-pay") {
                    this.walletEnabled.applePay = true;
                }
            },
        });
    }
}