<div class="search">
    <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
        <input type="text" pInputText [placeholder]="orderSvc.orderLink.searchPlaceholder" ngModel
            (ngModelChange)="searchValueChanged.next($event)" #target />
    </div>
</div>

<p-overlayPanel #op [styleClass]="'op-menu-item-search'" appendTo="body">
    <ng-template pTemplate>
        <p-listbox [options]="items" optionLabel="name" (onChange)="selectItem($event)"
            [group]="true" [listStyle]="{'max-height':'250px'}" emptyMessage=" ">
            <ng-template let-group pTemplate="group">
                <div class="flex group p-ai-center" (click)="selectMenuOption(group)">
                    <span>{{group.label}}</span>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div class="image">
                    <img *ngIf="item.imageS3" [src]="item.imageS3 | s3URL | async" />
                </div>
                <div class="content">
                    {{item.name}}
                </div>
            </ng-template>
        </p-listbox>
    </ng-template>
</p-overlayPanel>