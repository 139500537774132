<ng-container *ngIf="order">
    <div *ngIf="gmapsSvc.gMapApiLoaded" class="map">
        <google-map height="400px" width="100%" [options]="gmapsSvc.gMapOptions" [center]="gmapsSvc.gMapCenter"
            [zoom]="gmapsSvc.gMapZoom">
            <map-directions-renderer *ngIf="gmapsSvc.gMapDirections" [directions]="gmapsSvc.gMapDirections">
            </map-directions-renderer>
            <map-marker *ngFor="let marker of gmapsSvc.gMapMarkers" [position]="marker.position" [label]="marker.label"
            [title]="marker.title" [options]="marker.options">
        </map-marker>
        </google-map>
    </div>

    <div class="section summary">
        <div class="flex" *ngIf="statusMessage">
            <div class="heading" style="width: 70%;">{{statusMessage}}</div>
            <div class="ml-auto" style="width: 30%;"></div>
        </div>
        <div class="flex" *ngIf="statusDetails">
            <div class="description" style="width: 70%;">{{statusDetails}}</div>
            <div class="description ml-auto" style="width: 30%;"></div>
        </div>

        <div class="sub-section">
            <div class="sub-heading">
                <div *ngIf="order.orderType?.serviceType === 'DELIVERY' || order.serviceType === 'DELIVERY'">Pickup Info</div>
                <div *ngIf="order.orderType?.serviceType === 'PICKUP' || order.serviceType === 'PICKUP'">Delivery Info</div>
            </div>

            <div class="flex">
                <div class="label" style="width: 30%;">
                    Pickup At
                </div>
                <div class="text flex flex-column" style="width: 70%;">
                    <div><span class="bold">{{order.orderLink.originName || order.originDetails.name}}</span></div>
                    <div>{{order.originDetails.street}}</div>
                    <div>{{order.originDetails.city}}, {{order.originDetails.state}}
                        {{order.originDetails.zip}}
                    </div>
                    <div>{{order.orderLink.originPhone || order.originDetails.phone | phone:'US'}}</div>
                </div>
            </div>

            <div class="flex" *ngIf="order.orderType?.serviceType === 'DELIVERY' || order.serviceType === 'DELIVERY'">
                <div class="label" style="width: 30%;">
                    Dropoff At
                </div>
                <div class="text flex flex-column" style="width: 70%;" *ngIf="order.customerDetails.address?.street">
                    <div><span class="bold">{{order.customerDetails.firstName}}
                            {{order.customerDetails.lastName}}</span></div>
                    <div>{{order.customerDetails.address.street}}</div>
                    <div>{{order.customerDetails.address.city}}, {{order.customerDetails.address.state}} {{order.customerDetails.address.zip}}
                    </div>
                    <div>{{order.customerDetails.phone | phone:'US'}}</div>
                </div>
            </div>
            <div class="flex" *ngIf="order.orderType?.serviceType === 'PICKUP' || order.serviceType === 'PICKUP'">
                <div class="label" style="width: 30%;">
                    Instructions
                </div>
                <div class="text flex flex-column" style="width: 70%;">
                </div>
            </div>
        </div>

        <div class="divider"></div>

        <div class="sub-section details">
            <div class="sub-heading">
                Order Details
            </div>

            <div class="flex">
                <div class="label" style="width: 30%;">
                    Reference
                </div>
                <div class="text flex flex-column" style="width: 70%;">
                    <div>Order# {{order.orderNumber}}</div>
                    <div>Submitted: {{order.submittedAt | amLocal | amDateFormat: 'LLL'}}</div>
                    <div>Due: {{order.dueTime | amLocal | amDateFormat: 'LLL'}}</div>
                    <div>{{order.serviceType}}</div>
                </div>
            </div>

            <div class="items">
                <app-check [check]="order.checks.items[0]" [mode]="mode"></app-check>
            </div>

            <a href="mailto:{{order.orderLink.originEmail || order.originDetails.email}}" class="w-full">
                <button class="btn-primary mt-3 w-full" >Contact Support</button>
            </a>

            <button *ngIf="mode === 'ORDER_SUMMARY'" class="btn-primary mt-3"
                (click)="orderSvc.showOrderProgress = false">Start New Order</button>
        </div>
    </div>

    <div class="bottom">
        <app-sidebar-footer [origin]="order.orderLink.location || order.originDetails"></app-sidebar-footer>
    </div>
</ng-container>