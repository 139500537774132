
<div *ngIf="app.isDesktop" class="header"
[style.background-image]="'linear-gradient(to right, rgba(0,0,0,.80) 15%, transparent 65%), url(' + menuSvc.bannerImage + ')'">
<div class="name">{{menuSvc.menu.name}}</div>
    <div class="description">{{menuSvc.menu.description}}</div>
    <div class="flex align-items-center">
        <p-badge *ngIf="menuSvc.menuOptionSelected.open" value="Open Now" severity="info"></p-badge>
        <p-badge *ngIf="!menuSvc.menuOptionSelected.open" value="Not Accepting Orders" severity="danger"></p-badge>
        <div class="hours">{{menuSvc.menuOptionSelected.timeBlocks}}</div>
    </div>
</div>

<div *ngIf="app.isMobile" class="header mobile"
    [style.background-image]="'url(' + menuSvc.bannerImageMobile + ')'">
    <img [src]="menuSvc.menuOptionSelected.logoS3 | s3URL | async">
    <div class="name">{{menuSvc.menu.name}}</div>
    <div class="description">{{menuSvc.menu.description}}</div>
    <div class="flex align-items-center">
        <p-badge *ngIf="menuSvc.menuOptionSelected.open" value="Open Now" severity="info"></p-badge>
        <p-badge *ngIf="!menuSvc.menuOptionSelected.open" value="Not Accepting Orders" severity="danger"></p-badge>
        <div class="hours">{{menuSvc.menuOptionSelected.timeBlocks}}</div>
    </div>
</div>

<app-menu-scroller></app-menu-scroller>

<div class="categories" [ngClass]="{'mobile': app.isMobile}">
    <div class="left">
        <fa-icon [icon]="['fal', 'list']" size="2x" style="cursor: pointer;" (click)="opMenuCategories.show($event)"
            *ngIf="!showOpMenuCategories"></fa-icon>
        <fa-icon [icon]="['fal', 'times']" size="2x" style="cursor: pointer;" (click)="opMenuCategories.hide()"
            *ngIf="showOpMenuCategories"></fa-icon>
    </div>
    <div class="center" *ngIf="app.isDesktop">
        <app-menu-category-scroller #categoryScroller1 [options]="menuSvc.menuCategoryOptions"
            (onSelect)="onClickCategory($event?.value.menuCategory.id)">
        </app-menu-category-scroller>
    </div>
    <div class="right" *ngIf="app.isDesktop">
        <img class="icon" *ngIf="layout === 'list'" (click)="layout = 'grid'" src="../../../assets/icons/icon-list.png">
        <img class="icon" *ngIf="layout === 'grid'" (click)="layout = 'list'" src="../../../assets/icons/icon-grid.png">
    </div>
</div>

<p-overlayPanel #opMenuCategories (onShow)="showOpMenuCategories = true" (onHide)="showOpMenuCategories = false"
    [styleClass]="'op-menu-categories'">
    <ng-template pTemplate>
        <app-menu-category-scroller #categoryScroller2 [options]="menuSvc.menuCategoryOptions"
            (onSelect)="onClickCategory($event?.value.menuCategory.id); categoryScroller1.moveTo($event?.idx)"
            layout="vertical" style="width: 375px;" [menu]="menuSvc.menu">
        </app-menu-category-scroller>
    </ng-template>
</p-overlayPanel>

<div class="menu" [ngClass]="{'mobile': app.isMobile}">
    <div #categoryElements class="category" *ngFor="let category of menuSvc.menuCategoryOptions"
        [id]="category.menuCategory.id">
        <div class="name">{{category.menuCategory.displayName || category.menuCategory.name}}</div>

        <p-dataView [value]="category.items" [layout]="layout">
            <ng-template let-item pTemplate="listItem">
                <div class="list-item col-12 md:col-6 lg:col-4"
                    (click)="menuSvc.selectMenuItem(item.menuItem.id, item.venueID, item.menuID, category.menuCategory.priceTypeID)">
                    <div class="inner">
                        <div style="flex: 1">
                            <div class="list-item-content">
                                <div class="list-item-name">{{item.menuItem.name}}</div>
                                <div class="list-item-description fade">{{item.menuItem.description}}</div>
                                <div class="list-item-price">{{item.menuItem.price | currency}}</div>
                            </div>
                        </div>
                        <div class="image">
                            <img [src]="item.menuItem.imageS3  | s3URL | async" *ngIf="item.menuItem.imageS3" />
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template let-item pTemplate="gridItem">
                <div class="grid-item col-6 md:col-4 lg:col-3" [ngClass]="{'mobile': app.isMobile}"
                    (click)="menuSvc.selectMenuItem(item.menuItem.id, item.venueID, item.menuID, category.menuCategory.priceTypeID)">
                    <div class="inner">
                        <div class="image">
                            <img [src]="item.menuItem.imageS3  | s3URL | async" *ngIf="item.menuItem.imageS3" />
                        </div>
                        <div class="grid-item-content">
                            <div style="flex: 1">
                                <div class="grid-item-name">{{item.menuItem.name}}</div>
                                <div class="grid-item-description fade">{{item.menuItem.description}}</div>
                            </div>
                            <div>
                                <div class="grid-item-price">{{item.menuItem.price | currency}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
</div>

<app-footer style="margin-top: -5px;"></app-footer>