import { Check, FeeType, TaxTypeType } from "src/app/services/API.service"

export class CheckDTO {
    id?: string
    tenantID: string
    items: CheckItemDTO[] = []
    payments: CheckPaymentDTO[] = []
    transactions: CheckTransactionDTO[] = []
    totals: CheckTotalsDTO
    readyEstimate: number
    fees: CheckFeeDTO[]
    valid: boolean

    constructor(init?: Partial<CheckDTO>) {
        Object.assign(this, init)
    }

    // static fromDataStore(entity: Check) {
    //     const dto = new this()
    //     Object.keys(entity).forEach(k => {
    //         if (!(entity[k] instanceof Array)) {
    //             dto[k] = entity[k]
    //         }
    //     })

    //     return dto
    // }

    static fromQuery(query: Check) {
        const dto = new this()
        Object.keys(query).forEach(k => {
            if (!(query[k] instanceof Array)) {
                dto[k] = query[k]
            }
        })

        dto.items = query.items?.items.map(i => CheckItemDTO.fromQuery(i))
        dto.payments = query.payments?.items.map(i => new CheckPaymentDTO(i))
        dto.transactions = query.transactions?.items.map(i => new CheckTransactionDTO(i))
        dto.fees = query.fees?.map(i => new CheckFeeDTO(i))

        return dto
    }
}

export class CheckFeeDTO {
    id: string
    name: string
    type: FeeType
    amount: number
    inclusive: boolean
    taxed: boolean

    constructor(init?: Partial<CheckFeeDTO>) {
        Object.assign(this, init)
    }
}

export class CheckTotalsDTO {
    subTotal: number
    taxTotal: number
    discountTotal: number
    feeTotal: number
    total: number
    paymentTotal: number
    remainingBalance: number

    constructor(init?: Partial<CheckTotalsDTO>) {
        Object.assign(this, init)
    }
}

export class CheckItemDTO {
    id?: string
    name: string
    price: number
    priceWithModifiers: number
    priceTypeID: string
    quantity: number
    orderLinkID: string
    checkID: string
    venueID: string
    venueName: string
    menuID: string
    menuItemID: string
    imageS3: string
    utensils?: boolean
    alcohol?: boolean
    instructions?: string
    modifiers: CheckItemModifierDTO[] = []
    unavailable?: boolean

    constructor(init?: Partial<CheckItemDTO>) {
        Object.assign(this, init)
    }

    static fromQuery(query: any) {
        const dto = new this()
        Object.keys(query).forEach(k => {
            if (!(query[k] instanceof Array)) {
                dto[k] = query[k]
            }
        })

        dto.modifiers = query.modifiers?.map(i => CheckItemModifierDTO.fromQuery(i))
        dto.priceWithModifiers = getCheckItemPrice(dto)

        return dto
    }

    // async toCheckItem(checkID: string, venueID: string, menuItemID: string) {
    //     const obj: any = { ...this }
    //     obj.check = await DataStore.query(Check, checkID)
    //     obj.venue = await DataStore.query(Venue, venueID)
    //     obj.menuItem = await DataStore.query(MenuItem, menuItemID)
    //     return new CheckItem(obj)
    // }
}

export class CheckItemModifierDTO {
    id?: string
    name: string
    price: number
    quantity: number
    orderLinkID: string
    checkID: string
    venueID: string
    menuItemID: string
    modifierGroupID: string
    modifiers: CheckItemDTO[] = []

    constructor(init?: Partial<CheckItemModifierDTO>) {
        Object.assign(this, init)
    }

    static fromQuery(query: any) {
        const dto = new this()
        Object.keys(query).forEach(k => {
            if (!(query[k] instanceof Array)) {
                dto[k] = query[k]
            }
        })

        dto.modifiers = query.modifiers?.map(i => CheckItemModifierDTO.fromQuery(i))

        return dto
    }

    // async toCheckItemModifier(checkItemID: string) {
    //     const obj: any = { ...this }
    //     obj.checkItem = await DataStore.query(CheckItem, checkItemID)
    //     return new CheckItemModifier(obj)
    // }
}

function getCheckItemPrice(item: CheckItemDTO | CheckItemModifierDTO) {
    let price = item.price || 0
    if (item.modifiers) {
        item.modifiers.forEach(modifier => {
            const modifierPrice = getCheckItemPrice(modifier)
            price = price + modifierPrice
        })
    }
    price = price * item.quantity
    return price
}

export class CheckPaymentDTO {
    amount: number
    tip: number
    token: string
    displayName: string
    cardPresent?: boolean

    constructor(init?: Partial<CheckPaymentDTO>) {
        Object.assign(this, init)
    }
}

export class CheckTransactionDTO {
    amount: number

    constructor(init?: Partial<CheckTransactionDTO>) {
        Object.assign(this, init)
    }
}
