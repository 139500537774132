import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem, OrderLink } from 'src/app/services/API.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-popular-menu-items',
  templateUrl: './popular-menu-items.component.html',
  styleUrls: ['./popular-menu-items.component.scss']
})
export class PopularMenuItemsComponent implements OnInit, OnChanges {
  items: Item[] = []
  @Input() orderLink: Partial<OrderLink>

  constructor(private menuSvc: MenuService) { }

  getMenuItemPrice(item: MenuItem, priceTypeID?: string) {
    let price = 0
    let prices = item.prices.items

    if (prices.length > 0) {
      price = prices.find(p => !p.priceTypeID)?.price || 0
      if (priceTypeID) {
        price = prices.find(p => p.priceTypeID === priceTypeID)?.price || price
      }
    }

    return price
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderLink?.currentValue) {
      const menus: any[] = this.orderLink.menus.items.map(i => i.menu).filter(m => m.active)
      if (menus[0] && menus[0].sortOrder >= 0) {
        menus.sort((a, b) => a.sortOrder - b.sortOrder)
      } else {
        menus.sort((a, b) => a.name.localeCompare(b.name))
      }

      for (let menu of menus) {
        const categories = menu.categories.items.map(i => i.menuCategory).filter(c => c.active)
        for (let category of categories) {
          const items = category.menuItems.items
          if (items[0] && items[0].sortOrder >= 0) {
              items.sort((a, b) => a.sortOrder - b.sortOrder)
          } else {
              items.sort((a, b) => a.menuItem.name.localeCompare(b.menuItem.name))
          }

          const menuItems = items.map(i => i.menuItem).filter(i => i.active)
          if (menuItems[0] && menuItems[0].sortOrder >= 0) {
            menuItems.sort((a, b) => a.sortOrder - b.sortOrder)
          } else {
            menuItems.sort((a, b) => a.name.localeCompare(b.name))
          }

          for (let menuItem of menuItems) {
            if (menuItem.popular && !this.items.some(i => i.menuItemID === menuItem.id)) {
              this.items.push(new Item({
                menuItemID: menuItem.id,
                venueID: menu.venueID,
                menuID: menu.id,
                menuName: menu.displayName || menu.name,
                priceTypeID: category.priceTypeID,
                imageS3: menuItem.imageS3,
                name: menuItem.name,
                price: this.getMenuItemPrice(menuItem, category.priceTypeID)
              }))
            }
          }
        }
      }
    }
  }

  ngOnInit(): void {
  }

  onClickItem(item) {
    this.menuSvc.selectMenuItem(item.menuItemID, item.venueID, item.menuID, item.priceTypeID)
  }
}

class Item {
  menuItemID: string
  venueID: string
  menuID: string
  menuName: string
  priceTypeID: string
  imageS3: string
  name: string
  price: number

  constructor(init?: Partial<Item>) {
    Object.assign(this, init)
  }
}