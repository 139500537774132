import { Component, Input, OnInit } from '@angular/core';
import { OrderMode, ServiceType } from 'src/app/services/API.service';
import { CheckService } from 'src/app/services/check.service';
import { GMapsService } from 'src/app/services/gmaps.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CheckDTO } from 'src/models/DTO/check.dto';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit {
  @Input() checkDTO: CheckDTO
  @Input() mode: string = 'ORDER_EDIT'
  @Input() orderMode: OrderMode
  orderModeKiosk = OrderMode.KIOSK
  orderModeOnlineOrder = OrderMode.ONLINE_ORDER

  constructor(
    public orderSvc: OrderService,
    public checkSvc: CheckService,
    private paymentSvc: PaymentService,
    private gmapsSvc: GMapsService) { }

    async checkout() {
      this.orderSvc.showCheck = false
      this.orderSvc.showCheckout = true
      if (!this.orderSvc.tipOption) {
        this.orderSvc.tipOption = 0.15
        this.orderSvc.selectTip(0.15)
      }
      if (this.paymentSvc.paymentMethodSelected) {
        this.paymentSvc.selectPaymentMethod(this.paymentSvc.paymentMethodSelected, this.orderSvc.total, this.orderSvc.tip)
      }
  
      await this.orderSvc.getDeliveryFee()
  
      if (this.orderMode !== OrderMode.KIOSK) {
        setTimeout(async () => {
          if (this.orderSvc.orderType?.serviceType === ServiceType.PICKUP) {
            await this.gmapsSvc.gMapsMarkOrigin({
              name: this.orderSvc.orderLink.originName || this.orderSvc.orderLink.location.name,
              street: this.orderSvc.orderLink.location.street,
              city: this.orderSvc.orderLink.location.city,
              state: this.orderSvc.orderLink.location.state,
              zip: this.orderSvc.orderLink.location.zip,
            })
          }
          if (this.orderSvc.orderType?.serviceType === ServiceType.DELIVERY && this.orderSvc.customer.address) {
            await this.gmapsSvc.gMapsDirections(
              {
                street: this.orderSvc.orderLink.location.street,
                city: this.orderSvc.orderLink.location.city,
                state: this.orderSvc.orderLink.location.state,
                zip: this.orderSvc.orderLink.location.zip,
              },
              this.orderSvc.customer.address,
              this.orderSvc.orderLink.location,
              this.orderSvc.customer
            )
          }
        }, 1000)
      }
    }
  clickBack() {
    this.orderSvc.showCheck = false
  }

  ngOnInit(): void {
  }

}
