import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderLink } from 'src/app/services/API.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-popular-menus',
  templateUrl: './popular-menus.component.html',
  styleUrls: ['./popular-menus.component.scss']
})
export class PopularMenusComponent implements OnInit, OnChanges {
  items: Item[] = []
  items2: Item[] = []
  @Input() orderLink: Partial<OrderLink>

  constructor(private menuSvc: MenuService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderLink?.currentValue) {
      const options = this.menuSvc.menuEntryOptions.filter(m => m.active && m.popular)

      let i = 0
      for (let option of options) {
        if (!this.items.some(i => i.menuID === option.menuID)) {
          this.items.push(new Item({
            menuID: option.menuID,
            venueID: option.venueID,
            name: option.name,
            imageS3: option.imageS3Featured[0]
          }))
          if (i >= 2) {
            this.items2.push(new Item({
              menuID: option.menuID,
              venueID: option.venueID,
              name: option.name,
              imageS3: option.imageS3Featured[0]
            }))
          }
          i++
        }
      }
    }
  }

  ngOnInit(): void {
  }

  onClickItem(item) {
    this.menuSvc.selectMenu(item.menuID)
  }
}

class Item {
  venueID: string
  menuID: string
  name: string
  imageS3: string
  sortOrder: number

  constructor(init?: Partial<Item>) {
    Object.assign(this, init)
  }
}