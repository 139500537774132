<div class="top">
    <div class="back" (click)="orderSvc.showCheckout=false">
        <fa-icon [icon]="['fas', 'arrow-left']" class="mr-2"></fa-icon>
        <div>
            BACK
        </div>
    </div>
    <div class="business-name">{{orderSvc.orderLink?.originName}}</div>
</div>

<div class="order-types">
    <!-- <button class="btn-toggle mr-1" *ngFor="let orderType of orderSvc.orderTypes"
        [class.active]="orderSvc.orderTypeID === orderType.id"
        (click)="orderSvc.selectOrderType(orderType)">{{orderType.displayName || orderType.name}}</button> -->
    <p-selectButton [options]="orderSvc.orderTypes" [(ngModel)]="orderSvc.orderType"
        (onChange)="orderSvc.selectOrderType($event.value)" optionLabel="name"></p-selectButton>
</div>

<div class="estimate" *ngIf="orderSvc.timeEstimate">
    <span *ngIf="orderSvc.orderType?.serviceType === 'PICKUP'">Pickup </span><span *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'">Delivery </span>Estimate {{orderSvc.timeEstimate}} MIN
</div>

<div class="timing">
    <div class="field-radiobutton" *ngIf="orderSvc.orderType?.asap">
        <p-radioButton name="timing" value="ASAP" label="ASAP" [(ngModel)]="orderSvc.orderQueueMode" (onClick)="orderSvc.selectOrderQueueMode(orderSvc.orderQueueMode)"></p-radioButton>
    </div>
    <div class="field-radiobutton" *ngIf="orderSvc.orderType?.schedule && orderSvc.orderQueueMode === 'ASAP'">
        <p-radioButton name="timing" value="SCHEDULE" label="Schedule for later" [(ngModel)]="orderSvc.orderQueueMode" (onClick)="orderSvc.selectOrderQueueMode(orderSvc.orderQueueMode)"></p-radioButton>
    </div>
    <div class="field-radiobutton" *ngIf="orderSvc.orderType?.schedule && orderSvc.orderQueueMode === 'SCHEDULE'">
        <p-radioButton name="timing" value="SCHEDULE" label="{{orderSvc.dueTime | amLocal | amDateFormat: 'MMMM D - LT'}}" [(ngModel)]="orderSvc.orderQueueMode" (onClick)="orderSvc.selectOrderQueueMode(orderSvc.orderQueueMode)"></p-radioButton>
    </div>
</div>

<div class="divider"></div>

<div class="delivery-type" *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'">
    <div class="heading">
        Address
    </div>
    <div *ngIf="gmapsSvc.gMapApiLoaded" class="map">
        <google-map height="300px" width="100%" [options]="gmapsSvc.gMapOptions" [center]="gmapsSvc.gMapCenter"
            [zoom]="gmapsSvc.gMapZoom">
            <map-directions-renderer *ngIf="gmapsSvc.gMapDirections" [directions]="gmapsSvc.gMapDirections">
            </map-directions-renderer>
            <map-marker *ngFor="let marker of gmapsSvc.gMapMarkers" [position]="marker.position" [label]="marker.label"
                [title]="marker.title" [options]="marker.options">
            </map-marker>
        </google-map>
    </div>
    <div class="flex align-content-center" style="width: 100%;">
        <div class="mr-3">
            <fa-icon [icon]="['far', 'map-marker-alt']" size="lg"></fa-icon>
        </div>
        <div class="address" *ngIf="orderSvc.customer.address?.street">
            <div class="street">
                {{orderSvc.customer.address.street}}
            </div>
            <div class="city-state-zip">
                {{orderSvc.customer.address.city}}, {{orderSvc.customer.address.state}} {{orderSvc.customer.address.zip}}
            </div>
        </div>
        <button class="btn-confirm ml-auto" (click)="editAddress()">Edit</button>
    </div>
</div>
<div class="delivery-type" *ngIf="orderSvc.orderType?.serviceType === 'PICKUP'">
    <div class="heading">
        Store Address
    </div>
    <div *ngIf="gmapsSvc.gMapApiLoaded" class="map">
        <google-map height="300px" width="100%" [options]="gmapsSvc.gMapOptions" [center]="gmapsSvc.gMapCenter"
            [zoom]="gmapsSvc.gMapZoom">
            <map-marker *ngFor="let marker of gmapsSvc.gMapMarkers" [position]="marker.position" [label]="marker.label"
                [title]="marker.title" [options]="marker.options">
            </map-marker>
        </google-map>
    </div>
    <div class="flex align-content-center" style="width: 100%;">
        <div class="mr-3">
            <fa-icon [icon]="['far', 'map-marker-alt']" size="lg"></fa-icon>
        </div>
        <div class="address">
            <div class="street">
                {{orderSvc.orderLink?.location.street}}
            </div>
            <div class="city-state-zip">
                {{orderSvc.orderLink?.location.city}}, {{orderSvc.orderLink?.location.state}}
                {{orderSvc.orderLink?.location.zip}}
            </div>
        </div>
    </div>
</div>

<div class="divider"></div>

<div class="contact-info">
    <div class="heading">
        Contact Info
    </div>
    <form ngForm #fContact="ngForm">
        <div class="p-fluid">
            <div class="field">
                <label for="firstName">First Name*</label>
                <input id="firstName" name="firstName" type="text" pInputText placeholder="Name"
                    [(ngModel)]="orderSvc.customer.firstName" [required]="true">
            </div>
            <div class="field">
                <label for="lastName">Last Name*</label>
                <input id="lastName" name="lastName" type="text" pInputText placeholder="Name"
                    [(ngModel)]="orderSvc.customer.lastName" [required]="true">
            </div>
            <div class="field">
                <label for="phone">Phone Number*</label>
                <p-inputMask mask="999-999-9999" name="phone" [(ngModel)]="orderSvc.customer.phone"
                    placeholder="###-###-####" [required]="true">
                </p-inputMask>
            </div>
            <div class="field">
                <label for="email">Email</label>
                <input id="email" type="email" name="email" email pInputText placeholder="Email"
                    [(ngModel)]="orderSvc.customer.email">
            </div>
            <div class="field" *ngIf="orderSvc.customer.dob">
                <label for="email">Date of Birth</label>
                <input id="email" type="text" name="dob" pInputText [disabled]="true" [(ngModel)]="orderSvc.customer.dob">
            </div>
        </div>
    </form>
</div>

<div class="divider"></div>

<div class="payment">
    <div class="heading">
        Payment
    </div>
    <div class="tips">
        <div class="heading">
            <div>
                Add a Tip
            </div>
            <div class="amount">
                {{orderSvc.tip | currency}}
            </div>
        </div>
        <div class="description">
            As a token of appreciation to our staff. Add a tip to say thanks!
        </div>
        <p-selectButton [options]="orderSvc.tipOptions" [(ngModel)]="orderSvc.tipOption"
            (onChange)="orderSvc.selectTip($event.value)"></p-selectButton>
        <p-inputNumber *ngIf="orderSvc.tipOption === 'other'" [(ngModel)]="orderSvc.tipCustom" mode="currency"
            currency="USD" locale="en-US" placeholder="Tip" (ngModelChange)="orderSvc.selectTip('other')">
        </p-inputNumber>
    </div>

    <div>
        <!-- <button class="pay-button pay-hidden apple-pay-button"></button>
        <button class="pay-button pay-hidden google-pay-button"></button> -->
        <!-- <div id="i4goFrame">
        </div> -->
    </div>

    <div class="payment-methods">
        <div class="heading">
            Payment Methods
        </div>
        <div class="items" *ngIf="paymentSvc.paymentMethods?.length > 0">
            <p-radioButton class='item' *ngFor="let paymentMethod of paymentSvc.paymentMethods" name="paymentMethod" [value]="paymentMethod.token"
            [label]="paymentMethod.name" [(ngModel)]="paymentSvc.paymentMethodSelected" (onClick)="paymentSvc.selectPaymentMethod(paymentSvc.paymentMethodSelected, orderSvc.total, orderSvc.tip)"></p-radioButton>
        </div>
        <button (click)="paymentSvc.openCCForm(orderSvc.orderLink.id, orderSvc.order.id, orderSvc.total, orderSvc.customer)" class="btn-primary"
            style="width: 100%; margin-bottom: 1.5rem;" [ladda]="paymentSvc.loading" [disabled]="fContact.invalid">Add
            Credit
            Card</button>
    </div>
    </div>

<div class="divider"></div>

<div class="order">
    <div class="heading">
        Your Order Summary
    </div>
    <app-check [checkDTO]="checkSvc.checkDTO" mode="ORDER_SUMMARY"></app-check>
</div>

<div class="divider"></div>

<div class="totals">
    <div class="row subtotal">
        <div class="label">
            Subtotal
        </div>
        <div class="value">
            {{checkSvc.checkDTO?.totals?.subTotal | currency}}
        </div>
    </div>
 <!-- <div class="row delivery-fee" *ngIf="orderSvc.orderType?.serviceType === 'DELIVERY'">
        <div class="label">
            Delivery Fee
        </div>
        <div class="value">
            {{orderSvc.deliveryFee | currency}}
        </div>
    </div> -->
    <!-- <div class="row delivery-fee" *ngIf="checkSvc.checkDTO?.totals?.feeTotal">
        <div class="label">
            Other Fees
        </div>
        <div class="value">
            {{checkSvc.checkDTO?.totals.feeTotal | currency}}
        </div>
    </div>
    <div class="row tax">
        <div class="label">
            Taxes
        </div>
        <div class="value">
            {{checkSvc.checkDTO?.totals?.taxTotal | currency}}
        </div>
    </div> -->
    <div class="row tax" *ngIf="orderSvc.taxAndFeeTotal > 0">
        <div class="label" style="cursor: pointer" (click)="opTaxes.toggle($event)">
            Taxes & Fees <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
        </div>
        <p-overlayPanel #opTaxes>
            <ng-template pTemplate>
                <div class="flex flex-column white-space-nowrap">
                    <div class="grid">
                        <div class="col">
                            Taxes
                        </div>
                        <div class="col text-right">
                            {{checkSvc.checkDTO?.totals.taxTotal | currency}}
                        </div>
                    </div>
                    <ng-container *ngFor="let fee of orderSvc.fees">
                        <div *ngIf="!fee.inclusive" class="grid">
                            <div class="col">
                                {{fee.name}}
                            </div>
                            <div class="col text-right">
                                {{fee.amount | currency}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let fee of checkSvc.checkDTO?.fees">
                        <div *ngIf="!fee.inclusive" class="grid">
                            <div class="col">
                                {{fee.name}}
                            </div>
                            <div class="col text-right">
                                {{fee.amount | currency}}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </p-overlayPanel>
        <div class="value">
            {{orderSvc.taxAndFeeTotal | currency}}
        </div>
    </div>
    <div class="row tip" *ngIf="orderSvc.tip">
        <div class="label">
            Tips
        </div>
        <div class="value">
            {{orderSvc.tip | currency}}
        </div>
    </div>
</div>

<div class="footer">
    <div class="row total">
        <div class="label">
            Total
        </div>
        <div class="value">
            {{orderSvc.total | currency}}
        </div>
    </div>
    <button class="btn-cta"
        [disabled]="fContact.invalid || (orderSvc.orderType?.serviceType === 'DELIVERY' && !orderSvc.customer.address?.street) || checkSvc.checkDTO?.payments?.length === 0"
        (click)="submitOrder()" [ladda]="orderSvc.working">Place Order</button>
</div>

<!-- <div class="bottom">
    <app-sidebar-footer></app-sidebar-footer>
</div> -->