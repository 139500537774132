import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as moment from "moment"

@Component({
  selector: 'app-datetime-selector',
  templateUrl: './datetime-selector.component.html',
  styleUrls: ['./datetime-selector.component.scss']
})
export class DatetimeSelectorComponent implements OnInit {
  minDate: Date
  showTime: boolean
  stepMinute: number
  value: Date

  constructor(private dConfig: DynamicDialogConfig, private dRef: DynamicDialogRef) { }

  getNow() {
    const start = moment()
    const remainder = 5 - (start.minute() % 5)
    const now = moment(start).add(remainder, "minutes").utc().toDate()
    return now
  }

  ngOnInit(): void {
    this.value = this.dConfig.data.value || moment().toDate()
    this.stepMinute = this.dConfig.data.stepMinute || 5
    this.minDate = this.dConfig.data.minDate || this.getNow()
    this.showTime = this.dConfig.data.showTime === false ? false : true

    if (this.dConfig.data.dob) {
      this.showTime = false
      this.minDate = null
    }
  }

  save() {
    this.dRef.close(this.value)
  }

}
