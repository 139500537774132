import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-menu-category-scroller',
  templateUrl: './menu-category-scroller.component.html',
  styleUrls: ['./menu-category-scroller.component.scss']
})
export class MenuCategoryScrollerComponent implements OnInit {
  @ViewChild('ds') ds: DragScrollComponent
  @Input() layout = 'horizontal'
  @Input() menu
  @Input() options = []
  @Output() onSelect = new EventEmitter

  constructor(public menuSvc: MenuService) { }

  moveTo(idx: number) {
    this.ds?.moveTo(idx);
  }

  ngOnInit(): void {
    if (!this.menuSvc.activeCategoryOption) {
      this.select(this.options[0]?.menuCategory.id, false)
    }
  }

  select(id: string, emit = true) {
    if (!this.menuSvc.changingCategory) {
      if (id !== this.menuSvc.activeCategoryOption?.menuCategory.id) {
        this.menuSvc.changingCategory = true
        this.menuSvc.activeCategoryOption = this.options.find(o => o.menuCategory.id === id)
        const idx = this.options.findIndex(o => o.menuCategory.id === id)
        this.moveTo(idx);
        emit && this.onSelect.next({ value: this.menuSvc.activeCategoryOption, idx: idx })
        setTimeout(() => {
          this.menuSvc.changingCategory = false
        }, 500);
      }
    }
  }
}
