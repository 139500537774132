import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showDoNotSell: boolean
  showNoticeAtCollection: boolean
  showPrivacyPolicy: boolean
  showTos: boolean

  constructor(public app: AppStateService, public orderSvc: OrderService) { }

  ngOnInit(): void {
  }

}
