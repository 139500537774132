import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Customer } from 'src/app/services/API.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderDetailsComponent } from 'src/app/shared/components/order-details/order-details.component';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  loading: boolean

  constructor(
    public customerSvc: CustomerService,
    private dRef: DynamicDialogRef,
    private dConfig: DynamicDialogConfig,
    private messageSvc: MessageService,
    private dialogSvc: DialogService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setHeader()
    });
  }

  ngOnInit(): void {
  }

  onChange(event) {
    if (event.index === 4) {
      this.signOut()
    }
  }

  selectCheck(row) {
    const orderID = row.data.orderID
    this.dialogSvc.open(OrderDetailsComponent, {
      header: `Order #${row.data.orderNumber}`,
      styleClass: 'dialog-order-details dialog-dynamic',
      data: { orderID: orderID }
  })
  }

  setHeader() {
    if (this.customerSvc.customer?.firstName) {
      this.dConfig.header = `Welcome Back, ${this.customerSvc.customer?.firstName} ${this.customerSvc.customer?.lastName}`
    } else {
      this.dConfig.header = `Welcome Back, ${this.customerSvc.customer?.email}`
    }
  }

  async updateCustomer(customer: Customer) {
    this.loading = true
    try {
      await this.customerSvc.updateCustomer(customer)
      this.loading = false
      this.setHeader()
    } catch (error) {
      console.error(error)
      this.loading = false
      this.messageSvc.add({ severity: 'error', detail: 'Error saving customer' })
    }
  }

  signOut() {
    this.customerSvc.signOut()
    this.dRef.close()
  }
}
