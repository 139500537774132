import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    subscriptions: { key: string, tag?: string, subscription: Subscription }[] = []

    constructor() {
    }

    addSubscription(params: { key: string, subscription: Subscription, tag?: string }) {
        console.log('addSubscription', params.tag, params.key)
        this.subscriptions.push({ key: params.key, subscription: params.subscription, tag: params.tag })
    }

    unsubscribe(params: { key?: string | string[], tag?: string | string[] }) {
        if (!params.key && !params.tag) {
            this.subscriptions.forEach(s => {
                console.log('unsubscribe', params.tag, params.key)
                s.subscription?.unsubscribe()
                this.subscriptions = []
            })
            return
        }

        const keys = (params.key instanceof Array) ? params.key : params.key && [params.key] || []
        keys.forEach(k => {
            const subscriptions = this.subscriptions.filter(s => s.key === k)
            subscriptions.forEach(s => {
                console.log('unsubscribe', s.tag, s.key)
                s.subscription?.unsubscribe()
                const idx = this.subscriptions.findIndex(i => i.key === s.key)
                this.subscriptions.splice(idx, 1)
            })
        })

        const tags = (params.tag instanceof Array) ? params.tag : params.tag && [params.tag] || []
        tags.forEach(t => {
            const subscriptions = this.subscriptions.filter(s => s.tag === t)
            subscriptions.forEach(s => {
                console.log('unsubscribe', s.tag, s.key)
                s.subscription?.unsubscribe()
                const idx = this.subscriptions.findIndex(i => i.key === s.key)
                this.subscriptions.splice(idx, 1)
            })
        })
    }
}

export interface Subscription {
    closed: boolean;
    unsubscribe(): void;
}