import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AppStateService } from 'src/app/services/app-state.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CheckService } from 'src/app/services/check.service';
import { AuthState, CustomerService } from 'src/app/services/customer.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { CustomerAddressComponent } from '../customer-address/customer-address.component';
import { CustomerLoginComponent } from '../customer-login/customer-login.component';

@Component({
  selector: 'app-order-top-bar',
  templateUrl: './order-top-bar.component.html',
  styleUrls: ['./order-top-bar.component.scss']
})
export class OrderTopBarComponent implements OnInit {
  showNotice = true

  constructor(
    public app: AppStateService,
    public menuSvc: MenuService,
    public orderSvc: OrderService,
    public checkSvc: CheckService,
    private dialogSvc: DialogService,
    public customerSvc: CustomerService) { }

  editAddress() {
    this.dialogSvc.open(CustomerAddressComponent, {
      header: 'Edit Address',
      styleClass: 'dialog-address dialog-dynamic'
    })

  }

  ngOnInit(): void {
  }

  openCustomerDetails() {
    if (this.customerSvc.authState.value === AuthState.SIGNEDOUT) {
      this.dialogSvc.open(CustomerLoginComponent, {
        styleClass: 'dialog-login dialog-dynamic',
        showHeader: false,
        dismissableMask: true
      })
    }

    if (this.customerSvc.authState.value === AuthState.SIGNEDIN) {
      this.customerSvc.showCustomerAccount = true
      // this.dialogSvc.open(CustomerDetailsComponent, {
      //   styleClass: 'dialog-customer dialog-dynamic',
      // })
    }
  }
}
