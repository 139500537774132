<div class="item-selected" *ngIf="menuSvc.menu && app.isDesktop">
    <img [src]="menuSvc.menuOptionSelected.logoS3 | s3URL | async">
</div>
<div class="scroller" [ngClass]="{'menu-selected': menuSvc.menu, 'mobile': app.isMobile}">
    <div *ngIf="app.isDesktop" class="left" (click)="ds.moveLeft()">
        <fa-icon class="icon" [icon]="['fal', menuSvc.menu ? 'chevron-left' : 'arrow-left']" [size]="menuSvc.menu ? 'sm' : 'lg'" [fixedWidth]="true"></fa-icon>
    </div>
    <div class="center masked">
        <drag-scroll #ds [scrollbar-hidden]="true" [scroll-x-wheel-enabled]="true" [drag-scroll-y-disabled]="true">
            <div *ngFor="let option of menuSvc.menuEntryOptions" class="item" (click)="selectMenuOption(option)"
                drag-scroll-item>
                <div class="badge">
                    <p-badge *ngIf="!option.open" [value]="'closed'" severity="danger"></p-badge>
                </div>
                <img [src]="option.logoS3 | s3URL | async">
                <div class="name" *ngIf="menuSvc.menu">
                    {{option.name}}
                </div>
            </div>
        </drag-scroll>
    </div>
    <div *ngIf="app.isDesktop" class="right" (click)="ds.moveRight()">
        <fa-icon class="icon" [icon]="['fal', menuSvc.menu ? 'chevron-right' : 'arrow-right']" [size]="menuSvc.menu ? 'sm' : 'lg'" [fixedWidth]="true"></fa-icon>
    </div>
</div>