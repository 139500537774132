import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import { OrderDetailsComponent } from 'src/app/shared/components/order-details/order-details.component';

@Component({
  selector: 'app-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrls: ['./customer-account.component.scss']
})
export class CustomerAccountComponent implements OnInit {
  activeNav: string = 'orders'

  constructor(
    public customerSvc: CustomerService, 
    public orderSvc: OrderService) { }

  ngOnInit(): void {
  }

  signOut() {
    this.customerSvc.signOut()
    this.customerSvc.showCustomerAccount = false
  }

}
