import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { I4GoService } from 'src/app/services/i4go.service';
import { OrderService } from 'src/app/services/order.service';

declare var $: any;

@Component({
  selector: 'app-i4go',
  templateUrl: './i4go.component.html',
  styleUrls: ['./i4go.component.scss']
})
export class I4goComponent implements OnInit, AfterViewInit {

  constructor(private i4go: I4GoService, private orderSvc: OrderService, private ref: DynamicDialogRef) {
    this.i4go.newPaymentMethod.subscribe(paymentMethod => {
      if (paymentMethod) {
        this.ref.close(paymentMethod);
      }
    })
  }

  ngAfterViewInit() {
    this.i4go.loadIFrame($("#i4goFrame"), document.location, this.orderSvc.total, this.orderSvc.tip)
  }

  ngOnInit(): void {
  }

}
