import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { APIService, MenuItem, SelectionType } from 'src/app/services/API.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { CheckItemDTO, CheckItemModifierDTO } from 'src/models/DTO/check.dto';
import { MenuItemDTO, ModifierGroupDTO } from 'src/models/DTO/menu.dto';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  api = new APIService()
  checkItemDTO: CheckItemDTO
  loading: boolean
  menuItemDTO = new MenuItemDTO()
  menuItemID: string
  menuName: string
  menuID: string
  menuOpen: boolean
  mode: string
  model: CheckItemDTO
  priceTypeID: string
  quantity = 1
  total = 0
  utensils: boolean
  valid: boolean
  venueID: string

  constructor(
    private spinner: NgxSpinnerService,
    public dRef: DynamicDialogRef,
    public dConfig: DynamicDialogConfig,
    private orderSvc: OrderService,
    private menuSvc: MenuService
  ) { }

  adjustModifier(group: ModifierGroupDTO, idx, quantity: number, menuItem: MenuItemDTO, parent = this.model) {
    group.modifiers[idx].quantity = quantity

    let count = 0
    group.modifiers.forEach(modifier => {
      count = count + modifier.quantity
    })

    setTimeout(() => {
      if (group.max > 0 && count > group.max) {
        group.modifiers[idx].quantity = group.modifiers[idx].quantity - 1
      } else {
        const existingIdx = parent.modifiers.findIndex(m => m.menuItemID === menuItem.id && m.modifierGroupID === group.id)
        if (existingIdx >= 0) {
          parent.modifiers.splice(existingIdx, 1)
        }
        const checkItemModifierDTO = new CheckItemModifierDTO({
          name: menuItem.name,
          price: menuItem.price,
          quantity: quantity,
          menuItemID: menuItem.id,
          modifierGroupID: group.id,
          venueID: this.venueID,
        })
        parent.modifiers.push(checkItemModifierDTO)
      }

      this.validate()
      this.updateCheckItem()
    });
  }

  getItemTotal(item: CheckItemDTO | CheckItemModifierDTO, root?: CheckItemDTO | CheckItemModifierDTO) {
    let price = item.price || 0
    let quantity = item.quantity || 0
    if (root) {
      quantity = quantity * root.quantity
    }

    root = root || item

    let total = price * quantity
    item.modifiers?.forEach(modifier => {
      total = total + this.getItemTotal(modifier, root)
    })

    return total
  }

  async init() {
    this.spinner.show('app-menu-item')

    this.checkItemDTO = this.dConfig.data.checkItemDTO
    if (this.checkItemDTO) {
      this.mode = 'EDIT'
      this.menuItemID = this.checkItemDTO.menuItemID
      this.venueID = this.checkItemDTO.venueID
      this.menuID = this.checkItemDTO.menuID
      this.priceTypeID = this.checkItemDTO.priceTypeID
      this.menuOpen = this.menuSvc.isOpen(this.menuID)
    } else {
      this.mode = 'ADD'
      this.menuItemID = this.dConfig.data.menuItemID
      this.venueID = this.dConfig.data.venueID
      this.menuID = this.dConfig.data.menuID
      this.priceTypeID = this.dConfig.data.priceTypeID
      this.menuOpen = this.menuSvc.isOpen(this.menuID)
    }
    this.menuName = this.menuSvc.menus.find(m => m.id === this.menuID)?.name

    const menuItem = await this.api.GetMenuItemRecursive(this.menuItemID)
    this.menuItemDTO = await MenuItemDTO.fromQuery(menuItem as MenuItem, this.priceTypeID)

    if (this.checkItemDTO) {
      this.quantity = this.checkItemDTO.quantity
      this.model = new CheckItemDTO({
        id: this.checkItemDTO.id,
        name: this.checkItemDTO.name,
        menuItemID: this.menuItemID,
        venueID: this.venueID,
        menuID: this.menuID,
        utensils: this.checkItemDTO.utensils,
        alcohol: this.checkItemDTO.alcohol,
        modifiers: []
      })
    } else {
      this.quantity = 1
      this.model = new CheckItemDTO({
        name: this.menuItemDTO.name,
        menuItemID: this.menuItemID,
        venueID: this.venueID,
        menuID: this.menuID,
        utensils: this.menuItemDTO.utensils,
        alcohol: this.menuItemDTO.alcohol
      })
    }

    this.validate()
    this.updateCheckItem()

    if (this.checkItemDTO) {
      this.checkItemDTO.modifiers?.forEach(modifier => {
        const group = this.menuItemDTO.modifierGroups.find(mg => mg.id === modifier.modifierGroupID)
        const menuItem = group.modifiers.find(mi => mi.id === modifier.menuItemID)
        const menuItemIdx = group.modifiers.findIndex(mi => mi.id === modifier.menuItemID)

        if (group.selectionType !== SelectionType.QUANTITY) {
          this.selectModifier(group, [], menuItem, this.model, true)
        } else {
          this.adjustModifier(group, menuItemIdx, modifier.quantity, menuItem)
        }
      })
    }

    this.spinner.hide('app-menu-item')

    // if (this.menuItemDTO.alcohol && !this.orderSvc.customer?.dob) {
    //   const ref = this.dialogSvc.open(DatetimeSelectorComponent, {
    //     header: 'Date of Birth',
    //     styleClass: 'dialog-dynamic',
    //     data: { dob: true }
    //   })
    //   ref.onClose.subscribe(async data => {
    //     if (data) {
    //       this.orderSvc.customer.dob = moment(data).format('YYYY-MM-DD')
    //     }
    //   })
    // }
  }

  ngOnInit(): void {
    this.init()
  }

  async onClickAdd() {
    // this.spinner.show('app-menu-item')
    this.loading = true
    try {
      await this.orderSvc.addCheckItem(this.model, this.venueID)
      this.dRef.close()
      this.orderSvc.showCheck = true
      this.loading = false
    } catch (error) {
      console.error(error)
      // this.spinner.hide('app-menu-item')
      this.loading = false
    }
  }

  selectModifier(group: ModifierGroupDTO, checkedItems: MenuItemDTO[], menuItem: MenuItemDTO, parent = this.model, override?: Boolean) {
    let checked
    if (checkedItems.some(i => i.id === menuItem.id)) checked = true
    if (override) checked = true
    if (checked) {
      let valid = true
      parent.modifiers = parent.modifiers || []
      group.selected = group.selected || []

      if (group.max === 1) {
        group.selected = []
        const idx = parent.modifiers.findIndex(m => m.modifierGroupID === group.id)
        if (idx >= 0) {
          parent.modifiers.splice(idx, 1)
        }
      }

      if (group.max > 1 && group.selected.length + 1 > group.max) {
        valid = false
        group.selected = [...group.selected]
      }

      if (valid) {
        const checkItemModifierDTO = new CheckItemModifierDTO({
          name: menuItem.name,
          price: menuItem.price,
          quantity: 1,
          menuItemID: menuItem.id,
          modifierGroupID: group.id,
          venueID: this.venueID
        })
        parent.modifiers.push(checkItemModifierDTO)
        group.selected.push(menuItem)
      }
    } else {
      const idx = parent.modifiers.findIndex(i => i.menuItemID === menuItem.id && i.modifierGroupID === group.id)
      parent.modifiers.splice(idx, 1)
      group.selected.splice(idx, 1)
    }

    this.validate()
    this.updateCheckItem()
  }

  updateCheckItem() {
    this.model.price = this.menuItemDTO.price
    this.model.quantity = this.quantity
    this.total = this.getItemTotal(this.model)
  }

  validate() {
    if (!this.orderSvc.orderLinkOpen) return false
    if (!this.menuSvc.isOpen(this.menuID)) return false

    this.valid = true
    this.menuItemDTO.modifierGroups?.forEach((g, idx) => {
      const selectedLength = g.selected?.length

      if (g.selectionType !== SelectionType.QUANTITY) {
        if (g.min > 0 && (!selectedLength || selectedLength < g.min)) {
          this.valid = false
        }
        if (g.max > 0 && selectedLength > g.max) {
          this.valid = false
        }
      }

      if (g.selectionType === SelectionType.QUANTITY) {
        let count = 0
        g.modifiers.forEach(m => {
          count = count + m.quantity
        })
        if (g.min > 0 && (count < g.min)) {
          this.valid = false
        }
        if (g.max > 0 && count > g.max) {
          this.valid = false
        }
      }

      if (g.modifiers) {
        //TODO: recursive group validation
      }
    })

    return this.valid
  }
}
