import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderStatus } from 'src/app/services/API.service';

@Component({
  selector: 'app-order-status-badge',
  templateUrl: './order-status-badge.component.html',
  styleUrls: ['./order-status-badge.component.scss']
})
export class OrderStatusBadgeComponent implements OnInit, OnChanges {
  @Input() detailed: boolean
  severity = 'info'
  @Input() status: string
  value: string

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.status) {
      case (OrderStatus.PENDING):
        this.value = 'Pending Submission'
        break;
      case (OrderStatus.QUEUED):
        this.value = 'Queued'
        break;
      case (OrderStatus.SUBMITTED):
        this.value = 'In Progress'
        break;
      case (OrderStatus.PREPARED):
        this.value = 'In Progress'
        if (this.detailed) this.value = 'Prepared'
        break;
      case (OrderStatus.COLLECTED):
        this.value = 'In Progress'
        if (this.detailed) this.value = 'Collected'
        break;
      case (OrderStatus.READY):
        this.value = 'Ready'
        break;
      case (OrderStatus.ENROUTE):
        this.value = 'Enroute'
        break;
      case (OrderStatus.DELIVERED):
        this.value = 'Complete'
        break;
      case (OrderStatus.CANCELLED):
        this.value = 'Cancelled'
        break;
      case (OrderStatus.ERROR):
        this.value = 'Error'
        break;
      case (OrderStatus.END_OF_DAY):
        this.value = 'End of Day'
        break;
      default:
        this.value = 'Unknown'
        break;
    }
  }

  ngOnInit(): void {
  }
}
