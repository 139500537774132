import { Injectable } from '@angular/core';
import { TimeRange } from './API.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {

    constructor() {
    }

    convert24to12(time: string) {
        if (!time) return ''

        let h = +time.split(':')[0]
        let m = +time.split(':')[1]
        if (!(h >= 0 && m >= 0)) return ''

        let ampm = 'AM'

        if (h > 12) {
            h = h - 12
            ampm = 'PM'
        }

        if (h === 0) {
            h = 12
        }

        const hours = ('0' + h).slice(-2)
        const minutes = (m < 10 ? '0' : '') + m
        return `${hours}:${minutes} ${ampm}`
    }

    isOpen(timeRanges: Partial<TimeRange>[], timezone?: string, ISOStringLocal?: string) {
        let value = false

        let now = ISOStringLocal ? moment(ISOStringLocal) : moment()
        if (timezone) now = now.tz(timezone)
        // console.log('OrderLink TimeZone:', timezone)
        // console.log('Now:', now.format())

        timeRanges.forEach(tr => {
            if (tr.day === now.day()) {
                const start = moment(now)
                start.hour(+tr.startTime.split(':')[0])
                start.minute(+tr.startTime.split(':')[1])
    
                const end = moment(now)
                end.hour(+tr.endTime.split(':')[0])
                end.minute(+tr.endTime.split(':')[1])

                if (now.isBetween(start, end, undefined, '[]')) {
                    // console.log(now.format(), start.format(), end.format())
                    value = true
                }
            }
        })

        return value
    }

    getTimeBlocks(timeRanges: Partial<TimeRange>[], day: number) {
        const blocks = []
        timeRanges.forEach(tr => {
            if (tr.day === day) {
                const startTime = this.convert24to12(tr.startTime)
                const endTime = this.convert24to12(tr.endTime)
                blocks.push(`${startTime} - ${endTime}`)
            }
        })
        return blocks
    }


}