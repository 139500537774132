import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { CheckPaymentDTO } from 'src/models/DTO/check.dto';
import { DataCapComponent } from '../shared/components/datacap/datacap.component';
import { I4goComponent } from '../shared/components/i4go/i4go.component';
import {
  APIService,
  CCProcessor,
  CreatePaymentMethodInput,
  OrderMode,
  PaymentMethodType,
} from './API.service';
import { CheckService } from './check.service';
import { DataCapService } from './datacap.service';
import { I4GoService } from './i4go.service';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  loading: boolean;
  newPaymentMethod = new BehaviorSubject<Partial<PaymentMethod>>(null)
  paymentMethods: Partial<PaymentMethod>[] = [];
  paymentMethodSelected: string;

  constructor(
    private dialogSvc: DialogService,
    private i4go: I4GoService,
    private datacapSvc: DataCapService,
    private orderSvc: OrderService,
    private checkSvc: CheckService,
    private api: APIService
  ) {
    this.i4go.newPaymentMethod.subscribe((paymentMethod) => {
      if (paymentMethod) {
        this.addPaymentMethod(paymentMethod);
      }
    });
    this.datacapSvc.newPaymentMethod.subscribe((paymentMethod) => {
        if (paymentMethod) {
          this.addPaymentMethod(paymentMethod);
        }
      });
    this.orderSvc.onReset.subscribe((data) => {
      if (data) {
        if (this.orderSvc.orderMode === OrderMode.KIOSK) {
          this.clearPaymentMethods();
        }
      }
    });
    this.orderSvc.onPaymentDeclined.subscribe((data) => {
      if (data) {
        this.clearPaymentMethods();
        if (this.orderSvc.paymentMode === 'I4GO') {
          this.openI4Go(
            this.orderSvc.orderLink.id,
            this.orderSvc.order.id,
            this.orderSvc.total,
            this.orderSvc.customer
          );
        }
        if (this.orderSvc.paymentMode === 'DATACAP') {
          this.openDataCap(
            this.orderSvc.orderLink.id,
            this.orderSvc.order.id,
            this.orderSvc.total,
            this.orderSvc.customer
          );
        }
      }
    });
  }

  async addPaymentMethod(
    paymentMethod: Partial<PaymentMethod>,
    skip?: boolean
  ) {
    this.paymentMethods = this.paymentMethods || [];
    const idx = this.paymentMethods.findIndex(
      (p) => p.token === paymentMethod.token
    );
    if (idx >= 0) {
      this.paymentMethods[idx] = paymentMethod;
    } else {
      this.paymentMethods.push(paymentMethod);
    }

    if (!skip) {
      this.selectPaymentMethod(
        paymentMethod.token,
        this.orderSvc.total,
        this.orderSvc.tip
      );

      const input: CreatePaymentMethodInput = {
        token: paymentMethod.token,
        type: PaymentMethodType.CREDIT,
        name: paymentMethod.name,
        cardExpiration: paymentMethod.cardExpiration,
        cardType: paymentMethod.cardType,
        zipcode: paymentMethod.zipcode,
        processor: paymentMethod.processor
      };
      let existing: any = await this.api.GetPaymentMethod(paymentMethod.token);
      existing
        ? await this.api.UpdatePaymentMethod(input)
        : await this.api.CreatePaymentMethod(input);
        this.newPaymentMethod.next(paymentMethod)
    }
  }

  async i4GoPreAuth(
    orderLinkID: string,
    orderId: string,
    amountWithTip: number,
    customerDetails: any,
    tip?: number
  ) {
    this.loading = true;
    await this.i4go.preAuth(
      orderLinkID,
      orderId,
      amountWithTip,
      customerDetails
    );
    this.loading = false;
  }

  async openCCForm(
    orderLinkID: string,
    orderId: string,
    amountWithTip: number,
    customerDetails: any,
    tip?: number
  ) {
    switch (this.orderSvc.paymentMode) {
      case 'I4GO': {
        await this.openI4Go(
          orderLinkID,
          orderId,
          amountWithTip,
          customerDetails,
          tip
        );
        break;
      }
      case 'DATACAP': {
        await this.openDataCap(
            orderLinkID,
            orderId,
            amountWithTip,
            customerDetails,
            tip
        );
        break;
      }
    }
  }

  async openDataCap(
    orderLinkID: string,
    orderId: string,
    amountWithTip: number,
    customerDetails: any,
    tip?: number
  ) {
    const ref = this.dialogSvc.open(DataCapComponent, {
      header: 'Add Credit Card',
      styleClass: 'dialog-datacap dialog-dynamic',
      data: {
        orderLinkID,
        orderId,
        amountWithTip,
        customerDetails,
        tip
      }
    });
  }

  async openI4Go(
    orderLinkID: string,
    orderId: string,
    amountWithTip: number,
    customerDetails: any,
    tip?: number
  ) {
    await this.i4GoPreAuth(
      orderLinkID,
      orderId,
      amountWithTip,
      customerDetails,
      tip
    );

    const ref = this.dialogSvc.open(I4goComponent, {
      header: 'Add Credit Card',
      styleClass: 'dialog-i4go dialog-dynamic',
    });
  }

  clearPaymentMethods() {
    this.paymentMethods = [];
    this.paymentMethodSelected = null;
    if (this.checkSvc.checkDTO?.payments) {
      this.checkSvc.checkDTO.payments = [];
    }
  }

  selectPaymentMethod(token: string, amount?: number, tip?: number) {
    this.paymentMethodSelected = token;
    const paymentMethod = this.paymentMethods.find((p) => p.token === token);

    if (!amount && this.orderSvc.total > 0) {
      amount = this.orderSvc.total;
    }
    if (!tip && this.orderSvc.tip > 0) {
      tip = this.orderSvc.tip;
    }

    if (this.checkSvc.checkDTO) {
      this.checkSvc.checkDTO.payments = [];
      const payment: CheckPaymentDTO = {
        amount: amount - tip,
        tip: tip,
        token: token,
        displayName: paymentMethod.name,
      };
      this.checkSvc.checkDTO.payments.push(payment);
    }
  }
}

export class PaymentMethod {
  token: string;
  name: string;
  zipcode: string;
  type: PaymentMethodType;
  cardType: string;
  cardExpiration: string;
  lastUsed: Date;
  firstUsed: Date;
  amount: number;
  tip: number;
  processor: CCProcessor
  isAuthed: boolean

  constructor(init?: Partial<PaymentMethod>) {
    Object.assign(this, init);
  }
}