/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCheckItemById: OnCheckItemByIdSubscription;
  onChitById: OnChitByIdSubscription;
  onChitByVenueId: OnChitByVenueIdSubscription;
  onConceptByTenantId: OnConceptByTenantIdSubscription;
  onCreateDomain: OnCreateDomainSubscription;
  onCreateTenant: OnCreateTenantSubscription;
  onDeleteDomain: OnDeleteDomainSubscription;
  onDeleteTenant: OnDeleteTenantSubscription;
  onDeviceTransactionByDeviceId: OnDeviceTransactionByDeviceIdSubscription;
  onDeviceTransactionByTransactionId: OnDeviceTransactionByTransactionIdSubscription;
  onLicenseByDeviceId: OnLicenseByDeviceIdSubscription;
  onOrderById: OnOrderByIdSubscription;
  onUpdateDomain: OnUpdateDomainSubscription;
  onUpdateTenant: OnUpdateTenantSubscription;
  onVenueByTenantId: OnVenueByTenantIdSubscription;
  onVenueOrderByVenueId: OnVenueOrderByVenueIdSubscription;
};

export type SaveCheckItemInput = {
  alcohol?: boolean | null;
  checkID: string;
  id?: string | null;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID: string;
  modifierGroupID?: string | null;
  modifiers?: Array<SaveCheckItemInput | null> | null;
  name: string;
  orderLinkID: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  utensils?: boolean | null;
  venueID: string;
};

export type Check = {
  __typename: "Check";
  createdAt: string;
  customer?: Customer | null;
  customerDetails?: CustomerDetails | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<CheckFee | null> | null;
  id: string;
  items?: ModelCheckItemConnection | null;
  logs?: ModelLogConnection | null;
  order: Order;
  orderID: string;
  payments?: ModelCheckPaymentConnection | null;
  readyEstimate?: number | null;
  taxes?: Array<CheckTax | null> | null;
  tenant: Tenant;
  tenantID: string;
  totals?: CheckTotals | null;
  transactions?: ModelCheckTransactionConnection | null;
  updatedAt: string;
  venueTotals?: Array<VenueTotals | null> | null;
};

export type Customer = {
  __typename: "Customer";
  addresses?: Array<Address | null> | null;
  checks?: ModelCheckConnection | null;
  createdAt: string;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  paymentMethods?: ModelCustomerPaymentMethodConnection | null;
  phone?: string | null;
  updatedAt: string;
  userName?: string | null;
};

export type Address = {
  __typename: "Address";
  city?: string | null;
  country?: string | null;
  distance?: number | null;
  email?: string | null;
  instructions?: string | null;
  isDefault?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
  name?: string | null;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  timezone?: string | null;
  unit?: string | null;
  zip?: string | null;
};

export type ModelCheckConnection = {
  __typename: "ModelCheckConnection";
  items: Array<Check | null>;
  nextToken?: string | null;
};

export type ModelCustomerPaymentMethodConnection = {
  __typename: "ModelCustomerPaymentMethodConnection";
  items: Array<CustomerPaymentMethod | null>;
  nextToken?: string | null;
};

export type CustomerPaymentMethod = {
  __typename: "CustomerPaymentMethod";
  createdAt: string;
  customerID: string;
  id: string;
  paymentMethod: PaymentMethod;
  paymentMethodID: string;
  updatedAt: string;
};

export type PaymentMethod = {
  __typename: "PaymentMethod";
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  createdAt: string;
  name: string;
  processor?: CCProcessor | null;
  token: string;
  type: PaymentMethodType;
  updatedAt: string;
  zipcode?: string | null;
};

export enum CCProcessor {
  DATACAP = "DATACAP",
  SHIFT4 = "SHIFT4"
}

export enum PaymentMethodType {
  CREDIT = "CREDIT",
  GIFT = "GIFT"
}

export type CustomerDetails = {
  __typename: "CustomerDetails";
  address?: Address | null;
  dob?: string | null;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  phone?: string | null;
};

export type CheckFee = {
  __typename: "CheckFee";
  amount: number;
  id: string;
  inclusive?: boolean | null;
  name: string;
  taxed?: boolean | null;
  type: FeeType;
};

export enum FeeType {
  FLAT = "FLAT",
  FLAT_PER_ITEM = "FLAT_PER_ITEM",
  OTHER = "OTHER",
  PERCENTAGE_CC_CAPTURE = "PERCENTAGE_CC_CAPTURE",
  PERCENTAGE_PER_ITEM = "PERCENTAGE_PER_ITEM",
  PERCENTAGE_TOTAL = "PERCENTAGE_TOTAL",
  THIRD_PARTY = "THIRD_PARTY"
}

export type ModelCheckItemConnection = {
  __typename: "ModelCheckItemConnection";
  items: Array<CheckItem | null>;
  nextToken?: string | null;
};

export type CheckItem = {
  __typename: "CheckItem";
  alcohol?: boolean | null;
  checkID: string;
  chit?: Chit | null;
  chitID?: string | null;
  createdAt: string;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<CheckItemModifier | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<CheckItemTag | null> | null;
  taxes?: Array<CheckItemTax | null> | null;
  unitPrice?: number | null;
  updatedAt: string;
  utensils?: boolean | null;
  venue?: Venue | null;
  venueID: string;
  venueName?: string | null;
};

export type Chit = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<Error | null> | null;
  id: string;
  items?: Array<ChitItem | null> | null;
  order?: Order | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: Venue | null;
  venueID: string;
  venueName?: string | null;
};

export type Error = {
  __typename: "Error";
  code?: number | null;
  data?: string | null;
  details?: string | null;
  message: string;
  type?: string | null;
};

export type ChitItem = {
  __typename: "ChitItem";
  alcohol?: boolean | null;
  id: string;
  instructions?: string | null;
  modifiers?: Array<ChitItem | null> | null;
  name: string;
  price?: number | null;
  quantity: number;
  status?: ChitItemStatus | null;
  utensils?: boolean | null;
};

export enum ChitItemStatus {
  PENDING = "PENDING",
  READY = "READY"
}

export type Order = {
  __typename: "Order";
  checks?: ModelCheckConnection | null;
  chits?: ModelChitConnection | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: CourierDetails | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: Customer | null;
  customerDetails?: CustomerDetails | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<Error | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: Location | null;
  locationID: string;
  logs?: ModelLogConnection | null;
  orderLink?: OrderLink | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: OrderType | null;
  orderTypeID?: string | null;
  originAddress?: Address | null;
  originDetails?: OriginDetails | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: Tenant | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: ModelVenueOrderConnection | null;
  venueStatus?: Array<VenueStatus | null> | null;
};

export type ModelChitConnection = {
  __typename: "ModelChitConnection";
  items: Array<Chit | null>;
  nextToken?: string | null;
};

export type CourierDetails = {
  __typename: "CourierDetails";
  errorCode?: number | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  lastUpdate?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  message?: string | null;
  phone?: string | null;
  pickupTime?: string | null;
  status?: CourierStatus | null;
  type?: string | null;
};

export enum CourierStatus {
  ARRIVE_DESTINATION = "ARRIVE_DESTINATION",
  ARRIVE_ORIGIN = "ARRIVE_ORIGIN",
  CANCELLED = "CANCELLED",
  DELIVERY_ACCEPTED = "DELIVERY_ACCEPTED",
  DELIVERY_COMPLETE = "DELIVERY_COMPLETE",
  DELIVERY_PICKUP = "DELIVERY_PICKUP",
  ENROUTE_DESTINATION = "ENROUTE_DESTINATION",
  ENROUTE_ORIGIN = "ENROUTE_ORIGIN",
  ERROR = "ERROR",
  UNASSIGNED = "UNASSIGNED"
}

export enum DeliveryProvider {
  COCO = "COCO",
  DOORDASH = "DOORDASH",
  JOLT = "JOLT",
  UBEREATS = "UBEREATS"
}

export type Location = {
  __typename: "Location";
  city: string;
  country?: string | null;
  createdAt: string;
  email?: string | null;
  endOfDay?: string | null;
  id: string;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state: string;
  street: string;
  tenantID: string;
  timezone?: string | null;
  unit?: string | null;
  updatedAt: string;
  zip: string;
};

export type ModelLogConnection = {
  __typename: "ModelLogConnection";
  items: Array<Log | null>;
  nextToken?: string | null;
};

export type Log = {
  __typename: "Log";
  checkID?: string | null;
  createdAt: string;
  employee?: Customer | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type: LogType;
  updatedAt: string;
  user?: Customer | null;
  userID?: string | null;
  venueID?: string | null;
};

export enum LogEvent {
  ORDER_STATUS_CHANGE = "ORDER_STATUS_CHANGE",
  PAYMENT_REFUND = "PAYMENT_REFUND",
  SYSTEM = "SYSTEM"
}

export enum LogSource {
  COCO = "COCO",
  SHIFT4 = "SHIFT4",
  SYSTEM = "SYSTEM"
}

export enum LogType {
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING"
}

export type OrderLink = {
  __typename: "OrderLink";
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredential?: Credential | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  createdAt: string;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  location: Location;
  locationID: string;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  menus?: ModelOrderLinkMenuConnection | null;
  name: string;
  notice?: string | null;
  noticeAtCollection?: string | null;
  notifications?: ModelOrderLinkNotificationConnection | null;
  orderMode?: OrderMode | null;
  orderTypes?: ModelOrderLinkOrderTypeConnection | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  schedules?: ModelOrderLinkScheduleConnection | null;
  searchPlaceholder?: string | null;
  smsCredential?: Credential | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenant?: Tenant | null;
  tenantID: string;
  tos?: string | null;
  updatedAt: string;
};

export type Credential = {
  __typename: "Credential";
  createdAt: string;
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id: string;
  key: string;
  secret: string;
  smsFrom?: string | null;
  tenantID?: string | null;
  type: CredentialType;
  updatedAt: string;
  venueID?: string | null;
};

export enum CredentialType {
  DATACAP = "DATACAP",
  DOORDASH = "DOORDASH",
  JOLT = "JOLT",
  POSTMATES = "POSTMATES",
  SHIFT4 = "SHIFT4",
  TWILIO = "TWILIO",
  UBEREATS = "UBEREATS"
}

export type ModelOrderLinkMenuConnection = {
  __typename: "ModelOrderLinkMenuConnection";
  items: Array<OrderLinkMenu | null>;
  nextToken?: string | null;
};

export type OrderLinkMenu = {
  __typename: "OrderLinkMenu";
  createdAt: string;
  id: string;
  menu: Menu;
  menuID: string;
  orderLink: OrderLink;
  orderLinkID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type Menu = {
  __typename: "Menu";
  active?: boolean | null;
  categories?: ModelMenuMenuCategoryConnection | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  facilitators?: ModelMenuFacilitatorConnection | null;
  featured?: boolean | null;
  id: string;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name: string;
  orderLinks?: ModelOrderLinkMenuConnection | null;
  popular?: boolean | null;
  schedules?: ModelMenuScheduleConnection | null;
  tags?: ModelMenuTagConnection | null;
  tenantID: string;
  updatedAt: string;
  venue?: Venue | null;
  venueID?: string | null;
};

export type ModelMenuMenuCategoryConnection = {
  __typename: "ModelMenuMenuCategoryConnection";
  items: Array<MenuMenuCategory | null>;
  nextToken?: string | null;
};

export type MenuMenuCategory = {
  __typename: "MenuMenuCategory";
  createdAt: string;
  id: string;
  menu: Menu;
  menuCategory: MenuCategory;
  menuCategoryID: string;
  menuID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type MenuCategory = {
  __typename: "MenuCategory";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  menuItems?: ModelMenuCategoryMenuItemConnection | null;
  menus?: ModelMenuMenuCategoryConnection | null;
  name: string;
  priceTypeID?: string | null;
  tags?: ModelMenuCategoryTagConnection | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type ModelMenuCategoryMenuItemConnection = {
  __typename: "ModelMenuCategoryMenuItemConnection";
  items: Array<MenuCategoryMenuItem | null>;
  nextToken?: string | null;
};

export type MenuCategoryMenuItem = {
  __typename: "MenuCategoryMenuItem";
  createdAt: string;
  id: string;
  menuCategory: MenuCategory;
  menuCategoryID: string;
  menuItem: MenuItem;
  menuItemID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type MenuItem = {
  __typename: "MenuItem";
  active?: boolean | null;
  alcohol?: boolean | null;
  categories?: ModelMenuCategoryMenuItemConnection | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  id: string;
  imageS3?: string | null;
  modifierGroups?: ModelMenuItemModifierGroupConnection | null;
  name: string;
  options?: ModelMenuItemOptionConnection | null;
  parentModifierGroups?: ModelModifierGroupMenuItemConnection | null;
  popular?: boolean | null;
  prepTime?: number | null;
  prices?: ModelMenuItemPriceConnection | null;
  tags?: ModelMenuItemTagConnection | null;
  taxes?: ModelMenuItemTaxTypeConnection | null;
  tenantID: string;
  updatedAt: string;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type ModelMenuItemModifierGroupConnection = {
  __typename: "ModelMenuItemModifierGroupConnection";
  items: Array<MenuItemModifierGroup | null>;
  nextToken?: string | null;
};

export type MenuItemModifierGroup = {
  __typename: "MenuItemModifierGroup";
  createdAt: string;
  id: string;
  menuItem: MenuItem;
  menuItemID: string;
  modifierGroup: ModifierGroup;
  modifierGroupID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type ModifierGroup = {
  __typename: "ModifierGroup";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  max?: number | null;
  menuItems?: ModelMenuItemModifierGroupConnection | null;
  min?: number | null;
  modifiers?: ModelModifierGroupMenuItemConnection | null;
  name: string;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tags?: ModelModifierGroupTagConnection | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type ModelModifierGroupMenuItemConnection = {
  __typename: "ModelModifierGroupMenuItemConnection";
  items: Array<ModifierGroupMenuItem | null>;
  nextToken?: string | null;
};

export type ModifierGroupMenuItem = {
  __typename: "ModifierGroupMenuItem";
  createdAt: string;
  id: string;
  menuItem: MenuItem;
  menuItemID: string;
  modifierGroup: ModifierGroup;
  modifierGroupID: string;
  updatedAt: string;
};

export enum SelectionType {
  CHECKBOX = "CHECKBOX",
  QUANTITY = "QUANTITY"
}

export type ModelModifierGroupTagConnection = {
  __typename: "ModelModifierGroupTagConnection";
  items: Array<ModifierGroupTag | null>;
  nextToken?: string | null;
};

export type ModifierGroupTag = {
  __typename: "ModifierGroupTag";
  createdAt: string;
  id: string;
  modifierGroupID: string;
  tag: Tag;
  tagID: string;
  updatedAt: string;
};

export type Tag = {
  __typename: "Tag";
  createdAt: string;
  id: string;
  imageS3Square?: string | null;
  name: string;
  tenantID: string;
  type: TagType;
  updatedAt: string;
};

export enum TagType {
  GENERAL = "GENERAL",
  ITEM_TYPE = "ITEM_TYPE",
  REPORT_GROUP = "REPORT_GROUP"
}

export type ModelMenuItemOptionConnection = {
  __typename: "ModelMenuItemOptionConnection";
  items: Array<MenuItemOption | null>;
  nextToken?: string | null;
};

export type MenuItemOption = {
  __typename: "MenuItemOption";
  createdAt: string;
  id: string;
  menuItemID: string;
  name: string;
  prices?: ModelMenuItemPriceConnection | null;
  updatedAt: string;
};

export type ModelMenuItemPriceConnection = {
  __typename: "ModelMenuItemPriceConnection";
  items: Array<MenuItemPrice | null>;
  nextToken?: string | null;
};

export type MenuItemPrice = {
  __typename: "MenuItemPrice";
  active?: boolean | null;
  createdAt: string;
  id: string;
  menuItemID?: string | null;
  menuItemOptionID?: string | null;
  price: number;
  priceType?: MenuItemPriceType | null;
  priceTypeID?: string | null;
  updatedAt: string;
};

export type MenuItemPriceType = {
  __typename: "MenuItemPriceType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type ModelMenuItemTagConnection = {
  __typename: "ModelMenuItemTagConnection";
  items: Array<MenuItemTag | null>;
  nextToken?: string | null;
};

export type MenuItemTag = {
  __typename: "MenuItemTag";
  createdAt: string;
  id: string;
  menuItemID: string;
  tag: Tag;
  tagID: string;
  updatedAt: string;
};

export type ModelMenuItemTaxTypeConnection = {
  __typename: "ModelMenuItemTaxTypeConnection";
  items: Array<MenuItemTaxType | null>;
  nextToken?: string | null;
};

export type MenuItemTaxType = {
  __typename: "MenuItemTaxType";
  createdAt: string;
  id: string;
  menuItemID: string;
  taxType: TaxType;
  taxTypeID: string;
  updatedAt: string;
};

export type TaxType = {
  __typename: "TaxType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  type: TaxTypeType;
  updatedAt: string;
};

export enum TaxTypeType {
  GRATUITY = "GRATUITY",
  ITEM = "ITEM",
  ORDER_FEE = "ORDER_FEE",
  SURCHARGE = "SURCHARGE"
}

export type ModelMenuCategoryTagConnection = {
  __typename: "ModelMenuCategoryTagConnection";
  items: Array<MenuCategoryTag | null>;
  nextToken?: string | null;
};

export type MenuCategoryTag = {
  __typename: "MenuCategoryTag";
  createdAt: string;
  id: string;
  menuCategoryID: string;
  tag: Tag;
  tagID: string;
  updatedAt: string;
};

export type ModelMenuFacilitatorConnection = {
  __typename: "ModelMenuFacilitatorConnection";
  items: Array<MenuFacilitator | null>;
  nextToken?: string | null;
};

export type MenuFacilitator = {
  __typename: "MenuFacilitator";
  createdAt: string;
  facilitator: Tenant;
  facilitatorID: string;
  id: string;
  menu: Menu;
  menuID: string;
  updatedAt: string;
};

export type Tenant = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: ModelCredentialConnection | null;
  email: string;
  id: string;
  menuFacilitations?: ModelMenuFacilitatorConnection | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: ModelVenueConnection | null;
};

export type ModelCredentialConnection = {
  __typename: "ModelCredentialConnection";
  items: Array<Credential | null>;
  nextToken?: string | null;
};

export enum TenantType {
  CORPORATE = "CORPORATE",
  DEALER = "DEALER",
  FACILITATOR = "FACILITATOR"
}

export type ModelVenueConnection = {
  __typename: "ModelVenueConnection";
  items: Array<Venue | null>;
  nextToken?: string | null;
};

export type Venue = {
  __typename: "Venue";
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  createdAt: string;
  credentials?: ModelCredentialConnection | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  location?: Location | null;
  locationID?: string | null;
  menuCategories?: ModelMenuCategoryConnection | null;
  menuItems?: ModelMenuItemConnection | null;
  menus?: ModelMenuConnection | null;
  modifierGroups?: ModelModifierGroupConnection | null;
  name: string;
  schedules?: ModelVenueScheduleConnection | null;
  taxRates?: ModelTaxRateConnection | null;
  tenant?: Tenant | null;
  tenantID: string;
  updatedAt: string;
  venueOrders?: ModelVenueOrderConnection | null;
};

export type ModelMenuCategoryConnection = {
  __typename: "ModelMenuCategoryConnection";
  items: Array<MenuCategory | null>;
  nextToken?: string | null;
};

export type ModelMenuItemConnection = {
  __typename: "ModelMenuItemConnection";
  items: Array<MenuItem | null>;
  nextToken?: string | null;
};

export type ModelMenuConnection = {
  __typename: "ModelMenuConnection";
  items: Array<Menu | null>;
  nextToken?: string | null;
};

export type ModelModifierGroupConnection = {
  __typename: "ModelModifierGroupConnection";
  items: Array<ModifierGroup | null>;
  nextToken?: string | null;
};

export type ModelVenueScheduleConnection = {
  __typename: "ModelVenueScheduleConnection";
  items: Array<VenueSchedule | null>;
  nextToken?: string | null;
};

export type VenueSchedule = {
  __typename: "VenueSchedule";
  createdAt: string;
  id: string;
  schedule: Schedule;
  scheduleID: string;
  updatedAt: string;
  venueID: string;
};

export type Schedule = {
  __typename: "Schedule";
  createdAt: string;
  endDate?: string | null;
  id: string;
  name: string;
  startDate?: string | null;
  tenantID: string;
  timeRanges?: Array<TimeRange | null> | null;
  type: ScheduleType;
  updatedAt: string;
};

export type TimeRange = {
  __typename: "TimeRange";
  day: number;
  endTime: string;
  startTime: string;
};

export enum ScheduleType {
  ACCEPTING_ORDERS = "ACCEPTING_ORDERS",
  GENERAL = "GENERAL"
}

export type ModelTaxRateConnection = {
  __typename: "ModelTaxRateConnection";
  items: Array<TaxRate | null>;
  nextToken?: string | null;
};

export type TaxRate = {
  __typename: "TaxRate";
  amount?: number | null;
  createdAt: string;
  id: string;
  rate?: number | null;
  taxType?: TaxType | null;
  taxTypeID: string;
  type: TaxRateType;
  updatedAt: string;
  venueID: string;
};

export enum TaxRateType {
  FLAT = "FLAT",
  PERCENT = "PERCENT"
}

export type ModelVenueOrderConnection = {
  __typename: "ModelVenueOrderConnection";
  items: Array<VenueOrder | null>;
  nextToken?: string | null;
};

export type VenueOrder = {
  __typename: "VenueOrder";
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  order: Order;
  orderID: string;
  status?: OrderStatus | null;
  updatedAt: string;
  venue: Venue;
  venueID: string;
  venueName?: string | null;
};

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  COLLECTED = "COLLECTED",
  DELIVERED = "DELIVERED",
  END_OF_DAY = "END_OF_DAY",
  ENROUTE = "ENROUTE",
  ERROR = "ERROR",
  PENDING = "PENDING",
  PREPARED = "PREPARED",
  QUEUED = "QUEUED",
  READY = "READY",
  SUBMITTED = "SUBMITTED"
}

export type ModelMenuScheduleConnection = {
  __typename: "ModelMenuScheduleConnection";
  items: Array<MenuSchedule | null>;
  nextToken?: string | null;
};

export type MenuSchedule = {
  __typename: "MenuSchedule";
  createdAt: string;
  id: string;
  menuID: string;
  schedule: Schedule;
  scheduleID: string;
  updatedAt: string;
};

export type ModelMenuTagConnection = {
  __typename: "ModelMenuTagConnection";
  items: Array<MenuTag | null>;
  nextToken?: string | null;
};

export type MenuTag = {
  __typename: "MenuTag";
  createdAt: string;
  id: string;
  menuID: string;
  tag: Tag;
  tagID: string;
  updatedAt: string;
};

export type ModelOrderLinkNotificationConnection = {
  __typename: "ModelOrderLinkNotificationConnection";
  items: Array<OrderLinkNotification | null>;
  nextToken?: string | null;
};

export type OrderLinkNotification = {
  __typename: "OrderLinkNotification";
  createdAt: string;
  event: NotificationEvent;
  id: string;
  notification: Notification;
  notificationID: string;
  orderLinkID: string;
  updatedAt: string;
};

export enum NotificationEvent {
  COURIER_ARRIVE_DESTINATION = "COURIER_ARRIVE_DESTINATION",
  COURIER_ARRIVE_ORIGIN = "COURIER_ARRIVE_ORIGIN",
  COURIER_CANCELLED = "COURIER_CANCELLED",
  COURIER_DELIVERY_COMPLETE = "COURIER_DELIVERY_COMPLETE",
  COURIER_DELIVERY_PICKUP = "COURIER_DELIVERY_PICKUP",
  COURIER_ENROUTE_DESTINATION = "COURIER_ENROUTE_DESTINATION",
  COURIER_ENROUTE_ORIGIN = "COURIER_ENROUTE_ORIGIN",
  COURIER_ERROR = "COURIER_ERROR",
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_COLLECTED = "ORDER_COLLECTED",
  ORDER_DELIVERED = "ORDER_DELIVERED",
  ORDER_END_OF_DAY = "ORDER_END_OF_DAY",
  ORDER_ENROUTE = "ORDER_ENROUTE",
  ORDER_ERROR = "ORDER_ERROR",
  ORDER_PREPARED = "ORDER_PREPARED",
  ORDER_QUEUED = "ORDER_QUEUED",
  ORDER_READY = "ORDER_READY",
  ORDER_RECEIVED = "ORDER_RECEIVED"
}

export type Notification = {
  __typename: "Notification";
  createdAt: string;
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplate?: EmailTemplate | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id: string;
  message?: string | null;
  name: string;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID: string;
  type: NotificationType;
  updatedAt: string;
};

export type EmailTemplate = {
  __typename: "EmailTemplate";
  createdAt: string;
  html?: string | null;
  id: string;
  name: string;
  subject?: string | null;
  tenantID: string;
  text?: string | null;
  updatedAt: string;
};

export enum PropertyType {
  DYNAMIC = "DYNAMIC",
  STATIC = "STATIC"
}

export enum NotificationType {
  EMAIL = "EMAIL",
  SMS = "SMS"
}

export enum OrderMode {
  KIOSK = "KIOSK",
  ONLINE_ORDER = "ONLINE_ORDER",
  POS = "POS"
}

export type ModelOrderLinkOrderTypeConnection = {
  __typename: "ModelOrderLinkOrderTypeConnection";
  items: Array<OrderLinkOrderType | null>;
  nextToken?: string | null;
};

export type OrderLinkOrderType = {
  __typename: "OrderLinkOrderType";
  createdAt: string;
  id: string;
  orderLinkID: string;
  orderType: OrderType;
  orderTypeID: string;
  updatedAt: string;
};

export type OrderType = {
  __typename: "OrderType";
  active?: boolean | null;
  asap?: boolean | null;
  createdAt: string;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  fees?: ModelOrderTypeFeeConnection | null;
  id: string;
  name: string;
  schedule?: boolean | null;
  schedules?: ModelOrderTypeScheduleConnection | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type ModelOrderTypeFeeConnection = {
  __typename: "ModelOrderTypeFeeConnection";
  items: Array<OrderTypeFee | null>;
  nextToken?: string | null;
};

export type OrderTypeFee = {
  __typename: "OrderTypeFee";
  createdAt: string;
  fee: Fee;
  feeID: string;
  id: string;
  orderTypeID: string;
  updatedAt: string;
};

export type Fee = {
  __typename: "Fee";
  amount?: number | null;
  createdAt: string;
  id: string;
  inclusive?: boolean | null;
  name: string;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID: string;
  type: FeeType;
  updatedAt: string;
};

export type ModelOrderTypeScheduleConnection = {
  __typename: "ModelOrderTypeScheduleConnection";
  items: Array<OrderTypeSchedule | null>;
  nextToken?: string | null;
};

export type OrderTypeSchedule = {
  __typename: "OrderTypeSchedule";
  createdAt: string;
  id: string;
  orderTypeID: string;
  schedule: Schedule;
  scheduleID: string;
  updatedAt: string;
};

export enum ServiceType {
  DELIVERY = "DELIVERY",
  DINEIN = "DINEIN",
  PICKUP = "PICKUP",
  THIRD_PARTY = "THIRD_PARTY"
}

export type ModelOrderLinkScheduleConnection = {
  __typename: "ModelOrderLinkScheduleConnection";
  items: Array<OrderLinkSchedule | null>;
  nextToken?: string | null;
};

export type OrderLinkSchedule = {
  __typename: "OrderLinkSchedule";
  createdAt: string;
  id: string;
  orderLinkID: string;
  schedule: Schedule;
  scheduleID: string;
  updatedAt: string;
};

export type OriginDetails = {
  __typename: "OriginDetails";
  city?: string | null;
  country?: string | null;
  distance?: number | null;
  email?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  timezone?: string | null;
  unit?: string | null;
  zip: string;
};

export enum OrderQueueMode {
  ASAP = "ASAP",
  SCHEDULE = "SCHEDULE"
}

export enum OrderSource {
  KIOSK = "KIOSK",
  ONLINE_ORDER = "ONLINE_ORDER",
  POS = "POS",
  THIRD_PARTY = "THIRD_PARTY"
}

export type VenueStatus = {
  __typename: "VenueStatus";
  status: OrderStatus;
  venueID: string;
};

export enum ChitStatus {
  CANCELLED = "CANCELLED",
  COLLECTED = "COLLECTED",
  END_OF_DAY = "END_OF_DAY",
  ERROR = "ERROR",
  PREPARED = "PREPARED",
  SUBMITTED = "SUBMITTED"
}

export type CheckItemModifier = {
  __typename: "CheckItemModifier";
  id: string;
  menuItemID?: string | null;
  modifierGroupID?: string | null;
  modifiers?: Array<CheckItemModifier | null> | null;
  name: string;
  price: number;
  quantity: number;
  tags?: Array<CheckItemTag | null> | null;
  taxes?: Array<CheckItemTax | null> | null;
  unitPrice?: number | null;
};

export type CheckItemTag = {
  __typename: "CheckItemTag";
  id: string;
  name: string;
  type: TagType;
};

export type CheckItemTax = {
  __typename: "CheckItemTax";
  amount: number;
  name: string;
  taxTypeID?: string | null;
  type: TaxTypeType;
};

export type ModelCheckPaymentConnection = {
  __typename: "ModelCheckPaymentConnection";
  items: Array<CheckPayment | null>;
  nextToken?: string | null;
};

export type CheckPayment = {
  __typename: "CheckPayment";
  amount: number;
  cardPresent?: boolean | null;
  checkID: string;
  createdAt: string;
  displayName?: string | null;
  errors?: Array<Error | null> | null;
  id: string;
  invoice?: number | null;
  isAuthed?: boolean | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  refNo?: string | null;
  status?: CheckPaymentStatus | null;
  tip?: number | null;
  token?: string | null;
  updatedAt: string;
};

export enum CheckPaymentStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  PROCESSED = "PROCESSED"
}

export type CheckTax = {
  __typename: "CheckTax";
  amount: number;
  name: string;
  taxTypeID?: string | null;
  type: TaxTypeType;
};

export type CheckTotals = {
  __typename: "CheckTotals";
  ccCaptureTotal?: number | null;
  ccRefundTotal?: number | null;
  ccVoidTotal?: number | null;
  discountTotal?: number | null;
  feeTotal?: number | null;
  feeTotalInclusive?: number | null;
  feesAndTaxTotal?: number | null;
  paymentTotal?: number | null;
  remainingBalance?: number | null;
  subTotal?: number | null;
  taxTotal?: number | null;
  tipTotal?: number | null;
  total?: number | null;
};

export type ModelCheckTransactionConnection = {
  __typename: "ModelCheckTransactionConnection";
  items: Array<CheckTransaction | null>;
  nextToken?: string | null;
};

export type CheckTransaction = {
  __typename: "CheckTransaction";
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  check?: Check | null;
  checkID: string;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<Error | null> | null;
  id: string;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<CheckRefund | null> | null;
  status: CheckTransactionStatus;
  thirdPartyData?: string | null;
  token?: string | null;
  type: CCTransactionType;
  updatedAt: string;
  venue: Venue;
  venueID: string;
};

export enum CheckTransactionReason {
  CHANGED_MIND = "CHANGED_MIND",
  COLD_FOOD = "COLD_FOOD",
  COMP = "COMP",
  DID_NOT_LIKE = "DID_NOT_LIKE",
  ITEM_MISSING = "ITEM_MISSING",
  KITCHEN_CLOSED = "KITCHEN_CLOSED",
  KITCHEN_LONG_WAIT = "KITCHEN_LONG_WAIT",
  ORDER_ERROR = "ORDER_ERROR",
  ORDER_INCOMPLETE = "ORDER_INCOMPLETE",
  ORDER_UNINTENTIONAL = "ORDER_UNINTENTIONAL",
  OTHER = "OTHER",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  PREPERATION_ERROR = "PREPERATION_ERROR",
  SYSTEM_ERROR = "SYSTEM_ERROR",
  UNKNOWN = "UNKNOWN",
  WRONG_ORDER = "WRONG_ORDER"
}

export type CheckRefund = {
  __typename: "CheckRefund";
  amount: number;
  checkItemID?: string | null;
  type: CheckRefundType;
};

export enum CheckRefundType {
  FEE = "FEE",
  ITEM = "ITEM",
  TAX = "TAX",
  TIP = "TIP"
}

export enum CheckTransactionStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

export enum CCTransactionType {
  AUTH = "AUTH",
  CAPTURE = "CAPTURE",
  REFUND = "REFUND",
  VOID = "VOID"
}

export type VenueTotals = {
  __typename: "VenueTotals";
  ccCaptureTotal?: number | null;
  ccRefundTotal?: number | null;
  ccVoidTotal?: number | null;
  checkPercent?: number | null;
  discountTotal?: number | null;
  feeTotal?: number | null;
  feeTotalInclusive?: number | null;
  fees?: Array<VenueTotalCheckFee | null> | null;
  feesAndTaxTotal?: number | null;
  paymentTotal?: number | null;
  remainingBalance?: number | null;
  subTotal?: number | null;
  taxTotal?: number | null;
  taxes?: Array<VenueTotalCheckTax | null> | null;
  tipTotal?: number | null;
  total?: number | null;
  venueID: string;
};

export type VenueTotalCheckFee = {
  __typename: "VenueTotalCheckFee";
  amount: number;
  id: string;
  inclusive?: boolean | null;
  name: string;
  taxed?: boolean | null;
  type: FeeType;
};

export type VenueTotalCheckTax = {
  __typename: "VenueTotalCheckTax";
  amount: number;
  name: string;
  taxTypeID?: string | null;
  type: TaxTypeType;
};

export type IDInput = {
  id: string;
};

export type UpdateCheckItemQuantityInput = {
  id: string;
  quantity: number;
};

export type ModelCustomerFilterInput = {
  and?: Array<ModelCustomerFilterInput | null> | null;
  defaultPaymentMethodID?: ModelIDInput | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  lastName?: ModelStringInput | null;
  not?: ModelCustomerFilterInput | null;
  or?: Array<ModelCustomerFilterInput | null> | null;
  phone?: ModelStringInput | null;
  userName?: ModelStringInput | null;
};

export type ModelIDInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet"
}

export type ModelSizeInput = {
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type ModelStringInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: ModelSizeInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection";
  items: Array<Customer | null>;
  nextToken?: string | null;
};

export type BumpChitInput = {
  id: string;
  status?: ChitStatus | null;
};

export type ChitStatusRes = {
  __typename: "ChitStatusRes";
  id: string;
  status?: ChitStatus | null;
};

export type ModelCheckConditionInput = {
  and?: Array<ModelCheckConditionInput | null> | null;
  customerID?: ModelIDInput | null;
  diagnostics?: ModelStringInput | null;
  not?: ModelCheckConditionInput | null;
  or?: Array<ModelCheckConditionInput | null> | null;
  orderID?: ModelIDInput | null;
  readyEstimate?: ModelIntInput | null;
  tenantID?: ModelIDInput | null;
};

export type ModelIntInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type CreateCheckInput = {
  customerDetails?: CustomerDetailsInput | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<CheckFeeInput | null> | null;
  id?: string | null;
  orderID: string;
  readyEstimate?: number | null;
  taxes?: Array<CheckTaxInput | null> | null;
  tenantID: string;
  totals?: CheckTotalsInput | null;
  venueTotals?: Array<VenueTotalsInput | null> | null;
};

export type CustomerDetailsInput = {
  address?: AddressInput | null;
  dob?: string | null;
  email?: string | null;
  firstName: string;
  lastName?: string | null;
  phone?: string | null;
};

export type AddressInput = {
  city?: string | null;
  country?: string | null;
  distance?: number | null;
  email?: string | null;
  instructions?: string | null;
  isDefault?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
  name?: string | null;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  timezone?: string | null;
  unit?: string | null;
  zip?: string | null;
};

export type CheckFeeInput = {
  amount: number;
  id: string;
  inclusive?: boolean | null;
  name: string;
  taxed?: boolean | null;
  type: FeeType;
};

export type CheckTaxInput = {
  amount: number;
  name: string;
  taxTypeID?: string | null;
  type: TaxTypeType;
};

export type CheckTotalsInput = {
  ccCaptureTotal?: number | null;
  ccRefundTotal?: number | null;
  ccVoidTotal?: number | null;
  discountTotal?: number | null;
  feeTotal?: number | null;
  feeTotalInclusive?: number | null;
  feesAndTaxTotal?: number | null;
  paymentTotal?: number | null;
  remainingBalance?: number | null;
  subTotal?: number | null;
  taxTotal?: number | null;
  tipTotal?: number | null;
  total?: number | null;
};

export type VenueTotalsInput = {
  ccCaptureTotal?: number | null;
  ccRefundTotal?: number | null;
  ccVoidTotal?: number | null;
  checkPercent?: number | null;
  discountTotal?: number | null;
  feeTotal?: number | null;
  feeTotalInclusive?: number | null;
  fees?: Array<VenueTotalCheckFeeInput | null> | null;
  feesAndTaxTotal?: number | null;
  paymentTotal?: number | null;
  remainingBalance?: number | null;
  subTotal?: number | null;
  taxTotal?: number | null;
  taxes?: Array<VenueTotalCheckTaxInput | null> | null;
  tipTotal?: number | null;
  total?: number | null;
  venueID: string;
};

export type VenueTotalCheckFeeInput = {
  amount: number;
  id: string;
  inclusive?: boolean | null;
  name: string;
  taxed?: boolean | null;
  type: FeeType;
};

export type VenueTotalCheckTaxInput = {
  amount: number;
  name: string;
  taxTypeID?: string | null;
  type: TaxTypeType;
};

export type ModelCheckItemConditionInput = {
  alcohol?: ModelBooleanInput | null;
  and?: Array<ModelCheckItemConditionInput | null> | null;
  checkID?: ModelIDInput | null;
  chitID?: ModelIDInput | null;
  instructions?: ModelStringInput | null;
  menuID?: ModelIDInput | null;
  menuItemID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelCheckItemConditionInput | null;
  or?: Array<ModelCheckItemConditionInput | null> | null;
  price?: ModelFloatInput | null;
  priceTypeID?: ModelIDInput | null;
  quantity?: ModelFloatInput | null;
  unitPrice?: ModelFloatInput | null;
  utensils?: ModelBooleanInput | null;
  venueID?: ModelIDInput | null;
  venueName?: ModelStringInput | null;
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  eq?: boolean | null;
  ne?: boolean | null;
};

export type ModelFloatInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type CreateCheckItemInput = {
  alcohol?: boolean | null;
  checkID: string;
  chitID?: string | null;
  id?: string | null;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<CheckItemModifierInput | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<CheckItemTagInput | null> | null;
  taxes?: Array<CheckItemTaxInput | null> | null;
  unitPrice?: number | null;
  utensils?: boolean | null;
  venueID: string;
  venueName?: string | null;
};

export type CheckItemModifierInput = {
  id: string;
  menuItemID?: string | null;
  modifierGroupID?: string | null;
  modifiers?: Array<CheckItemModifierInput | null> | null;
  name: string;
  price: number;
  quantity: number;
  tags?: Array<CheckItemTagInput | null> | null;
  taxes?: Array<CheckItemTaxInput | null> | null;
  unitPrice?: number | null;
};

export type CheckItemTagInput = {
  id: string;
  name: string;
  type: TagType;
};

export type CheckItemTaxInput = {
  amount: number;
  name: string;
  taxTypeID?: string | null;
  type: TaxTypeType;
};

export type ModelCheckPaymentConditionInput = {
  amount?: ModelFloatInput | null;
  and?: Array<ModelCheckPaymentConditionInput | null> | null;
  cardPresent?: ModelBooleanInput | null;
  checkID?: ModelIDInput | null;
  displayName?: ModelStringInput | null;
  invoice?: ModelIntInput | null;
  isAuthed?: ModelBooleanInput | null;
  not?: ModelCheckPaymentConditionInput | null;
  or?: Array<ModelCheckPaymentConditionInput | null> | null;
  paymentMethodID?: ModelIDInput | null;
  paymentMethodType?: ModelPaymentMethodTypeInput | null;
  refNo?: ModelStringInput | null;
  status?: ModelCheckPaymentStatusInput | null;
  tip?: ModelFloatInput | null;
  token?: ModelStringInput | null;
};

export type ModelPaymentMethodTypeInput = {
  eq?: PaymentMethodType | null;
  ne?: PaymentMethodType | null;
};

export type ModelCheckPaymentStatusInput = {
  eq?: CheckPaymentStatus | null;
  ne?: CheckPaymentStatus | null;
};

export type CreateCheckPaymentInput = {
  amount: number;
  cardPresent?: boolean | null;
  checkID: string;
  displayName?: string | null;
  errors?: Array<ErrorInput | null> | null;
  id?: string | null;
  invoice?: number | null;
  isAuthed?: boolean | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  refNo?: string | null;
  status?: CheckPaymentStatus | null;
  tip?: number | null;
  token?: string | null;
};

export type ErrorInput = {
  code?: number | null;
  data?: string | null;
  details?: string | null;
  message: string;
  type?: string | null;
};

export type ModelCheckTransactionConditionInput = {
  amount?: ModelFloatInput | null;
  amountFees?: ModelFloatInput | null;
  amountItems?: ModelFloatInput | null;
  amountTax?: ModelFloatInput | null;
  amountTip?: ModelFloatInput | null;
  and?: Array<ModelCheckTransactionConditionInput | null> | null;
  authCode?: ModelStringInput | null;
  cardNumber?: ModelStringInput | null;
  cardType?: ModelStringInput | null;
  checkID?: ModelIDInput | null;
  checkItemIDs?: ModelIDInput | null;
  comp?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  invoice?: ModelIntInput | null;
  last4?: ModelIntInput | null;
  not?: ModelCheckTransactionConditionInput | null;
  note?: ModelStringInput | null;
  or?: Array<ModelCheckTransactionConditionInput | null> | null;
  paymentID?: ModelIDInput | null;
  paymentMethodID?: ModelIDInput | null;
  paymentMethodType?: ModelPaymentMethodTypeInput | null;
  processor?: ModelCCProcessorInput | null;
  processorCredentialID?: ModelIDInput | null;
  reason?: ModelCheckTransactionReasonInput | null;
  refNo?: ModelStringInput | null;
  status?: ModelCheckTransactionStatusInput | null;
  thirdPartyData?: ModelStringInput | null;
  token?: ModelStringInput | null;
  type?: ModelCCTransactionTypeInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelCCProcessorInput = {
  eq?: CCProcessor | null;
  ne?: CCProcessor | null;
};

export type ModelCheckTransactionReasonInput = {
  eq?: CheckTransactionReason | null;
  ne?: CheckTransactionReason | null;
};

export type ModelCheckTransactionStatusInput = {
  eq?: CheckTransactionStatus | null;
  ne?: CheckTransactionStatus | null;
};

export type ModelCCTransactionTypeInput = {
  eq?: CCTransactionType | null;
  ne?: CCTransactionType | null;
};

export type CreateCheckTransactionInput = {
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  checkID: string;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<ErrorInput | null> | null;
  id?: string | null;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<CheckRefundInput | null> | null;
  status: CheckTransactionStatus;
  thirdPartyData?: string | null;
  token?: string | null;
  type: CCTransactionType;
  venueID: string;
};

export type CheckRefundInput = {
  amount: number;
  checkItemID?: string | null;
  type: CheckRefundType;
};

export type ModelChitConditionInput = {
  and?: Array<ModelChitConditionInput | null> | null;
  createdAt?: ModelStringInput | null;
  not?: ModelChitConditionInput | null;
  or?: Array<ModelChitConditionInput | null> | null;
  orderID?: ModelIDInput | null;
  status?: ModelChitStatusInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
  venueName?: ModelStringInput | null;
};

export type ModelChitStatusInput = {
  eq?: ChitStatus | null;
  ne?: ChitStatus | null;
};

export type CreateChitInput = {
  createdAt?: string | null;
  errors?: Array<ErrorInput | null> | null;
  id?: string | null;
  items?: Array<ChitItemInput | null> | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  venueID: string;
  venueName?: string | null;
};

export type ChitItemInput = {
  alcohol?: boolean | null;
  id: string;
  instructions?: string | null;
  modifiers?: Array<ChitItemInput | null> | null;
  name: string;
  price?: number | null;
  quantity: number;
  status?: ChitItemStatus | null;
  utensils?: boolean | null;
};

export type ModelConceptConditionInput = {
  and?: Array<ModelConceptConditionInput | null> | null;
  name?: ModelStringInput | null;
  not?: ModelConceptConditionInput | null;
  or?: Array<ModelConceptConditionInput | null> | null;
  tenantID?: ModelIDInput | null;
};

export type CreateConceptInput = {
  id?: string | null;
  name: string;
  tenantID: string;
};

export type Concept = {
  __typename: "Concept";
  createdAt: string;
  id: string;
  menuCategories?: ModelMenuCategoryConnection | null;
  menuItems?: ModelMenuItemConnection | null;
  menus?: ModelMenuConnection | null;
  modifierGroups?: ModelModifierGroupConnection | null;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type ModelCredentialConditionInput = {
  and?: Array<ModelCredentialConditionInput | null> | null;
  dcEcomMID?: ModelIDInput | null;
  dcMID?: ModelIDInput | null;
  dcTokenKey?: ModelIDInput | null;
  key?: ModelStringInput | null;
  not?: ModelCredentialConditionInput | null;
  or?: Array<ModelCredentialConditionInput | null> | null;
  secret?: ModelStringInput | null;
  smsFrom?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelCredentialTypeInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelCredentialTypeInput = {
  eq?: CredentialType | null;
  ne?: CredentialType | null;
};

export type CreateCredentialInput = {
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id?: string | null;
  key: string;
  secret: string;
  smsFrom?: string | null;
  tenantID?: string | null;
  type: CredentialType;
  venueID?: string | null;
};

export type ModelCustomerConditionInput = {
  and?: Array<ModelCustomerConditionInput | null> | null;
  defaultPaymentMethodID?: ModelIDInput | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  not?: ModelCustomerConditionInput | null;
  or?: Array<ModelCustomerConditionInput | null> | null;
  phone?: ModelStringInput | null;
  userName?: ModelStringInput | null;
};

export type CreateCustomerInput = {
  addresses?: Array<AddressInput | null> | null;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  phone?: string | null;
  userName?: string | null;
};

export type ModelCustomerPaymentMethodConditionInput = {
  and?: Array<ModelCustomerPaymentMethodConditionInput | null> | null;
  customerID?: ModelIDInput | null;
  not?: ModelCustomerPaymentMethodConditionInput | null;
  or?: Array<ModelCustomerPaymentMethodConditionInput | null> | null;
  paymentMethodID?: ModelIDInput | null;
};

export type CreateCustomerPaymentMethodInput = {
  customerID: string;
  id?: string | null;
  paymentMethodID: string;
};

export type ModelDeviceConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelDeviceConditionInput | null> | null;
  autoPrint?: ModelBooleanInput | null;
  deviceID?: ModelIDInput | null;
  displayName?: ModelStringInput | null;
  host?: ModelStringInput | null;
  ipAddress?: ModelStringInput | null;
  licenseID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelDeviceConditionInput | null;
  or?: Array<ModelDeviceConditionInput | null> | null;
  port?: ModelIntInput | null;
  printOnBump?: ModelBooleanInput | null;
  serialNumber?: ModelIDInput | null;
  type?: ModelDeviceTypeInput | null;
};

export type ModelDeviceTypeInput = {
  eq?: DeviceType | null;
  ne?: DeviceType | null;
};

export enum DeviceType {
  DATACAP = "DATACAP",
  KIOSK = "KIOSK",
  KITCHEN = "KITCHEN",
  PAYMENT = "PAYMENT",
  POS = "POS",
  PRINTER = "PRINTER",
  UTG = "UTG"
}

export type CreateDeviceInput = {
  active?: boolean | null;
  autoPrint?: boolean | null;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id?: string | null;
  ipAddress?: string | null;
  licenseID: string;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type: DeviceType;
};

export type Device = {
  __typename: "Device";
  active?: boolean | null;
  autoPrint?: boolean | null;
  createdAt: string;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id: string;
  ipAddress?: string | null;
  licenseID: string;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type: DeviceType;
  updatedAt: string;
};

export type ModelDeviceTransactionConditionInput = {
  and?: Array<ModelDeviceTransactionConditionInput | null> | null;
  data?: ModelStringInput | null;
  deviceID?: ModelIDInput | null;
  event?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  not?: ModelDeviceTransactionConditionInput | null;
  or?: Array<ModelDeviceTransactionConditionInput | null> | null;
  status?: ModelDeviceTransactionStatusInput | null;
  transactionID?: ModelIDInput | null;
  type?: ModelDeviceTransactionTypeInput | null;
};

export type ModelDeviceTransactionStatusInput = {
  eq?: DeviceTransactionStatus | null;
  ne?: DeviceTransactionStatus | null;
};

export enum DeviceTransactionStatus {
  PENDING = "PENDING",
  RECEIVED = "RECEIVED"
}

export type ModelDeviceTransactionTypeInput = {
  eq?: DeviceTransactionType | null;
  ne?: DeviceTransactionType | null;
};

export enum DeviceTransactionType {
  REQUEST = "REQUEST",
  RESPONSE = "RESPONSE"
}

export type CreateDeviceTransactionInput = {
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id?: string | null;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
};

export type DeviceTransaction = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type ModelDomainConditionInput = {
  and?: Array<ModelDomainConditionInput | null> | null;
  host?: ModelStringInput | null;
  imageS3Logo?: ModelStringInput | null;
  not?: ModelDomainConditionInput | null;
  or?: Array<ModelDomainConditionInput | null> | null;
};

export type CreateDomainInput = {
  host: string;
  id?: string | null;
  imageS3Logo?: string | null;
};

export type Domain = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type ModelEmailTemplateConditionInput = {
  and?: Array<ModelEmailTemplateConditionInput | null> | null;
  html?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelEmailTemplateConditionInput | null;
  or?: Array<ModelEmailTemplateConditionInput | null> | null;
  subject?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  text?: ModelStringInput | null;
};

export type CreateEmailTemplateInput = {
  html?: string | null;
  id?: string | null;
  name: string;
  subject?: string | null;
  tenantID: string;
  text?: string | null;
};

export type ModelEmployeeConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelEmployeeConditionInput | null> | null;
  code?: ModelStringInput | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  not?: ModelEmployeeConditionInput | null;
  or?: Array<ModelEmployeeConditionInput | null> | null;
  phone?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
};

export type CreateEmployeeInput = {
  active?: boolean | null;
  code?: string | null;
  email?: string | null;
  firstName: string;
  id?: string | null;
  lastName: string;
  phone?: string | null;
  tenantID: string;
};

export type Employee = {
  __typename: "Employee";
  active?: boolean | null;
  code?: string | null;
  createdAt: string;
  email?: string | null;
  firstName: string;
  id: string;
  jobs?: ModelJobConnection | null;
  lastName: string;
  phone?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection";
  items: Array<Job | null>;
  nextToken?: string | null;
};

export type Job = {
  __typename: "Job";
  createdAt: string;
  employee: Employee;
  employeeID: string;
  entityType: EntityType;
  id: string;
  payRate?: number | null;
  tenant?: Tenant | null;
  tenantID?: string | null;
  type: JobType;
  typeID: string;
  updatedAt: string;
  venue?: Venue | null;
  venueID?: string | null;
};

export enum EntityType {
  PAYTRAX = "PAYTRAX",
  TENANT = "TENANT",
  VENUE = "VENUE"
}

export type JobType = {
  __typename: "JobType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<JobPermission | null> | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export enum JobPermission {
  KITCHEN_ADMIN = "KITCHEN_ADMIN",
  PAYMENT_REFUND = "PAYMENT_REFUND",
  QR_BUMP = "QR_BUMP"
}

export type ModelFeeConditionInput = {
  amount?: ModelFloatInput | null;
  and?: Array<ModelFeeConditionInput | null> | null;
  inclusive?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  not?: ModelFeeConditionInput | null;
  or?: Array<ModelFeeConditionInput | null> | null;
  rate?: ModelFloatInput | null;
  taxed?: ModelBooleanInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelFeeTypeInput | null;
};

export type ModelFeeTypeInput = {
  eq?: FeeType | null;
  ne?: FeeType | null;
};

export type CreateFeeInput = {
  amount?: number | null;
  id?: string | null;
  inclusive?: boolean | null;
  name: string;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID: string;
  type: FeeType;
};

export type ModelJobConditionInput = {
  and?: Array<ModelJobConditionInput | null> | null;
  employeeID?: ModelIDInput | null;
  entityType?: ModelEntityTypeInput | null;
  not?: ModelJobConditionInput | null;
  or?: Array<ModelJobConditionInput | null> | null;
  payRate?: ModelFloatInput | null;
  tenantID?: ModelIDInput | null;
  typeID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelEntityTypeInput = {
  eq?: EntityType | null;
  ne?: EntityType | null;
};

export type CreateJobInput = {
  employeeID: string;
  entityType: EntityType;
  id?: string | null;
  payRate?: number | null;
  tenantID?: string | null;
  typeID: string;
  venueID?: string | null;
};

export type ModelJobTypeConditionInput = {
  and?: Array<ModelJobTypeConditionInput | null> | null;
  name?: ModelStringInput | null;
  not?: ModelJobTypeConditionInput | null;
  or?: Array<ModelJobTypeConditionInput | null> | null;
  permissions?: ModelJobPermissionListInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelJobPermissionListInput = {
  contains?: JobPermission | null;
  eq?: Array<JobPermission | null> | null;
  ne?: Array<JobPermission | null> | null;
  notContains?: JobPermission | null;
};

export type CreateJobTypeInput = {
  id?: string | null;
  name: string;
  permissions?: Array<JobPermission | null> | null;
  tenantID: string;
  venueID?: string | null;
};

export type ModelLicenseConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelLicenseConditionInput | null> | null;
  displayName?: ModelStringInput | null;
  guid?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelLicenseConditionInput | null;
  or?: Array<ModelLicenseConditionInput | null> | null;
  pollingInterval?: ModelIntInput | null;
  receiptConfigID?: ModelIDInput | null;
  status?: ModelLicenseStatusInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelDeviceTypeInput | null;
};

export type ModelLicenseStatusInput = {
  eq?: LicenseStatus | null;
  ne?: LicenseStatus | null;
};

export enum LicenseStatus {
  AVAILABLE = "AVAILABLE",
  CLAIMED = "CLAIMED"
}

export type CreateLicenseInput = {
  active?: boolean | null;
  deviceDetails?: DeviceDetailsInput | null;
  displayName?: string | null;
  guid?: string | null;
  id?: string | null;
  kioskConfig?: KioskConfigInput | null;
  kitchenConfig?: KitchenConfigInput | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenantID: string;
  type: DeviceType;
};

export type DeviceDetailsInput = {
  deviceID?: string | null;
  displayName?: string | null;
  heartbeat?: string | null;
  initTime?: string | null;
  name?: string | null;
  online?: boolean | null;
  version?: string | null;
};

export type KioskConfigInput = {
  orderLinkID?: string | null;
  showSettings?: boolean | null;
};

export type KitchenConfigInput = {
  showSettings?: boolean | null;
};

export type License = {
  __typename: "License";
  active?: boolean | null;
  createdAt: string;
  deviceDetails?: DeviceDetails | null;
  devices?: ModelDeviceConnection | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: KioskConfig | null;
  kitchenConfig?: KitchenConfig | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfig?: ReceiptConfig | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenant?: Tenant | null;
  tenantID: string;
  type: DeviceType;
  updatedAt: string;
  venues?: ModelLicenseVenueConnection | null;
};

export type DeviceDetails = {
  __typename: "DeviceDetails";
  deviceID?: string | null;
  displayName?: string | null;
  heartbeat?: string | null;
  initTime?: string | null;
  name?: string | null;
  online?: boolean | null;
  version?: string | null;
};

export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection";
  items: Array<Device | null>;
  nextToken?: string | null;
};

export type KioskConfig = {
  __typename: "KioskConfig";
  orderLinkID?: string | null;
  showSettings?: boolean | null;
};

export type KitchenConfig = {
  __typename: "KitchenConfig";
  showSettings?: boolean | null;
};

export type ReceiptConfig = {
  __typename: "ReceiptConfig";
  createdAt: string;
  headerSize?: number | null;
  id: string;
  itemSize?: number | null;
  name: string;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenant?: Tenant | null;
  tenantID: string;
  updatedAt: string;
  venueSize?: number | null;
};

export type ModelLicenseVenueConnection = {
  __typename: "ModelLicenseVenueConnection";
  items: Array<LicenseVenue | null>;
  nextToken?: string | null;
};

export type LicenseVenue = {
  __typename: "LicenseVenue";
  createdAt: string;
  id: string;
  licenseID: string;
  updatedAt: string;
  venue: Venue;
  venueID: string;
};

export type ModelLicenseVenueConditionInput = {
  and?: Array<ModelLicenseVenueConditionInput | null> | null;
  licenseID?: ModelIDInput | null;
  not?: ModelLicenseVenueConditionInput | null;
  or?: Array<ModelLicenseVenueConditionInput | null> | null;
  venueID?: ModelIDInput | null;
};

export type CreateLicenseVenueInput = {
  id?: string | null;
  licenseID: string;
  venueID: string;
};

export type ModelLocationConditionInput = {
  and?: Array<ModelLocationConditionInput | null> | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  email?: ModelStringInput | null;
  endOfDay?: ModelStringInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  not?: ModelLocationConditionInput | null;
  or?: Array<ModelLocationConditionInput | null> | null;
  phone?: ModelStringInput | null;
  state?: ModelStringInput | null;
  street?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  timezone?: ModelStringInput | null;
  unit?: ModelStringInput | null;
  zip?: ModelStringInput | null;
};

export type CreateLocationInput = {
  city: string;
  country?: string | null;
  email?: string | null;
  endOfDay?: string | null;
  id?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state: string;
  street: string;
  tenantID: string;
  timezone?: string | null;
  unit?: string | null;
  zip: string;
};

export type ModelLogConditionInput = {
  and?: Array<ModelLogConditionInput | null> | null;
  checkID?: ModelIDInput | null;
  employeeID?: ModelIDInput | null;
  event?: ModelLogEventInput | null;
  message?: ModelStringInput | null;
  not?: ModelLogConditionInput | null;
  or?: Array<ModelLogConditionInput | null> | null;
  orderID?: ModelIDInput | null;
  source?: ModelLogSourceInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelLogTypeInput | null;
  userID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelLogEventInput = {
  eq?: LogEvent | null;
  ne?: LogEvent | null;
};

export type ModelLogSourceInput = {
  eq?: LogSource | null;
  ne?: LogSource | null;
};

export type ModelLogTypeInput = {
  eq?: LogType | null;
  ne?: LogType | null;
};

export type CreateLogInput = {
  checkID?: string | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id?: string | null;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type: LogType;
  userID?: string | null;
  venueID?: string | null;
};

export type ModelMenuConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelMenuConditionInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  featured?: ModelBooleanInput | null;
  imageS3Featured?: ModelStringInput | null;
  imageS3Headers?: ModelStringInput | null;
  imageS3HeadersMobile?: ModelStringInput | null;
  imageS3LogoSquare?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuConditionInput | null;
  or?: Array<ModelMenuConditionInput | null> | null;
  popular?: ModelBooleanInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type CreateMenuInput = {
  active?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  displayName?: string | null;
  featured?: boolean | null;
  id?: string | null;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name: string;
  popular?: boolean | null;
  tenantID: string;
  venueID?: string | null;
};

export type ModelMenuCategoryConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelMenuCategoryConditionInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuCategoryConditionInput | null;
  or?: Array<ModelMenuCategoryConditionInput | null> | null;
  priceTypeID?: ModelIDInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type CreateMenuCategoryInput = {
  active?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  displayName?: string | null;
  id?: string | null;
  name: string;
  priceTypeID?: string | null;
  tenantID: string;
  venueID?: string | null;
};

export type ModelMenuCategoryMenuItemConditionInput = {
  and?: Array<ModelMenuCategoryMenuItemConditionInput | null> | null;
  menuCategoryID?: ModelIDInput | null;
  menuItemID?: ModelIDInput | null;
  not?: ModelMenuCategoryMenuItemConditionInput | null;
  or?: Array<ModelMenuCategoryMenuItemConditionInput | null> | null;
  sortOrder?: ModelIntInput | null;
};

export type CreateMenuCategoryMenuItemInput = {
  id?: string | null;
  menuCategoryID: string;
  menuItemID: string;
  sortOrder?: number | null;
};

export type ModelMenuCategoryTagConditionInput = {
  and?: Array<ModelMenuCategoryTagConditionInput | null> | null;
  menuCategoryID?: ModelIDInput | null;
  not?: ModelMenuCategoryTagConditionInput | null;
  or?: Array<ModelMenuCategoryTagConditionInput | null> | null;
  tagID?: ModelIDInput | null;
};

export type CreateMenuCategoryTagInput = {
  id?: string | null;
  menuCategoryID: string;
  tagID: string;
};

export type ModelMenuFacilitatorConditionInput = {
  and?: Array<ModelMenuFacilitatorConditionInput | null> | null;
  facilitatorID?: ModelIDInput | null;
  menuID?: ModelIDInput | null;
  not?: ModelMenuFacilitatorConditionInput | null;
  or?: Array<ModelMenuFacilitatorConditionInput | null> | null;
};

export type CreateMenuFacilitatorInput = {
  facilitatorID: string;
  id?: string | null;
  menuID: string;
};

export type ModelMenuItemConditionInput = {
  active?: ModelBooleanInput | null;
  alcohol?: ModelBooleanInput | null;
  and?: Array<ModelMenuItemConditionInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  imageS3?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuItemConditionInput | null;
  or?: Array<ModelMenuItemConditionInput | null> | null;
  popular?: ModelBooleanInput | null;
  prepTime?: ModelIntInput | null;
  tenantID?: ModelIDInput | null;
  utensils?: ModelBooleanInput | null;
  venueID?: ModelIDInput | null;
};

export type CreateMenuItemInput = {
  active?: boolean | null;
  alcohol?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  id?: string | null;
  imageS3?: string | null;
  name: string;
  popular?: boolean | null;
  prepTime?: number | null;
  tenantID: string;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type ModelMenuItemModifierGroupConditionInput = {
  and?: Array<ModelMenuItemModifierGroupConditionInput | null> | null;
  menuItemID?: ModelIDInput | null;
  modifierGroupID?: ModelIDInput | null;
  not?: ModelMenuItemModifierGroupConditionInput | null;
  or?: Array<ModelMenuItemModifierGroupConditionInput | null> | null;
  sortOrder?: ModelIntInput | null;
};

export type CreateMenuItemModifierGroupInput = {
  id?: string | null;
  menuItemID: string;
  modifierGroupID: string;
  sortOrder?: number | null;
};

export type ModelMenuItemOptionConditionInput = {
  and?: Array<ModelMenuItemOptionConditionInput | null> | null;
  menuItemID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuItemOptionConditionInput | null;
  or?: Array<ModelMenuItemOptionConditionInput | null> | null;
};

export type CreateMenuItemOptionInput = {
  id?: string | null;
  menuItemID: string;
  name: string;
};

export type ModelMenuItemPriceConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelMenuItemPriceConditionInput | null> | null;
  menuItemID?: ModelIDInput | null;
  menuItemOptionID?: ModelIDInput | null;
  not?: ModelMenuItemPriceConditionInput | null;
  or?: Array<ModelMenuItemPriceConditionInput | null> | null;
  price?: ModelFloatInput | null;
  priceTypeID?: ModelIDInput | null;
};

export type CreateMenuItemPriceInput = {
  active?: boolean | null;
  id?: string | null;
  menuItemID?: string | null;
  menuItemOptionID?: string | null;
  price: number;
  priceTypeID?: string | null;
};

export type ModelMenuItemPriceTypeConditionInput = {
  and?: Array<ModelMenuItemPriceTypeConditionInput | null> | null;
  name?: ModelStringInput | null;
  not?: ModelMenuItemPriceTypeConditionInput | null;
  or?: Array<ModelMenuItemPriceTypeConditionInput | null> | null;
  tenantID?: ModelIDInput | null;
};

export type CreateMenuItemPriceTypeInput = {
  id?: string | null;
  name: string;
  tenantID: string;
};

export type ModelMenuItemTagConditionInput = {
  and?: Array<ModelMenuItemTagConditionInput | null> | null;
  menuItemID?: ModelIDInput | null;
  not?: ModelMenuItemTagConditionInput | null;
  or?: Array<ModelMenuItemTagConditionInput | null> | null;
  tagID?: ModelIDInput | null;
};

export type CreateMenuItemTagInput = {
  id?: string | null;
  menuItemID: string;
  tagID: string;
};

export type ModelMenuItemTaxTypeConditionInput = {
  and?: Array<ModelMenuItemTaxTypeConditionInput | null> | null;
  menuItemID?: ModelIDInput | null;
  not?: ModelMenuItemTaxTypeConditionInput | null;
  or?: Array<ModelMenuItemTaxTypeConditionInput | null> | null;
  taxTypeID?: ModelIDInput | null;
};

export type CreateMenuItemTaxTypeInput = {
  id?: string | null;
  menuItemID: string;
  taxTypeID: string;
};

export type ModelMenuMenuCategoryConditionInput = {
  and?: Array<ModelMenuMenuCategoryConditionInput | null> | null;
  menuCategoryID?: ModelIDInput | null;
  menuID?: ModelIDInput | null;
  not?: ModelMenuMenuCategoryConditionInput | null;
  or?: Array<ModelMenuMenuCategoryConditionInput | null> | null;
  sortOrder?: ModelIntInput | null;
};

export type CreateMenuMenuCategoryInput = {
  id?: string | null;
  menuCategoryID: string;
  menuID: string;
  sortOrder?: number | null;
};

export type ModelMenuScheduleConditionInput = {
  and?: Array<ModelMenuScheduleConditionInput | null> | null;
  menuID?: ModelIDInput | null;
  not?: ModelMenuScheduleConditionInput | null;
  or?: Array<ModelMenuScheduleConditionInput | null> | null;
  scheduleID?: ModelIDInput | null;
};

export type CreateMenuScheduleInput = {
  id?: string | null;
  menuID: string;
  scheduleID: string;
};

export type ModelMenuTagConditionInput = {
  and?: Array<ModelMenuTagConditionInput | null> | null;
  menuID?: ModelIDInput | null;
  not?: ModelMenuTagConditionInput | null;
  or?: Array<ModelMenuTagConditionInput | null> | null;
  tagID?: ModelIDInput | null;
};

export type CreateMenuTagInput = {
  id?: string | null;
  menuID: string;
  tagID: string;
};

export type ModelModifierGroupConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelModifierGroupConditionInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  max?: ModelIntInput | null;
  min?: ModelIntInput | null;
  name?: ModelStringInput | null;
  not?: ModelModifierGroupConditionInput | null;
  or?: Array<ModelModifierGroupConditionInput | null> | null;
  priceTypeID?: ModelIDInput | null;
  selectionType?: ModelSelectionTypeInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelSelectionTypeInput = {
  eq?: SelectionType | null;
  ne?: SelectionType | null;
};

export type CreateModifierGroupInput = {
  active?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  displayName?: string | null;
  id?: string | null;
  max?: number | null;
  min?: number | null;
  name: string;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tenantID: string;
  venueID?: string | null;
};

export type ModelModifierGroupMenuItemConditionInput = {
  and?: Array<ModelModifierGroupMenuItemConditionInput | null> | null;
  menuItemID?: ModelIDInput | null;
  modifierGroupID?: ModelIDInput | null;
  not?: ModelModifierGroupMenuItemConditionInput | null;
  or?: Array<ModelModifierGroupMenuItemConditionInput | null> | null;
};

export type CreateModifierGroupMenuItemInput = {
  id?: string | null;
  menuItemID: string;
  modifierGroupID: string;
};

export type ModelModifierGroupTagConditionInput = {
  and?: Array<ModelModifierGroupTagConditionInput | null> | null;
  modifierGroupID?: ModelIDInput | null;
  not?: ModelModifierGroupTagConditionInput | null;
  or?: Array<ModelModifierGroupTagConditionInput | null> | null;
  tagID?: ModelIDInput | null;
};

export type CreateModifierGroupTagInput = {
  id?: string | null;
  modifierGroupID: string;
  tagID: string;
};

export type ModelNotificationConditionInput = {
  and?: Array<ModelNotificationConditionInput | null> | null;
  emailBCC?: ModelStringInput | null;
  emailCC?: ModelStringInput | null;
  emailFrom?: ModelStringInput | null;
  emailReplyTo?: ModelStringInput | null;
  emailTemplateData?: ModelStringInput | null;
  emailTemplateID?: ModelIDInput | null;
  emailTo?: ModelStringInput | null;
  message?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelNotificationConditionInput | null;
  or?: Array<ModelNotificationConditionInput | null> | null;
  smsFrom?: ModelStringInput | null;
  smsTo?: ModelStringInput | null;
  smsToDynamic?: ModelStringInput | null;
  smsToType?: ModelPropertyTypeInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelNotificationTypeInput | null;
};

export type ModelPropertyTypeInput = {
  eq?: PropertyType | null;
  ne?: PropertyType | null;
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null;
  ne?: NotificationType | null;
};

export type CreateNotificationInput = {
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id?: string | null;
  message?: string | null;
  name: string;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID: string;
  type: NotificationType;
};

export type ModelOrderConditionInput = {
  and?: Array<ModelOrderConditionInput | null> | null;
  clerkID?: ModelIntInput | null;
  courierCheckin?: ModelStringInput | null;
  courierPhone?: ModelStringInput | null;
  courierStatus?: ModelCourierStatusInput | null;
  createdAt?: ModelStringInput | null;
  customerID?: ModelIDInput | null;
  deliveryEstimate?: ModelFloatInput | null;
  deliveryID?: ModelIDInput | null;
  deliveryPickupTime?: ModelStringInput | null;
  deliveryProvider?: ModelDeliveryProviderInput | null;
  deliveryQuoteID?: ModelIDInput | null;
  deliveryTime?: ModelStringInput | null;
  diagnostics?: ModelStringInput | null;
  dueTime?: ModelStringInput | null;
  fascilitatedTenantReportExclusions?: ModelStringInput | null;
  locationID?: ModelIDInput | null;
  not?: ModelOrderConditionInput | null;
  or?: Array<ModelOrderConditionInput | null> | null;
  orderLinkID?: ModelIDInput | null;
  orderNumber?: ModelStringInput | null;
  orderTypeID?: ModelIDInput | null;
  queueMode?: ModelOrderQueueModeInput | null;
  queueTime?: ModelStringInput | null;
  queuedAt?: ModelStringInput | null;
  readyEstimate?: ModelIntInput | null;
  readyTime?: ModelStringInput | null;
  serviceType?: ModelServiceTypeInput | null;
  source?: ModelOrderSourceInput | null;
  status?: ModelOrderStatusInput | null;
  statusDetails?: ModelStringInput | null;
  statusMessage?: ModelStringInput | null;
  submittedAt?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  tenantName?: ModelStringInput | null;
  thirdPartyOrderID?: ModelStringInput | null;
};

export type ModelCourierStatusInput = {
  eq?: CourierStatus | null;
  ne?: CourierStatus | null;
};

export type ModelDeliveryProviderInput = {
  eq?: DeliveryProvider | null;
  ne?: DeliveryProvider | null;
};

export type ModelOrderQueueModeInput = {
  eq?: OrderQueueMode | null;
  ne?: OrderQueueMode | null;
};

export type ModelServiceTypeInput = {
  eq?: ServiceType | null;
  ne?: ServiceType | null;
};

export type ModelOrderSourceInput = {
  eq?: OrderSource | null;
  ne?: OrderSource | null;
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null;
  ne?: OrderStatus | null;
};

export type CreateOrderInput = {
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: CourierDetailsInput | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customerDetails?: CustomerDetailsInput | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<ErrorInput | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id?: string | null;
  locationID: string;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderTypeID?: string | null;
  originAddress?: AddressInput | null;
  originDetails?: OriginDetailsInput | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  venueStatus?: Array<VenueStatusInput | null> | null;
};

export type CourierDetailsInput = {
  errorCode?: number | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  lastUpdate?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  message?: string | null;
  phone?: string | null;
  pickupTime?: string | null;
  status?: CourierStatus | null;
  type?: string | null;
};

export type OriginDetailsInput = {
  city?: string | null;
  country?: string | null;
  distance?: number | null;
  email?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  timezone?: string | null;
  unit?: string | null;
  zip: string;
};

export type VenueStatusInput = {
  status: OrderStatus;
  venueID: string;
};

export type ModelOrderLinkConditionInput = {
  active?: ModelBooleanInput | null;
  adminEmail?: ModelStringInput | null;
  and?: Array<ModelOrderLinkConditionInput | null> | null;
  ccCredentialID?: ModelIDInput | null;
  clerkID?: ModelIntInput | null;
  doNotSell?: ModelStringInput | null;
  domain?: ModelStringInput | null;
  exploreHeading?: ModelStringInput | null;
  exploreSubheading?: ModelStringInput | null;
  fascilitatedTenantReportExclusions?: ModelStringInput | null;
  featuredRestarauntHeading?: ModelStringInput | null;
  featuredRestarauntSubheading?: ModelStringInput | null;
  footerURL?: ModelStringInput | null;
  heading?: ModelStringInput | null;
  imageS3EntryHeaders?: ModelStringInput | null;
  imageS3EntryHeadersMobile?: ModelStringInput | null;
  imageS3Footer?: ModelStringInput | null;
  isDefault?: ModelBooleanInput | null;
  leadTime?: ModelIntInput | null;
  locationID?: ModelIDInput | null;
  menuScollerHeading?: ModelStringInput | null;
  menuScollerSubheading?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelOrderLinkConditionInput | null;
  notice?: ModelStringInput | null;
  noticeAtCollection?: ModelStringInput | null;
  or?: Array<ModelOrderLinkConditionInput | null> | null;
  orderMode?: ModelOrderModeInput | null;
  originEmail?: ModelStringInput | null;
  originLinkFacebook?: ModelStringInput | null;
  originLinkIG?: ModelStringInput | null;
  originName?: ModelStringInput | null;
  originPhone?: ModelStringInput | null;
  popularItemsHeading?: ModelStringInput | null;
  popularItemsSubheading?: ModelStringInput | null;
  popularRestarauntHeading?: ModelStringInput | null;
  popularRestarauntSubheading?: ModelStringInput | null;
  privacyPolicy?: ModelStringInput | null;
  searchPlaceholder?: ModelStringInput | null;
  smsCredentialID?: ModelIDInput | null;
  subHeading?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  tos?: ModelStringInput | null;
};

export type ModelOrderModeInput = {
  eq?: OrderMode | null;
  ne?: OrderMode | null;
};

export type CreateOrderLinkInput = {
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id?: string | null;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  locationID: string;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  name: string;
  notice?: string | null;
  noticeAtCollection?: string | null;
  orderMode?: OrderMode | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  searchPlaceholder?: string | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenantID: string;
  tos?: string | null;
};

export type ModelOrderLinkMenuConditionInput = {
  and?: Array<ModelOrderLinkMenuConditionInput | null> | null;
  menuID?: ModelIDInput | null;
  not?: ModelOrderLinkMenuConditionInput | null;
  or?: Array<ModelOrderLinkMenuConditionInput | null> | null;
  orderLinkID?: ModelIDInput | null;
  sortOrder?: ModelIntInput | null;
};

export type CreateOrderLinkMenuInput = {
  id?: string | null;
  menuID: string;
  orderLinkID: string;
  sortOrder?: number | null;
};

export type ModelOrderLinkNotificationConditionInput = {
  and?: Array<ModelOrderLinkNotificationConditionInput | null> | null;
  event?: ModelNotificationEventInput | null;
  not?: ModelOrderLinkNotificationConditionInput | null;
  notificationID?: ModelIDInput | null;
  or?: Array<ModelOrderLinkNotificationConditionInput | null> | null;
  orderLinkID?: ModelIDInput | null;
};

export type ModelNotificationEventInput = {
  eq?: NotificationEvent | null;
  ne?: NotificationEvent | null;
};

export type CreateOrderLinkNotificationInput = {
  event: NotificationEvent;
  id?: string | null;
  notificationID: string;
  orderLinkID: string;
};

export type ModelOrderLinkOrderTypeConditionInput = {
  and?: Array<ModelOrderLinkOrderTypeConditionInput | null> | null;
  not?: ModelOrderLinkOrderTypeConditionInput | null;
  or?: Array<ModelOrderLinkOrderTypeConditionInput | null> | null;
  orderLinkID?: ModelIDInput | null;
  orderTypeID?: ModelIDInput | null;
};

export type CreateOrderLinkOrderTypeInput = {
  id?: string | null;
  orderLinkID: string;
  orderTypeID: string;
};

export type ModelOrderLinkScheduleConditionInput = {
  and?: Array<ModelOrderLinkScheduleConditionInput | null> | null;
  not?: ModelOrderLinkScheduleConditionInput | null;
  or?: Array<ModelOrderLinkScheduleConditionInput | null> | null;
  orderLinkID?: ModelIDInput | null;
  scheduleID?: ModelIDInput | null;
};

export type CreateOrderLinkScheduleInput = {
  id?: string | null;
  orderLinkID: string;
  scheduleID: string;
};

export type ModelOrderTypeConditionInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelOrderTypeConditionInput | null> | null;
  asap?: ModelBooleanInput | null;
  deliveryProvider?: ModelDeliveryProviderInput | null;
  displayName?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelOrderTypeConditionInput | null;
  or?: Array<ModelOrderTypeConditionInput | null> | null;
  schedule?: ModelBooleanInput | null;
  serviceType?: ModelServiceTypeInput | null;
  source?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
};

export type CreateOrderTypeInput = {
  active?: boolean | null;
  asap?: boolean | null;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  id?: string | null;
  name: string;
  schedule?: boolean | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID: string;
};

export type ModelOrderTypeFeeConditionInput = {
  and?: Array<ModelOrderTypeFeeConditionInput | null> | null;
  feeID?: ModelIDInput | null;
  not?: ModelOrderTypeFeeConditionInput | null;
  or?: Array<ModelOrderTypeFeeConditionInput | null> | null;
  orderTypeID?: ModelIDInput | null;
};

export type CreateOrderTypeFeeInput = {
  feeID: string;
  id?: string | null;
  orderTypeID: string;
};

export type ModelOrderTypeScheduleConditionInput = {
  and?: Array<ModelOrderTypeScheduleConditionInput | null> | null;
  not?: ModelOrderTypeScheduleConditionInput | null;
  or?: Array<ModelOrderTypeScheduleConditionInput | null> | null;
  orderTypeID?: ModelIDInput | null;
  scheduleID?: ModelIDInput | null;
};

export type CreateOrderTypeScheduleInput = {
  id?: string | null;
  orderTypeID: string;
  scheduleID: string;
};

export type ModelPaymentMethodConditionInput = {
  and?: Array<ModelPaymentMethodConditionInput | null> | null;
  cardExpiration?: ModelStringInput | null;
  cardPresent?: ModelBooleanInput | null;
  cardType?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelPaymentMethodConditionInput | null;
  or?: Array<ModelPaymentMethodConditionInput | null> | null;
  processor?: ModelCCProcessorInput | null;
  type?: ModelPaymentMethodTypeInput | null;
  zipcode?: ModelStringInput | null;
};

export type CreatePaymentMethodInput = {
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  name: string;
  processor?: CCProcessor | null;
  token: string;
  type: PaymentMethodType;
  zipcode?: string | null;
};

export type ModelPrintJobConditionInput = {
  and?: Array<ModelPrintJobConditionInput | null> | null;
  data?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  not?: ModelPrintJobConditionInput | null;
  or?: Array<ModelPrintJobConditionInput | null> | null;
  serialNumber?: ModelIDInput | null;
  status?: ModelPrintJobStatusInput | null;
};

export type ModelPrintJobStatusInput = {
  eq?: PrintJobStatus | null;
  ne?: PrintJobStatus | null;
};

export enum PrintJobStatus {
  COMPLETE = "COMPLETE",
  CONSUMED = "CONSUMED",
  PENDING = "PENDING"
}

export type CreatePrintJobInput = {
  data: string;
  expirationUnixTime?: number | null;
  id?: string | null;
  serialNumber: string;
  status?: PrintJobStatus | null;
};

export type PrintJob = {
  __typename: "PrintJob";
  createdAt: string;
  data: string;
  expirationUnixTime?: number | null;
  id: string;
  serialNumber: string;
  status?: PrintJobStatus | null;
  updatedAt: string;
};

export type ModelPrinterConditionInput = {
  and?: Array<ModelPrinterConditionInput | null> | null;
  not?: ModelPrinterConditionInput | null;
  or?: Array<ModelPrinterConditionInput | null> | null;
  status?: ModelPrinterStatusInput | null;
};

export type ModelPrinterStatusInput = {
  eq?: PrinterStatus | null;
  ne?: PrinterStatus | null;
};

export enum PrinterStatus {
  OFFLINE = "OFFLINE",
  READY = "READY"
}

export type CreatePrinterInput = {
  serialNumber: string;
  status?: PrinterStatus | null;
};

export type Printer = {
  __typename: "Printer";
  createdAt: string;
  serialNumber: string;
  status?: PrinterStatus | null;
  updatedAt: string;
};

export type ModelReceiptConfigConditionInput = {
  and?: Array<ModelReceiptConfigConditionInput | null> | null;
  headerSize?: ModelIntInput | null;
  itemSize?: ModelIntInput | null;
  name?: ModelStringInput | null;
  not?: ModelReceiptConfigConditionInput | null;
  or?: Array<ModelReceiptConfigConditionInput | null> | null;
  showDiscounts?: ModelBooleanInput | null;
  showFees?: ModelBooleanInput | null;
  showPayments?: ModelBooleanInput | null;
  showPrice?: ModelBooleanInput | null;
  showQR?: ModelBooleanInput | null;
  showRemaining?: ModelBooleanInput | null;
  showSubtotal?: ModelBooleanInput | null;
  showTaxes?: ModelBooleanInput | null;
  showTips?: ModelBooleanInput | null;
  showTransactions?: ModelBooleanInput | null;
  tenantID?: ModelIDInput | null;
  venueSize?: ModelIntInput | null;
};

export type CreateReceiptConfigInput = {
  headerSize?: number | null;
  id?: string | null;
  itemSize?: number | null;
  name: string;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenantID: string;
  venueSize?: number | null;
};

export type ModelSMSMessageConditionInput = {
  and?: Array<ModelSMSMessageConditionInput | null> | null;
  from?: ModelStringInput | null;
  message?: ModelStringInput | null;
  not?: ModelSMSMessageConditionInput | null;
  or?: Array<ModelSMSMessageConditionInput | null> | null;
  orderID?: ModelIDInput | null;
  tenantID?: ModelIDInput | null;
  to?: ModelStringInput | null;
};

export type CreateSMSMessageInput = {
  from: string;
  id?: string | null;
  message: string;
  orderID?: string | null;
  tenantID: string;
  to: string;
};

export type SMSMessage = {
  __typename: "SMSMessage";
  createdAt: string;
  from: string;
  id: string;
  message: string;
  orderID?: string | null;
  tenantID: string;
  to: string;
  updatedAt: string;
};

export type ModelScheduleConditionInput = {
  and?: Array<ModelScheduleConditionInput | null> | null;
  endDate?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelScheduleConditionInput | null;
  or?: Array<ModelScheduleConditionInput | null> | null;
  startDate?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelScheduleTypeInput | null;
};

export type ModelScheduleTypeInput = {
  eq?: ScheduleType | null;
  ne?: ScheduleType | null;
};

export type CreateScheduleInput = {
  endDate?: string | null;
  id?: string | null;
  name: string;
  startDate?: string | null;
  tenantID: string;
  timeRanges?: Array<TimeRangeInput | null> | null;
  type: ScheduleType;
};

export type TimeRangeInput = {
  day: number;
  endTime: string;
  startTime: string;
};

export type ModelTagConditionInput = {
  and?: Array<ModelTagConditionInput | null> | null;
  imageS3Square?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelTagConditionInput | null;
  or?: Array<ModelTagConditionInput | null> | null;
  tenantID?: ModelIDInput | null;
  type?: ModelTagTypeInput | null;
};

export type ModelTagTypeInput = {
  eq?: TagType | null;
  ne?: TagType | null;
};

export type CreateTagInput = {
  id?: string | null;
  imageS3Square?: string | null;
  name: string;
  tenantID: string;
  type: TagType;
};

export type ModelTaxRateConditionInput = {
  amount?: ModelFloatInput | null;
  and?: Array<ModelTaxRateConditionInput | null> | null;
  not?: ModelTaxRateConditionInput | null;
  or?: Array<ModelTaxRateConditionInput | null> | null;
  rate?: ModelFloatInput | null;
  taxTypeID?: ModelIDInput | null;
  type?: ModelTaxRateTypeInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelTaxRateTypeInput = {
  eq?: TaxRateType | null;
  ne?: TaxRateType | null;
};

export type CreateTaxRateInput = {
  amount?: number | null;
  id?: string | null;
  rate?: number | null;
  taxTypeID: string;
  type: TaxRateType;
  venueID: string;
};

export type ModelTaxTypeConditionInput = {
  and?: Array<ModelTaxTypeConditionInput | null> | null;
  name?: ModelStringInput | null;
  not?: ModelTaxTypeConditionInput | null;
  or?: Array<ModelTaxTypeConditionInput | null> | null;
  tenantID?: ModelIDInput | null;
  type?: ModelTaxTypeTypeInput | null;
};

export type ModelTaxTypeTypeInput = {
  eq?: TaxTypeType | null;
  ne?: TaxTypeType | null;
};

export type CreateTaxTypeInput = {
  id?: string | null;
  name: string;
  tenantID: string;
  type: TaxTypeType;
};

export type ModelTenantConditionInput = {
  and?: Array<ModelTenantConditionInput | null> | null;
  appDomain?: ModelStringInput | null;
  email?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelTenantConditionInput | null;
  or?: Array<ModelTenantConditionInput | null> | null;
  orderLinkDomains?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  shift4ApiKey?: ModelStringInput | null;
  shift4ApiSecret?: ModelStringInput | null;
  type?: ModelTenantTypeInput | null;
};

export type ModelTenantTypeInput = {
  eq?: TenantType | null;
  ne?: TenantType | null;
};

export type CreateTenantInput = {
  appDomain?: string | null;
  email: string;
  id?: string | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
};

export type ModelUserConditionInput = {
  and?: Array<ModelUserConditionInput | null> | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  not?: ModelUserConditionInput | null;
  or?: Array<ModelUserConditionInput | null> | null;
  phone?: ModelStringInput | null;
  userName?: ModelStringInput | null;
};

export type CreateUserInput = {
  email: string;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  phone?: string | null;
  userName: string;
};

export type User = {
  __typename: "User";
  createdAt: string;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  updatedAt: string;
  userName: string;
  userRoles?: ModelUserRoleConnection | null;
};

export type ModelUserRoleConnection = {
  __typename: "ModelUserRoleConnection";
  items: Array<UserRole | null>;
  nextToken?: string | null;
};

export type UserRole = {
  __typename: "UserRole";
  createdAt: string;
  entityType: EntityType;
  id: string;
  tenant?: Tenant | null;
  tenantID?: string | null;
  type: UserRoleType;
  typeID: string;
  updatedAt: string;
  user: User;
  userID: string;
  venue?: Venue | null;
  venueID?: string | null;
};

export type UserRoleType = {
  __typename: "UserRoleType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  updatedAt: string;
  venueID?: string | null;
};

export enum UserRolePermission {
  ACCESS = "ACCESS",
  CONCEPTS_CREATE = "CONCEPTS_CREATE",
  CONCEPTS_DELETE = "CONCEPTS_DELETE",
  CONCEPTS_UPDATE = "CONCEPTS_UPDATE",
  CONCEPTS_VIEW = "CONCEPTS_VIEW",
  CORPORATE_CONFIG = "CORPORATE_CONFIG",
  CREDENTIALS_CREATE = "CREDENTIALS_CREATE",
  CREDENTIALS_DELETE = "CREDENTIALS_DELETE",
  CREDENTIALS_UPDATE = "CREDENTIALS_UPDATE",
  CREDENTIALS_VIEW = "CREDENTIALS_VIEW",
  DEVICES_CREATE = "DEVICES_CREATE",
  DEVICES_DELETE = "DEVICES_DELETE",
  DEVICES_UPDATE = "DEVICES_UPDATE",
  DEVICES_VIEW = "DEVICES_VIEW",
  EMAIL_TEMPLATES_CREATE = "EMAIL_TEMPLATES_CREATE",
  EMAIL_TEMPLATES_DELETE = "EMAIL_TEMPLATES_DELETE",
  EMAIL_TEMPLATES_UPDATE = "EMAIL_TEMPLATES_UPDATE",
  EMAIL_TEMPLATES_VIEW = "EMAIL_TEMPLATES_VIEW",
  EMPLOYEES_CREATE = "EMPLOYEES_CREATE",
  EMPLOYEES_DELETE = "EMPLOYEES_DELETE",
  EMPLOYEES_UPDATE = "EMPLOYEES_UPDATE",
  EMPLOYEES_VIEW = "EMPLOYEES_VIEW",
  FEES_CREATE = "FEES_CREATE",
  FEES_DELETE = "FEES_DELETE",
  FEES_UPDATE = "FEES_UPDATE",
  FEES_VIEW = "FEES_VIEW",
  JOB_TYPES_CREATE = "JOB_TYPES_CREATE",
  JOB_TYPES_DELETE = "JOB_TYPES_DELETE",
  JOB_TYPES_UPDATE = "JOB_TYPES_UPDATE",
  JOB_TYPES_VIEW = "JOB_TYPES_VIEW",
  KIOSK_CONFIG = "KIOSK_CONFIG",
  KIOSK_LAUNCH = "KIOSK_LAUNCH",
  KITCHEN_CONFIG = "KITCHEN_CONFIG",
  KITCHEN_LAUNCH = "KITCHEN_LAUNCH",
  LOCATIONS_CREATE = "LOCATIONS_CREATE",
  LOCATIONS_DELETE = "LOCATIONS_DELETE",
  LOCATIONS_UPDATE = "LOCATIONS_UPDATE",
  LOCATIONS_VIEW = "LOCATIONS_VIEW",
  MENUS_CREATE = "MENUS_CREATE",
  MENUS_DELETE = "MENUS_DELETE",
  MENUS_UPDATE = "MENUS_UPDATE",
  MENUS_VIEW = "MENUS_VIEW",
  MENU_CATEGORIES_CREATE = "MENU_CATEGORIES_CREATE",
  MENU_CATEGORIES_DELETE = "MENU_CATEGORIES_DELETE",
  MENU_CATEGORIES_UPDATE = "MENU_CATEGORIES_UPDATE",
  MENU_CATEGORIES_VIEW = "MENU_CATEGORIES_VIEW",
  MENU_ITEMS_CREATE = "MENU_ITEMS_CREATE",
  MENU_ITEMS_DELETE = "MENU_ITEMS_DELETE",
  MENU_ITEMS_UPDATE = "MENU_ITEMS_UPDATE",
  MENU_ITEMS_VIEW = "MENU_ITEMS_VIEW",
  MENU_MODIFIER_GROUPS_CREATE = "MENU_MODIFIER_GROUPS_CREATE",
  MENU_MODIFIER_GROUPS_DELETE = "MENU_MODIFIER_GROUPS_DELETE",
  MENU_MODIFIER_GROUPS_UPDATE = "MENU_MODIFIER_GROUPS_UPDATE",
  MENU_MODIFIER_GROUPS_VIEW = "MENU_MODIFIER_GROUPS_VIEW",
  NOTIFICATIONS_CREATE = "NOTIFICATIONS_CREATE",
  NOTIFICATIONS_DELETE = "NOTIFICATIONS_DELETE",
  NOTIFICATIONS_UPDATE = "NOTIFICATIONS_UPDATE",
  NOTIFICATIONS_VIEW = "NOTIFICATIONS_VIEW",
  ORDERS_CREATE = "ORDERS_CREATE",
  ORDERS_DELETE = "ORDERS_DELETE",
  ORDERS_UPDATE = "ORDERS_UPDATE",
  ORDERS_VIEW = "ORDERS_VIEW",
  ORDER_LINKS_CREATE = "ORDER_LINKS_CREATE",
  ORDER_LINKS_DELETE = "ORDER_LINKS_DELETE",
  ORDER_LINKS_UPDATE = "ORDER_LINKS_UPDATE",
  ORDER_LINKS_VIEW = "ORDER_LINKS_VIEW",
  ORDER_TYPES_CREATE = "ORDER_TYPES_CREATE",
  ORDER_TYPES_DELETE = "ORDER_TYPES_DELETE",
  ORDER_TYPES_UPDATE = "ORDER_TYPES_UPDATE",
  ORDER_TYPES_VIEW = "ORDER_TYPES_VIEW",
  PAYTRAX_ADMIN = "PAYTRAX_ADMIN",
  PAYTRAX_SIGNIN = "PAYTRAX_SIGNIN",
  POS_CONFIG = "POS_CONFIG",
  POS_LAUNCH = "POS_LAUNCH",
  PRICE_TYPES_CREATE = "PRICE_TYPES_CREATE",
  PRICE_TYPES_DELETE = "PRICE_TYPES_DELETE",
  PRICE_TYPES_UPDATE = "PRICE_TYPES_UPDATE",
  PRICE_TYPES_VIEW = "PRICE_TYPES_VIEW",
  RECEIPT_CONFIG_CREATE = "RECEIPT_CONFIG_CREATE",
  RECEIPT_CONFIG_DELETE = "RECEIPT_CONFIG_DELETE",
  RECEIPT_CONFIG_UPDATE = "RECEIPT_CONFIG_UPDATE",
  RECEIPT_CONFIG_VIEW = "RECEIPT_CONFIG_VIEW",
  REPORTS_CREATE = "REPORTS_CREATE",
  REPORTS_DELETE = "REPORTS_DELETE",
  REPORTS_UPDATE = "REPORTS_UPDATE",
  REPORTS_VIEW = "REPORTS_VIEW",
  SCHEDULES_CREATE = "SCHEDULES_CREATE",
  SCHEDULES_DELETE = "SCHEDULES_DELETE",
  SCHEDULES_UPDATE = "SCHEDULES_UPDATE",
  SCHEDULES_VIEW = "SCHEDULES_VIEW",
  TAGS_CREATE = "TAGS_CREATE",
  TAGS_DELETE = "TAGS_DELETE",
  TAGS_UPDATE = "TAGS_UPDATE",
  TAGS_VIEW = "TAGS_VIEW",
  TAX_TYPES_CREATE = "TAX_TYPES_CREATE",
  TAX_TYPES_DELETE = "TAX_TYPES_DELETE",
  TAX_TYPES_UPDATE = "TAX_TYPES_UPDATE",
  TAX_TYPES_VIEW = "TAX_TYPES_VIEW",
  VENUES_CREATE = "VENUES_CREATE",
  VENUES_DELETE = "VENUES_DELETE",
  VENUES_UPDATE = "VENUES_UPDATE",
  VENUES_VIEW = "VENUES_VIEW"
}

export type ModelUserRoleConditionInput = {
  and?: Array<ModelUserRoleConditionInput | null> | null;
  entityType?: ModelEntityTypeInput | null;
  not?: ModelUserRoleConditionInput | null;
  or?: Array<ModelUserRoleConditionInput | null> | null;
  tenantID?: ModelIDInput | null;
  typeID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type CreateUserRoleInput = {
  entityType: EntityType;
  id?: string | null;
  tenantID?: string | null;
  typeID: string;
  userID: string;
  venueID?: string | null;
};

export type ModelUserRoleTypeConditionInput = {
  and?: Array<ModelUserRoleTypeConditionInput | null> | null;
  name?: ModelStringInput | null;
  not?: ModelUserRoleTypeConditionInput | null;
  or?: Array<ModelUserRoleTypeConditionInput | null> | null;
  permissions?: ModelUserRolePermissionListInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelUserRolePermissionListInput = {
  contains?: UserRolePermission | null;
  eq?: Array<UserRolePermission | null> | null;
  ne?: Array<UserRolePermission | null> | null;
  notContains?: UserRolePermission | null;
};

export type CreateUserRoleTypeInput = {
  id?: string | null;
  name: string;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  venueID?: string | null;
};

export type ModelVenueConditionInput = {
  acceptingOrders?: ModelBooleanInput | null;
  active?: ModelBooleanInput | null;
  and?: Array<ModelVenueConditionInput | null> | null;
  imageS3LogoSquare?: ModelStringInput | null;
  leadTime?: ModelIntInput | null;
  locationID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelVenueConditionInput | null;
  or?: Array<ModelVenueConditionInput | null> | null;
  tenantID?: ModelIDInput | null;
};

export type CreateVenueInput = {
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  id?: string | null;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  locationID?: string | null;
  name: string;
  tenantID: string;
};

export type ModelVenueOrderConditionInput = {
  and?: Array<ModelVenueOrderConditionInput | null> | null;
  createdAt?: ModelStringInput | null;
  dueTime?: ModelStringInput | null;
  not?: ModelVenueOrderConditionInput | null;
  or?: Array<ModelVenueOrderConditionInput | null> | null;
  orderID?: ModelIDInput | null;
  status?: ModelOrderStatusInput | null;
  venueID?: ModelIDInput | null;
  venueName?: ModelStringInput | null;
};

export type CreateVenueOrderInput = {
  createdAt?: string | null;
  dueTime?: string | null;
  id?: string | null;
  orderID: string;
  status?: OrderStatus | null;
  venueID: string;
  venueName?: string | null;
};

export type ModelVenueScheduleConditionInput = {
  and?: Array<ModelVenueScheduleConditionInput | null> | null;
  not?: ModelVenueScheduleConditionInput | null;
  or?: Array<ModelVenueScheduleConditionInput | null> | null;
  scheduleID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type CreateVenueScheduleInput = {
  id?: string | null;
  scheduleID: string;
  venueID: string;
};

export type DCPayAuthOnlyInput = {
  orderID?: string | null;
  orderLinkID: string;
  token: string;
};

export type DCPayRes = {
  __typename: "DCPayRes";
  Account?: string | null;
  AuthCode?: string | null;
  Brand?: string | null;
  RefNo?: string | null;
  Token?: string | null;
};

export type DeleteCheckInput = {
  id: string;
};

export type DeleteCheckItemInput = {
  id: string;
};

export type DeleteCheckPaymentInput = {
  id: string;
};

export type DeleteCheckTransactionInput = {
  id: string;
};

export type DeleteChitInput = {
  id: string;
};

export type DeleteConceptInput = {
  id: string;
};

export type DeleteCredentialInput = {
  id: string;
};

export type DeleteCustomerInput = {
  id: string;
};

export type DeleteCustomerPaymentMethodInput = {
  id: string;
};

export type DeleteDeviceInput = {
  id: string;
};

export type DeleteDeviceTransactionInput = {
  id: string;
};

export type DeleteDomainInput = {
  id: string;
};

export type DeleteEmailTemplateInput = {
  id: string;
};

export type DeleteEmployeeInput = {
  id: string;
};

export type DeleteFeeInput = {
  id: string;
};

export type DeleteJobInput = {
  id: string;
};

export type DeleteJobTypeInput = {
  id: string;
};

export type DeleteLicenseInput = {
  id: string;
};

export type DeleteLicenseVenueInput = {
  id: string;
};

export type DeleteLocationInput = {
  id: string;
};

export type DeleteLogInput = {
  id: string;
};

export type DeleteMenuInput = {
  id: string;
};

export type DeleteMenuCategoryInput = {
  id: string;
};

export type DeleteMenuCategoryMenuItemInput = {
  id: string;
};

export type DeleteMenuCategoryTagInput = {
  id: string;
};

export type DeleteMenuFacilitatorInput = {
  id: string;
};

export type DeleteMenuItemInput = {
  id: string;
};

export type DeleteMenuItemModifierGroupInput = {
  id: string;
};

export type DeleteMenuItemOptionInput = {
  id: string;
};

export type DeleteMenuItemPriceInput = {
  id: string;
};

export type DeleteMenuItemPriceTypeInput = {
  id: string;
};

export type DeleteMenuItemTagInput = {
  id: string;
};

export type DeleteMenuItemTaxTypeInput = {
  id: string;
};

export type DeleteMenuMenuCategoryInput = {
  id: string;
};

export type DeleteMenuScheduleInput = {
  id: string;
};

export type DeleteMenuTagInput = {
  id: string;
};

export type DeleteModifierGroupInput = {
  id: string;
};

export type DeleteModifierGroupMenuItemInput = {
  id: string;
};

export type DeleteModifierGroupTagInput = {
  id: string;
};

export type DeleteNotificationInput = {
  id: string;
};

export type DeleteOrderInput = {
  id: string;
};

export type DeleteOrderLinkInput = {
  id: string;
};

export type DeleteOrderLinkMenuInput = {
  id: string;
};

export type DeleteOrderLinkNotificationInput = {
  id: string;
};

export type DeleteOrderLinkOrderTypeInput = {
  id: string;
};

export type DeleteOrderLinkScheduleInput = {
  id: string;
};

export type DeleteOrderTypeInput = {
  id: string;
};

export type DeleteOrderTypeFeeInput = {
  id: string;
};

export type DeleteOrderTypeScheduleInput = {
  id: string;
};

export type DeletePaymentMethodInput = {
  token: string;
};

export type DeletePrintJobInput = {
  id: string;
};

export type DeletePrinterInput = {
  serialNumber: string;
};

export type DeleteReceiptConfigInput = {
  id: string;
};

export type DeleteSMSMessageInput = {
  id: string;
};

export type DeleteScheduleInput = {
  id: string;
};

export type DeleteTagInput = {
  id: string;
};

export type DeleteTaxRateInput = {
  id: string;
};

export type DeleteTaxTypeInput = {
  id: string;
};

export type DeleteTenantInput = {
  id: string;
};

export type DeleteUserInput = {
  id: string;
};

export type DeleteUserRoleInput = {
  id: string;
};

export type DeleteUserRoleTypeInput = {
  id: string;
};

export type DeleteVenueInput = {
  id: string;
};

export type DeleteVenueOrderInput = {
  id: string;
};

export type DeleteVenueScheduleInput = {
  id: string;
};

export type DeviceTransactionInput = {
  payload: string;
};

export type DeviceTransactionResponse = {
  __typename: "DeviceTransactionResponse";
  status?: string | null;
};

export type GetOrderDeliveryFeeInput = {
  deliveryProvider?: DeliveryProvider | null;
  destination: AddressInput;
  items?: Array<GetOrderDeliveryFeeItemInput | null> | null;
  orderID: string;
  origin: AddressInput;
  pickupTime?: string | null;
  tenantID: string;
  total: number;
};

export type GetOrderDeliveryFeeItemInput = {
  name: string;
  notes?: string | null;
  price: number;
  quantity: number;
  unitPrice: number;
};

export type OrderDeliveryFeeRes = {
  __typename: "OrderDeliveryFeeRes";
  duration?: number | null;
  fee?: number | null;
  pickupTime?: string | null;
  quoteId?: string | null;
};

export type I4GoPreAuthInput = {
  amount: number;
  clientIp: string;
  customerDetails?: CustomerDetailsInput | null;
  orderID?: string | null;
  orderLinkID: string;
};

export type I4GoPreAuthRes = {
  __typename: "I4GoPreAuthRes";
  i4go_accessblock?: string | null;
  i4go_countrycode?: string | null;
  i4go_f21e?: string | null;
  i4go_i4m_url?: string | null;
  i4go_response?: string | null;
  i4go_responsecode?: string | null;
  i4go_server?: string | null;
};

export type PaymentRefundInput = {
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  comp?: boolean | null;
  note?: string | null;
  reason?: CheckTransactionReason | null;
  refunds?: Array<CheckRefundInput | null> | null;
  transactionID: string;
};

export type GenericResponse = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type SESInput = {
  html?: string | null;
  name?: string | null;
  subject?: string | null;
  text?: string | null;
};

export type SubmitOrderInput = {
  checks?: Array<SubmitOrderCheckInput | null> | null;
  clerkID?: number | null;
  customerDetails?: CustomerDetailsInput | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  dueTime?: string | null;
  id: string;
  orderTypeID?: string | null;
  originDetails?: OriginDetailsInput | null;
  queueMode?: OrderQueueMode | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
};

export type SubmitOrderCheckInput = {
  customerDetails?: CustomerDetailsInput | null;
  customerID?: string | null;
  fees?: Array<SubmitOrderFeeInput | null> | null;
  id: string;
  payments?: Array<SubmitOrderPaymentInput | null> | null;
};

export type SubmitOrderFeeInput = {
  amount: number;
  id: string;
  inclusive?: boolean | null;
  name: string;
  taxed?: boolean | null;
  type: FeeType;
};

export type SubmitOrderPaymentInput = {
  amount: number;
  cardPresent?: boolean | null;
  displayName?: string | null;
  isAuthed?: boolean | null;
  refNo?: string | null;
  tip?: number | null;
  token: string;
};

export type OrderRes = {
  __typename: "OrderRes";
  customerDetails?: CustomerDetails | null;
  deliveryEstimate?: number | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  dueTime?: string | null;
  id: string;
  queueTime?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  status?: OrderStatus | null;
};

export type UpdateCheckInput = {
  customerDetails?: CustomerDetailsInput | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<CheckFeeInput | null> | null;
  id: string;
  orderID?: string | null;
  readyEstimate?: number | null;
  taxes?: Array<CheckTaxInput | null> | null;
  tenantID?: string | null;
  totals?: CheckTotalsInput | null;
  venueTotals?: Array<VenueTotalsInput | null> | null;
};

export type UpdateCheckItemInput = {
  alcohol?: boolean | null;
  checkID?: string | null;
  chitID?: string | null;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<CheckItemModifierInput | null> | null;
  name?: string | null;
  price?: number | null;
  priceTypeID?: string | null;
  quantity?: number | null;
  tags?: Array<CheckItemTagInput | null> | null;
  taxes?: Array<CheckItemTaxInput | null> | null;
  unitPrice?: number | null;
  utensils?: boolean | null;
  venueID?: string | null;
  venueName?: string | null;
};

export type UpdateCheckPaymentInput = {
  amount?: number | null;
  cardPresent?: boolean | null;
  checkID?: string | null;
  displayName?: string | null;
  errors?: Array<ErrorInput | null> | null;
  id: string;
  invoice?: number | null;
  isAuthed?: boolean | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  refNo?: string | null;
  status?: CheckPaymentStatus | null;
  tip?: number | null;
  token?: string | null;
};

export type UpdateCheckTransactionInput = {
  amount?: number | null;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  checkID?: string | null;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<ErrorInput | null> | null;
  id: string;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<CheckRefundInput | null> | null;
  status?: CheckTransactionStatus | null;
  thirdPartyData?: string | null;
  token?: string | null;
  type?: CCTransactionType | null;
  venueID?: string | null;
};

export type UpdateChitInput = {
  createdAt?: string | null;
  errors?: Array<ErrorInput | null> | null;
  id: string;
  items?: Array<ChitItemInput | null> | null;
  orderID?: string | null;
  status?: ChitStatus | null;
  tenantID?: string | null;
  venueID?: string | null;
  venueName?: string | null;
};

export type UpdateConceptInput = {
  id: string;
  name?: string | null;
  tenantID?: string | null;
};

export type UpdateCredentialInput = {
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id: string;
  key?: string | null;
  secret?: string | null;
  smsFrom?: string | null;
  tenantID?: string | null;
  type?: CredentialType | null;
  venueID?: string | null;
};

export type UpdateCustomerInput = {
  addresses?: Array<AddressInput | null> | null;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  userName?: string | null;
};

export type UpdateCustomerPaymentMethodInput = {
  customerID?: string | null;
  id: string;
  paymentMethodID?: string | null;
};

export type UpdateDeviceInput = {
  active?: boolean | null;
  autoPrint?: boolean | null;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id: string;
  ipAddress?: string | null;
  licenseID?: string | null;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type?: DeviceType | null;
};

export type UpdateDeviceTransactionInput = {
  data?: string | null;
  deviceID?: string | null;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID?: string | null;
  type?: DeviceTransactionType | null;
};

export type UpdateDomainInput = {
  host?: string | null;
  id: string;
  imageS3Logo?: string | null;
};

export type UpdateEmailTemplateInput = {
  html?: string | null;
  id: string;
  name?: string | null;
  subject?: string | null;
  tenantID?: string | null;
  text?: string | null;
};

export type UpdateEmployeeInput = {
  active?: boolean | null;
  code?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  tenantID?: string | null;
};

export type UpdateFeeInput = {
  amount?: number | null;
  id: string;
  inclusive?: boolean | null;
  name?: string | null;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID?: string | null;
  type?: FeeType | null;
};

export type UpdateJobInput = {
  employeeID?: string | null;
  entityType?: EntityType | null;
  id: string;
  payRate?: number | null;
  tenantID?: string | null;
  typeID?: string | null;
  venueID?: string | null;
};

export type UpdateJobTypeInput = {
  id: string;
  name?: string | null;
  permissions?: Array<JobPermission | null> | null;
  tenantID?: string | null;
  venueID?: string | null;
};

export type UpdateLicenseInput = {
  active?: boolean | null;
  deviceDetails?: DeviceDetailsInput | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: KioskConfigInput | null;
  kitchenConfig?: KitchenConfigInput | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenantID?: string | null;
  type?: DeviceType | null;
};

export type UpdateLicenseVenueInput = {
  id: string;
  licenseID?: string | null;
  venueID?: string | null;
};

export type UpdateLocationInput = {
  city?: string | null;
  country?: string | null;
  email?: string | null;
  endOfDay?: string | null;
  id: string;
  latitude?: number | null;
  longitude?: number | null;
  name?: string | null;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  tenantID?: string | null;
  timezone?: string | null;
  unit?: string | null;
  zip?: string | null;
};

export type UpdateLogInput = {
  checkID?: string | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type?: LogType | null;
  userID?: string | null;
  venueID?: string | null;
};

export type UpdateMenuInput = {
  active?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  displayName?: string | null;
  featured?: boolean | null;
  id: string;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name?: string | null;
  popular?: boolean | null;
  tenantID?: string | null;
  venueID?: string | null;
};

export type UpdateMenuCategoryInput = {
  active?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  displayName?: string | null;
  id: string;
  name?: string | null;
  priceTypeID?: string | null;
  tenantID?: string | null;
  venueID?: string | null;
};

export type UpdateMenuCategoryMenuItemInput = {
  id: string;
  menuCategoryID?: string | null;
  menuItemID?: string | null;
  sortOrder?: number | null;
};

export type UpdateMenuCategoryTagInput = {
  id: string;
  menuCategoryID?: string | null;
  tagID?: string | null;
};

export type UpdateMenuFacilitatorInput = {
  facilitatorID?: string | null;
  id: string;
  menuID?: string | null;
};

export type UpdateMenuItemInput = {
  active?: boolean | null;
  alcohol?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  id: string;
  imageS3?: string | null;
  name?: string | null;
  popular?: boolean | null;
  prepTime?: number | null;
  tenantID?: string | null;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type UpdateMenuItemModifierGroupInput = {
  id: string;
  menuItemID?: string | null;
  modifierGroupID?: string | null;
  sortOrder?: number | null;
};

export type UpdateMenuItemOptionInput = {
  id: string;
  menuItemID?: string | null;
  name?: string | null;
};

export type UpdateMenuItemPriceInput = {
  active?: boolean | null;
  id: string;
  menuItemID?: string | null;
  menuItemOptionID?: string | null;
  price?: number | null;
  priceTypeID?: string | null;
};

export type UpdateMenuItemPriceTypeInput = {
  id: string;
  name?: string | null;
  tenantID?: string | null;
};

export type UpdateMenuItemTagInput = {
  id: string;
  menuItemID?: string | null;
  tagID?: string | null;
};

export type UpdateMenuItemTaxTypeInput = {
  id: string;
  menuItemID?: string | null;
  taxTypeID?: string | null;
};

export type UpdateMenuMenuCategoryInput = {
  id: string;
  menuCategoryID?: string | null;
  menuID?: string | null;
  sortOrder?: number | null;
};

export type UpdateMenuScheduleInput = {
  id: string;
  menuID?: string | null;
  scheduleID?: string | null;
};

export type UpdateMenuTagInput = {
  id: string;
  menuID?: string | null;
  tagID?: string | null;
};

export type UpdateModifierGroupInput = {
  active?: boolean | null;
  conceptID?: string | null;
  description?: string | null;
  displayName?: string | null;
  id: string;
  max?: number | null;
  min?: number | null;
  name?: string | null;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tenantID?: string | null;
  venueID?: string | null;
};

export type UpdateModifierGroupMenuItemInput = {
  id: string;
  menuItemID?: string | null;
  modifierGroupID?: string | null;
};

export type UpdateModifierGroupTagInput = {
  id: string;
  modifierGroupID?: string | null;
  tagID?: string | null;
};

export type UpdateNotificationInput = {
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id: string;
  message?: string | null;
  name?: string | null;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID?: string | null;
  type?: NotificationType | null;
};

export type UpdateOrderInput = {
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: CourierDetailsInput | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customerDetails?: CustomerDetailsInput | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<ErrorInput | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  locationID?: string | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderTypeID?: string | null;
  originAddress?: AddressInput | null;
  originDetails?: OriginDetailsInput | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenantID?: string | null;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  venueStatus?: Array<VenueStatusInput | null> | null;
};

export type UpdateOrderLinkInput = {
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  locationID?: string | null;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  name?: string | null;
  notice?: string | null;
  noticeAtCollection?: string | null;
  orderMode?: OrderMode | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  searchPlaceholder?: string | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenantID?: string | null;
  tos?: string | null;
};

export type UpdateOrderLinkMenuInput = {
  id: string;
  menuID?: string | null;
  orderLinkID?: string | null;
  sortOrder?: number | null;
};

export type UpdateOrderLinkNotificationInput = {
  event?: NotificationEvent | null;
  id: string;
  notificationID?: string | null;
  orderLinkID?: string | null;
};

export type UpdateOrderLinkOrderTypeInput = {
  id: string;
  orderLinkID?: string | null;
  orderTypeID?: string | null;
};

export type UpdateOrderLinkScheduleInput = {
  id: string;
  orderLinkID?: string | null;
  scheduleID?: string | null;
};

export type UpdateOrderOrderTypeInput = {
  id: string;
  orderTypeID: string;
};

export type UpdateOrderTypeInput = {
  active?: boolean | null;
  asap?: boolean | null;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  id: string;
  name?: string | null;
  schedule?: boolean | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID?: string | null;
};

export type UpdateOrderTypeFeeInput = {
  feeID?: string | null;
  id: string;
  orderTypeID?: string | null;
};

export type UpdateOrderTypeScheduleInput = {
  id: string;
  orderTypeID?: string | null;
  scheduleID?: string | null;
};

export type UpdatePaymentMethodInput = {
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  name?: string | null;
  processor?: CCProcessor | null;
  token: string;
  type?: PaymentMethodType | null;
  zipcode?: string | null;
};

export type UpdatePrintJobInput = {
  data?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  serialNumber?: string | null;
  status?: PrintJobStatus | null;
};

export type UpdatePrinterInput = {
  serialNumber: string;
  status?: PrinterStatus | null;
};

export type UpdateReceiptConfigInput = {
  headerSize?: number | null;
  id: string;
  itemSize?: number | null;
  name?: string | null;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenantID?: string | null;
  venueSize?: number | null;
};

export type UpdateSMSMessageInput = {
  from?: string | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  tenantID?: string | null;
  to?: string | null;
};

export type UpdateScheduleInput = {
  endDate?: string | null;
  id: string;
  name?: string | null;
  startDate?: string | null;
  tenantID?: string | null;
  timeRanges?: Array<TimeRangeInput | null> | null;
  type?: ScheduleType | null;
};

export type UpdateTagInput = {
  id: string;
  imageS3Square?: string | null;
  name?: string | null;
  tenantID?: string | null;
  type?: TagType | null;
};

export type UpdateTaxRateInput = {
  amount?: number | null;
  id: string;
  rate?: number | null;
  taxTypeID?: string | null;
  type?: TaxRateType | null;
  venueID?: string | null;
};

export type UpdateTaxTypeInput = {
  id: string;
  name?: string | null;
  tenantID?: string | null;
  type?: TaxTypeType | null;
};

export type UpdateTenantInput = {
  appDomain?: string | null;
  email?: string | null;
  id: string;
  name?: string | null;
  orderLinkDomains?: Array<string | null> | null;
  phone?: string | null;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type?: TenantType | null;
};

export type UpdateUserInput = {
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  userName?: string | null;
};

export type UpdateUserRoleInput = {
  entityType?: EntityType | null;
  id: string;
  tenantID?: string | null;
  typeID?: string | null;
  userID?: string | null;
  venueID?: string | null;
};

export type UpdateUserRoleTypeInput = {
  id: string;
  name?: string | null;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  venueID?: string | null;
};

export type UpdateVenueInput = {
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  locationID?: string | null;
  name?: string | null;
  tenantID?: string | null;
};

export type UpdateVenueOrderInput = {
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  orderID?: string | null;
  status?: OrderStatus | null;
  venueID?: string | null;
  venueName?: string | null;
};

export type UpdateVenueScheduleInput = {
  id: string;
  scheduleID?: string | null;
  venueID?: string | null;
};

export type ModelOrderLinkFilterInput = {
  active?: ModelBooleanInput | null;
  adminEmail?: ModelStringInput | null;
  and?: Array<ModelOrderLinkFilterInput | null> | null;
  ccCredentialID?: ModelIDInput | null;
  clerkID?: ModelIntInput | null;
  doNotSell?: ModelStringInput | null;
  domain?: ModelStringInput | null;
  exploreHeading?: ModelStringInput | null;
  exploreSubheading?: ModelStringInput | null;
  fascilitatedTenantReportExclusions?: ModelStringInput | null;
  featuredRestarauntHeading?: ModelStringInput | null;
  featuredRestarauntSubheading?: ModelStringInput | null;
  footerURL?: ModelStringInput | null;
  heading?: ModelStringInput | null;
  id?: ModelIDInput | null;
  imageS3EntryHeaders?: ModelStringInput | null;
  imageS3EntryHeadersMobile?: ModelStringInput | null;
  imageS3Footer?: ModelStringInput | null;
  isDefault?: ModelBooleanInput | null;
  leadTime?: ModelIntInput | null;
  locationID?: ModelIDInput | null;
  menuScollerHeading?: ModelStringInput | null;
  menuScollerSubheading?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelOrderLinkFilterInput | null;
  notice?: ModelStringInput | null;
  noticeAtCollection?: ModelStringInput | null;
  or?: Array<ModelOrderLinkFilterInput | null> | null;
  orderMode?: ModelOrderModeInput | null;
  originEmail?: ModelStringInput | null;
  originLinkFacebook?: ModelStringInput | null;
  originLinkIG?: ModelStringInput | null;
  originName?: ModelStringInput | null;
  originPhone?: ModelStringInput | null;
  popularItemsHeading?: ModelStringInput | null;
  popularItemsSubheading?: ModelStringInput | null;
  popularRestarauntHeading?: ModelStringInput | null;
  popularRestarauntSubheading?: ModelStringInput | null;
  privacyPolicy?: ModelStringInput | null;
  searchPlaceholder?: ModelStringInput | null;
  smsCredentialID?: ModelIDInput | null;
  subHeading?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  tos?: ModelStringInput | null;
};

export type ModelOrderLinkConnection = {
  __typename: "ModelOrderLinkConnection";
  items: Array<OrderLink | null>;
  nextToken?: string | null;
};

export type ModelCheckItemFilterInput = {
  alcohol?: ModelBooleanInput | null;
  and?: Array<ModelCheckItemFilterInput | null> | null;
  checkID?: ModelIDInput | null;
  chitID?: ModelIDInput | null;
  id?: ModelIDInput | null;
  instructions?: ModelStringInput | null;
  menuID?: ModelIDInput | null;
  menuItemID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelCheckItemFilterInput | null;
  or?: Array<ModelCheckItemFilterInput | null> | null;
  price?: ModelFloatInput | null;
  priceTypeID?: ModelIDInput | null;
  quantity?: ModelFloatInput | null;
  unitPrice?: ModelFloatInput | null;
  utensils?: ModelBooleanInput | null;
  venueID?: ModelIDInput | null;
  venueName?: ModelStringInput | null;
};

export type ModelCheckTransactionFilterInput = {
  amount?: ModelFloatInput | null;
  amountFees?: ModelFloatInput | null;
  amountItems?: ModelFloatInput | null;
  amountTax?: ModelFloatInput | null;
  amountTip?: ModelFloatInput | null;
  and?: Array<ModelCheckTransactionFilterInput | null> | null;
  authCode?: ModelStringInput | null;
  cardNumber?: ModelStringInput | null;
  cardType?: ModelStringInput | null;
  checkID?: ModelIDInput | null;
  checkItemIDs?: ModelIDInput | null;
  comp?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  id?: ModelIDInput | null;
  invoice?: ModelIntInput | null;
  last4?: ModelIntInput | null;
  not?: ModelCheckTransactionFilterInput | null;
  note?: ModelStringInput | null;
  or?: Array<ModelCheckTransactionFilterInput | null> | null;
  paymentID?: ModelIDInput | null;
  paymentMethodID?: ModelIDInput | null;
  paymentMethodType?: ModelPaymentMethodTypeInput | null;
  processor?: ModelCCProcessorInput | null;
  processorCredentialID?: ModelIDInput | null;
  reason?: ModelCheckTransactionReasonInput | null;
  refNo?: ModelStringInput | null;
  status?: ModelCheckTransactionStatusInput | null;
  thirdPartyData?: ModelStringInput | null;
  token?: ModelStringInput | null;
  type?: ModelCCTransactionTypeInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelCheckTransactionByVenueByTypeByDateCompositeKeyConditionInput = {
  beginsWith?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null;
  between?: Array<ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null> | null;
  eq?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null;
  ge?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null;
  gt?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null;
  le?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null;
  lt?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput | null;
};

export type ModelCheckTransactionByVenueByTypeByDateCompositeKeyInput = {
  createdAt?: string | null;
  type?: CCTransactionType | null;
};

export type ModelCheckFilterInput = {
  and?: Array<ModelCheckFilterInput | null> | null;
  customerID?: ModelIDInput | null;
  diagnostics?: ModelStringInput | null;
  id?: ModelIDInput | null;
  not?: ModelCheckFilterInput | null;
  or?: Array<ModelCheckFilterInput | null> | null;
  orderID?: ModelIDInput | null;
  readyEstimate?: ModelIntInput | null;
  tenantID?: ModelIDInput | null;
};

export type ModelChitFilterInput = {
  and?: Array<ModelChitFilterInput | null> | null;
  createdAt?: ModelStringInput | null;
  id?: ModelIDInput | null;
  not?: ModelChitFilterInput | null;
  or?: Array<ModelChitFilterInput | null> | null;
  orderID?: ModelIDInput | null;
  status?: ModelChitStatusInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
  venueName?: ModelStringInput | null;
};

export type ModelChitByVenueByStatusByDateCompositeKeyConditionInput = {
  beginsWith?: ModelChitByVenueByStatusByDateCompositeKeyInput | null;
  between?: Array<ModelChitByVenueByStatusByDateCompositeKeyInput | null> | null;
  eq?: ModelChitByVenueByStatusByDateCompositeKeyInput | null;
  ge?: ModelChitByVenueByStatusByDateCompositeKeyInput | null;
  gt?: ModelChitByVenueByStatusByDateCompositeKeyInput | null;
  le?: ModelChitByVenueByStatusByDateCompositeKeyInput | null;
  lt?: ModelChitByVenueByStatusByDateCompositeKeyInput | null;
};

export type ModelChitByVenueByStatusByDateCompositeKeyInput = {
  createdAt?: string | null;
  status?: ChitStatus | null;
};

export type ModelConceptFilterInput = {
  and?: Array<ModelConceptFilterInput | null> | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelConceptFilterInput | null;
  or?: Array<ModelConceptFilterInput | null> | null;
  tenantID?: ModelIDInput | null;
};

export type ModelConceptConnection = {
  __typename: "ModelConceptConnection";
  items: Array<Concept | null>;
  nextToken?: string | null;
};

export type ModelCredentialFilterInput = {
  and?: Array<ModelCredentialFilterInput | null> | null;
  dcEcomMID?: ModelIDInput | null;
  dcMID?: ModelIDInput | null;
  dcTokenKey?: ModelIDInput | null;
  id?: ModelIDInput | null;
  key?: ModelStringInput | null;
  not?: ModelCredentialFilterInput | null;
  or?: Array<ModelCredentialFilterInput | null> | null;
  secret?: ModelStringInput | null;
  smsFrom?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelCredentialTypeInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelDeviceTransactionFilterInput = {
  and?: Array<ModelDeviceTransactionFilterInput | null> | null;
  data?: ModelStringInput | null;
  deviceID?: ModelIDInput | null;
  event?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  id?: ModelIDInput | null;
  not?: ModelDeviceTransactionFilterInput | null;
  or?: Array<ModelDeviceTransactionFilterInput | null> | null;
  status?: ModelDeviceTransactionStatusInput | null;
  transactionID?: ModelIDInput | null;
  type?: ModelDeviceTransactionTypeInput | null;
};

export type ModelDeviceTransactionConnection = {
  __typename: "ModelDeviceTransactionConnection";
  items: Array<DeviceTransaction | null>;
  nextToken?: string | null;
};

export type ModelDeviceFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelDeviceFilterInput | null> | null;
  autoPrint?: ModelBooleanInput | null;
  deviceID?: ModelIDInput | null;
  displayName?: ModelStringInput | null;
  host?: ModelStringInput | null;
  id?: ModelIDInput | null;
  ipAddress?: ModelStringInput | null;
  licenseID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelDeviceFilterInput | null;
  or?: Array<ModelDeviceFilterInput | null> | null;
  port?: ModelIntInput | null;
  printOnBump?: ModelBooleanInput | null;
  serialNumber?: ModelIDInput | null;
  type?: ModelDeviceTypeInput | null;
};

export type ModelDomainFilterInput = {
  and?: Array<ModelDomainFilterInput | null> | null;
  host?: ModelStringInput | null;
  id?: ModelIDInput | null;
  imageS3Logo?: ModelStringInput | null;
  not?: ModelDomainFilterInput | null;
  or?: Array<ModelDomainFilterInput | null> | null;
};

export type ModelDomainConnection = {
  __typename: "ModelDomainConnection";
  items: Array<Domain | null>;
  nextToken?: string | null;
};

export type ModelEmailTemplateFilterInput = {
  and?: Array<ModelEmailTemplateFilterInput | null> | null;
  html?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelEmailTemplateFilterInput | null;
  or?: Array<ModelEmailTemplateFilterInput | null> | null;
  subject?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  text?: ModelStringInput | null;
};

export type ModelEmailTemplateConnection = {
  __typename: "ModelEmailTemplateConnection";
  items: Array<EmailTemplate | null>;
  nextToken?: string | null;
};

export type ModelEmployeeFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelEmployeeFilterInput | null> | null;
  code?: ModelStringInput | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  lastName?: ModelStringInput | null;
  not?: ModelEmployeeFilterInput | null;
  or?: Array<ModelEmployeeFilterInput | null> | null;
  phone?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
};

export type ModelEmployeeConnection = {
  __typename: "ModelEmployeeConnection";
  items: Array<Employee | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
};

export type ModelFeeFilterInput = {
  amount?: ModelFloatInput | null;
  and?: Array<ModelFeeFilterInput | null> | null;
  id?: ModelIDInput | null;
  inclusive?: ModelBooleanInput | null;
  name?: ModelStringInput | null;
  not?: ModelFeeFilterInput | null;
  or?: Array<ModelFeeFilterInput | null> | null;
  rate?: ModelFloatInput | null;
  taxed?: ModelBooleanInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelFeeTypeInput | null;
};

export type ModelFeeConnection = {
  __typename: "ModelFeeConnection";
  items: Array<Fee | null>;
  nextToken?: string | null;
};

export type ModelJobTypeFilterInput = {
  and?: Array<ModelJobTypeFilterInput | null> | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelJobTypeFilterInput | null;
  or?: Array<ModelJobTypeFilterInput | null> | null;
  permissions?: ModelJobPermissionListInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelJobTypeConnection = {
  __typename: "ModelJobTypeConnection";
  items: Array<JobType | null>;
  nextToken?: string | null;
};

export type ModelJobFilterInput = {
  and?: Array<ModelJobFilterInput | null> | null;
  employeeID?: ModelIDInput | null;
  entityType?: ModelEntityTypeInput | null;
  id?: ModelIDInput | null;
  not?: ModelJobFilterInput | null;
  or?: Array<ModelJobFilterInput | null> | null;
  payRate?: ModelFloatInput | null;
  tenantID?: ModelIDInput | null;
  typeID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelLicenseFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelLicenseFilterInput | null> | null;
  displayName?: ModelStringInput | null;
  guid?: ModelIDInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelLicenseFilterInput | null;
  or?: Array<ModelLicenseFilterInput | null> | null;
  pollingInterval?: ModelIntInput | null;
  receiptConfigID?: ModelIDInput | null;
  status?: ModelLicenseStatusInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelDeviceTypeInput | null;
};

export type ModelLicenseConnection = {
  __typename: "ModelLicenseConnection";
  items: Array<License | null>;
  nextToken?: string | null;
};

export type ModelLocationFilterInput = {
  and?: Array<ModelLocationFilterInput | null> | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  email?: ModelStringInput | null;
  endOfDay?: ModelStringInput | null;
  id?: ModelIDInput | null;
  latitude?: ModelFloatInput | null;
  longitude?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  not?: ModelLocationFilterInput | null;
  or?: Array<ModelLocationFilterInput | null> | null;
  phone?: ModelStringInput | null;
  state?: ModelStringInput | null;
  street?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  timezone?: ModelStringInput | null;
  unit?: ModelStringInput | null;
  zip?: ModelStringInput | null;
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection";
  items: Array<Location | null>;
  nextToken?: string | null;
};

export type ModelLogFilterInput = {
  and?: Array<ModelLogFilterInput | null> | null;
  checkID?: ModelIDInput | null;
  employeeID?: ModelIDInput | null;
  event?: ModelLogEventInput | null;
  id?: ModelIDInput | null;
  message?: ModelStringInput | null;
  not?: ModelLogFilterInput | null;
  or?: Array<ModelLogFilterInput | null> | null;
  orderID?: ModelIDInput | null;
  source?: ModelLogSourceInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelLogTypeInput | null;
  userID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelMenuCategoryFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelMenuCategoryFilterInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuCategoryFilterInput | null;
  or?: Array<ModelMenuCategoryFilterInput | null> | null;
  priceTypeID?: ModelIDInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelMenuItemPriceTypeFilterInput = {
  and?: Array<ModelMenuItemPriceTypeFilterInput | null> | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuItemPriceTypeFilterInput | null;
  or?: Array<ModelMenuItemPriceTypeFilterInput | null> | null;
  tenantID?: ModelIDInput | null;
};

export type ModelMenuItemPriceTypeConnection = {
  __typename: "ModelMenuItemPriceTypeConnection";
  items: Array<MenuItemPriceType | null>;
  nextToken?: string | null;
};

export type ModelMenuItemFilterInput = {
  active?: ModelBooleanInput | null;
  alcohol?: ModelBooleanInput | null;
  and?: Array<ModelMenuItemFilterInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  id?: ModelIDInput | null;
  imageS3?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuItemFilterInput | null;
  or?: Array<ModelMenuItemFilterInput | null> | null;
  popular?: ModelBooleanInput | null;
  prepTime?: ModelIntInput | null;
  tenantID?: ModelIDInput | null;
  utensils?: ModelBooleanInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelMenuFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelMenuFilterInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  featured?: ModelBooleanInput | null;
  id?: ModelIDInput | null;
  imageS3Featured?: ModelStringInput | null;
  imageS3Headers?: ModelStringInput | null;
  imageS3HeadersMobile?: ModelStringInput | null;
  imageS3LogoSquare?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelMenuFilterInput | null;
  or?: Array<ModelMenuFilterInput | null> | null;
  popular?: ModelBooleanInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelModifierGroupFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelModifierGroupFilterInput | null> | null;
  conceptID?: ModelIDInput | null;
  description?: ModelStringInput | null;
  displayName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  max?: ModelIntInput | null;
  min?: ModelIntInput | null;
  name?: ModelStringInput | null;
  not?: ModelModifierGroupFilterInput | null;
  or?: Array<ModelModifierGroupFilterInput | null> | null;
  priceTypeID?: ModelIDInput | null;
  selectionType?: ModelSelectionTypeInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelNotificationFilterInput = {
  and?: Array<ModelNotificationFilterInput | null> | null;
  emailBCC?: ModelStringInput | null;
  emailCC?: ModelStringInput | null;
  emailFrom?: ModelStringInput | null;
  emailReplyTo?: ModelStringInput | null;
  emailTemplateData?: ModelStringInput | null;
  emailTemplateID?: ModelIDInput | null;
  emailTo?: ModelStringInput | null;
  id?: ModelIDInput | null;
  message?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelNotificationFilterInput | null;
  or?: Array<ModelNotificationFilterInput | null> | null;
  smsFrom?: ModelStringInput | null;
  smsTo?: ModelStringInput | null;
  smsToDynamic?: ModelStringInput | null;
  smsToType?: ModelPropertyTypeInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelNotificationTypeInput | null;
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection";
  items: Array<Notification | null>;
  nextToken?: string | null;
};

export type ModelOrderTypeFilterInput = {
  active?: ModelBooleanInput | null;
  and?: Array<ModelOrderTypeFilterInput | null> | null;
  asap?: ModelBooleanInput | null;
  deliveryProvider?: ModelDeliveryProviderInput | null;
  displayName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelOrderTypeFilterInput | null;
  or?: Array<ModelOrderTypeFilterInput | null> | null;
  schedule?: ModelBooleanInput | null;
  serviceType?: ModelServiceTypeInput | null;
  source?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
};

export type ModelOrderTypeConnection = {
  __typename: "ModelOrderTypeConnection";
  items: Array<OrderType | null>;
  nextToken?: string | null;
};

export type ModelOrderFilterInput = {
  and?: Array<ModelOrderFilterInput | null> | null;
  clerkID?: ModelIntInput | null;
  courierCheckin?: ModelStringInput | null;
  courierPhone?: ModelStringInput | null;
  courierStatus?: ModelCourierStatusInput | null;
  createdAt?: ModelStringInput | null;
  customerID?: ModelIDInput | null;
  deliveryEstimate?: ModelFloatInput | null;
  deliveryID?: ModelIDInput | null;
  deliveryPickupTime?: ModelStringInput | null;
  deliveryProvider?: ModelDeliveryProviderInput | null;
  deliveryQuoteID?: ModelIDInput | null;
  deliveryTime?: ModelStringInput | null;
  diagnostics?: ModelStringInput | null;
  dueTime?: ModelStringInput | null;
  fascilitatedTenantReportExclusions?: ModelStringInput | null;
  id?: ModelIDInput | null;
  locationID?: ModelIDInput | null;
  not?: ModelOrderFilterInput | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  orderLinkID?: ModelIDInput | null;
  orderNumber?: ModelStringInput | null;
  orderTypeID?: ModelIDInput | null;
  queueMode?: ModelOrderQueueModeInput | null;
  queueTime?: ModelStringInput | null;
  queuedAt?: ModelStringInput | null;
  readyEstimate?: ModelIntInput | null;
  readyTime?: ModelStringInput | null;
  serviceType?: ModelServiceTypeInput | null;
  source?: ModelOrderSourceInput | null;
  status?: ModelOrderStatusInput | null;
  statusDetails?: ModelStringInput | null;
  statusMessage?: ModelStringInput | null;
  submittedAt?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  tenantName?: ModelStringInput | null;
  thirdPartyOrderID?: ModelStringInput | null;
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection";
  items: Array<Order | null>;
  nextToken?: string | null;
};

export type ModelOrderByLocationByServiceTypeByStatusCompositeKeyConditionInput = {
  beginsWith?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null;
  between?: Array<ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null> | null;
  eq?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null;
  ge?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null;
  gt?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null;
  le?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null;
  lt?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput | null;
};

export type ModelOrderByLocationByServiceTypeByStatusCompositeKeyInput = {
  serviceType?: ServiceType | null;
  status?: OrderStatus | null;
};

export type ModelOrderByTenantByStatusByDueTimeCompositeKeyConditionInput = {
  beginsWith?: ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null;
  between?: Array<ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null> | null;
  eq?: ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null;
  ge?: ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null;
  gt?: ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null;
  le?: ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null;
  lt?: ModelOrderByTenantByStatusByDueTimeCompositeKeyInput | null;
};

export type ModelOrderByTenantByStatusByDueTimeCompositeKeyInput = {
  dueTime?: string | null;
  status?: OrderStatus | null;
};

export type ModelPaymentMethodFilterInput = {
  and?: Array<ModelPaymentMethodFilterInput | null> | null;
  cardExpiration?: ModelStringInput | null;
  cardPresent?: ModelBooleanInput | null;
  cardType?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelPaymentMethodFilterInput | null;
  or?: Array<ModelPaymentMethodFilterInput | null> | null;
  processor?: ModelCCProcessorInput | null;
  token?: ModelIDInput | null;
  type?: ModelPaymentMethodTypeInput | null;
  zipcode?: ModelStringInput | null;
};

export type ModelPaymentMethodConnection = {
  __typename: "ModelPaymentMethodConnection";
  items: Array<PaymentMethod | null>;
  nextToken?: string | null;
};

export type ModelPrintJobFilterInput = {
  and?: Array<ModelPrintJobFilterInput | null> | null;
  data?: ModelStringInput | null;
  expirationUnixTime?: ModelIntInput | null;
  id?: ModelIDInput | null;
  not?: ModelPrintJobFilterInput | null;
  or?: Array<ModelPrintJobFilterInput | null> | null;
  serialNumber?: ModelIDInput | null;
  status?: ModelPrintJobStatusInput | null;
};

export type ModelPrintJobConnection = {
  __typename: "ModelPrintJobConnection";
  items: Array<PrintJob | null>;
  nextToken?: string | null;
};

export type ModelPrinterFilterInput = {
  and?: Array<ModelPrinterFilterInput | null> | null;
  not?: ModelPrinterFilterInput | null;
  or?: Array<ModelPrinterFilterInput | null> | null;
  serialNumber?: ModelIDInput | null;
  status?: ModelPrinterStatusInput | null;
};

export type ModelPrinterConnection = {
  __typename: "ModelPrinterConnection";
  items: Array<Printer | null>;
  nextToken?: string | null;
};

export type ModelReceiptConfigFilterInput = {
  and?: Array<ModelReceiptConfigFilterInput | null> | null;
  headerSize?: ModelIntInput | null;
  id?: ModelIDInput | null;
  itemSize?: ModelIntInput | null;
  name?: ModelStringInput | null;
  not?: ModelReceiptConfigFilterInput | null;
  or?: Array<ModelReceiptConfigFilterInput | null> | null;
  showDiscounts?: ModelBooleanInput | null;
  showFees?: ModelBooleanInput | null;
  showPayments?: ModelBooleanInput | null;
  showPrice?: ModelBooleanInput | null;
  showQR?: ModelBooleanInput | null;
  showRemaining?: ModelBooleanInput | null;
  showSubtotal?: ModelBooleanInput | null;
  showTaxes?: ModelBooleanInput | null;
  showTips?: ModelBooleanInput | null;
  showTransactions?: ModelBooleanInput | null;
  tenantID?: ModelIDInput | null;
  venueSize?: ModelIntInput | null;
};

export type ModelReceiptConfigConnection = {
  __typename: "ModelReceiptConfigConnection";
  items: Array<ReceiptConfig | null>;
  nextToken?: string | null;
};

export type ModelSMSMessageFilterInput = {
  and?: Array<ModelSMSMessageFilterInput | null> | null;
  from?: ModelStringInput | null;
  id?: ModelIDInput | null;
  message?: ModelStringInput | null;
  not?: ModelSMSMessageFilterInput | null;
  or?: Array<ModelSMSMessageFilterInput | null> | null;
  orderID?: ModelIDInput | null;
  tenantID?: ModelIDInput | null;
  to?: ModelStringInput | null;
};

export type ModelSMSMessageConnection = {
  __typename: "ModelSMSMessageConnection";
  items: Array<SMSMessage | null>;
  nextToken?: string | null;
};

export type ModelScheduleFilterInput = {
  and?: Array<ModelScheduleFilterInput | null> | null;
  endDate?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelScheduleFilterInput | null;
  or?: Array<ModelScheduleFilterInput | null> | null;
  startDate?: ModelStringInput | null;
  tenantID?: ModelIDInput | null;
  type?: ModelScheduleTypeInput | null;
};

export type ModelScheduleConnection = {
  __typename: "ModelScheduleConnection";
  items: Array<Schedule | null>;
  nextToken?: string | null;
};

export type ModelTagFilterInput = {
  and?: Array<ModelTagFilterInput | null> | null;
  id?: ModelIDInput | null;
  imageS3Square?: ModelStringInput | null;
  name?: ModelStringInput | null;
  not?: ModelTagFilterInput | null;
  or?: Array<ModelTagFilterInput | null> | null;
  tenantID?: ModelIDInput | null;
  type?: ModelTagTypeInput | null;
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection";
  items: Array<Tag | null>;
  nextToken?: string | null;
};

export type ModelTaxRateFilterInput = {
  amount?: ModelFloatInput | null;
  and?: Array<ModelTaxRateFilterInput | null> | null;
  id?: ModelIDInput | null;
  not?: ModelTaxRateFilterInput | null;
  or?: Array<ModelTaxRateFilterInput | null> | null;
  rate?: ModelFloatInput | null;
  taxTypeID?: ModelIDInput | null;
  type?: ModelTaxRateTypeInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelTaxTypeFilterInput = {
  and?: Array<ModelTaxTypeFilterInput | null> | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelTaxTypeFilterInput | null;
  or?: Array<ModelTaxTypeFilterInput | null> | null;
  tenantID?: ModelIDInput | null;
  type?: ModelTaxTypeTypeInput | null;
};

export type ModelTaxTypeConnection = {
  __typename: "ModelTaxTypeConnection";
  items: Array<TaxType | null>;
  nextToken?: string | null;
};

export type ModelTenantFilterInput = {
  and?: Array<ModelTenantFilterInput | null> | null;
  appDomain?: ModelStringInput | null;
  email?: ModelStringInput | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelTenantFilterInput | null;
  or?: Array<ModelTenantFilterInput | null> | null;
  orderLinkDomains?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  shift4ApiKey?: ModelStringInput | null;
  shift4ApiSecret?: ModelStringInput | null;
  type?: ModelTenantTypeInput | null;
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection";
  items: Array<Tenant | null>;
  nextToken?: string | null;
};

export type ModelUserRoleTypeFilterInput = {
  and?: Array<ModelUserRoleTypeFilterInput | null> | null;
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelUserRoleTypeFilterInput | null;
  or?: Array<ModelUserRoleTypeFilterInput | null> | null;
  permissions?: ModelUserRolePermissionListInput | null;
  tenantID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelUserRoleTypeConnection = {
  __typename: "ModelUserRoleTypeConnection";
  items: Array<UserRoleType | null>;
  nextToken?: string | null;
};

export type ModelUserRoleFilterInput = {
  and?: Array<ModelUserRoleFilterInput | null> | null;
  entityType?: ModelEntityTypeInput | null;
  id?: ModelIDInput | null;
  not?: ModelUserRoleFilterInput | null;
  or?: Array<ModelUserRoleFilterInput | null> | null;
  tenantID?: ModelIDInput | null;
  typeID?: ModelIDInput | null;
  userID?: ModelIDInput | null;
  venueID?: ModelIDInput | null;
};

export type ModelUserFilterInput = {
  and?: Array<ModelUserFilterInput | null> | null;
  email?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  lastName?: ModelStringInput | null;
  not?: ModelUserFilterInput | null;
  or?: Array<ModelUserFilterInput | null> | null;
  phone?: ModelStringInput | null;
  userName?: ModelStringInput | null;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Array<User | null>;
  nextToken?: string | null;
};

export type ModelVenueOrderFilterInput = {
  and?: Array<ModelVenueOrderFilterInput | null> | null;
  createdAt?: ModelStringInput | null;
  dueTime?: ModelStringInput | null;
  id?: ModelIDInput | null;
  not?: ModelVenueOrderFilterInput | null;
  or?: Array<ModelVenueOrderFilterInput | null> | null;
  orderID?: ModelIDInput | null;
  status?: ModelOrderStatusInput | null;
  venueID?: ModelIDInput | null;
  venueName?: ModelStringInput | null;
};

export type ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyConditionInput = {
  beginsWith?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null;
  between?: Array<ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null> | null;
  eq?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null;
  ge?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null;
  gt?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null;
  le?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null;
  lt?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput | null;
};

export type ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyInput = {
  dueTime?: string | null;
  status?: OrderStatus | null;
};

export type ModelVenueFilterInput = {
  acceptingOrders?: ModelBooleanInput | null;
  active?: ModelBooleanInput | null;
  and?: Array<ModelVenueFilterInput | null> | null;
  id?: ModelIDInput | null;
  imageS3LogoSquare?: ModelStringInput | null;
  leadTime?: ModelIntInput | null;
  locationID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  not?: ModelVenueFilterInput | null;
  or?: Array<ModelVenueFilterInput | null> | null;
  tenantID?: ModelIDInput | null;
};

export type SaveCheckItemDeepMutation = {
  __typename: "Check";
  id: string;
  readyEstimate?: number | null;
  totals?: {
    __typename: "CheckTotals";
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    feeTotal?: number | null;
    discountTotal?: number | null;
    paymentTotal?: number | null;
    total?: number | null;
    remainingBalance?: number | null;
  } | null;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      id: string;
      venueID: string;
      venueName?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      utensils?: boolean | null;
      instructions?: string | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        name: string;
        amount: number;
        type: TaxTypeType;
      } | null> | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        taxes?: Array<{
          __typename: "CheckItemTax";
          name: string;
          amount: number;
          type: TaxTypeType;
        } | null> | null;
        modifiers?: Array<{
          __typename: "CheckItemModifier";
          id: string;
          menuItemID?: string | null;
          modifierGroupID?: string | null;
          name: string;
          price: number;
          quantity: number;
          taxes?: Array<{
            __typename: "CheckItemTax";
            name: string;
            amount: number;
            type: TaxTypeType;
          } | null> | null;
          modifiers?: Array<{
            __typename: "CheckItemModifier";
            id: string;
            menuItemID?: string | null;
            modifierGroupID?: string | null;
            name: string;
            price: number;
            quantity: number;
            taxes?: Array<{
              __typename: "CheckItemTax";
              name: string;
              amount: number;
              type: TaxTypeType;
            } | null> | null;
            modifiers?: Array<{
              __typename: "CheckItemModifier";
              id: string;
              menuItemID?: string | null;
              modifierGroupID?: string | null;
              name: string;
              price: number;
              quantity: number;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  } | null;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  fees?: Array<{
    __typename: "CheckFee";
    name: string;
    amount: number;
    inclusive?: boolean | null;
  } | null> | null;
  tenantID: string;
  orderID: string;
};

export type RemoveCheckItemDeepMutation = {
  __typename: "Check";
  id: string;
  readyEstimate?: number | null;
  totals?: {
    __typename: "CheckTotals";
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    feeTotal?: number | null;
    discountTotal?: number | null;
    paymentTotal?: number | null;
    total?: number | null;
    remainingBalance?: number | null;
  } | null;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      id: string;
      venueID: string;
      menuID?: string | null;
      menuItemID?: string | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      utensils?: boolean | null;
      instructions?: string | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        name: string;
        amount: number;
        type: TaxTypeType;
      } | null> | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        taxes?: Array<{
          __typename: "CheckItemTax";
          name: string;
          amount: number;
          type: TaxTypeType;
        } | null> | null;
        modifiers?: Array<{
          __typename: "CheckItemModifier";
          id: string;
          menuItemID?: string | null;
          modifierGroupID?: string | null;
          name: string;
          price: number;
          quantity: number;
          taxes?: Array<{
            __typename: "CheckItemTax";
            name: string;
            amount: number;
            type: TaxTypeType;
          } | null> | null;
          modifiers?: Array<{
            __typename: "CheckItemModifier";
            id: string;
            menuItemID?: string | null;
            modifierGroupID?: string | null;
            name: string;
            price: number;
            quantity: number;
            taxes?: Array<{
              __typename: "CheckItemTax";
              name: string;
              amount: number;
              type: TaxTypeType;
            } | null> | null;
            modifiers?: Array<{
              __typename: "CheckItemModifier";
              id: string;
              menuItemID?: string | null;
              modifierGroupID?: string | null;
              name: string;
              price: number;
              quantity: number;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  } | null;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  fees?: Array<{
    __typename: "CheckFee";
    name: string;
    amount: number;
    inclusive?: boolean | null;
  } | null> | null;
  tenantID: string;
  orderID: string;
};

export type UpdateCheckItemQuantityDeepMutation = {
  __typename: "Check";
  id: string;
  readyEstimate?: number | null;
  totals?: {
    __typename: "CheckTotals";
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    feeTotal?: number | null;
    discountTotal?: number | null;
    paymentTotal?: number | null;
    total?: number | null;
    remainingBalance?: number | null;
  } | null;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      id: string;
      venueID: string;
      menuID?: string | null;
      menuItemID?: string | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      utensils?: boolean | null;
      instructions?: string | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        name: string;
        amount: number;
        type: TaxTypeType;
      } | null> | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        taxes?: Array<{
          __typename: "CheckItemTax";
          name: string;
          amount: number;
          type: TaxTypeType;
        } | null> | null;
        modifiers?: Array<{
          __typename: "CheckItemModifier";
          id: string;
          menuItemID?: string | null;
          modifierGroupID?: string | null;
          name: string;
          price: number;
          quantity: number;
          taxes?: Array<{
            __typename: "CheckItemTax";
            name: string;
            amount: number;
            type: TaxTypeType;
          } | null> | null;
          modifiers?: Array<{
            __typename: "CheckItemModifier";
            id: string;
            menuItemID?: string | null;
            modifierGroupID?: string | null;
            name: string;
            price: number;
            quantity: number;
            taxes?: Array<{
              __typename: "CheckItemTax";
              name: string;
              amount: number;
              type: TaxTypeType;
            } | null> | null;
            modifiers?: Array<{
              __typename: "CheckItemModifier";
              id: string;
              menuItemID?: string | null;
              modifierGroupID?: string | null;
              name: string;
              price: number;
              quantity: number;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  } | null;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  fees?: Array<{
    __typename: "CheckFee";
    name: string;
    amount: number;
    inclusive?: boolean | null;
  } | null> | null;
  tenantID: string;
  orderID: string;
};

export type GetCheckDeepQuery = {
  __typename: "Check";
  id: string;
  readyEstimate?: number | null;
  totals?: {
    __typename: "CheckTotals";
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    discountTotal?: number | null;
    paymentTotal?: number | null;
    total?: number | null;
    remainingBalance?: number | null;
  } | null;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      id: string;
      venueID: string;
      menuItemID?: string | null;
      name: string;
      price: number;
      quantity: number;
      taxes?: Array<{
        __typename: "CheckItemTax";
        name: string;
        amount: number;
        type: TaxTypeType;
      } | null> | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        name: string;
        price: number;
        quantity: number;
        taxes?: Array<{
          __typename: "CheckItemTax";
          name: string;
          amount: number;
          type: TaxTypeType;
        } | null> | null;
        modifiers?: Array<{
          __typename: "CheckItemModifier";
          id: string;
          menuItemID?: string | null;
          name: string;
          price: number;
          quantity: number;
          taxes?: Array<{
            __typename: "CheckItemTax";
            name: string;
            amount: number;
            type: TaxTypeType;
          } | null> | null;
          modifiers?: Array<{
            __typename: "CheckItemModifier";
            id: string;
            menuItemID?: string | null;
            name: string;
            price: number;
            quantity: number;
            taxes?: Array<{
              __typename: "CheckItemTax";
              name: string;
              amount: number;
              type: TaxTypeType;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  } | null;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      id: string;
      checkID: string;
      amount: number;
    } | null>;
  } | null;
  tenantID: string;
  orderID: string;
};

export type ListCustomersByEmailCustomQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        id: string;
        tenantID: string;
        orderID: string;
        order: {
          __typename: "Order";
          id: string;
          orderNumber?: string | null;
          tenantID: string;
          status?: OrderStatus | null;
          dueTime?: string | null;
          serviceType?: ServiceType | null;
          deliveryProvider?: DeliveryProvider | null;
        };
        totals?: {
          __typename: "CheckTotals";
          subTotal?: number | null;
          taxTotal?: number | null;
          tipTotal?: number | null;
          feeTotal?: number | null;
          discountTotal?: number | null;
          paymentTotal?: number | null;
          total?: number | null;
          remainingBalance?: number | null;
        } | null;
        venueTotals?: Array<{
          __typename: "VenueTotals";
          venueID: string;
          subTotal?: number | null;
          taxTotal?: number | null;
          tipTotal?: number | null;
          feeTotal?: number | null;
          discountTotal?: number | null;
          paymentTotal?: number | null;
          total?: number | null;
          remainingBalance?: number | null;
          checkPercent?: number | null;
        } | null> | null;
      } | null>;
    } | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    defaultPaymentMethodID?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        id: string;
        paymentMethod: {
          __typename: "PaymentMethod";
          token: string;
          name: string;
          type: PaymentMethodType;
          cardType?: string | null;
          cardExpiration?: string | null;
          zipcode?: string | null;
          processor?: CCProcessor | null;
        };
      } | null>;
    } | null;
    addresses?: Array<{
      __typename: "Address";
      name?: string | null;
      street?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      email?: string | null;
      phone?: string | null;
      isDefault?: boolean | null;
      instructions?: string | null;
    } | null> | null;
    phone?: string | null;
    userName?: string | null;
  } | null>;
};

export type GetMenuItemRecursiveQuery = {
  __typename: "MenuItem";
  id: string;
  conceptID?: string | null;
  venueID?: string | null;
  name: string;
  description?: string | null;
  active?: boolean | null;
  imageS3?: string | null;
  utensils?: boolean | null;
  alcohol?: boolean | null;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      id: string;
      price: number;
      priceTypeID?: string | null;
      active?: boolean | null;
    } | null>;
  } | null;
  taxes?: {
    __typename: "ModelMenuItemTaxTypeConnection";
    items: Array<{
      __typename: "MenuItemTaxType";
      taxType: {
        __typename: "TaxType";
        id: string;
        name: string;
      };
    } | null>;
  } | null;
  modifierGroups?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      sortOrder?: number | null;
      modifierGroup: {
        __typename: "ModifierGroup";
        id: string;
        conceptID?: string | null;
        venueID?: string | null;
        name: string;
        displayName?: string | null;
        description?: string | null;
        priceTypeID?: string | null;
        active?: boolean | null;
        min?: number | null;
        max?: number | null;
        selectionType?: SelectionType | null;
        modifiers?: {
          __typename: "ModelModifierGroupMenuItemConnection";
          items: Array<{
            __typename: "ModifierGroupMenuItem";
            menuItem: {
              __typename: "MenuItem";
              id: string;
              conceptID?: string | null;
              venueID?: string | null;
              name: string;
              description?: string | null;
              active?: boolean | null;
              imageS3?: string | null;
              prices?: {
                __typename: "ModelMenuItemPriceConnection";
                items: Array<{
                  __typename: "MenuItemPrice";
                  id: string;
                  price: number;
                  priceTypeID?: string | null;
                  active?: boolean | null;
                } | null>;
              } | null;
              taxes?: {
                __typename: "ModelMenuItemTaxTypeConnection";
                items: Array<{
                  __typename: "MenuItemTaxType";
                  taxType: {
                    __typename: "TaxType";
                    id: string;
                    name: string;
                  };
                } | null>;
              } | null;
              modifierGroups?: {
                __typename: "ModelMenuItemModifierGroupConnection";
                items: Array<{
                  __typename: "MenuItemModifierGroup";
                  sortOrder?: number | null;
                  modifierGroup: {
                    __typename: "ModifierGroup";
                    id: string;
                    conceptID?: string | null;
                    venueID?: string | null;
                    name: string;
                    displayName?: string | null;
                    description?: string | null;
                    priceTypeID?: string | null;
                    active?: boolean | null;
                    min?: number | null;
                    max?: number | null;
                    selectionType?: SelectionType | null;
                    modifiers?: {
                      __typename: "ModelModifierGroupMenuItemConnection";
                      items: Array<{
                        __typename: "ModifierGroupMenuItem";
                        menuItem: {
                          __typename: "MenuItem";
                          id: string;
                          conceptID?: string | null;
                          venueID?: string | null;
                          name: string;
                          description?: string | null;
                          active?: boolean | null;
                          imageS3?: string | null;
                          prices?: {
                            __typename: "ModelMenuItemPriceConnection";
                            items: Array<{
                              __typename: "MenuItemPrice";
                              id: string;
                              price: number;
                              priceTypeID?: string | null;
                              active?: boolean | null;
                            } | null>;
                          } | null;
                          taxes?: {
                            __typename: "ModelMenuItemTaxTypeConnection";
                            items: Array<{
                              __typename: "MenuItemTaxType";
                              taxType: {
                                __typename: "TaxType";
                                id: string;
                                name: string;
                              };
                            } | null>;
                          } | null;
                          modifierGroups?: {
                            __typename: "ModelMenuItemModifierGroupConnection";
                            items: Array<{
                              __typename: "MenuItemModifierGroup";
                              sortOrder?: number | null;
                              modifierGroup: {
                                __typename: "ModifierGroup";
                                id: string;
                                conceptID?: string | null;
                                venueID?: string | null;
                                name: string;
                                displayName?: string | null;
                                description?: string | null;
                                priceTypeID?: string | null;
                                active?: boolean | null;
                                min?: number | null;
                                max?: number | null;
                                selectionType?: SelectionType | null;
                                modifiers?: {
                                  __typename: "ModelModifierGroupMenuItemConnection";
                                  items: Array<{
                                    __typename: "ModifierGroupMenuItem";
                                    menuItem: {
                                      __typename: "MenuItem";
                                      id: string;
                                      conceptID?: string | null;
                                      venueID?: string | null;
                                      name: string;
                                      description?: string | null;
                                      active?: boolean | null;
                                      imageS3?: string | null;
                                      prices?: {
                                        __typename: "ModelMenuItemPriceConnection";
                                        items: Array<{
                                          __typename: "MenuItemPrice";
                                          id: string;
                                          price: number;
                                          priceTypeID?: string | null;
                                          active?: boolean | null;
                                        } | null>;
                                      } | null;
                                      taxes?: {
                                        __typename: "ModelMenuItemTaxTypeConnection";
                                        items: Array<{
                                          __typename: "MenuItemTaxType";
                                          taxType: {
                                            __typename: "TaxType";
                                            id: string;
                                            name: string;
                                          };
                                        } | null>;
                                      } | null;
                                    };
                                  } | null>;
                                } | null;
                              };
                            } | null>;
                          } | null;
                        };
                      } | null>;
                    } | null;
                  };
                } | null>;
              } | null;
            };
          } | null>;
        } | null;
      };
    } | null>;
  } | null;
};

export type BumpChitMutation = {
  __typename: "ChitStatusRes";
  id: string;
  status?: ChitStatus | null;
};

export type CreateCheckMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type CreateCheckItemMutation = {
  __typename: "CheckItem";
  alcohol?: boolean | null;
  checkID: string;
  chit?: {
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null;
  chitID?: string | null;
  createdAt: string;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<{
    __typename: "CheckItemModifier";
    id: string;
    menuItemID?: string | null;
    modifierGroupID?: string | null;
    modifiers?: Array<{
      __typename: "CheckItemModifier";
      id: string;
      menuItemID?: string | null;
      modifierGroupID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
    } | null> | null;
    name: string;
    price: number;
    quantity: number;
    tags?: Array<{
      __typename: "CheckItemTag";
      id: string;
      name: string;
      type: TagType;
    } | null> | null;
    taxes?: Array<{
      __typename: "CheckItemTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    unitPrice?: number | null;
  } | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<{
    __typename: "CheckItemTag";
    id: string;
    name: string;
    type: TagType;
  } | null> | null;
  taxes?: Array<{
    __typename: "CheckItemTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  unitPrice?: number | null;
  updatedAt: string;
  utensils?: boolean | null;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type CreateCheckPaymentMutation = {
  __typename: "CheckPayment";
  amount: number;
  cardPresent?: boolean | null;
  checkID: string;
  createdAt: string;
  displayName?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  isAuthed?: boolean | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  refNo?: string | null;
  status?: CheckPaymentStatus | null;
  tip?: number | null;
  token?: string | null;
  updatedAt: string;
};

export type CreateCheckTransactionMutation = {
  __typename: "CheckTransaction";
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  check?: {
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null;
  checkID: string;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<{
    __typename: "CheckRefund";
    amount: number;
    checkItemID?: string | null;
    type: CheckRefundType;
  } | null> | null;
  status: CheckTransactionStatus;
  thirdPartyData?: string | null;
  token?: string | null;
  type: CCTransactionType;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type CreateChitMutation = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  items?: Array<{
    __typename: "ChitItem";
    alcohol?: boolean | null;
    id: string;
    instructions?: string | null;
    modifiers?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    name: string;
    price?: number | null;
    quantity: number;
    status?: ChitItemStatus | null;
    utensils?: boolean | null;
  } | null> | null;
  order?: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type CreateConceptMutation = {
  __typename: "Concept";
  createdAt: string;
  id: string;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type CreateCredentialMutation = {
  __typename: "Credential";
  createdAt: string;
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id: string;
  key: string;
  secret: string;
  smsFrom?: string | null;
  tenantID?: string | null;
  type: CredentialType;
  updatedAt: string;
  venueID?: string | null;
};

export type CreateCustomerMutation = {
  __typename: "Customer";
  addresses?: Array<{
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null> | null;
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  paymentMethods?: {
    __typename: "ModelCustomerPaymentMethodConnection";
    items: Array<{
      __typename: "CustomerPaymentMethod";
      createdAt: string;
      customerID: string;
      id: string;
      paymentMethod: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      };
      paymentMethodID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  phone?: string | null;
  updatedAt: string;
  userName?: string | null;
};

export type CreateCustomerPaymentMethodMutation = {
  __typename: "CustomerPaymentMethod";
  createdAt: string;
  customerID: string;
  id: string;
  paymentMethod: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  };
  paymentMethodID: string;
  updatedAt: string;
};

export type CreateDeviceMutation = {
  __typename: "Device";
  active?: boolean | null;
  autoPrint?: boolean | null;
  createdAt: string;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id: string;
  ipAddress?: string | null;
  licenseID: string;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type: DeviceType;
  updatedAt: string;
};

export type CreateDeviceTransactionMutation = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type CreateDomainMutation = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type CreateEmailTemplateMutation = {
  __typename: "EmailTemplate";
  createdAt: string;
  html?: string | null;
  id: string;
  name: string;
  subject?: string | null;
  tenantID: string;
  text?: string | null;
  updatedAt: string;
};

export type CreateEmployeeMutation = {
  __typename: "Employee";
  active?: boolean | null;
  code?: string | null;
  createdAt: string;
  email?: string | null;
  firstName: string;
  id: string;
  jobs?: {
    __typename: "ModelJobConnection";
    items: Array<{
      __typename: "Job";
      createdAt: string;
      employee: {
        __typename: "Employee";
        active?: boolean | null;
        code?: string | null;
        createdAt: string;
        email?: string | null;
        firstName: string;
        id: string;
        lastName: string;
        phone?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      employeeID: string;
      entityType: EntityType;
      id: string;
      payRate?: number | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "JobType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<JobPermission | null> | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  lastName: string;
  phone?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type CreateFeeMutation = {
  __typename: "Fee";
  amount?: number | null;
  createdAt: string;
  id: string;
  inclusive?: boolean | null;
  name: string;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID: string;
  type: FeeType;
  updatedAt: string;
};

export type CreateJobMutation = {
  __typename: "Job";
  createdAt: string;
  employee: {
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  employeeID: string;
  entityType: EntityType;
  id: string;
  payRate?: number | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type CreateJobTypeMutation = {
  __typename: "JobType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<JobPermission | null> | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type CreateLicenseMutation = {
  __typename: "License";
  active?: boolean | null;
  createdAt: string;
  deviceDetails?: {
    __typename: "DeviceDetails";
    deviceID?: string | null;
    displayName?: string | null;
    heartbeat?: string | null;
    initTime?: string | null;
    name?: string | null;
    online?: boolean | null;
    version?: string | null;
  } | null;
  devices?: {
    __typename: "ModelDeviceConnection";
    items: Array<{
      __typename: "Device";
      active?: boolean | null;
      autoPrint?: boolean | null;
      createdAt: string;
      deviceID?: string | null;
      displayName?: string | null;
      host?: string | null;
      id: string;
      ipAddress?: string | null;
      licenseID: string;
      name?: string | null;
      port?: number | null;
      printOnBump?: boolean | null;
      serialNumber?: string | null;
      type: DeviceType;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: {
    __typename: "KioskConfig";
    orderLinkID?: string | null;
    showSettings?: boolean | null;
  } | null;
  kitchenConfig?: {
    __typename: "KitchenConfig";
    showSettings?: boolean | null;
  } | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfig?: {
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  type: DeviceType;
  updatedAt: string;
  venues?: {
    __typename: "ModelLicenseVenueConnection";
    items: Array<{
      __typename: "LicenseVenue";
      createdAt: string;
      id: string;
      licenseID: string;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateLicenseVenueMutation = {
  __typename: "LicenseVenue";
  createdAt: string;
  id: string;
  licenseID: string;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type CreateLocationMutation = {
  __typename: "Location";
  city: string;
  country?: string | null;
  createdAt: string;
  email?: string | null;
  endOfDay?: string | null;
  id: string;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state: string;
  street: string;
  tenantID: string;
  timezone?: string | null;
  unit?: string | null;
  updatedAt: string;
  zip: string;
};

export type CreateLogMutation = {
  __typename: "Log";
  checkID?: string | null;
  createdAt: string;
  employee?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type: LogType;
  updatedAt: string;
  user?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  userID?: string | null;
  venueID?: string | null;
};

export type CreateMenuMutation = {
  __typename: "Menu";
  active?: boolean | null;
  categories?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  facilitators?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  featured?: boolean | null;
  id: string;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name: string;
  orderLinks?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  schedules?: {
    __typename: "ModelMenuScheduleConnection";
    items: Array<{
      __typename: "MenuSchedule";
      createdAt: string;
      id: string;
      menuID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuTagConnection";
    items: Array<{
      __typename: "MenuTag";
      createdAt: string;
      id: string;
      menuID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type CreateMenuCategoryMutation = {
  __typename: "MenuCategory";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  menuItems?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  tags?: {
    __typename: "ModelMenuCategoryTagConnection";
    items: Array<{
      __typename: "MenuCategoryTag";
      createdAt: string;
      id: string;
      menuCategoryID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type CreateMenuCategoryMenuItemMutation = {
  __typename: "MenuCategoryMenuItem";
  createdAt: string;
  id: string;
  menuCategory: {
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  menuCategoryID: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type CreateMenuCategoryTagMutation = {
  __typename: "MenuCategoryTag";
  createdAt: string;
  id: string;
  menuCategoryID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type CreateMenuFacilitatorMutation = {
  __typename: "MenuFacilitator";
  createdAt: string;
  facilitator: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  facilitatorID: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuID: string;
  updatedAt: string;
};

export type CreateMenuItemMutation = {
  __typename: "MenuItem";
  active?: boolean | null;
  alcohol?: boolean | null;
  categories?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  id: string;
  imageS3?: string | null;
  modifierGroups?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  options?: {
    __typename: "ModelMenuItemOptionConnection";
    items: Array<{
      __typename: "MenuItemOption";
      createdAt: string;
      id: string;
      menuItemID: string;
      name: string;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  parentModifierGroups?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  prepTime?: number | null;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuItemTagConnection";
    items: Array<{
      __typename: "MenuItemTag";
      createdAt: string;
      id: string;
      menuItemID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxes?: {
    __typename: "ModelMenuItemTaxTypeConnection";
    items: Array<{
      __typename: "MenuItemTaxType";
      createdAt: string;
      id: string;
      menuItemID: string;
      taxType: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      };
      taxTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type CreateMenuItemModifierGroupMutation = {
  __typename: "MenuItemModifierGroup";
  createdAt: string;
  id: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  modifierGroup: {
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  modifierGroupID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type CreateMenuItemOptionMutation = {
  __typename: "MenuItemOption";
  createdAt: string;
  id: string;
  menuItemID: string;
  name: string;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
};

export type CreateMenuItemPriceMutation = {
  __typename: "MenuItemPrice";
  active?: boolean | null;
  createdAt: string;
  id: string;
  menuItemID?: string | null;
  menuItemOptionID?: string | null;
  price: number;
  priceType?: {
    __typename: "MenuItemPriceType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null;
  priceTypeID?: string | null;
  updatedAt: string;
};

export type CreateMenuItemPriceTypeMutation = {
  __typename: "MenuItemPriceType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type CreateMenuItemTagMutation = {
  __typename: "MenuItemTag";
  createdAt: string;
  id: string;
  menuItemID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type CreateMenuItemTaxTypeMutation = {
  __typename: "MenuItemTaxType";
  createdAt: string;
  id: string;
  menuItemID: string;
  taxType: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  };
  taxTypeID: string;
  updatedAt: string;
};

export type CreateMenuMenuCategoryMutation = {
  __typename: "MenuMenuCategory";
  createdAt: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuCategory: {
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  menuCategoryID: string;
  menuID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type CreateMenuScheduleMutation = {
  __typename: "MenuSchedule";
  createdAt: string;
  id: string;
  menuID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type CreateMenuTagMutation = {
  __typename: "MenuTag";
  createdAt: string;
  id: string;
  menuID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type CreateModifierGroupMutation = {
  __typename: "ModifierGroup";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  max?: number | null;
  menuItems?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  min?: number | null;
  modifiers?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tags?: {
    __typename: "ModelModifierGroupTagConnection";
    items: Array<{
      __typename: "ModifierGroupTag";
      createdAt: string;
      id: string;
      modifierGroupID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type CreateModifierGroupMenuItemMutation = {
  __typename: "ModifierGroupMenuItem";
  createdAt: string;
  id: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  modifierGroup: {
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  modifierGroupID: string;
  updatedAt: string;
};

export type CreateModifierGroupTagMutation = {
  __typename: "ModifierGroupTag";
  createdAt: string;
  id: string;
  modifierGroupID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type CreateNotificationMutation = {
  __typename: "Notification";
  createdAt: string;
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplate?: {
    __typename: "EmailTemplate";
    createdAt: string;
    html?: string | null;
    id: string;
    name: string;
    subject?: string | null;
    tenantID: string;
    text?: string | null;
    updatedAt: string;
  } | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id: string;
  message?: string | null;
  name: string;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID: string;
  type: NotificationType;
  updatedAt: string;
};

export type CreateOrderMutation = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  chits?: {
    __typename: "ModelChitConnection";
    items: Array<{
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    errorCode?: number | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    lastUpdate?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    message?: string | null;
    phone?: string | null;
    pickupTime?: string | null;
    status?: CourierStatus | null;
    type?: string | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID: string;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null;
  orderTypeID?: string | null;
  originAddress?: {
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip: string;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  venueStatus?: Array<{
    __typename: "VenueStatus";
    status: OrderStatus;
    venueID: string;
  } | null> | null;
};

export type CreateOrderLinkMutation = {
  __typename: "OrderLink";
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  createdAt: string;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  location: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  };
  locationID: string;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  menus?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  notice?: string | null;
  noticeAtCollection?: string | null;
  notifications?: {
    __typename: "ModelOrderLinkNotificationConnection";
    items: Array<{
      __typename: "OrderLinkNotification";
      createdAt: string;
      event: NotificationEvent;
      id: string;
      notification: {
        __typename: "Notification";
        createdAt: string;
        emailBCC?: string | null;
        emailCC?: string | null;
        emailFrom?: string | null;
        emailReplyTo?: string | null;
        emailTemplateData?: string | null;
        emailTemplateID?: string | null;
        emailTo?: string | null;
        id: string;
        message?: string | null;
        name: string;
        smsFrom?: string | null;
        smsTo?: string | null;
        smsToDynamic?: string | null;
        smsToType?: PropertyType | null;
        tenantID: string;
        type: NotificationType;
        updatedAt: string;
      };
      notificationID: string;
      orderLinkID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderMode?: OrderMode | null;
  orderTypes?: {
    __typename: "ModelOrderLinkOrderTypeConnection";
    items: Array<{
      __typename: "OrderLinkOrderType";
      createdAt: string;
      id: string;
      orderLinkID: string;
      orderType: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  schedules?: {
    __typename: "ModelOrderLinkScheduleConnection";
    items: Array<{
      __typename: "OrderLinkSchedule";
      createdAt: string;
      id: string;
      orderLinkID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  searchPlaceholder?: string | null;
  smsCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tos?: string | null;
  updatedAt: string;
};

export type CreateOrderLinkMenuMutation = {
  __typename: "OrderLinkMenu";
  createdAt: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuID: string;
  orderLink: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  };
  orderLinkID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type CreateOrderLinkNotificationMutation = {
  __typename: "OrderLinkNotification";
  createdAt: string;
  event: NotificationEvent;
  id: string;
  notification: {
    __typename: "Notification";
    createdAt: string;
    emailBCC?: string | null;
    emailCC?: string | null;
    emailFrom?: string | null;
    emailReplyTo?: string | null;
    emailTemplate?: {
      __typename: "EmailTemplate";
      createdAt: string;
      html?: string | null;
      id: string;
      name: string;
      subject?: string | null;
      tenantID: string;
      text?: string | null;
      updatedAt: string;
    } | null;
    emailTemplateData?: string | null;
    emailTemplateID?: string | null;
    emailTo?: string | null;
    id: string;
    message?: string | null;
    name: string;
    smsFrom?: string | null;
    smsTo?: string | null;
    smsToDynamic?: string | null;
    smsToType?: PropertyType | null;
    tenantID: string;
    type: NotificationType;
    updatedAt: string;
  };
  notificationID: string;
  orderLinkID: string;
  updatedAt: string;
};

export type CreateOrderLinkOrderTypeMutation = {
  __typename: "OrderLinkOrderType";
  createdAt: string;
  id: string;
  orderLinkID: string;
  orderType: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  orderTypeID: string;
  updatedAt: string;
};

export type CreateOrderLinkScheduleMutation = {
  __typename: "OrderLinkSchedule";
  createdAt: string;
  id: string;
  orderLinkID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type CreateOrderTypeMutation = {
  __typename: "OrderType";
  active?: boolean | null;
  asap?: boolean | null;
  createdAt: string;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  fees?: {
    __typename: "ModelOrderTypeFeeConnection";
    items: Array<{
      __typename: "OrderTypeFee";
      createdAt: string;
      fee: {
        __typename: "Fee";
        amount?: number | null;
        createdAt: string;
        id: string;
        inclusive?: boolean | null;
        name: string;
        rate?: number | null;
        taxed?: boolean | null;
        tenantID: string;
        type: FeeType;
        updatedAt: string;
      };
      feeID: string;
      id: string;
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  name: string;
  schedule?: boolean | null;
  schedules?: {
    __typename: "ModelOrderTypeScheduleConnection";
    items: Array<{
      __typename: "OrderTypeSchedule";
      createdAt: string;
      id: string;
      orderTypeID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type CreateOrderTypeFeeMutation = {
  __typename: "OrderTypeFee";
  createdAt: string;
  fee: {
    __typename: "Fee";
    amount?: number | null;
    createdAt: string;
    id: string;
    inclusive?: boolean | null;
    name: string;
    rate?: number | null;
    taxed?: boolean | null;
    tenantID: string;
    type: FeeType;
    updatedAt: string;
  };
  feeID: string;
  id: string;
  orderTypeID: string;
  updatedAt: string;
};

export type CreateOrderTypeScheduleMutation = {
  __typename: "OrderTypeSchedule";
  createdAt: string;
  id: string;
  orderTypeID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type CreatePaymentMethodMutation = {
  __typename: "PaymentMethod";
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  createdAt: string;
  name: string;
  processor?: CCProcessor | null;
  token: string;
  type: PaymentMethodType;
  updatedAt: string;
  zipcode?: string | null;
};

export type CreatePrintJobMutation = {
  __typename: "PrintJob";
  createdAt: string;
  data: string;
  expirationUnixTime?: number | null;
  id: string;
  serialNumber: string;
  status?: PrintJobStatus | null;
  updatedAt: string;
};

export type CreatePrinterMutation = {
  __typename: "Printer";
  createdAt: string;
  serialNumber: string;
  status?: PrinterStatus | null;
  updatedAt: string;
};

export type CreateReceiptConfigMutation = {
  __typename: "ReceiptConfig";
  createdAt: string;
  headerSize?: number | null;
  id: string;
  itemSize?: number | null;
  name: string;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueSize?: number | null;
};

export type CreateSMSMessageMutation = {
  __typename: "SMSMessage";
  createdAt: string;
  from: string;
  id: string;
  message: string;
  orderID?: string | null;
  tenantID: string;
  to: string;
  updatedAt: string;
};

export type CreateScheduleMutation = {
  __typename: "Schedule";
  createdAt: string;
  endDate?: string | null;
  id: string;
  name: string;
  startDate?: string | null;
  tenantID: string;
  timeRanges?: Array<{
    __typename: "TimeRange";
    day: number;
    endTime: string;
    startTime: string;
  } | null> | null;
  type: ScheduleType;
  updatedAt: string;
};

export type CreateTagMutation = {
  __typename: "Tag";
  createdAt: string;
  id: string;
  imageS3Square?: string | null;
  name: string;
  tenantID: string;
  type: TagType;
  updatedAt: string;
};

export type CreateTaxRateMutation = {
  __typename: "TaxRate";
  amount?: number | null;
  createdAt: string;
  id: string;
  rate?: number | null;
  taxType?: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  } | null;
  taxTypeID: string;
  type: TaxRateType;
  updatedAt: string;
  venueID: string;
};

export type CreateTaxTypeMutation = {
  __typename: "TaxType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  type: TaxTypeType;
  updatedAt: string;
};

export type CreateTenantMutation = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateUserMutation = {
  __typename: "User";
  createdAt: string;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  updatedAt: string;
  userName: string;
  userRoles?: {
    __typename: "ModelUserRoleConnection";
    items: Array<{
      __typename: "UserRole";
      createdAt: string;
      entityType: EntityType;
      id: string;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "UserRoleType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<UserRolePermission | null> | null;
        tenantID?: string | null;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      user: {
        __typename: "User";
        createdAt: string;
        email: string;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName: string;
      };
      userID: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateUserRoleMutation = {
  __typename: "UserRole";
  createdAt: string;
  entityType: EntityType;
  id: string;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  user: {
    __typename: "User";
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    phone?: string | null;
    updatedAt: string;
    userName: string;
    userRoles?: {
      __typename: "ModelUserRoleConnection";
      items: Array<{
        __typename: "UserRole";
        createdAt: string;
        entityType: EntityType;
        id: string;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        userID: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  userID: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type CreateUserRoleTypeMutation = {
  __typename: "UserRoleType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  updatedAt: string;
  venueID?: string | null;
};

export type CreateVenueMutation = {
  __typename: "Venue";
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID?: string | null;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  schedules?: {
    __typename: "ModelVenueScheduleConnection";
    items: Array<{
      __typename: "VenueSchedule";
      createdAt: string;
      id: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxRates?: {
    __typename: "ModelTaxRateConnection";
    items: Array<{
      __typename: "TaxRate";
      amount?: number | null;
      createdAt: string;
      id: string;
      rate?: number | null;
      taxType?: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      } | null;
      taxTypeID: string;
      type: TaxRateType;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type CreateVenueOrderMutation = {
  __typename: "VenueOrder";
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  status?: OrderStatus | null;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
  venueName?: string | null;
};

export type CreateVenueScheduleMutation = {
  __typename: "VenueSchedule";
  createdAt: string;
  id: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
  venueID: string;
};

export type DcPayAuthOnlyMutation = {
  __typename: "DCPayRes";
  Account?: string | null;
  AuthCode?: string | null;
  Brand?: string | null;
  RefNo?: string | null;
  Token?: string | null;
};

export type DeleteCheckMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type DeleteCheckItemMutation = {
  __typename: "CheckItem";
  alcohol?: boolean | null;
  checkID: string;
  chit?: {
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null;
  chitID?: string | null;
  createdAt: string;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<{
    __typename: "CheckItemModifier";
    id: string;
    menuItemID?: string | null;
    modifierGroupID?: string | null;
    modifiers?: Array<{
      __typename: "CheckItemModifier";
      id: string;
      menuItemID?: string | null;
      modifierGroupID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
    } | null> | null;
    name: string;
    price: number;
    quantity: number;
    tags?: Array<{
      __typename: "CheckItemTag";
      id: string;
      name: string;
      type: TagType;
    } | null> | null;
    taxes?: Array<{
      __typename: "CheckItemTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    unitPrice?: number | null;
  } | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<{
    __typename: "CheckItemTag";
    id: string;
    name: string;
    type: TagType;
  } | null> | null;
  taxes?: Array<{
    __typename: "CheckItemTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  unitPrice?: number | null;
  updatedAt: string;
  utensils?: boolean | null;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type DeleteCheckPaymentMutation = {
  __typename: "CheckPayment";
  amount: number;
  cardPresent?: boolean | null;
  checkID: string;
  createdAt: string;
  displayName?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  isAuthed?: boolean | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  refNo?: string | null;
  status?: CheckPaymentStatus | null;
  tip?: number | null;
  token?: string | null;
  updatedAt: string;
};

export type DeleteCheckTransactionMutation = {
  __typename: "CheckTransaction";
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  check?: {
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null;
  checkID: string;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<{
    __typename: "CheckRefund";
    amount: number;
    checkItemID?: string | null;
    type: CheckRefundType;
  } | null> | null;
  status: CheckTransactionStatus;
  thirdPartyData?: string | null;
  token?: string | null;
  type: CCTransactionType;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type DeleteChitMutation = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  items?: Array<{
    __typename: "ChitItem";
    alcohol?: boolean | null;
    id: string;
    instructions?: string | null;
    modifiers?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    name: string;
    price?: number | null;
    quantity: number;
    status?: ChitItemStatus | null;
    utensils?: boolean | null;
  } | null> | null;
  order?: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type DeleteConceptMutation = {
  __typename: "Concept";
  createdAt: string;
  id: string;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type DeleteCredentialMutation = {
  __typename: "Credential";
  createdAt: string;
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id: string;
  key: string;
  secret: string;
  smsFrom?: string | null;
  tenantID?: string | null;
  type: CredentialType;
  updatedAt: string;
  venueID?: string | null;
};

export type DeleteCustomerMutation = {
  __typename: "Customer";
  addresses?: Array<{
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null> | null;
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  paymentMethods?: {
    __typename: "ModelCustomerPaymentMethodConnection";
    items: Array<{
      __typename: "CustomerPaymentMethod";
      createdAt: string;
      customerID: string;
      id: string;
      paymentMethod: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      };
      paymentMethodID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  phone?: string | null;
  updatedAt: string;
  userName?: string | null;
};

export type DeleteCustomerPaymentMethodMutation = {
  __typename: "CustomerPaymentMethod";
  createdAt: string;
  customerID: string;
  id: string;
  paymentMethod: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  };
  paymentMethodID: string;
  updatedAt: string;
};

export type DeleteDeviceMutation = {
  __typename: "Device";
  active?: boolean | null;
  autoPrint?: boolean | null;
  createdAt: string;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id: string;
  ipAddress?: string | null;
  licenseID: string;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type: DeviceType;
  updatedAt: string;
};

export type DeleteDeviceTransactionMutation = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type DeleteDomainMutation = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type DeleteEmailTemplateMutation = {
  __typename: "EmailTemplate";
  createdAt: string;
  html?: string | null;
  id: string;
  name: string;
  subject?: string | null;
  tenantID: string;
  text?: string | null;
  updatedAt: string;
};

export type DeleteEmployeeMutation = {
  __typename: "Employee";
  active?: boolean | null;
  code?: string | null;
  createdAt: string;
  email?: string | null;
  firstName: string;
  id: string;
  jobs?: {
    __typename: "ModelJobConnection";
    items: Array<{
      __typename: "Job";
      createdAt: string;
      employee: {
        __typename: "Employee";
        active?: boolean | null;
        code?: string | null;
        createdAt: string;
        email?: string | null;
        firstName: string;
        id: string;
        lastName: string;
        phone?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      employeeID: string;
      entityType: EntityType;
      id: string;
      payRate?: number | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "JobType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<JobPermission | null> | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  lastName: string;
  phone?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type DeleteFeeMutation = {
  __typename: "Fee";
  amount?: number | null;
  createdAt: string;
  id: string;
  inclusive?: boolean | null;
  name: string;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID: string;
  type: FeeType;
  updatedAt: string;
};

export type DeleteJobMutation = {
  __typename: "Job";
  createdAt: string;
  employee: {
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  employeeID: string;
  entityType: EntityType;
  id: string;
  payRate?: number | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type DeleteJobTypeMutation = {
  __typename: "JobType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<JobPermission | null> | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type DeleteLicenseMutation = {
  __typename: "License";
  active?: boolean | null;
  createdAt: string;
  deviceDetails?: {
    __typename: "DeviceDetails";
    deviceID?: string | null;
    displayName?: string | null;
    heartbeat?: string | null;
    initTime?: string | null;
    name?: string | null;
    online?: boolean | null;
    version?: string | null;
  } | null;
  devices?: {
    __typename: "ModelDeviceConnection";
    items: Array<{
      __typename: "Device";
      active?: boolean | null;
      autoPrint?: boolean | null;
      createdAt: string;
      deviceID?: string | null;
      displayName?: string | null;
      host?: string | null;
      id: string;
      ipAddress?: string | null;
      licenseID: string;
      name?: string | null;
      port?: number | null;
      printOnBump?: boolean | null;
      serialNumber?: string | null;
      type: DeviceType;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: {
    __typename: "KioskConfig";
    orderLinkID?: string | null;
    showSettings?: boolean | null;
  } | null;
  kitchenConfig?: {
    __typename: "KitchenConfig";
    showSettings?: boolean | null;
  } | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfig?: {
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  type: DeviceType;
  updatedAt: string;
  venues?: {
    __typename: "ModelLicenseVenueConnection";
    items: Array<{
      __typename: "LicenseVenue";
      createdAt: string;
      id: string;
      licenseID: string;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteLicenseVenueMutation = {
  __typename: "LicenseVenue";
  createdAt: string;
  id: string;
  licenseID: string;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type DeleteLocationMutation = {
  __typename: "Location";
  city: string;
  country?: string | null;
  createdAt: string;
  email?: string | null;
  endOfDay?: string | null;
  id: string;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state: string;
  street: string;
  tenantID: string;
  timezone?: string | null;
  unit?: string | null;
  updatedAt: string;
  zip: string;
};

export type DeleteLogMutation = {
  __typename: "Log";
  checkID?: string | null;
  createdAt: string;
  employee?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type: LogType;
  updatedAt: string;
  user?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  userID?: string | null;
  venueID?: string | null;
};

export type DeleteMenuMutation = {
  __typename: "Menu";
  active?: boolean | null;
  categories?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  facilitators?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  featured?: boolean | null;
  id: string;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name: string;
  orderLinks?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  schedules?: {
    __typename: "ModelMenuScheduleConnection";
    items: Array<{
      __typename: "MenuSchedule";
      createdAt: string;
      id: string;
      menuID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuTagConnection";
    items: Array<{
      __typename: "MenuTag";
      createdAt: string;
      id: string;
      menuID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type DeleteMenuCategoryMutation = {
  __typename: "MenuCategory";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  menuItems?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  tags?: {
    __typename: "ModelMenuCategoryTagConnection";
    items: Array<{
      __typename: "MenuCategoryTag";
      createdAt: string;
      id: string;
      menuCategoryID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type DeleteMenuCategoryMenuItemMutation = {
  __typename: "MenuCategoryMenuItem";
  createdAt: string;
  id: string;
  menuCategory: {
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  menuCategoryID: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type DeleteMenuCategoryTagMutation = {
  __typename: "MenuCategoryTag";
  createdAt: string;
  id: string;
  menuCategoryID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type DeleteMenuFacilitatorMutation = {
  __typename: "MenuFacilitator";
  createdAt: string;
  facilitator: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  facilitatorID: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuID: string;
  updatedAt: string;
};

export type DeleteMenuItemMutation = {
  __typename: "MenuItem";
  active?: boolean | null;
  alcohol?: boolean | null;
  categories?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  id: string;
  imageS3?: string | null;
  modifierGroups?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  options?: {
    __typename: "ModelMenuItemOptionConnection";
    items: Array<{
      __typename: "MenuItemOption";
      createdAt: string;
      id: string;
      menuItemID: string;
      name: string;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  parentModifierGroups?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  prepTime?: number | null;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuItemTagConnection";
    items: Array<{
      __typename: "MenuItemTag";
      createdAt: string;
      id: string;
      menuItemID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxes?: {
    __typename: "ModelMenuItemTaxTypeConnection";
    items: Array<{
      __typename: "MenuItemTaxType";
      createdAt: string;
      id: string;
      menuItemID: string;
      taxType: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      };
      taxTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type DeleteMenuItemModifierGroupMutation = {
  __typename: "MenuItemModifierGroup";
  createdAt: string;
  id: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  modifierGroup: {
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  modifierGroupID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type DeleteMenuItemOptionMutation = {
  __typename: "MenuItemOption";
  createdAt: string;
  id: string;
  menuItemID: string;
  name: string;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
};

export type DeleteMenuItemPriceMutation = {
  __typename: "MenuItemPrice";
  active?: boolean | null;
  createdAt: string;
  id: string;
  menuItemID?: string | null;
  menuItemOptionID?: string | null;
  price: number;
  priceType?: {
    __typename: "MenuItemPriceType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null;
  priceTypeID?: string | null;
  updatedAt: string;
};

export type DeleteMenuItemPriceTypeMutation = {
  __typename: "MenuItemPriceType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type DeleteMenuItemTagMutation = {
  __typename: "MenuItemTag";
  createdAt: string;
  id: string;
  menuItemID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type DeleteMenuItemTaxTypeMutation = {
  __typename: "MenuItemTaxType";
  createdAt: string;
  id: string;
  menuItemID: string;
  taxType: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  };
  taxTypeID: string;
  updatedAt: string;
};

export type DeleteMenuMenuCategoryMutation = {
  __typename: "MenuMenuCategory";
  createdAt: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuCategory: {
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  menuCategoryID: string;
  menuID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type DeleteMenuScheduleMutation = {
  __typename: "MenuSchedule";
  createdAt: string;
  id: string;
  menuID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type DeleteMenuTagMutation = {
  __typename: "MenuTag";
  createdAt: string;
  id: string;
  menuID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type DeleteModifierGroupMutation = {
  __typename: "ModifierGroup";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  max?: number | null;
  menuItems?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  min?: number | null;
  modifiers?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tags?: {
    __typename: "ModelModifierGroupTagConnection";
    items: Array<{
      __typename: "ModifierGroupTag";
      createdAt: string;
      id: string;
      modifierGroupID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type DeleteModifierGroupMenuItemMutation = {
  __typename: "ModifierGroupMenuItem";
  createdAt: string;
  id: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  modifierGroup: {
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  modifierGroupID: string;
  updatedAt: string;
};

export type DeleteModifierGroupTagMutation = {
  __typename: "ModifierGroupTag";
  createdAt: string;
  id: string;
  modifierGroupID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type DeleteNotificationMutation = {
  __typename: "Notification";
  createdAt: string;
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplate?: {
    __typename: "EmailTemplate";
    createdAt: string;
    html?: string | null;
    id: string;
    name: string;
    subject?: string | null;
    tenantID: string;
    text?: string | null;
    updatedAt: string;
  } | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id: string;
  message?: string | null;
  name: string;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID: string;
  type: NotificationType;
  updatedAt: string;
};

export type DeleteOrderMutation = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  chits?: {
    __typename: "ModelChitConnection";
    items: Array<{
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    errorCode?: number | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    lastUpdate?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    message?: string | null;
    phone?: string | null;
    pickupTime?: string | null;
    status?: CourierStatus | null;
    type?: string | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID: string;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null;
  orderTypeID?: string | null;
  originAddress?: {
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip: string;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  venueStatus?: Array<{
    __typename: "VenueStatus";
    status: OrderStatus;
    venueID: string;
  } | null> | null;
};

export type DeleteOrderLinkMutation = {
  __typename: "OrderLink";
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  createdAt: string;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  location: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  };
  locationID: string;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  menus?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  notice?: string | null;
  noticeAtCollection?: string | null;
  notifications?: {
    __typename: "ModelOrderLinkNotificationConnection";
    items: Array<{
      __typename: "OrderLinkNotification";
      createdAt: string;
      event: NotificationEvent;
      id: string;
      notification: {
        __typename: "Notification";
        createdAt: string;
        emailBCC?: string | null;
        emailCC?: string | null;
        emailFrom?: string | null;
        emailReplyTo?: string | null;
        emailTemplateData?: string | null;
        emailTemplateID?: string | null;
        emailTo?: string | null;
        id: string;
        message?: string | null;
        name: string;
        smsFrom?: string | null;
        smsTo?: string | null;
        smsToDynamic?: string | null;
        smsToType?: PropertyType | null;
        tenantID: string;
        type: NotificationType;
        updatedAt: string;
      };
      notificationID: string;
      orderLinkID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderMode?: OrderMode | null;
  orderTypes?: {
    __typename: "ModelOrderLinkOrderTypeConnection";
    items: Array<{
      __typename: "OrderLinkOrderType";
      createdAt: string;
      id: string;
      orderLinkID: string;
      orderType: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  schedules?: {
    __typename: "ModelOrderLinkScheduleConnection";
    items: Array<{
      __typename: "OrderLinkSchedule";
      createdAt: string;
      id: string;
      orderLinkID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  searchPlaceholder?: string | null;
  smsCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tos?: string | null;
  updatedAt: string;
};

export type DeleteOrderLinkMenuMutation = {
  __typename: "OrderLinkMenu";
  createdAt: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuID: string;
  orderLink: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  };
  orderLinkID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type DeleteOrderLinkNotificationMutation = {
  __typename: "OrderLinkNotification";
  createdAt: string;
  event: NotificationEvent;
  id: string;
  notification: {
    __typename: "Notification";
    createdAt: string;
    emailBCC?: string | null;
    emailCC?: string | null;
    emailFrom?: string | null;
    emailReplyTo?: string | null;
    emailTemplate?: {
      __typename: "EmailTemplate";
      createdAt: string;
      html?: string | null;
      id: string;
      name: string;
      subject?: string | null;
      tenantID: string;
      text?: string | null;
      updatedAt: string;
    } | null;
    emailTemplateData?: string | null;
    emailTemplateID?: string | null;
    emailTo?: string | null;
    id: string;
    message?: string | null;
    name: string;
    smsFrom?: string | null;
    smsTo?: string | null;
    smsToDynamic?: string | null;
    smsToType?: PropertyType | null;
    tenantID: string;
    type: NotificationType;
    updatedAt: string;
  };
  notificationID: string;
  orderLinkID: string;
  updatedAt: string;
};

export type DeleteOrderLinkOrderTypeMutation = {
  __typename: "OrderLinkOrderType";
  createdAt: string;
  id: string;
  orderLinkID: string;
  orderType: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  orderTypeID: string;
  updatedAt: string;
};

export type DeleteOrderLinkScheduleMutation = {
  __typename: "OrderLinkSchedule";
  createdAt: string;
  id: string;
  orderLinkID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type DeleteOrderTypeMutation = {
  __typename: "OrderType";
  active?: boolean | null;
  asap?: boolean | null;
  createdAt: string;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  fees?: {
    __typename: "ModelOrderTypeFeeConnection";
    items: Array<{
      __typename: "OrderTypeFee";
      createdAt: string;
      fee: {
        __typename: "Fee";
        amount?: number | null;
        createdAt: string;
        id: string;
        inclusive?: boolean | null;
        name: string;
        rate?: number | null;
        taxed?: boolean | null;
        tenantID: string;
        type: FeeType;
        updatedAt: string;
      };
      feeID: string;
      id: string;
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  name: string;
  schedule?: boolean | null;
  schedules?: {
    __typename: "ModelOrderTypeScheduleConnection";
    items: Array<{
      __typename: "OrderTypeSchedule";
      createdAt: string;
      id: string;
      orderTypeID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type DeleteOrderTypeFeeMutation = {
  __typename: "OrderTypeFee";
  createdAt: string;
  fee: {
    __typename: "Fee";
    amount?: number | null;
    createdAt: string;
    id: string;
    inclusive?: boolean | null;
    name: string;
    rate?: number | null;
    taxed?: boolean | null;
    tenantID: string;
    type: FeeType;
    updatedAt: string;
  };
  feeID: string;
  id: string;
  orderTypeID: string;
  updatedAt: string;
};

export type DeleteOrderTypeScheduleMutation = {
  __typename: "OrderTypeSchedule";
  createdAt: string;
  id: string;
  orderTypeID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type DeletePaymentMethodMutation = {
  __typename: "PaymentMethod";
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  createdAt: string;
  name: string;
  processor?: CCProcessor | null;
  token: string;
  type: PaymentMethodType;
  updatedAt: string;
  zipcode?: string | null;
};

export type DeletePrintJobMutation = {
  __typename: "PrintJob";
  createdAt: string;
  data: string;
  expirationUnixTime?: number | null;
  id: string;
  serialNumber: string;
  status?: PrintJobStatus | null;
  updatedAt: string;
};

export type DeletePrinterMutation = {
  __typename: "Printer";
  createdAt: string;
  serialNumber: string;
  status?: PrinterStatus | null;
  updatedAt: string;
};

export type DeleteReceiptConfigMutation = {
  __typename: "ReceiptConfig";
  createdAt: string;
  headerSize?: number | null;
  id: string;
  itemSize?: number | null;
  name: string;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueSize?: number | null;
};

export type DeleteSMSMessageMutation = {
  __typename: "SMSMessage";
  createdAt: string;
  from: string;
  id: string;
  message: string;
  orderID?: string | null;
  tenantID: string;
  to: string;
  updatedAt: string;
};

export type DeleteScheduleMutation = {
  __typename: "Schedule";
  createdAt: string;
  endDate?: string | null;
  id: string;
  name: string;
  startDate?: string | null;
  tenantID: string;
  timeRanges?: Array<{
    __typename: "TimeRange";
    day: number;
    endTime: string;
    startTime: string;
  } | null> | null;
  type: ScheduleType;
  updatedAt: string;
};

export type DeleteTagMutation = {
  __typename: "Tag";
  createdAt: string;
  id: string;
  imageS3Square?: string | null;
  name: string;
  tenantID: string;
  type: TagType;
  updatedAt: string;
};

export type DeleteTaxRateMutation = {
  __typename: "TaxRate";
  amount?: number | null;
  createdAt: string;
  id: string;
  rate?: number | null;
  taxType?: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  } | null;
  taxTypeID: string;
  type: TaxRateType;
  updatedAt: string;
  venueID: string;
};

export type DeleteTaxTypeMutation = {
  __typename: "TaxType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  type: TaxTypeType;
  updatedAt: string;
};

export type DeleteTenantMutation = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteUserMutation = {
  __typename: "User";
  createdAt: string;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  updatedAt: string;
  userName: string;
  userRoles?: {
    __typename: "ModelUserRoleConnection";
    items: Array<{
      __typename: "UserRole";
      createdAt: string;
      entityType: EntityType;
      id: string;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "UserRoleType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<UserRolePermission | null> | null;
        tenantID?: string | null;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      user: {
        __typename: "User";
        createdAt: string;
        email: string;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName: string;
      };
      userID: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteUserRoleMutation = {
  __typename: "UserRole";
  createdAt: string;
  entityType: EntityType;
  id: string;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  user: {
    __typename: "User";
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    phone?: string | null;
    updatedAt: string;
    userName: string;
    userRoles?: {
      __typename: "ModelUserRoleConnection";
      items: Array<{
        __typename: "UserRole";
        createdAt: string;
        entityType: EntityType;
        id: string;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        userID: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  userID: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type DeleteUserRoleTypeMutation = {
  __typename: "UserRoleType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  updatedAt: string;
  venueID?: string | null;
};

export type DeleteVenueMutation = {
  __typename: "Venue";
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID?: string | null;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  schedules?: {
    __typename: "ModelVenueScheduleConnection";
    items: Array<{
      __typename: "VenueSchedule";
      createdAt: string;
      id: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxRates?: {
    __typename: "ModelTaxRateConnection";
    items: Array<{
      __typename: "TaxRate";
      amount?: number | null;
      createdAt: string;
      id: string;
      rate?: number | null;
      taxType?: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      } | null;
      taxTypeID: string;
      type: TaxRateType;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type DeleteVenueOrderMutation = {
  __typename: "VenueOrder";
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  status?: OrderStatus | null;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
  venueName?: string | null;
};

export type DeleteVenueScheduleMutation = {
  __typename: "VenueSchedule";
  createdAt: string;
  id: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
  venueID: string;
};

export type DeviceTransactionMutation = {
  __typename: "DeviceTransactionResponse";
  status?: string | null;
};

export type GetOrderDeliveryFeeMutation = {
  __typename: "OrderDeliveryFeeRes";
  duration?: number | null;
  fee?: number | null;
  pickupTime?: string | null;
  quoteId?: string | null;
};

export type I4GoPreAuthMutation = {
  __typename: "I4GoPreAuthRes";
  i4go_accessblock?: string | null;
  i4go_countrycode?: string | null;
  i4go_f21e?: string | null;
  i4go_i4m_url?: string | null;
  i4go_response?: string | null;
  i4go_responsecode?: string | null;
  i4go_server?: string | null;
};

export type PaymentRefundMutation = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type RemoveCheckItemMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type SaveCheckItemMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type SesCreateTemplateMutation = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type SesDeleteTemplateMutation = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type SesGetTemplateMutation = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type SesListTemplatesMutation = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type SesUpdateTemplateMutation = {
  __typename: "GenericResponse";
  response?: string | null;
};

export type SubmitOrderMutation = {
  __typename: "OrderRes";
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  deliveryEstimate?: number | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  dueTime?: string | null;
  id: string;
  queueTime?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  status?: OrderStatus | null;
};

export type UpdateCheckMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type UpdateCheckItemMutation = {
  __typename: "CheckItem";
  alcohol?: boolean | null;
  checkID: string;
  chit?: {
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null;
  chitID?: string | null;
  createdAt: string;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<{
    __typename: "CheckItemModifier";
    id: string;
    menuItemID?: string | null;
    modifierGroupID?: string | null;
    modifiers?: Array<{
      __typename: "CheckItemModifier";
      id: string;
      menuItemID?: string | null;
      modifierGroupID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
    } | null> | null;
    name: string;
    price: number;
    quantity: number;
    tags?: Array<{
      __typename: "CheckItemTag";
      id: string;
      name: string;
      type: TagType;
    } | null> | null;
    taxes?: Array<{
      __typename: "CheckItemTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    unitPrice?: number | null;
  } | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<{
    __typename: "CheckItemTag";
    id: string;
    name: string;
    type: TagType;
  } | null> | null;
  taxes?: Array<{
    __typename: "CheckItemTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  unitPrice?: number | null;
  updatedAt: string;
  utensils?: boolean | null;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type UpdateCheckItemQuantityMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type UpdateCheckPaymentMutation = {
  __typename: "CheckPayment";
  amount: number;
  cardPresent?: boolean | null;
  checkID: string;
  createdAt: string;
  displayName?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  isAuthed?: boolean | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  refNo?: string | null;
  status?: CheckPaymentStatus | null;
  tip?: number | null;
  token?: string | null;
  updatedAt: string;
};

export type UpdateCheckTotalsMutation = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type UpdateCheckTransactionMutation = {
  __typename: "CheckTransaction";
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  check?: {
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null;
  checkID: string;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<{
    __typename: "CheckRefund";
    amount: number;
    checkItemID?: string | null;
    type: CheckRefundType;
  } | null> | null;
  status: CheckTransactionStatus;
  thirdPartyData?: string | null;
  token?: string | null;
  type: CCTransactionType;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type UpdateChitMutation = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  items?: Array<{
    __typename: "ChitItem";
    alcohol?: boolean | null;
    id: string;
    instructions?: string | null;
    modifiers?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    name: string;
    price?: number | null;
    quantity: number;
    status?: ChitItemStatus | null;
    utensils?: boolean | null;
  } | null> | null;
  order?: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type UpdateConceptMutation = {
  __typename: "Concept";
  createdAt: string;
  id: string;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type UpdateCredentialMutation = {
  __typename: "Credential";
  createdAt: string;
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id: string;
  key: string;
  secret: string;
  smsFrom?: string | null;
  tenantID?: string | null;
  type: CredentialType;
  updatedAt: string;
  venueID?: string | null;
};

export type UpdateCustomerMutation = {
  __typename: "Customer";
  addresses?: Array<{
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null> | null;
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  paymentMethods?: {
    __typename: "ModelCustomerPaymentMethodConnection";
    items: Array<{
      __typename: "CustomerPaymentMethod";
      createdAt: string;
      customerID: string;
      id: string;
      paymentMethod: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      };
      paymentMethodID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  phone?: string | null;
  updatedAt: string;
  userName?: string | null;
};

export type UpdateCustomerPaymentMethodMutation = {
  __typename: "CustomerPaymentMethod";
  createdAt: string;
  customerID: string;
  id: string;
  paymentMethod: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  };
  paymentMethodID: string;
  updatedAt: string;
};

export type UpdateDeviceMutation = {
  __typename: "Device";
  active?: boolean | null;
  autoPrint?: boolean | null;
  createdAt: string;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id: string;
  ipAddress?: string | null;
  licenseID: string;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type: DeviceType;
  updatedAt: string;
};

export type UpdateDeviceTransactionMutation = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type UpdateDomainMutation = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type UpdateEmailTemplateMutation = {
  __typename: "EmailTemplate";
  createdAt: string;
  html?: string | null;
  id: string;
  name: string;
  subject?: string | null;
  tenantID: string;
  text?: string | null;
  updatedAt: string;
};

export type UpdateEmployeeMutation = {
  __typename: "Employee";
  active?: boolean | null;
  code?: string | null;
  createdAt: string;
  email?: string | null;
  firstName: string;
  id: string;
  jobs?: {
    __typename: "ModelJobConnection";
    items: Array<{
      __typename: "Job";
      createdAt: string;
      employee: {
        __typename: "Employee";
        active?: boolean | null;
        code?: string | null;
        createdAt: string;
        email?: string | null;
        firstName: string;
        id: string;
        lastName: string;
        phone?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      employeeID: string;
      entityType: EntityType;
      id: string;
      payRate?: number | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "JobType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<JobPermission | null> | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  lastName: string;
  phone?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type UpdateFeeMutation = {
  __typename: "Fee";
  amount?: number | null;
  createdAt: string;
  id: string;
  inclusive?: boolean | null;
  name: string;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID: string;
  type: FeeType;
  updatedAt: string;
};

export type UpdateJobMutation = {
  __typename: "Job";
  createdAt: string;
  employee: {
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  employeeID: string;
  entityType: EntityType;
  id: string;
  payRate?: number | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type UpdateJobTypeMutation = {
  __typename: "JobType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<JobPermission | null> | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type UpdateLicenseMutation = {
  __typename: "License";
  active?: boolean | null;
  createdAt: string;
  deviceDetails?: {
    __typename: "DeviceDetails";
    deviceID?: string | null;
    displayName?: string | null;
    heartbeat?: string | null;
    initTime?: string | null;
    name?: string | null;
    online?: boolean | null;
    version?: string | null;
  } | null;
  devices?: {
    __typename: "ModelDeviceConnection";
    items: Array<{
      __typename: "Device";
      active?: boolean | null;
      autoPrint?: boolean | null;
      createdAt: string;
      deviceID?: string | null;
      displayName?: string | null;
      host?: string | null;
      id: string;
      ipAddress?: string | null;
      licenseID: string;
      name?: string | null;
      port?: number | null;
      printOnBump?: boolean | null;
      serialNumber?: string | null;
      type: DeviceType;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: {
    __typename: "KioskConfig";
    orderLinkID?: string | null;
    showSettings?: boolean | null;
  } | null;
  kitchenConfig?: {
    __typename: "KitchenConfig";
    showSettings?: boolean | null;
  } | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfig?: {
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  type: DeviceType;
  updatedAt: string;
  venues?: {
    __typename: "ModelLicenseVenueConnection";
    items: Array<{
      __typename: "LicenseVenue";
      createdAt: string;
      id: string;
      licenseID: string;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateLicenseVenueMutation = {
  __typename: "LicenseVenue";
  createdAt: string;
  id: string;
  licenseID: string;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type UpdateLocationMutation = {
  __typename: "Location";
  city: string;
  country?: string | null;
  createdAt: string;
  email?: string | null;
  endOfDay?: string | null;
  id: string;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state: string;
  street: string;
  tenantID: string;
  timezone?: string | null;
  unit?: string | null;
  updatedAt: string;
  zip: string;
};

export type UpdateLogMutation = {
  __typename: "Log";
  checkID?: string | null;
  createdAt: string;
  employee?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type: LogType;
  updatedAt: string;
  user?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  userID?: string | null;
  venueID?: string | null;
};

export type UpdateMenuMutation = {
  __typename: "Menu";
  active?: boolean | null;
  categories?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  facilitators?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  featured?: boolean | null;
  id: string;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name: string;
  orderLinks?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  schedules?: {
    __typename: "ModelMenuScheduleConnection";
    items: Array<{
      __typename: "MenuSchedule";
      createdAt: string;
      id: string;
      menuID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuTagConnection";
    items: Array<{
      __typename: "MenuTag";
      createdAt: string;
      id: string;
      menuID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type UpdateMenuCategoryMutation = {
  __typename: "MenuCategory";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  menuItems?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  tags?: {
    __typename: "ModelMenuCategoryTagConnection";
    items: Array<{
      __typename: "MenuCategoryTag";
      createdAt: string;
      id: string;
      menuCategoryID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type UpdateMenuCategoryMenuItemMutation = {
  __typename: "MenuCategoryMenuItem";
  createdAt: string;
  id: string;
  menuCategory: {
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  menuCategoryID: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type UpdateMenuCategoryTagMutation = {
  __typename: "MenuCategoryTag";
  createdAt: string;
  id: string;
  menuCategoryID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type UpdateMenuFacilitatorMutation = {
  __typename: "MenuFacilitator";
  createdAt: string;
  facilitator: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  facilitatorID: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuID: string;
  updatedAt: string;
};

export type UpdateMenuItemMutation = {
  __typename: "MenuItem";
  active?: boolean | null;
  alcohol?: boolean | null;
  categories?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  id: string;
  imageS3?: string | null;
  modifierGroups?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  options?: {
    __typename: "ModelMenuItemOptionConnection";
    items: Array<{
      __typename: "MenuItemOption";
      createdAt: string;
      id: string;
      menuItemID: string;
      name: string;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  parentModifierGroups?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  prepTime?: number | null;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuItemTagConnection";
    items: Array<{
      __typename: "MenuItemTag";
      createdAt: string;
      id: string;
      menuItemID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxes?: {
    __typename: "ModelMenuItemTaxTypeConnection";
    items: Array<{
      __typename: "MenuItemTaxType";
      createdAt: string;
      id: string;
      menuItemID: string;
      taxType: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      };
      taxTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type UpdateMenuItemModifierGroupMutation = {
  __typename: "MenuItemModifierGroup";
  createdAt: string;
  id: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  modifierGroup: {
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  modifierGroupID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type UpdateMenuItemOptionMutation = {
  __typename: "MenuItemOption";
  createdAt: string;
  id: string;
  menuItemID: string;
  name: string;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
};

export type UpdateMenuItemPriceMutation = {
  __typename: "MenuItemPrice";
  active?: boolean | null;
  createdAt: string;
  id: string;
  menuItemID?: string | null;
  menuItemOptionID?: string | null;
  price: number;
  priceType?: {
    __typename: "MenuItemPriceType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null;
  priceTypeID?: string | null;
  updatedAt: string;
};

export type UpdateMenuItemPriceTypeMutation = {
  __typename: "MenuItemPriceType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type UpdateMenuItemTagMutation = {
  __typename: "MenuItemTag";
  createdAt: string;
  id: string;
  menuItemID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type UpdateMenuItemTaxTypeMutation = {
  __typename: "MenuItemTaxType";
  createdAt: string;
  id: string;
  menuItemID: string;
  taxType: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  };
  taxTypeID: string;
  updatedAt: string;
};

export type UpdateMenuMenuCategoryMutation = {
  __typename: "MenuMenuCategory";
  createdAt: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuCategory: {
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  menuCategoryID: string;
  menuID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type UpdateMenuScheduleMutation = {
  __typename: "MenuSchedule";
  createdAt: string;
  id: string;
  menuID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type UpdateMenuTagMutation = {
  __typename: "MenuTag";
  createdAt: string;
  id: string;
  menuID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type UpdateModifierGroupMutation = {
  __typename: "ModifierGroup";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  max?: number | null;
  menuItems?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  min?: number | null;
  modifiers?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tags?: {
    __typename: "ModelModifierGroupTagConnection";
    items: Array<{
      __typename: "ModifierGroupTag";
      createdAt: string;
      id: string;
      modifierGroupID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type UpdateModifierGroupMenuItemMutation = {
  __typename: "ModifierGroupMenuItem";
  createdAt: string;
  id: string;
  menuItem: {
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  };
  menuItemID: string;
  modifierGroup: {
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  modifierGroupID: string;
  updatedAt: string;
};

export type UpdateModifierGroupTagMutation = {
  __typename: "ModifierGroupTag";
  createdAt: string;
  id: string;
  modifierGroupID: string;
  tag: {
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  };
  tagID: string;
  updatedAt: string;
};

export type UpdateNotificationMutation = {
  __typename: "Notification";
  createdAt: string;
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplate?: {
    __typename: "EmailTemplate";
    createdAt: string;
    html?: string | null;
    id: string;
    name: string;
    subject?: string | null;
    tenantID: string;
    text?: string | null;
    updatedAt: string;
  } | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id: string;
  message?: string | null;
  name: string;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID: string;
  type: NotificationType;
  updatedAt: string;
};

export type UpdateOrderMutation = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  chits?: {
    __typename: "ModelChitConnection";
    items: Array<{
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    errorCode?: number | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    lastUpdate?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    message?: string | null;
    phone?: string | null;
    pickupTime?: string | null;
    status?: CourierStatus | null;
    type?: string | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID: string;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null;
  orderTypeID?: string | null;
  originAddress?: {
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip: string;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  venueStatus?: Array<{
    __typename: "VenueStatus";
    status: OrderStatus;
    venueID: string;
  } | null> | null;
};

export type UpdateOrderLinkMutation = {
  __typename: "OrderLink";
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  createdAt: string;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  location: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  };
  locationID: string;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  menus?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  notice?: string | null;
  noticeAtCollection?: string | null;
  notifications?: {
    __typename: "ModelOrderLinkNotificationConnection";
    items: Array<{
      __typename: "OrderLinkNotification";
      createdAt: string;
      event: NotificationEvent;
      id: string;
      notification: {
        __typename: "Notification";
        createdAt: string;
        emailBCC?: string | null;
        emailCC?: string | null;
        emailFrom?: string | null;
        emailReplyTo?: string | null;
        emailTemplateData?: string | null;
        emailTemplateID?: string | null;
        emailTo?: string | null;
        id: string;
        message?: string | null;
        name: string;
        smsFrom?: string | null;
        smsTo?: string | null;
        smsToDynamic?: string | null;
        smsToType?: PropertyType | null;
        tenantID: string;
        type: NotificationType;
        updatedAt: string;
      };
      notificationID: string;
      orderLinkID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderMode?: OrderMode | null;
  orderTypes?: {
    __typename: "ModelOrderLinkOrderTypeConnection";
    items: Array<{
      __typename: "OrderLinkOrderType";
      createdAt: string;
      id: string;
      orderLinkID: string;
      orderType: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  schedules?: {
    __typename: "ModelOrderLinkScheduleConnection";
    items: Array<{
      __typename: "OrderLinkSchedule";
      createdAt: string;
      id: string;
      orderLinkID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  searchPlaceholder?: string | null;
  smsCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tos?: string | null;
  updatedAt: string;
};

export type UpdateOrderLinkMenuMutation = {
  __typename: "OrderLinkMenu";
  createdAt: string;
  id: string;
  menu: {
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  };
  menuID: string;
  orderLink: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  };
  orderLinkID: string;
  sortOrder?: number | null;
  updatedAt: string;
};

export type UpdateOrderLinkNotificationMutation = {
  __typename: "OrderLinkNotification";
  createdAt: string;
  event: NotificationEvent;
  id: string;
  notification: {
    __typename: "Notification";
    createdAt: string;
    emailBCC?: string | null;
    emailCC?: string | null;
    emailFrom?: string | null;
    emailReplyTo?: string | null;
    emailTemplate?: {
      __typename: "EmailTemplate";
      createdAt: string;
      html?: string | null;
      id: string;
      name: string;
      subject?: string | null;
      tenantID: string;
      text?: string | null;
      updatedAt: string;
    } | null;
    emailTemplateData?: string | null;
    emailTemplateID?: string | null;
    emailTo?: string | null;
    id: string;
    message?: string | null;
    name: string;
    smsFrom?: string | null;
    smsTo?: string | null;
    smsToDynamic?: string | null;
    smsToType?: PropertyType | null;
    tenantID: string;
    type: NotificationType;
    updatedAt: string;
  };
  notificationID: string;
  orderLinkID: string;
  updatedAt: string;
};

export type UpdateOrderLinkOrderTypeMutation = {
  __typename: "OrderLinkOrderType";
  createdAt: string;
  id: string;
  orderLinkID: string;
  orderType: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  orderTypeID: string;
  updatedAt: string;
};

export type UpdateOrderLinkScheduleMutation = {
  __typename: "OrderLinkSchedule";
  createdAt: string;
  id: string;
  orderLinkID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type UpdateOrderOrderTypeMutation = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  chits?: {
    __typename: "ModelChitConnection";
    items: Array<{
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    errorCode?: number | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    lastUpdate?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    message?: string | null;
    phone?: string | null;
    pickupTime?: string | null;
    status?: CourierStatus | null;
    type?: string | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID: string;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null;
  orderTypeID?: string | null;
  originAddress?: {
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip: string;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  venueStatus?: Array<{
    __typename: "VenueStatus";
    status: OrderStatus;
    venueID: string;
  } | null> | null;
};

export type UpdateOrderTypeMutation = {
  __typename: "OrderType";
  active?: boolean | null;
  asap?: boolean | null;
  createdAt: string;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  fees?: {
    __typename: "ModelOrderTypeFeeConnection";
    items: Array<{
      __typename: "OrderTypeFee";
      createdAt: string;
      fee: {
        __typename: "Fee";
        amount?: number | null;
        createdAt: string;
        id: string;
        inclusive?: boolean | null;
        name: string;
        rate?: number | null;
        taxed?: boolean | null;
        tenantID: string;
        type: FeeType;
        updatedAt: string;
      };
      feeID: string;
      id: string;
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  name: string;
  schedule?: boolean | null;
  schedules?: {
    __typename: "ModelOrderTypeScheduleConnection";
    items: Array<{
      __typename: "OrderTypeSchedule";
      createdAt: string;
      id: string;
      orderTypeID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type UpdateOrderTypeFeeMutation = {
  __typename: "OrderTypeFee";
  createdAt: string;
  fee: {
    __typename: "Fee";
    amount?: number | null;
    createdAt: string;
    id: string;
    inclusive?: boolean | null;
    name: string;
    rate?: number | null;
    taxed?: boolean | null;
    tenantID: string;
    type: FeeType;
    updatedAt: string;
  };
  feeID: string;
  id: string;
  orderTypeID: string;
  updatedAt: string;
};

export type UpdateOrderTypeScheduleMutation = {
  __typename: "OrderTypeSchedule";
  createdAt: string;
  id: string;
  orderTypeID: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
};

export type UpdatePaymentMethodMutation = {
  __typename: "PaymentMethod";
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  createdAt: string;
  name: string;
  processor?: CCProcessor | null;
  token: string;
  type: PaymentMethodType;
  updatedAt: string;
  zipcode?: string | null;
};

export type UpdatePrintJobMutation = {
  __typename: "PrintJob";
  createdAt: string;
  data: string;
  expirationUnixTime?: number | null;
  id: string;
  serialNumber: string;
  status?: PrintJobStatus | null;
  updatedAt: string;
};

export type UpdatePrinterMutation = {
  __typename: "Printer";
  createdAt: string;
  serialNumber: string;
  status?: PrinterStatus | null;
  updatedAt: string;
};

export type UpdateReceiptConfigMutation = {
  __typename: "ReceiptConfig";
  createdAt: string;
  headerSize?: number | null;
  id: string;
  itemSize?: number | null;
  name: string;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueSize?: number | null;
};

export type UpdateSMSMessageMutation = {
  __typename: "SMSMessage";
  createdAt: string;
  from: string;
  id: string;
  message: string;
  orderID?: string | null;
  tenantID: string;
  to: string;
  updatedAt: string;
};

export type UpdateScheduleMutation = {
  __typename: "Schedule";
  createdAt: string;
  endDate?: string | null;
  id: string;
  name: string;
  startDate?: string | null;
  tenantID: string;
  timeRanges?: Array<{
    __typename: "TimeRange";
    day: number;
    endTime: string;
    startTime: string;
  } | null> | null;
  type: ScheduleType;
  updatedAt: string;
};

export type UpdateTagMutation = {
  __typename: "Tag";
  createdAt: string;
  id: string;
  imageS3Square?: string | null;
  name: string;
  tenantID: string;
  type: TagType;
  updatedAt: string;
};

export type UpdateTaxRateMutation = {
  __typename: "TaxRate";
  amount?: number | null;
  createdAt: string;
  id: string;
  rate?: number | null;
  taxType?: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  } | null;
  taxTypeID: string;
  type: TaxRateType;
  updatedAt: string;
  venueID: string;
};

export type UpdateTaxTypeMutation = {
  __typename: "TaxType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  type: TaxTypeType;
  updatedAt: string;
};

export type UpdateTenantMutation = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateUserMutation = {
  __typename: "User";
  createdAt: string;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  updatedAt: string;
  userName: string;
  userRoles?: {
    __typename: "ModelUserRoleConnection";
    items: Array<{
      __typename: "UserRole";
      createdAt: string;
      entityType: EntityType;
      id: string;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "UserRoleType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<UserRolePermission | null> | null;
        tenantID?: string | null;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      user: {
        __typename: "User";
        createdAt: string;
        email: string;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName: string;
      };
      userID: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateUserRoleMutation = {
  __typename: "UserRole";
  createdAt: string;
  entityType: EntityType;
  id: string;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  user: {
    __typename: "User";
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    phone?: string | null;
    updatedAt: string;
    userName: string;
    userRoles?: {
      __typename: "ModelUserRoleConnection";
      items: Array<{
        __typename: "UserRole";
        createdAt: string;
        entityType: EntityType;
        id: string;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        userID: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  userID: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type UpdateUserRoleTypeMutation = {
  __typename: "UserRoleType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  updatedAt: string;
  venueID?: string | null;
};

export type UpdateVenueMutation = {
  __typename: "Venue";
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID?: string | null;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  schedules?: {
    __typename: "ModelVenueScheduleConnection";
    items: Array<{
      __typename: "VenueSchedule";
      createdAt: string;
      id: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxRates?: {
    __typename: "ModelTaxRateConnection";
    items: Array<{
      __typename: "TaxRate";
      amount?: number | null;
      createdAt: string;
      id: string;
      rate?: number | null;
      taxType?: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      } | null;
      taxTypeID: string;
      type: TaxRateType;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type UpdateVenueOrderMutation = {
  __typename: "VenueOrder";
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  status?: OrderStatus | null;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
  venueName?: string | null;
};

export type UpdateVenueScheduleMutation = {
  __typename: "VenueSchedule";
  createdAt: string;
  id: string;
  schedule: {
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  };
  scheduleID: string;
  updatedAt: string;
  venueID: string;
};

export type GetOrderLinkCustomQuery = {
  __typename: "OrderLink";
  active?: boolean | null;
  locationID: string;
  location: {
    __typename: "Location";
    street: string;
    city: string;
    state: string;
    zip: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    timezone?: string | null;
  };
  originName?: string | null;
  originEmail?: string | null;
  originPhone?: string | null;
  heading?: string | null;
  subHeading?: string | null;
  notice?: string | null;
  searchPlaceholder?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  privacyPolicy?: string | null;
  tos?: string | null;
  footerURL?: string | null;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  ccCredential?: {
    __typename: "Credential";
    id: string;
    type: CredentialType;
    dcTokenKey?: string | null;
  } | null;
  schedules?: {
    __typename: "ModelOrderLinkScheduleConnection";
    items: Array<{
      __typename: "OrderLinkSchedule";
      schedule: {
        __typename: "Schedule";
        id: string;
        name: string;
        startDate?: string | null;
        endDate?: string | null;
        timeRanges?: Array<{
          __typename: "TimeRange";
          day: number;
          startTime: string;
          endTime: string;
        } | null> | null;
      };
    } | null>;
  } | null;
  menus?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        id: string;
        name: string;
        displayName?: string | null;
        description?: string | null;
        imageS3LogoSquare?: string | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3Featured?: Array<string | null> | null;
        venueID?: string | null;
        featured?: boolean | null;
        popular?: boolean | null;
        venue?: {
          __typename: "Venue";
          id: string;
          name: string;
          imageS3LogoSquare?: string | null;
        } | null;
        categories?: {
          __typename: "ModelMenuMenuCategoryConnection";
          items: Array<{
            __typename: "MenuMenuCategory";
            id: string;
            sortOrder?: number | null;
            menuCategory: {
              __typename: "MenuCategory";
              active?: boolean | null;
              conceptID?: string | null;
              id: string;
              name: string;
              displayName?: string | null;
              description?: string | null;
              venueID?: string | null;
              priceTypeID?: string | null;
              menuItems?: {
                __typename: "ModelMenuCategoryMenuItemConnection";
                items: Array<{
                  __typename: "MenuCategoryMenuItem";
                  id: string;
                  sortOrder?: number | null;
                  menuCategoryID: string;
                  menuItem: {
                    __typename: "MenuItem";
                    active?: boolean | null;
                    conceptID?: string | null;
                    description?: string | null;
                    id: string;
                    imageS3?: string | null;
                    name: string;
                    venueID?: string | null;
                    popular?: boolean | null;
                    prices?: {
                      __typename: "ModelMenuItemPriceConnection";
                      items: Array<{
                        __typename: "MenuItemPrice";
                        id: string;
                        price: number;
                        priceTypeID?: string | null;
                        active?: boolean | null;
                      } | null>;
                    } | null;
                  };
                  menuItemID: string;
                } | null>;
              } | null;
            };
            menuCategoryID: string;
            menuID: string;
          } | null>;
        } | null;
        tags?: {
          __typename: "ModelMenuTagConnection";
          items: Array<{
            __typename: "MenuTag";
            tag: {
              __typename: "Tag";
              id: string;
              imageS3Square?: string | null;
              name: string;
              type: TagType;
            };
          } | null>;
        } | null;
        schedules?: {
          __typename: "ModelMenuScheduleConnection";
          items: Array<{
            __typename: "MenuSchedule";
            schedule: {
              __typename: "Schedule";
              id: string;
              name: string;
              startDate?: string | null;
              endDate?: string | null;
              timeRanges?: Array<{
                __typename: "TimeRange";
                day: number;
                startTime: string;
                endTime: string;
              } | null> | null;
            };
          } | null>;
        } | null;
      };
      menuID: string;
      orderLinkID: string;
      sortOrder?: number | null;
    } | null>;
  } | null;
  name: string;
  orderTypes?: {
    __typename: "ModelOrderLinkOrderTypeConnection";
    items: Array<{
      __typename: "OrderLinkOrderType";
      orderType: {
        __typename: "OrderType";
        active?: boolean | null;
        id: string;
        name: string;
        displayName?: string | null;
        asap?: boolean | null;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        deliveryProvider?: DeliveryProvider | null;
      };
      orderTypeID: string;
    } | null>;
  } | null;
  tenantID: string;
};

export type GetOrderCustomQuery = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      customerID?: string | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        firstName: string;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
        address?: {
          __typename: "Address";
          street?: string | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          distance?: number | null;
          latitude?: number | null;
          longitude?: number | null;
        } | null;
      } | null;
      fees?: Array<{
        __typename: "CheckFee";
        id: string;
        name: string;
        type: FeeType;
        amount: number;
        inclusive?: boolean | null;
        taxed?: boolean | null;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        items: Array<{
          __typename: "CheckItem";
          name: string;
          price: number;
          quantity: number;
          venueID: string;
          venueName?: string | null;
          instructions?: string | null;
          utensils?: boolean | null;
          modifiers?: Array<{
            __typename: "CheckItemModifier";
            name: string;
            price: number;
            quantity: number;
            modifiers?: Array<{
              __typename: "CheckItemModifier";
              name: string;
              price: number;
              quantity: number;
            } | null> | null;
          } | null> | null;
        } | null>;
      } | null;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        items: Array<{
          __typename: "CheckPayment";
          amount: number;
          displayName?: string | null;
          id: string;
          status?: CheckPaymentStatus | null;
          tip?: number | null;
        } | null>;
      } | null;
      readyEstimate?: number | null;
      totals?: {
        __typename: "CheckTotals";
        discountTotal?: number | null;
        feeTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        items: Array<{
          __typename: "CheckTransaction";
          amount: number;
          amountItems?: number | null;
          amountFees?: number | null;
          amountTax?: number | null;
          amountTip?: number | null;
          authCode?: string | null;
          cardNumber?: string | null;
          cardType?: string | null;
          createdAt?: string | null;
          id: string;
          status: CheckTransactionStatus;
          type: CCTransactionType;
          invoice?: number | null;
          venueID: string;
          refunds?: Array<{
            __typename: "CheckRefund";
            amount: number;
            type: CheckRefundType;
            checkItemID?: string | null;
          } | null> | null;
          checkItemIDs?: Array<string | null> | null;
        } | null>;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
  } | null;
  createdAt?: string | null;
  submittedAt?: string | null;
  queuedAt?: string | null;
  customerID?: string | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    address?: {
      __typename: "Address";
      street?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      distance?: number | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    name: string;
    email?: string | null;
    phone?: string | null;
    street?: string | null;
    city?: string | null;
    state?: string | null;
    zip: string;
  } | null;
  serviceType?: ServiceType | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryEstimate?: number | null;
  deliveryTime?: string | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    type?: string | null;
    status?: CourierStatus | null;
    latitude?: number | null;
    longitude?: number | null;
    lastUpdate?: string | null;
    message?: string | null;
    pickupTime?: string | null;
    errorCode?: number | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    leadTime?: number | null;
    name: string;
    locationID: string;
    location: {
      __typename: "Location";
      street: string;
      city: string;
      state: string;
      zip: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      timezone?: string | null;
    };
    originName?: string | null;
    originEmail?: string | null;
    originPhone?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    privacyPolicy?: string | null;
    tos?: string | null;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderTypeID?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    displayName?: string | null;
    id: string;
    name: string;
    asap?: boolean | null;
    schedule?: boolean | null;
    serviceType?: ServiceType | null;
    deliveryProvider?: DeliveryProvider | null;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  locationID: string;
};

export type ListOrderLinksByDomainMinQuery = {
  __typename: "ModelOrderLinkConnection";
  items: Array<{
    __typename: "OrderLink";
    active?: boolean | null;
    id: string;
    isDefault?: boolean | null;
  } | null>;
};

export type GetCheckQuery = {
  __typename: "Check";
  createdAt: string;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  diagnostics?: string | null;
  fees?: Array<{
    __typename: "CheckFee";
    amount: number;
    id: string;
    inclusive?: boolean | null;
    name: string;
    taxed?: boolean | null;
    type: FeeType;
  } | null> | null;
  id: string;
  items?: {
    __typename: "ModelCheckItemConnection";
    items: Array<{
      __typename: "CheckItem";
      alcohol?: boolean | null;
      checkID: string;
      chit?: {
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null;
      chitID?: string | null;
      createdAt: string;
      id: string;
      instructions?: string | null;
      menuID?: string | null;
      menuItemID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      priceTypeID?: string | null;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
      updatedAt: string;
      utensils?: boolean | null;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  payments?: {
    __typename: "ModelCheckPaymentConnection";
    items: Array<{
      __typename: "CheckPayment";
      amount: number;
      cardPresent?: boolean | null;
      checkID: string;
      createdAt: string;
      displayName?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      isAuthed?: boolean | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      refNo?: string | null;
      status?: CheckPaymentStatus | null;
      tip?: number | null;
      token?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  readyEstimate?: number | null;
  taxes?: Array<{
    __typename: "CheckTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  tenant: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  tenantID: string;
  totals?: {
    __typename: "CheckTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    tipTotal?: number | null;
    total?: number | null;
  } | null;
  transactions?: {
    __typename: "ModelCheckTransactionConnection";
    items: Array<{
      __typename: "CheckTransaction";
      amount: number;
      amountFees?: number | null;
      amountItems?: number | null;
      amountTax?: number | null;
      amountTip?: number | null;
      authCode?: string | null;
      cardNumber?: string | null;
      cardType?: string | null;
      check?: {
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      checkID: string;
      checkItemIDs?: Array<string | null> | null;
      comp?: boolean | null;
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      invoice?: number | null;
      last4?: number | null;
      note?: string | null;
      paymentID?: string | null;
      paymentMethod?: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      } | null;
      paymentMethodID?: string | null;
      paymentMethodType?: PaymentMethodType | null;
      processor?: CCProcessor | null;
      processorCredentialID?: string | null;
      reason?: CheckTransactionReason | null;
      refNo?: string | null;
      refunds?: Array<{
        __typename: "CheckRefund";
        amount: number;
        checkItemID?: string | null;
        type: CheckRefundType;
      } | null> | null;
      status: CheckTransactionStatus;
      thirdPartyData?: string | null;
      token?: string | null;
      type: CCTransactionType;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  updatedAt: string;
  venueTotals?: Array<{
    __typename: "VenueTotals";
    ccCaptureTotal?: number | null;
    ccRefundTotal?: number | null;
    ccVoidTotal?: number | null;
    checkPercent?: number | null;
    discountTotal?: number | null;
    feeTotal?: number | null;
    feeTotalInclusive?: number | null;
    fees?: Array<{
      __typename: "VenueTotalCheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    feesAndTaxTotal?: number | null;
    paymentTotal?: number | null;
    remainingBalance?: number | null;
    subTotal?: number | null;
    taxTotal?: number | null;
    taxes?: Array<{
      __typename: "VenueTotalCheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tipTotal?: number | null;
    total?: number | null;
    venueID: string;
  } | null> | null;
};

export type GetCheckItemQuery = {
  __typename: "CheckItem";
  alcohol?: boolean | null;
  checkID: string;
  chit?: {
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null;
  chitID?: string | null;
  createdAt: string;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<{
    __typename: "CheckItemModifier";
    id: string;
    menuItemID?: string | null;
    modifierGroupID?: string | null;
    modifiers?: Array<{
      __typename: "CheckItemModifier";
      id: string;
      menuItemID?: string | null;
      modifierGroupID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
    } | null> | null;
    name: string;
    price: number;
    quantity: number;
    tags?: Array<{
      __typename: "CheckItemTag";
      id: string;
      name: string;
      type: TagType;
    } | null> | null;
    taxes?: Array<{
      __typename: "CheckItemTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    unitPrice?: number | null;
  } | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<{
    __typename: "CheckItemTag";
    id: string;
    name: string;
    type: TagType;
  } | null> | null;
  taxes?: Array<{
    __typename: "CheckItemTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  unitPrice?: number | null;
  updatedAt: string;
  utensils?: boolean | null;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type GetCheckTransactionQuery = {
  __typename: "CheckTransaction";
  amount: number;
  amountFees?: number | null;
  amountItems?: number | null;
  amountTax?: number | null;
  amountTip?: number | null;
  authCode?: string | null;
  cardNumber?: string | null;
  cardType?: string | null;
  check?: {
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null;
  checkID: string;
  checkItemIDs?: Array<string | null> | null;
  comp?: boolean | null;
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  invoice?: number | null;
  last4?: number | null;
  note?: string | null;
  paymentID?: string | null;
  paymentMethod?: {
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null;
  paymentMethodID?: string | null;
  paymentMethodType?: PaymentMethodType | null;
  processor?: CCProcessor | null;
  processorCredentialID?: string | null;
  reason?: CheckTransactionReason | null;
  refNo?: string | null;
  refunds?: Array<{
    __typename: "CheckRefund";
    amount: number;
    checkItemID?: string | null;
    type: CheckRefundType;
  } | null> | null;
  status: CheckTransactionStatus;
  thirdPartyData?: string | null;
  token?: string | null;
  type: CCTransactionType;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
};

export type GetChitQuery = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  items?: Array<{
    __typename: "ChitItem";
    alcohol?: boolean | null;
    id: string;
    instructions?: string | null;
    modifiers?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    name: string;
    price?: number | null;
    quantity: number;
    status?: ChitItemStatus | null;
    utensils?: boolean | null;
  } | null> | null;
  order?: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type GetConceptQuery = {
  __typename: "Concept";
  createdAt: string;
  id: string;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type GetCredentialQuery = {
  __typename: "Credential";
  createdAt: string;
  dcEcomMID?: string | null;
  dcMID?: string | null;
  dcTokenKey?: string | null;
  id: string;
  key: string;
  secret: string;
  smsFrom?: string | null;
  tenantID?: string | null;
  type: CredentialType;
  updatedAt: string;
  venueID?: string | null;
};

export type GetCustomerQuery = {
  __typename: "Customer";
  addresses?: Array<{
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null> | null;
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  createdAt: string;
  defaultPaymentMethodID?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  paymentMethods?: {
    __typename: "ModelCustomerPaymentMethodConnection";
    items: Array<{
      __typename: "CustomerPaymentMethod";
      createdAt: string;
      customerID: string;
      id: string;
      paymentMethod: {
        __typename: "PaymentMethod";
        cardExpiration?: string | null;
        cardPresent?: boolean | null;
        cardType?: string | null;
        createdAt: string;
        name: string;
        processor?: CCProcessor | null;
        token: string;
        type: PaymentMethodType;
        updatedAt: string;
        zipcode?: string | null;
      };
      paymentMethodID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  phone?: string | null;
  updatedAt: string;
  userName?: string | null;
};

export type GetDeviceQuery = {
  __typename: "Device";
  active?: boolean | null;
  autoPrint?: boolean | null;
  createdAt: string;
  deviceID?: string | null;
  displayName?: string | null;
  host?: string | null;
  id: string;
  ipAddress?: string | null;
  licenseID: string;
  name?: string | null;
  port?: number | null;
  printOnBump?: boolean | null;
  serialNumber?: string | null;
  type: DeviceType;
  updatedAt: string;
};

export type GetDeviceTransactionQuery = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type GetDomainQuery = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type GetEmailTemplateQuery = {
  __typename: "EmailTemplate";
  createdAt: string;
  html?: string | null;
  id: string;
  name: string;
  subject?: string | null;
  tenantID: string;
  text?: string | null;
  updatedAt: string;
};

export type GetEmployeeQuery = {
  __typename: "Employee";
  active?: boolean | null;
  code?: string | null;
  createdAt: string;
  email?: string | null;
  firstName: string;
  id: string;
  jobs?: {
    __typename: "ModelJobConnection";
    items: Array<{
      __typename: "Job";
      createdAt: string;
      employee: {
        __typename: "Employee";
        active?: boolean | null;
        code?: string | null;
        createdAt: string;
        email?: string | null;
        firstName: string;
        id: string;
        lastName: string;
        phone?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      employeeID: string;
      entityType: EntityType;
      id: string;
      payRate?: number | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "JobType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<JobPermission | null> | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  lastName: string;
  phone?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type GetFeeQuery = {
  __typename: "Fee";
  amount?: number | null;
  createdAt: string;
  id: string;
  inclusive?: boolean | null;
  name: string;
  rate?: number | null;
  taxed?: boolean | null;
  tenantID: string;
  type: FeeType;
  updatedAt: string;
};

export type GetJobQuery = {
  __typename: "Job";
  createdAt: string;
  employee: {
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  };
  employeeID: string;
  entityType: EntityType;
  id: string;
  payRate?: number | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type GetJobTypeQuery = {
  __typename: "JobType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<JobPermission | null> | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type GetLicenseQuery = {
  __typename: "License";
  active?: boolean | null;
  createdAt: string;
  deviceDetails?: {
    __typename: "DeviceDetails";
    deviceID?: string | null;
    displayName?: string | null;
    heartbeat?: string | null;
    initTime?: string | null;
    name?: string | null;
    online?: boolean | null;
    version?: string | null;
  } | null;
  devices?: {
    __typename: "ModelDeviceConnection";
    items: Array<{
      __typename: "Device";
      active?: boolean | null;
      autoPrint?: boolean | null;
      createdAt: string;
      deviceID?: string | null;
      displayName?: string | null;
      host?: string | null;
      id: string;
      ipAddress?: string | null;
      licenseID: string;
      name?: string | null;
      port?: number | null;
      printOnBump?: boolean | null;
      serialNumber?: string | null;
      type: DeviceType;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: {
    __typename: "KioskConfig";
    orderLinkID?: string | null;
    showSettings?: boolean | null;
  } | null;
  kitchenConfig?: {
    __typename: "KitchenConfig";
    showSettings?: boolean | null;
  } | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfig?: {
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  type: DeviceType;
  updatedAt: string;
  venues?: {
    __typename: "ModelLicenseVenueConnection";
    items: Array<{
      __typename: "LicenseVenue";
      createdAt: string;
      id: string;
      licenseID: string;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetLocationQuery = {
  __typename: "Location";
  city: string;
  country?: string | null;
  createdAt: string;
  email?: string | null;
  endOfDay?: string | null;
  id: string;
  latitude?: number | null;
  longitude?: number | null;
  name: string;
  phone?: string | null;
  state: string;
  street: string;
  tenantID: string;
  timezone?: string | null;
  unit?: string | null;
  updatedAt: string;
  zip: string;
};

export type GetLogQuery = {
  __typename: "Log";
  checkID?: string | null;
  createdAt: string;
  employee?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  employeeID?: string | null;
  event?: LogEvent | null;
  id: string;
  message?: string | null;
  orderID?: string | null;
  source?: LogSource | null;
  tenantID?: string | null;
  type: LogType;
  updatedAt: string;
  user?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  userID?: string | null;
  venueID?: string | null;
};

export type GetMenuQuery = {
  __typename: "Menu";
  active?: boolean | null;
  categories?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  facilitators?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  featured?: boolean | null;
  id: string;
  imageS3Featured?: Array<string | null> | null;
  imageS3Headers?: Array<string | null> | null;
  imageS3HeadersMobile?: Array<string | null> | null;
  imageS3LogoSquare?: string | null;
  name: string;
  orderLinks?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  schedules?: {
    __typename: "ModelMenuScheduleConnection";
    items: Array<{
      __typename: "MenuSchedule";
      createdAt: string;
      id: string;
      menuID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuTagConnection";
    items: Array<{
      __typename: "MenuTag";
      createdAt: string;
      id: string;
      menuID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type GetMenuCategoryQuery = {
  __typename: "MenuCategory";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  menuItems?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuMenuCategoryConnection";
    items: Array<{
      __typename: "MenuMenuCategory";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  tags?: {
    __typename: "ModelMenuCategoryTagConnection";
    items: Array<{
      __typename: "MenuCategoryTag";
      createdAt: string;
      id: string;
      menuCategoryID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type GetMenuItemQuery = {
  __typename: "MenuItem";
  active?: boolean | null;
  alcohol?: boolean | null;
  categories?: {
    __typename: "ModelMenuCategoryMenuItemConnection";
    items: Array<{
      __typename: "MenuCategoryMenuItem";
      createdAt: string;
      id: string;
      menuCategory: {
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuCategoryID: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  id: string;
  imageS3?: string | null;
  modifierGroups?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  options?: {
    __typename: "ModelMenuItemOptionConnection";
    items: Array<{
      __typename: "MenuItemOption";
      createdAt: string;
      id: string;
      menuItemID: string;
      name: string;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  parentModifierGroups?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  popular?: boolean | null;
  prepTime?: number | null;
  prices?: {
    __typename: "ModelMenuItemPriceConnection";
    items: Array<{
      __typename: "MenuItemPrice";
      active?: boolean | null;
      createdAt: string;
      id: string;
      menuItemID?: string | null;
      menuItemOptionID?: string | null;
      price: number;
      priceType?: {
        __typename: "MenuItemPriceType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      priceTypeID?: string | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tags?: {
    __typename: "ModelMenuItemTagConnection";
    items: Array<{
      __typename: "MenuItemTag";
      createdAt: string;
      id: string;
      menuItemID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxes?: {
    __typename: "ModelMenuItemTaxTypeConnection";
    items: Array<{
      __typename: "MenuItemTaxType";
      createdAt: string;
      id: string;
      menuItemID: string;
      taxType: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      };
      taxTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  utensils?: boolean | null;
  venueID?: string | null;
};

export type GetMenuItemPriceTypeQuery = {
  __typename: "MenuItemPriceType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type GetModifierGroupQuery = {
  __typename: "ModifierGroup";
  active?: boolean | null;
  conceptID?: string | null;
  createdAt: string;
  description?: string | null;
  displayName?: string | null;
  id: string;
  max?: number | null;
  menuItems?: {
    __typename: "ModelMenuItemModifierGroupConnection";
    items: Array<{
      __typename: "MenuItemModifierGroup";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  min?: number | null;
  modifiers?: {
    __typename: "ModelModifierGroupMenuItemConnection";
    items: Array<{
      __typename: "ModifierGroupMenuItem";
      createdAt: string;
      id: string;
      menuItem: {
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      };
      menuItemID: string;
      modifierGroup: {
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      modifierGroupID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  priceTypeID?: string | null;
  selectionType?: SelectionType | null;
  tags?: {
    __typename: "ModelModifierGroupTagConnection";
    items: Array<{
      __typename: "ModifierGroupTag";
      createdAt: string;
      id: string;
      modifierGroupID: string;
      tag: {
        __typename: "Tag";
        createdAt: string;
        id: string;
        imageS3Square?: string | null;
        name: string;
        tenantID: string;
        type: TagType;
        updatedAt: string;
      };
      tagID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueID?: string | null;
};

export type GetNotificationQuery = {
  __typename: "Notification";
  createdAt: string;
  emailBCC?: string | null;
  emailCC?: string | null;
  emailFrom?: string | null;
  emailReplyTo?: string | null;
  emailTemplate?: {
    __typename: "EmailTemplate";
    createdAt: string;
    html?: string | null;
    id: string;
    name: string;
    subject?: string | null;
    tenantID: string;
    text?: string | null;
    updatedAt: string;
  } | null;
  emailTemplateData?: string | null;
  emailTemplateID?: string | null;
  emailTo?: string | null;
  id: string;
  message?: string | null;
  name: string;
  smsFrom?: string | null;
  smsTo?: string | null;
  smsToDynamic?: string | null;
  smsToType?: PropertyType | null;
  tenantID: string;
  type: NotificationType;
  updatedAt: string;
};

export type GetOrderQuery = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  chits?: {
    __typename: "ModelChitConnection";
    items: Array<{
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    errorCode?: number | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    lastUpdate?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    message?: string | null;
    phone?: string | null;
    pickupTime?: string | null;
    status?: CourierStatus | null;
    type?: string | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID: string;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null;
  orderTypeID?: string | null;
  originAddress?: {
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip: string;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  venueStatus?: Array<{
    __typename: "VenueStatus";
    status: OrderStatus;
    venueID: string;
  } | null> | null;
};

export type GetOrderLinkQuery = {
  __typename: "OrderLink";
  active?: boolean | null;
  adminEmail?: string | null;
  ccCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  ccCredentialID?: string | null;
  clerkID?: number | null;
  createdAt: string;
  doNotSell?: string | null;
  domain?: string | null;
  exploreHeading?: string | null;
  exploreSubheading?: string | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  featuredRestarauntHeading?: string | null;
  featuredRestarauntSubheading?: string | null;
  footerURL?: string | null;
  heading?: string | null;
  id: string;
  imageS3EntryHeaders?: Array<string | null> | null;
  imageS3EntryHeadersMobile?: Array<string | null> | null;
  imageS3Footer?: string | null;
  isDefault?: boolean | null;
  leadTime?: number | null;
  location: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  };
  locationID: string;
  menuScollerHeading?: string | null;
  menuScollerSubheading?: string | null;
  menus?: {
    __typename: "ModelOrderLinkMenuConnection";
    items: Array<{
      __typename: "OrderLinkMenu";
      createdAt: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      orderLink: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      };
      orderLinkID: string;
      sortOrder?: number | null;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  notice?: string | null;
  noticeAtCollection?: string | null;
  notifications?: {
    __typename: "ModelOrderLinkNotificationConnection";
    items: Array<{
      __typename: "OrderLinkNotification";
      createdAt: string;
      event: NotificationEvent;
      id: string;
      notification: {
        __typename: "Notification";
        createdAt: string;
        emailBCC?: string | null;
        emailCC?: string | null;
        emailFrom?: string | null;
        emailReplyTo?: string | null;
        emailTemplateData?: string | null;
        emailTemplateID?: string | null;
        emailTo?: string | null;
        id: string;
        message?: string | null;
        name: string;
        smsFrom?: string | null;
        smsTo?: string | null;
        smsToDynamic?: string | null;
        smsToType?: PropertyType | null;
        tenantID: string;
        type: NotificationType;
        updatedAt: string;
      };
      notificationID: string;
      orderLinkID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderMode?: OrderMode | null;
  orderTypes?: {
    __typename: "ModelOrderLinkOrderTypeConnection";
    items: Array<{
      __typename: "OrderLinkOrderType";
      createdAt: string;
      id: string;
      orderLinkID: string;
      orderType: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      };
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  originEmail?: string | null;
  originLinkFacebook?: string | null;
  originLinkIG?: string | null;
  originName?: string | null;
  originPhone?: string | null;
  popularItemsHeading?: string | null;
  popularItemsSubheading?: string | null;
  popularRestarauntHeading?: string | null;
  popularRestarauntSubheading?: string | null;
  privacyPolicy?: string | null;
  schedules?: {
    __typename: "ModelOrderLinkScheduleConnection";
    items: Array<{
      __typename: "OrderLinkSchedule";
      createdAt: string;
      id: string;
      orderLinkID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  searchPlaceholder?: string | null;
  smsCredential?: {
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null;
  smsCredentialID?: string | null;
  subHeading?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tos?: string | null;
  updatedAt: string;
};

export type GetOrderTypeQuery = {
  __typename: "OrderType";
  active?: boolean | null;
  asap?: boolean | null;
  createdAt: string;
  deliveryProvider?: DeliveryProvider | null;
  displayName?: string | null;
  fees?: {
    __typename: "ModelOrderTypeFeeConnection";
    items: Array<{
      __typename: "OrderTypeFee";
      createdAt: string;
      fee: {
        __typename: "Fee";
        amount?: number | null;
        createdAt: string;
        id: string;
        inclusive?: boolean | null;
        name: string;
        rate?: number | null;
        taxed?: boolean | null;
        tenantID: string;
        type: FeeType;
        updatedAt: string;
      };
      feeID: string;
      id: string;
      orderTypeID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  name: string;
  schedule?: boolean | null;
  schedules?: {
    __typename: "ModelOrderTypeScheduleConnection";
    items: Array<{
      __typename: "OrderTypeSchedule";
      createdAt: string;
      id: string;
      orderTypeID: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  serviceType?: ServiceType | null;
  source?: string | null;
  tenantID: string;
  updatedAt: string;
};

export type GetPaymentMethodQuery = {
  __typename: "PaymentMethod";
  cardExpiration?: string | null;
  cardPresent?: boolean | null;
  cardType?: string | null;
  createdAt: string;
  name: string;
  processor?: CCProcessor | null;
  token: string;
  type: PaymentMethodType;
  updatedAt: string;
  zipcode?: string | null;
};

export type GetPrintJobQuery = {
  __typename: "PrintJob";
  createdAt: string;
  data: string;
  expirationUnixTime?: number | null;
  id: string;
  serialNumber: string;
  status?: PrintJobStatus | null;
  updatedAt: string;
};

export type GetPrinterQuery = {
  __typename: "Printer";
  createdAt: string;
  serialNumber: string;
  status?: PrinterStatus | null;
  updatedAt: string;
};

export type GetReceiptConfigQuery = {
  __typename: "ReceiptConfig";
  createdAt: string;
  headerSize?: number | null;
  id: string;
  itemSize?: number | null;
  name: string;
  showDiscounts?: boolean | null;
  showFees?: boolean | null;
  showPayments?: boolean | null;
  showPrice?: boolean | null;
  showQR?: boolean | null;
  showRemaining?: boolean | null;
  showSubtotal?: boolean | null;
  showTaxes?: boolean | null;
  showTips?: boolean | null;
  showTransactions?: boolean | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueSize?: number | null;
};

export type GetSMSMessageQuery = {
  __typename: "SMSMessage";
  createdAt: string;
  from: string;
  id: string;
  message: string;
  orderID?: string | null;
  tenantID: string;
  to: string;
  updatedAt: string;
};

export type GetScheduleQuery = {
  __typename: "Schedule";
  createdAt: string;
  endDate?: string | null;
  id: string;
  name: string;
  startDate?: string | null;
  tenantID: string;
  timeRanges?: Array<{
    __typename: "TimeRange";
    day: number;
    endTime: string;
    startTime: string;
  } | null> | null;
  type: ScheduleType;
  updatedAt: string;
};

export type GetTagQuery = {
  __typename: "Tag";
  createdAt: string;
  id: string;
  imageS3Square?: string | null;
  name: string;
  tenantID: string;
  type: TagType;
  updatedAt: string;
};

export type GetTaxRateQuery = {
  __typename: "TaxRate";
  amount?: number | null;
  createdAt: string;
  id: string;
  rate?: number | null;
  taxType?: {
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  } | null;
  taxTypeID: string;
  type: TaxRateType;
  updatedAt: string;
  venueID: string;
};

export type GetTaxTypeQuery = {
  __typename: "TaxType";
  createdAt: string;
  id: string;
  name: string;
  tenantID: string;
  type: TaxTypeType;
  updatedAt: string;
};

export type GetTenantQuery = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserQuery = {
  __typename: "User";
  createdAt: string;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  updatedAt: string;
  userName: string;
  userRoles?: {
    __typename: "ModelUserRoleConnection";
    items: Array<{
      __typename: "UserRole";
      createdAt: string;
      entityType: EntityType;
      id: string;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID?: string | null;
      type: {
        __typename: "UserRoleType";
        createdAt: string;
        id: string;
        name: string;
        permissions?: Array<UserRolePermission | null> | null;
        tenantID?: string | null;
        updatedAt: string;
        venueID?: string | null;
      };
      typeID: string;
      updatedAt: string;
      user: {
        __typename: "User";
        createdAt: string;
        email: string;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName: string;
      };
      userID: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetUserRoleQuery = {
  __typename: "UserRole";
  createdAt: string;
  entityType: EntityType;
  id: string;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID?: string | null;
  type: {
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  };
  typeID: string;
  updatedAt: string;
  user: {
    __typename: "User";
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    phone?: string | null;
    updatedAt: string;
    userName: string;
    userRoles?: {
      __typename: "ModelUserRoleConnection";
      items: Array<{
        __typename: "UserRole";
        createdAt: string;
        entityType: EntityType;
        id: string;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        userID: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  userID: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID?: string | null;
};

export type GetUserRoleTypeQuery = {
  __typename: "UserRoleType";
  createdAt: string;
  id: string;
  name: string;
  permissions?: Array<UserRolePermission | null> | null;
  tenantID?: string | null;
  updatedAt: string;
  venueID?: string | null;
};

export type GetVenueQuery = {
  __typename: "Venue";
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID?: string | null;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  schedules?: {
    __typename: "ModelVenueScheduleConnection";
    items: Array<{
      __typename: "VenueSchedule";
      createdAt: string;
      id: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxRates?: {
    __typename: "ModelTaxRateConnection";
    items: Array<{
      __typename: "TaxRate";
      amount?: number | null;
      createdAt: string;
      id: string;
      rate?: number | null;
      taxType?: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      } | null;
      taxTypeID: string;
      type: TaxRateType;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetVenueOrderQuery = {
  __typename: "VenueOrder";
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  status?: OrderStatus | null;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
  venueName?: string | null;
};

export type ListCheckItemsQuery = {
  __typename: "ModelCheckItemConnection";
  items: Array<{
    __typename: "CheckItem";
    alcohol?: boolean | null;
    checkID: string;
    chit?: {
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null;
    chitID?: string | null;
    createdAt: string;
    id: string;
    instructions?: string | null;
    menuID?: string | null;
    menuItemID?: string | null;
    modifiers?: Array<{
      __typename: "CheckItemModifier";
      id: string;
      menuItemID?: string | null;
      modifierGroupID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
    } | null> | null;
    name: string;
    price: number;
    priceTypeID?: string | null;
    quantity: number;
    tags?: Array<{
      __typename: "CheckItemTag";
      id: string;
      name: string;
      type: TagType;
    } | null> | null;
    taxes?: Array<{
      __typename: "CheckItemTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    unitPrice?: number | null;
    updatedAt: string;
    utensils?: boolean | null;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCheckTransactionsQuery = {
  __typename: "ModelCheckTransactionConnection";
  items: Array<{
    __typename: "CheckTransaction";
    amount: number;
    amountFees?: number | null;
    amountItems?: number | null;
    amountTax?: number | null;
    amountTip?: number | null;
    authCode?: string | null;
    cardNumber?: string | null;
    cardType?: string | null;
    check?: {
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null;
    checkID: string;
    checkItemIDs?: Array<string | null> | null;
    comp?: boolean | null;
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    invoice?: number | null;
    last4?: number | null;
    note?: string | null;
    paymentID?: string | null;
    paymentMethod?: {
      __typename: "PaymentMethod";
      cardExpiration?: string | null;
      cardPresent?: boolean | null;
      cardType?: string | null;
      createdAt: string;
      name: string;
      processor?: CCProcessor | null;
      token: string;
      type: PaymentMethodType;
      updatedAt: string;
      zipcode?: string | null;
    } | null;
    paymentMethodID?: string | null;
    paymentMethodType?: PaymentMethodType | null;
    processor?: CCProcessor | null;
    processorCredentialID?: string | null;
    reason?: CheckTransactionReason | null;
    refNo?: string | null;
    refunds?: Array<{
      __typename: "CheckRefund";
      amount: number;
      checkItemID?: string | null;
      type: CheckRefundType;
    } | null> | null;
    status: CheckTransactionStatus;
    thirdPartyData?: string | null;
    token?: string | null;
    type: CCTransactionType;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
  } | null>;
  nextToken?: string | null;
};

export type ListCheckTransactionsByVenueIdByTypeBydateQuery = {
  __typename: "ModelCheckTransactionConnection";
  items: Array<{
    __typename: "CheckTransaction";
    amount: number;
    amountFees?: number | null;
    amountItems?: number | null;
    amountTax?: number | null;
    amountTip?: number | null;
    authCode?: string | null;
    cardNumber?: string | null;
    cardType?: string | null;
    check?: {
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null;
    checkID: string;
    checkItemIDs?: Array<string | null> | null;
    comp?: boolean | null;
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    invoice?: number | null;
    last4?: number | null;
    note?: string | null;
    paymentID?: string | null;
    paymentMethod?: {
      __typename: "PaymentMethod";
      cardExpiration?: string | null;
      cardPresent?: boolean | null;
      cardType?: string | null;
      createdAt: string;
      name: string;
      processor?: CCProcessor | null;
      token: string;
      type: PaymentMethodType;
      updatedAt: string;
      zipcode?: string | null;
    } | null;
    paymentMethodID?: string | null;
    paymentMethodType?: PaymentMethodType | null;
    processor?: CCProcessor | null;
    processorCredentialID?: string | null;
    reason?: CheckTransactionReason | null;
    refNo?: string | null;
    refunds?: Array<{
      __typename: "CheckRefund";
      amount: number;
      checkItemID?: string | null;
      type: CheckRefundType;
    } | null> | null;
    status: CheckTransactionStatus;
    thirdPartyData?: string | null;
    token?: string | null;
    type: CCTransactionType;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
  } | null>;
  nextToken?: string | null;
};

export type ListChecksQuery = {
  __typename: "ModelCheckConnection";
  items: Array<{
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListChecksByCustomerQuery = {
  __typename: "ModelCheckConnection";
  items: Array<{
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListChecksByTenantIdQuery = {
  __typename: "ModelCheckConnection";
  items: Array<{
    __typename: "Check";
    createdAt: string;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    diagnostics?: string | null;
    fees?: Array<{
      __typename: "CheckFee";
      amount: number;
      id: string;
      inclusive?: boolean | null;
      name: string;
      taxed?: boolean | null;
      type: FeeType;
    } | null> | null;
    id: string;
    items?: {
      __typename: "ModelCheckItemConnection";
      items: Array<{
        __typename: "CheckItem";
        alcohol?: boolean | null;
        checkID: string;
        chitID?: string | null;
        createdAt: string;
        id: string;
        instructions?: string | null;
        menuID?: string | null;
        menuItemID?: string | null;
        name: string;
        price: number;
        priceTypeID?: string | null;
        quantity: number;
        unitPrice?: number | null;
        updatedAt: string;
        utensils?: boolean | null;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    payments?: {
      __typename: "ModelCheckPaymentConnection";
      items: Array<{
        __typename: "CheckPayment";
        amount: number;
        cardPresent?: boolean | null;
        checkID: string;
        createdAt: string;
        displayName?: string | null;
        id: string;
        invoice?: number | null;
        isAuthed?: boolean | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        refNo?: string | null;
        status?: CheckPaymentStatus | null;
        tip?: number | null;
        token?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    readyEstimate?: number | null;
    taxes?: Array<{
      __typename: "CheckTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    tenant: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    };
    tenantID: string;
    totals?: {
      __typename: "CheckTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      tipTotal?: number | null;
      total?: number | null;
    } | null;
    transactions?: {
      __typename: "ModelCheckTransactionConnection";
      items: Array<{
        __typename: "CheckTransaction";
        amount: number;
        amountFees?: number | null;
        amountItems?: number | null;
        amountTax?: number | null;
        amountTip?: number | null;
        authCode?: string | null;
        cardNumber?: string | null;
        cardType?: string | null;
        checkID: string;
        checkItemIDs?: Array<string | null> | null;
        comp?: boolean | null;
        createdAt?: string | null;
        id: string;
        invoice?: number | null;
        last4?: number | null;
        note?: string | null;
        paymentID?: string | null;
        paymentMethodID?: string | null;
        paymentMethodType?: PaymentMethodType | null;
        processor?: CCProcessor | null;
        processorCredentialID?: string | null;
        reason?: CheckTransactionReason | null;
        refNo?: string | null;
        status: CheckTransactionStatus;
        thirdPartyData?: string | null;
        token?: string | null;
        type: CCTransactionType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    updatedAt: string;
    venueTotals?: Array<{
      __typename: "VenueTotals";
      ccCaptureTotal?: number | null;
      ccRefundTotal?: number | null;
      ccVoidTotal?: number | null;
      checkPercent?: number | null;
      discountTotal?: number | null;
      feeTotal?: number | null;
      feeTotalInclusive?: number | null;
      fees?: Array<{
        __typename: "VenueTotalCheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      feesAndTaxTotal?: number | null;
      paymentTotal?: number | null;
      remainingBalance?: number | null;
      subTotal?: number | null;
      taxTotal?: number | null;
      taxes?: Array<{
        __typename: "VenueTotalCheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tipTotal?: number | null;
      total?: number | null;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListChitsQuery = {
  __typename: "ModelChitConnection";
  items: Array<{
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListChitsByVenueIdByStatusByDateQuery = {
  __typename: "ModelChitConnection";
  items: Array<{
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListConceptsQuery = {
  __typename: "ModelConceptConnection";
  items: Array<{
    __typename: "Concept";
    createdAt: string;
    id: string;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListConceptsByTenantIdQuery = {
  __typename: "ModelConceptConnection";
  items: Array<{
    __typename: "Concept";
    createdAt: string;
    id: string;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListCredentialsQuery = {
  __typename: "ModelCredentialConnection";
  items: Array<{
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCredentialsByTenantIdQuery = {
  __typename: "ModelCredentialConnection";
  items: Array<{
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCredentialsByVenueIdQuery = {
  __typename: "ModelCredentialConnection";
  items: Array<{
    __typename: "Credential";
    createdAt: string;
    dcEcomMID?: string | null;
    dcMID?: string | null;
    dcTokenKey?: string | null;
    id: string;
    key: string;
    secret: string;
    smsFrom?: string | null;
    tenantID?: string | null;
    type: CredentialType;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCustomersQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCustomersByEmailQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCustomersByPhoneQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListCustomersByUserNameQuery = {
  __typename: "ModelCustomerConnection";
  items: Array<{
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListDeviceTransactionsQuery = {
  __typename: "ModelDeviceTransactionConnection";
  items: Array<{
    __typename: "DeviceTransaction";
    createdAt: string;
    data?: string | null;
    deviceID: string;
    event?: string | null;
    expirationUnixTime?: number | null;
    id: string;
    status?: DeviceTransactionStatus | null;
    transactionID: string;
    type: DeviceTransactionType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListDevicesQuery = {
  __typename: "ModelDeviceConnection";
  items: Array<{
    __typename: "Device";
    active?: boolean | null;
    autoPrint?: boolean | null;
    createdAt: string;
    deviceID?: string | null;
    displayName?: string | null;
    host?: string | null;
    id: string;
    ipAddress?: string | null;
    licenseID: string;
    name?: string | null;
    port?: number | null;
    printOnBump?: boolean | null;
    serialNumber?: string | null;
    type: DeviceType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListDevicesByDeviceIdQuery = {
  __typename: "ModelDeviceConnection";
  items: Array<{
    __typename: "Device";
    active?: boolean | null;
    autoPrint?: boolean | null;
    createdAt: string;
    deviceID?: string | null;
    displayName?: string | null;
    host?: string | null;
    id: string;
    ipAddress?: string | null;
    licenseID: string;
    name?: string | null;
    port?: number | null;
    printOnBump?: boolean | null;
    serialNumber?: string | null;
    type: DeviceType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListDomainsQuery = {
  __typename: "ModelDomainConnection";
  items: Array<{
    __typename: "Domain";
    createdAt: string;
    host: string;
    id: string;
    imageS3Logo?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListDomainsByHostQuery = {
  __typename: "ModelDomainConnection";
  items: Array<{
    __typename: "Domain";
    createdAt: string;
    host: string;
    id: string;
    imageS3Logo?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListEmailTemplatesQuery = {
  __typename: "ModelEmailTemplateConnection";
  items: Array<{
    __typename: "EmailTemplate";
    createdAt: string;
    html?: string | null;
    id: string;
    name: string;
    subject?: string | null;
    tenantID: string;
    text?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListEmailTemplatesByTenantIdQuery = {
  __typename: "ModelEmailTemplateConnection";
  items: Array<{
    __typename: "EmailTemplate";
    createdAt: string;
    html?: string | null;
    id: string;
    name: string;
    subject?: string | null;
    tenantID: string;
    text?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListEmployeesQuery = {
  __typename: "ModelEmployeeConnection";
  items: Array<{
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListEmployeesByTenantIdQuery = {
  __typename: "ModelEmployeeConnection";
  items: Array<{
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListEmployeesByTenantIdByCodeQuery = {
  __typename: "ModelEmployeeConnection";
  items: Array<{
    __typename: "Employee";
    active?: boolean | null;
    code?: string | null;
    createdAt: string;
    email?: string | null;
    firstName: string;
    id: string;
    jobs?: {
      __typename: "ModelJobConnection";
      items: Array<{
        __typename: "Job";
        createdAt: string;
        employeeID: string;
        entityType: EntityType;
        id: string;
        payRate?: number | null;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    lastName: string;
    phone?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListFeesQuery = {
  __typename: "ModelFeeConnection";
  items: Array<{
    __typename: "Fee";
    amount?: number | null;
    createdAt: string;
    id: string;
    inclusive?: boolean | null;
    name: string;
    rate?: number | null;
    taxed?: boolean | null;
    tenantID: string;
    type: FeeType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListFeesByTenantIdQuery = {
  __typename: "ModelFeeConnection";
  items: Array<{
    __typename: "Fee";
    amount?: number | null;
    createdAt: string;
    id: string;
    inclusive?: boolean | null;
    name: string;
    rate?: number | null;
    taxed?: boolean | null;
    tenantID: string;
    type: FeeType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListJobTypesQuery = {
  __typename: "ModelJobTypeConnection";
  items: Array<{
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListJobTypesByTenantIdQuery = {
  __typename: "ModelJobTypeConnection";
  items: Array<{
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListJobTypesByVenueIdQuery = {
  __typename: "ModelJobTypeConnection";
  items: Array<{
    __typename: "JobType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<JobPermission | null> | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListJobsQuery = {
  __typename: "ModelJobConnection";
  items: Array<{
    __typename: "Job";
    createdAt: string;
    employee: {
      __typename: "Employee";
      active?: boolean | null;
      code?: string | null;
      createdAt: string;
      email?: string | null;
      firstName: string;
      id: string;
      jobs?: {
        __typename: "ModelJobConnection";
        nextToken?: string | null;
      } | null;
      lastName: string;
      phone?: string | null;
      tenantID: string;
      updatedAt: string;
    };
    employeeID: string;
    entityType: EntityType;
    id: string;
    payRate?: number | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID?: string | null;
    type: {
      __typename: "JobType";
      createdAt: string;
      id: string;
      name: string;
      permissions?: Array<JobPermission | null> | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    };
    typeID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLicensesQuery = {
  __typename: "ModelLicenseConnection";
  items: Array<{
    __typename: "License";
    active?: boolean | null;
    createdAt: string;
    deviceDetails?: {
      __typename: "DeviceDetails";
      deviceID?: string | null;
      displayName?: string | null;
      heartbeat?: string | null;
      initTime?: string | null;
      name?: string | null;
      online?: boolean | null;
      version?: string | null;
    } | null;
    devices?: {
      __typename: "ModelDeviceConnection";
      items: Array<{
        __typename: "Device";
        active?: boolean | null;
        autoPrint?: boolean | null;
        createdAt: string;
        deviceID?: string | null;
        displayName?: string | null;
        host?: string | null;
        id: string;
        ipAddress?: string | null;
        licenseID: string;
        name?: string | null;
        port?: number | null;
        printOnBump?: boolean | null;
        serialNumber?: string | null;
        type: DeviceType;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    displayName?: string | null;
    guid?: string | null;
    id: string;
    kioskConfig?: {
      __typename: "KioskConfig";
      orderLinkID?: string | null;
      showSettings?: boolean | null;
    } | null;
    kitchenConfig?: {
      __typename: "KitchenConfig";
      showSettings?: boolean | null;
    } | null;
    name?: string | null;
    pollingInterval?: number | null;
    receiptConfig?: {
      __typename: "ReceiptConfig";
      createdAt: string;
      headerSize?: number | null;
      id: string;
      itemSize?: number | null;
      name: string;
      showDiscounts?: boolean | null;
      showFees?: boolean | null;
      showPayments?: boolean | null;
      showPrice?: boolean | null;
      showQR?: boolean | null;
      showRemaining?: boolean | null;
      showSubtotal?: boolean | null;
      showTaxes?: boolean | null;
      showTips?: boolean | null;
      showTransactions?: boolean | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueSize?: number | null;
    } | null;
    receiptConfigID?: string | null;
    status?: LicenseStatus | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    type: DeviceType;
    updatedAt: string;
    venues?: {
      __typename: "ModelLicenseVenueConnection";
      items: Array<{
        __typename: "LicenseVenue";
        createdAt: string;
        id: string;
        licenseID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLicensesByGuidQuery = {
  __typename: "ModelLicenseConnection";
  items: Array<{
    __typename: "License";
    active?: boolean | null;
    createdAt: string;
    deviceDetails?: {
      __typename: "DeviceDetails";
      deviceID?: string | null;
      displayName?: string | null;
      heartbeat?: string | null;
      initTime?: string | null;
      name?: string | null;
      online?: boolean | null;
      version?: string | null;
    } | null;
    devices?: {
      __typename: "ModelDeviceConnection";
      items: Array<{
        __typename: "Device";
        active?: boolean | null;
        autoPrint?: boolean | null;
        createdAt: string;
        deviceID?: string | null;
        displayName?: string | null;
        host?: string | null;
        id: string;
        ipAddress?: string | null;
        licenseID: string;
        name?: string | null;
        port?: number | null;
        printOnBump?: boolean | null;
        serialNumber?: string | null;
        type: DeviceType;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    displayName?: string | null;
    guid?: string | null;
    id: string;
    kioskConfig?: {
      __typename: "KioskConfig";
      orderLinkID?: string | null;
      showSettings?: boolean | null;
    } | null;
    kitchenConfig?: {
      __typename: "KitchenConfig";
      showSettings?: boolean | null;
    } | null;
    name?: string | null;
    pollingInterval?: number | null;
    receiptConfig?: {
      __typename: "ReceiptConfig";
      createdAt: string;
      headerSize?: number | null;
      id: string;
      itemSize?: number | null;
      name: string;
      showDiscounts?: boolean | null;
      showFees?: boolean | null;
      showPayments?: boolean | null;
      showPrice?: boolean | null;
      showQR?: boolean | null;
      showRemaining?: boolean | null;
      showSubtotal?: boolean | null;
      showTaxes?: boolean | null;
      showTips?: boolean | null;
      showTransactions?: boolean | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueSize?: number | null;
    } | null;
    receiptConfigID?: string | null;
    status?: LicenseStatus | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    type: DeviceType;
    updatedAt: string;
    venues?: {
      __typename: "ModelLicenseVenueConnection";
      items: Array<{
        __typename: "LicenseVenue";
        createdAt: string;
        id: string;
        licenseID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLicensesByTenantIdQuery = {
  __typename: "ModelLicenseConnection";
  items: Array<{
    __typename: "License";
    active?: boolean | null;
    createdAt: string;
    deviceDetails?: {
      __typename: "DeviceDetails";
      deviceID?: string | null;
      displayName?: string | null;
      heartbeat?: string | null;
      initTime?: string | null;
      name?: string | null;
      online?: boolean | null;
      version?: string | null;
    } | null;
    devices?: {
      __typename: "ModelDeviceConnection";
      items: Array<{
        __typename: "Device";
        active?: boolean | null;
        autoPrint?: boolean | null;
        createdAt: string;
        deviceID?: string | null;
        displayName?: string | null;
        host?: string | null;
        id: string;
        ipAddress?: string | null;
        licenseID: string;
        name?: string | null;
        port?: number | null;
        printOnBump?: boolean | null;
        serialNumber?: string | null;
        type: DeviceType;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    displayName?: string | null;
    guid?: string | null;
    id: string;
    kioskConfig?: {
      __typename: "KioskConfig";
      orderLinkID?: string | null;
      showSettings?: boolean | null;
    } | null;
    kitchenConfig?: {
      __typename: "KitchenConfig";
      showSettings?: boolean | null;
    } | null;
    name?: string | null;
    pollingInterval?: number | null;
    receiptConfig?: {
      __typename: "ReceiptConfig";
      createdAt: string;
      headerSize?: number | null;
      id: string;
      itemSize?: number | null;
      name: string;
      showDiscounts?: boolean | null;
      showFees?: boolean | null;
      showPayments?: boolean | null;
      showPrice?: boolean | null;
      showQR?: boolean | null;
      showRemaining?: boolean | null;
      showSubtotal?: boolean | null;
      showTaxes?: boolean | null;
      showTips?: boolean | null;
      showTransactions?: boolean | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueSize?: number | null;
    } | null;
    receiptConfigID?: string | null;
    status?: LicenseStatus | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    type: DeviceType;
    updatedAt: string;
    venues?: {
      __typename: "ModelLicenseVenueConnection";
      items: Array<{
        __typename: "LicenseVenue";
        createdAt: string;
        id: string;
        licenseID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLocationsQuery = {
  __typename: "ModelLocationConnection";
  items: Array<{
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null>;
  nextToken?: string | null;
};

export type ListLocationsByEndOfDayQuery = {
  __typename: "ModelLocationConnection";
  items: Array<{
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null>;
  nextToken?: string | null;
};

export type ListLocationsByTenantIdQuery = {
  __typename: "ModelLocationConnection";
  items: Array<{
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null>;
  nextToken?: string | null;
};

export type ListLogsQuery = {
  __typename: "ModelLogConnection";
  items: Array<{
    __typename: "Log";
    checkID?: string | null;
    createdAt: string;
    employee?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    employeeID?: string | null;
    event?: LogEvent | null;
    id: string;
    message?: string | null;
    orderID?: string | null;
    source?: LogSource | null;
    tenantID?: string | null;
    type: LogType;
    updatedAt: string;
    user?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    userID?: string | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLogsByCheckIdQuery = {
  __typename: "ModelLogConnection";
  items: Array<{
    __typename: "Log";
    checkID?: string | null;
    createdAt: string;
    employee?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    employeeID?: string | null;
    event?: LogEvent | null;
    id: string;
    message?: string | null;
    orderID?: string | null;
    source?: LogSource | null;
    tenantID?: string | null;
    type: LogType;
    updatedAt: string;
    user?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    userID?: string | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLogsByOrderIdQuery = {
  __typename: "ModelLogConnection";
  items: Array<{
    __typename: "Log";
    checkID?: string | null;
    createdAt: string;
    employee?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    employeeID?: string | null;
    event?: LogEvent | null;
    id: string;
    message?: string | null;
    orderID?: string | null;
    source?: LogSource | null;
    tenantID?: string | null;
    type: LogType;
    updatedAt: string;
    user?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    userID?: string | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLogsByTenantIdQuery = {
  __typename: "ModelLogConnection";
  items: Array<{
    __typename: "Log";
    checkID?: string | null;
    createdAt: string;
    employee?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    employeeID?: string | null;
    event?: LogEvent | null;
    id: string;
    message?: string | null;
    orderID?: string | null;
    source?: LogSource | null;
    tenantID?: string | null;
    type: LogType;
    updatedAt: string;
    user?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    userID?: string | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListLogsByVenueIdQuery = {
  __typename: "ModelLogConnection";
  items: Array<{
    __typename: "Log";
    checkID?: string | null;
    createdAt: string;
    employee?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    employeeID?: string | null;
    event?: LogEvent | null;
    id: string;
    message?: string | null;
    orderID?: string | null;
    source?: LogSource | null;
    tenantID?: string | null;
    type: LogType;
    updatedAt: string;
    user?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    userID?: string | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuCategorysQuery = {
  __typename: "ModelMenuCategoryConnection";
  items: Array<{
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuCategorysByConceptIdQuery = {
  __typename: "ModelMenuCategoryConnection";
  items: Array<{
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuCategorysByTenantIdQuery = {
  __typename: "ModelMenuCategoryConnection";
  items: Array<{
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuCategorysByVenueIdQuery = {
  __typename: "ModelMenuCategoryConnection";
  items: Array<{
    __typename: "MenuCategory";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    menuItems?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    tags?: {
      __typename: "ModelMenuCategoryTagConnection";
      items: Array<{
        __typename: "MenuCategoryTag";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuItemPriceTypesQuery = {
  __typename: "ModelMenuItemPriceTypeConnection";
  items: Array<{
    __typename: "MenuItemPriceType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuItemPriceTypesByTenantIdQuery = {
  __typename: "ModelMenuItemPriceTypeConnection";
  items: Array<{
    __typename: "MenuItemPriceType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuItemsQuery = {
  __typename: "ModelMenuItemConnection";
  items: Array<{
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuItemsByConceptIdQuery = {
  __typename: "ModelMenuItemConnection";
  items: Array<{
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuItemsByTenantIdQuery = {
  __typename: "ModelMenuItemConnection";
  items: Array<{
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenuItemsByVenueIdQuery = {
  __typename: "ModelMenuItemConnection";
  items: Array<{
    __typename: "MenuItem";
    active?: boolean | null;
    alcohol?: boolean | null;
    categories?: {
      __typename: "ModelMenuCategoryMenuItemConnection";
      items: Array<{
        __typename: "MenuCategoryMenuItem";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuItemID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    id: string;
    imageS3?: string | null;
    modifierGroups?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    options?: {
      __typename: "ModelMenuItemOptionConnection";
      items: Array<{
        __typename: "MenuItemOption";
        createdAt: string;
        id: string;
        menuItemID: string;
        name: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    parentModifierGroups?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    prepTime?: number | null;
    prices?: {
      __typename: "ModelMenuItemPriceConnection";
      items: Array<{
        __typename: "MenuItemPrice";
        active?: boolean | null;
        createdAt: string;
        id: string;
        menuItemID?: string | null;
        menuItemOptionID?: string | null;
        price: number;
        priceTypeID?: string | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuItemTagConnection";
      items: Array<{
        __typename: "MenuItemTag";
        createdAt: string;
        id: string;
        menuItemID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxes?: {
      __typename: "ModelMenuItemTaxTypeConnection";
      items: Array<{
        __typename: "MenuItemTaxType";
        createdAt: string;
        id: string;
        menuItemID: string;
        taxTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    utensils?: boolean | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenusQuery = {
  __typename: "ModelMenuConnection";
  items: Array<{
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenusByConceptIdQuery = {
  __typename: "ModelMenuConnection";
  items: Array<{
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenusByTenantIdQuery = {
  __typename: "ModelMenuConnection";
  items: Array<{
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListMenusByVenueIdQuery = {
  __typename: "ModelMenuConnection";
  items: Array<{
    __typename: "Menu";
    active?: boolean | null;
    categories?: {
      __typename: "ModelMenuMenuCategoryConnection";
      items: Array<{
        __typename: "MenuMenuCategory";
        createdAt: string;
        id: string;
        menuCategoryID: string;
        menuID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    facilitators?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    featured?: boolean | null;
    id: string;
    imageS3Featured?: Array<string | null> | null;
    imageS3Headers?: Array<string | null> | null;
    imageS3HeadersMobile?: Array<string | null> | null;
    imageS3LogoSquare?: string | null;
    name: string;
    orderLinks?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    popular?: boolean | null;
    schedules?: {
      __typename: "ModelMenuScheduleConnection";
      items: Array<{
        __typename: "MenuSchedule";
        createdAt: string;
        id: string;
        menuID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tags?: {
      __typename: "ModelMenuTagConnection";
      items: Array<{
        __typename: "MenuTag";
        createdAt: string;
        id: string;
        menuID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListModifierGroupsQuery = {
  __typename: "ModelModifierGroupConnection";
  items: Array<{
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListModifierGroupsByConceptIdQuery = {
  __typename: "ModelModifierGroupConnection";
  items: Array<{
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListModifierGroupsByTenantIdQuery = {
  __typename: "ModelModifierGroupConnection";
  items: Array<{
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListModifierGroupsByVenueIdQuery = {
  __typename: "ModelModifierGroupConnection";
  items: Array<{
    __typename: "ModifierGroup";
    active?: boolean | null;
    conceptID?: string | null;
    createdAt: string;
    description?: string | null;
    displayName?: string | null;
    id: string;
    max?: number | null;
    menuItems?: {
      __typename: "ModelMenuItemModifierGroupConnection";
      items: Array<{
        __typename: "MenuItemModifierGroup";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    min?: number | null;
    modifiers?: {
      __typename: "ModelModifierGroupMenuItemConnection";
      items: Array<{
        __typename: "ModifierGroupMenuItem";
        createdAt: string;
        id: string;
        menuItemID: string;
        modifierGroupID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    priceTypeID?: string | null;
    selectionType?: SelectionType | null;
    tags?: {
      __typename: "ModelModifierGroupTagConnection";
      items: Array<{
        __typename: "ModifierGroupTag";
        createdAt: string;
        id: string;
        modifierGroupID: string;
        tagID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListNotificationsQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    createdAt: string;
    emailBCC?: string | null;
    emailCC?: string | null;
    emailFrom?: string | null;
    emailReplyTo?: string | null;
    emailTemplate?: {
      __typename: "EmailTemplate";
      createdAt: string;
      html?: string | null;
      id: string;
      name: string;
      subject?: string | null;
      tenantID: string;
      text?: string | null;
      updatedAt: string;
    } | null;
    emailTemplateData?: string | null;
    emailTemplateID?: string | null;
    emailTo?: string | null;
    id: string;
    message?: string | null;
    name: string;
    smsFrom?: string | null;
    smsTo?: string | null;
    smsToDynamic?: string | null;
    smsToType?: PropertyType | null;
    tenantID: string;
    type: NotificationType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListNotificationsByTenantIdQuery = {
  __typename: "ModelNotificationConnection";
  items: Array<{
    __typename: "Notification";
    createdAt: string;
    emailBCC?: string | null;
    emailCC?: string | null;
    emailFrom?: string | null;
    emailReplyTo?: string | null;
    emailTemplate?: {
      __typename: "EmailTemplate";
      createdAt: string;
      html?: string | null;
      id: string;
      name: string;
      subject?: string | null;
      tenantID: string;
      text?: string | null;
      updatedAt: string;
    } | null;
    emailTemplateData?: string | null;
    emailTemplateID?: string | null;
    emailTo?: string | null;
    id: string;
    message?: string | null;
    name: string;
    smsFrom?: string | null;
    smsTo?: string | null;
    smsToDynamic?: string | null;
    smsToType?: PropertyType | null;
    tenantID: string;
    type: NotificationType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListOrderLinksQuery = {
  __typename: "ModelOrderLinkConnection";
  items: Array<{
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListOrderLinksByDomainQuery = {
  __typename: "ModelOrderLinkConnection";
  items: Array<{
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListOrderLinksByTenantIdQuery = {
  __typename: "ModelOrderLinkConnection";
  items: Array<{
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListOrderTypesQuery = {
  __typename: "ModelOrderTypeConnection";
  items: Array<{
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListOrderTypesByTenantIdQuery = {
  __typename: "ModelOrderTypeConnection";
  items: Array<{
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListOrdersQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListOrdersByLocationIdByServiceTypeByStatusQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListOrdersByOrderNumberQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListOrdersByStatusByQueueTimeQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListOrdersByTenantIdQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListOrdersByTenantIdByStatusByDueTimeQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null>;
  nextToken?: string | null;
};

export type ListPaymentMethodsQuery = {
  __typename: "ModelPaymentMethodConnection";
  items: Array<{
    __typename: "PaymentMethod";
    cardExpiration?: string | null;
    cardPresent?: boolean | null;
    cardType?: string | null;
    createdAt: string;
    name: string;
    processor?: CCProcessor | null;
    token: string;
    type: PaymentMethodType;
    updatedAt: string;
    zipcode?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListPrintJobsQuery = {
  __typename: "ModelPrintJobConnection";
  items: Array<{
    __typename: "PrintJob";
    createdAt: string;
    data: string;
    expirationUnixTime?: number | null;
    id: string;
    serialNumber: string;
    status?: PrintJobStatus | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListPrintJobsBySerialNumberByStatusQuery = {
  __typename: "ModelPrintJobConnection";
  items: Array<{
    __typename: "PrintJob";
    createdAt: string;
    data: string;
    expirationUnixTime?: number | null;
    id: string;
    serialNumber: string;
    status?: PrintJobStatus | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListPrintersQuery = {
  __typename: "ModelPrinterConnection";
  items: Array<{
    __typename: "Printer";
    createdAt: string;
    serialNumber: string;
    status?: PrinterStatus | null;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListReceiptConfigsQuery = {
  __typename: "ModelReceiptConfigConnection";
  items: Array<{
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null>;
  nextToken?: string | null;
};

export type ListReceiptConfigsByTenantIdQuery = {
  __typename: "ModelReceiptConfigConnection";
  items: Array<{
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null>;
  nextToken?: string | null;
};

export type ListSMSMessagesQuery = {
  __typename: "ModelSMSMessageConnection";
  items: Array<{
    __typename: "SMSMessage";
    createdAt: string;
    from: string;
    id: string;
    message: string;
    orderID?: string | null;
    tenantID: string;
    to: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSchedulesByTenantIdQuery = {
  __typename: "ModelScheduleConnection";
  items: Array<{
    __typename: "Schedule";
    createdAt: string;
    endDate?: string | null;
    id: string;
    name: string;
    startDate?: string | null;
    tenantID: string;
    timeRanges?: Array<{
      __typename: "TimeRange";
      day: number;
      endTime: string;
      startTime: string;
    } | null> | null;
    type: ScheduleType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListSmsByTenantIdQuery = {
  __typename: "ModelSMSMessageConnection";
  items: Array<{
    __typename: "SMSMessage";
    createdAt: string;
    from: string;
    id: string;
    message: string;
    orderID?: string | null;
    tenantID: string;
    to: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTagsQuery = {
  __typename: "ModelTagConnection";
  items: Array<{
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTagsByTenantIdQuery = {
  __typename: "ModelTagConnection";
  items: Array<{
    __typename: "Tag";
    createdAt: string;
    id: string;
    imageS3Square?: string | null;
    name: string;
    tenantID: string;
    type: TagType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTaxRatesQuery = {
  __typename: "ModelTaxRateConnection";
  items: Array<{
    __typename: "TaxRate";
    amount?: number | null;
    createdAt: string;
    id: string;
    rate?: number | null;
    taxType?: {
      __typename: "TaxType";
      createdAt: string;
      id: string;
      name: string;
      tenantID: string;
      type: TaxTypeType;
      updatedAt: string;
    } | null;
    taxTypeID: string;
    type: TaxRateType;
    updatedAt: string;
    venueID: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTaxRatesByVenueIdQuery = {
  __typename: "ModelTaxRateConnection";
  items: Array<{
    __typename: "TaxRate";
    amount?: number | null;
    createdAt: string;
    id: string;
    rate?: number | null;
    taxType?: {
      __typename: "TaxType";
      createdAt: string;
      id: string;
      name: string;
      tenantID: string;
      type: TaxTypeType;
      updatedAt: string;
    } | null;
    taxTypeID: string;
    type: TaxRateType;
    updatedAt: string;
    venueID: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTaxTypesQuery = {
  __typename: "ModelTaxTypeConnection";
  items: Array<{
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTaxTypesByTenantIdQuery = {
  __typename: "ModelTaxTypeConnection";
  items: Array<{
    __typename: "TaxType";
    createdAt: string;
    id: string;
    name: string;
    tenantID: string;
    type: TaxTypeType;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type ListTenantsQuery = {
  __typename: "ModelTenantConnection";
  items: Array<{
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListTenantsByDomainQuery = {
  __typename: "ModelTenantConnection";
  items: Array<{
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListTenantsByTypeQuery = {
  __typename: "ModelTenantConnection";
  items: Array<{
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListUserRoleTypesQuery = {
  __typename: "ModelUserRoleTypeConnection";
  items: Array<{
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListUserRoleTypesByTenantIdQuery = {
  __typename: "ModelUserRoleTypeConnection";
  items: Array<{
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListUserRoleTypesByVenueIdQuery = {
  __typename: "ModelUserRoleTypeConnection";
  items: Array<{
    __typename: "UserRoleType";
    createdAt: string;
    id: string;
    name: string;
    permissions?: Array<UserRolePermission | null> | null;
    tenantID?: string | null;
    updatedAt: string;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListUserRolesQuery = {
  __typename: "ModelUserRoleConnection";
  items: Array<{
    __typename: "UserRole";
    createdAt: string;
    entityType: EntityType;
    id: string;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID?: string | null;
    type: {
      __typename: "UserRoleType";
      createdAt: string;
      id: string;
      name: string;
      permissions?: Array<UserRolePermission | null> | null;
      tenantID?: string | null;
      updatedAt: string;
      venueID?: string | null;
    };
    typeID: string;
    updatedAt: string;
    user: {
      __typename: "User";
      createdAt: string;
      email: string;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phone?: string | null;
      updatedAt: string;
      userName: string;
      userRoles?: {
        __typename: "ModelUserRoleConnection";
        nextToken?: string | null;
      } | null;
    };
    userID: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    phone?: string | null;
    updatedAt: string;
    userName: string;
    userRoles?: {
      __typename: "ModelUserRoleConnection";
      items: Array<{
        __typename: "UserRole";
        createdAt: string;
        entityType: EntityType;
        id: string;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        userID: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListUsersByUserNameQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    phone?: string | null;
    updatedAt: string;
    userName: string;
    userRoles?: {
      __typename: "ModelUserRoleConnection";
      items: Array<{
        __typename: "UserRole";
        createdAt: string;
        entityType: EntityType;
        id: string;
        tenantID?: string | null;
        typeID: string;
        updatedAt: string;
        userID: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenueOrdersQuery = {
  __typename: "ModelVenueOrderConnection";
  items: Array<{
    __typename: "VenueOrder";
    createdAt?: string | null;
    dueTime?: string | null;
    id: string;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    status?: OrderStatus | null;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenueOrdersByOrderIdQuery = {
  __typename: "ModelVenueOrderConnection";
  items: Array<{
    __typename: "VenueOrder";
    createdAt?: string | null;
    dueTime?: string | null;
    id: string;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    status?: OrderStatus | null;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenueOrdersByVenueIdQuery = {
  __typename: "ModelVenueOrderConnection";
  items: Array<{
    __typename: "VenueOrder";
    createdAt?: string | null;
    dueTime?: string | null;
    id: string;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    status?: OrderStatus | null;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenueOrdersByVenueIdByDateQuery = {
  __typename: "ModelVenueOrderConnection";
  items: Array<{
    __typename: "VenueOrder";
    createdAt?: string | null;
    dueTime?: string | null;
    id: string;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    status?: OrderStatus | null;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenueOrdersByVenueIdByStatusByDueTimeQuery = {
  __typename: "ModelVenueOrderConnection";
  items: Array<{
    __typename: "VenueOrder";
    createdAt?: string | null;
    dueTime?: string | null;
    id: string;
    order: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    };
    orderID: string;
    status?: OrderStatus | null;
    updatedAt: string;
    venue: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    };
    venueID: string;
    venueName?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenuesQuery = {
  __typename: "ModelVenueConnection";
  items: Array<{
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type ListVenuesByTenantIdQuery = {
  __typename: "ModelVenueConnection";
  items: Array<{
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null>;
  nextToken?: string | null;
};

export type OnCheckItemByIdSubscription = {
  __typename: "CheckItem";
  alcohol?: boolean | null;
  checkID: string;
  chit?: {
    __typename: "Chit";
    createdAt?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    id: string;
    items?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    order?: {
      __typename: "Order";
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      chits?: {
        __typename: "ModelChitConnection";
        nextToken?: string | null;
      } | null;
      clerkID?: number | null;
      courierCheckin?: string | null;
      courierDetails?: {
        __typename: "CourierDetails";
        errorCode?: number | null;
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        lastUpdate?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        message?: string | null;
        phone?: string | null;
        pickupTime?: string | null;
        status?: CourierStatus | null;
        type?: string | null;
      } | null;
      courierPhone?: string | null;
      courierStatus?: CourierStatus | null;
      createdAt?: string | null;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      deliveryEstimate?: number | null;
      deliveryID?: string | null;
      deliveryPickupTime?: string | null;
      deliveryProvider?: DeliveryProvider | null;
      deliveryQuoteID?: string | null;
      deliveryTime?: string | null;
      diagnostics?: string | null;
      dueTime?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      id: string;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID: string;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      orderLink?: {
        __typename: "OrderLink";
        active?: boolean | null;
        adminEmail?: string | null;
        ccCredentialID?: string | null;
        clerkID?: number | null;
        createdAt: string;
        doNotSell?: string | null;
        domain?: string | null;
        exploreHeading?: string | null;
        exploreSubheading?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        featuredRestarauntHeading?: string | null;
        featuredRestarauntSubheading?: string | null;
        footerURL?: string | null;
        heading?: string | null;
        id: string;
        imageS3EntryHeaders?: Array<string | null> | null;
        imageS3EntryHeadersMobile?: Array<string | null> | null;
        imageS3Footer?: string | null;
        isDefault?: boolean | null;
        leadTime?: number | null;
        locationID: string;
        menuScollerHeading?: string | null;
        menuScollerSubheading?: string | null;
        name: string;
        notice?: string | null;
        noticeAtCollection?: string | null;
        orderMode?: OrderMode | null;
        originEmail?: string | null;
        originLinkFacebook?: string | null;
        originLinkIG?: string | null;
        originName?: string | null;
        originPhone?: string | null;
        popularItemsHeading?: string | null;
        popularItemsSubheading?: string | null;
        popularRestarauntHeading?: string | null;
        popularRestarauntSubheading?: string | null;
        privacyPolicy?: string | null;
        searchPlaceholder?: string | null;
        smsCredentialID?: string | null;
        subHeading?: string | null;
        tenantID: string;
        tos?: string | null;
        updatedAt: string;
      } | null;
      orderLinkID?: string | null;
      orderNumber?: string | null;
      orderType?: {
        __typename: "OrderType";
        active?: boolean | null;
        asap?: boolean | null;
        createdAt: string;
        deliveryProvider?: DeliveryProvider | null;
        displayName?: string | null;
        id: string;
        name: string;
        schedule?: boolean | null;
        serviceType?: ServiceType | null;
        source?: string | null;
        tenantID: string;
        updatedAt: string;
      } | null;
      orderTypeID?: string | null;
      originAddress?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      originDetails?: {
        __typename: "OriginDetails";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip: string;
      } | null;
      queueMode?: OrderQueueMode | null;
      queueTime?: string | null;
      queuedAt?: string | null;
      readyEstimate?: number | null;
      readyTime?: string | null;
      serviceType?: ServiceType | null;
      source?: OrderSource | null;
      status?: OrderStatus | null;
      statusDetails?: string | null;
      statusMessage?: string | null;
      submittedAt?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tenantName?: string | null;
      thirdPartyOrderID?: string | null;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
      venueStatus?: Array<{
        __typename: "VenueStatus";
        status: OrderStatus;
        venueID: string;
      } | null> | null;
    } | null;
    orderID: string;
    status?: ChitStatus | null;
    tenantID: string;
    updatedAt: string;
    venue?: {
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    venueID: string;
    venueName?: string | null;
  } | null;
  chitID?: string | null;
  createdAt: string;
  id: string;
  instructions?: string | null;
  menuID?: string | null;
  menuItemID?: string | null;
  modifiers?: Array<{
    __typename: "CheckItemModifier";
    id: string;
    menuItemID?: string | null;
    modifierGroupID?: string | null;
    modifiers?: Array<{
      __typename: "CheckItemModifier";
      id: string;
      menuItemID?: string | null;
      modifierGroupID?: string | null;
      modifiers?: Array<{
        __typename: "CheckItemModifier";
        id: string;
        menuItemID?: string | null;
        modifierGroupID?: string | null;
        name: string;
        price: number;
        quantity: number;
        unitPrice?: number | null;
      } | null> | null;
      name: string;
      price: number;
      quantity: number;
      tags?: Array<{
        __typename: "CheckItemTag";
        id: string;
        name: string;
        type: TagType;
      } | null> | null;
      taxes?: Array<{
        __typename: "CheckItemTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      unitPrice?: number | null;
    } | null> | null;
    name: string;
    price: number;
    quantity: number;
    tags?: Array<{
      __typename: "CheckItemTag";
      id: string;
      name: string;
      type: TagType;
    } | null> | null;
    taxes?: Array<{
      __typename: "CheckItemTax";
      amount: number;
      name: string;
      taxTypeID?: string | null;
      type: TaxTypeType;
    } | null> | null;
    unitPrice?: number | null;
  } | null> | null;
  name: string;
  price: number;
  priceTypeID?: string | null;
  quantity: number;
  tags?: Array<{
    __typename: "CheckItemTag";
    id: string;
    name: string;
    type: TagType;
  } | null> | null;
  taxes?: Array<{
    __typename: "CheckItemTax";
    amount: number;
    name: string;
    taxTypeID?: string | null;
    type: TaxTypeType;
  } | null> | null;
  unitPrice?: number | null;
  updatedAt: string;
  utensils?: boolean | null;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type OnChitByIdSubscription = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  items?: Array<{
    __typename: "ChitItem";
    alcohol?: boolean | null;
    id: string;
    instructions?: string | null;
    modifiers?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    name: string;
    price?: number | null;
    quantity: number;
    status?: ChitItemStatus | null;
    utensils?: boolean | null;
  } | null> | null;
  order?: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type OnChitByVenueIdSubscription = {
  __typename: "Chit";
  createdAt?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  id: string;
  items?: Array<{
    __typename: "ChitItem";
    alcohol?: boolean | null;
    id: string;
    instructions?: string | null;
    modifiers?: Array<{
      __typename: "ChitItem";
      alcohol?: boolean | null;
      id: string;
      instructions?: string | null;
      modifiers?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      name: string;
      price?: number | null;
      quantity: number;
      status?: ChitItemStatus | null;
      utensils?: boolean | null;
    } | null> | null;
    name: string;
    price?: number | null;
    quantity: number;
    status?: ChitItemStatus | null;
    utensils?: boolean | null;
  } | null> | null;
  order?: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  } | null;
  orderID: string;
  status?: ChitStatus | null;
  tenantID: string;
  updatedAt: string;
  venue?: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  venueID: string;
  venueName?: string | null;
};

export type OnConceptByTenantIdSubscription = {
  __typename: "Concept";
  createdAt: string;
  id: string;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  tenantID: string;
  updatedAt: string;
};

export type OnCreateDomainSubscription = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type OnCreateTenantSubscription = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeleteDomainSubscription = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type OnDeleteTenantSubscription = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnDeviceTransactionByDeviceIdSubscription = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type OnDeviceTransactionByTransactionIdSubscription = {
  __typename: "DeviceTransaction";
  createdAt: string;
  data?: string | null;
  deviceID: string;
  event?: string | null;
  expirationUnixTime?: number | null;
  id: string;
  status?: DeviceTransactionStatus | null;
  transactionID: string;
  type: DeviceTransactionType;
  updatedAt: string;
};

export type OnLicenseByDeviceIdSubscription = {
  __typename: "License";
  active?: boolean | null;
  createdAt: string;
  deviceDetails?: {
    __typename: "DeviceDetails";
    deviceID?: string | null;
    displayName?: string | null;
    heartbeat?: string | null;
    initTime?: string | null;
    name?: string | null;
    online?: boolean | null;
    version?: string | null;
  } | null;
  devices?: {
    __typename: "ModelDeviceConnection";
    items: Array<{
      __typename: "Device";
      active?: boolean | null;
      autoPrint?: boolean | null;
      createdAt: string;
      deviceID?: string | null;
      displayName?: string | null;
      host?: string | null;
      id: string;
      ipAddress?: string | null;
      licenseID: string;
      name?: string | null;
      port?: number | null;
      printOnBump?: boolean | null;
      serialNumber?: string | null;
      type: DeviceType;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  displayName?: string | null;
  guid?: string | null;
  id: string;
  kioskConfig?: {
    __typename: "KioskConfig";
    orderLinkID?: string | null;
    showSettings?: boolean | null;
  } | null;
  kitchenConfig?: {
    __typename: "KitchenConfig";
    showSettings?: boolean | null;
  } | null;
  name?: string | null;
  pollingInterval?: number | null;
  receiptConfig?: {
    __typename: "ReceiptConfig";
    createdAt: string;
    headerSize?: number | null;
    id: string;
    itemSize?: number | null;
    name: string;
    showDiscounts?: boolean | null;
    showFees?: boolean | null;
    showPayments?: boolean | null;
    showPrice?: boolean | null;
    showQR?: boolean | null;
    showRemaining?: boolean | null;
    showSubtotal?: boolean | null;
    showTaxes?: boolean | null;
    showTips?: boolean | null;
    showTransactions?: boolean | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueSize?: number | null;
  } | null;
  receiptConfigID?: string | null;
  status?: LicenseStatus | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  type: DeviceType;
  updatedAt: string;
  venues?: {
    __typename: "ModelLicenseVenueConnection";
    items: Array<{
      __typename: "LicenseVenue";
      createdAt: string;
      id: string;
      licenseID: string;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnOrderByIdSubscription = {
  __typename: "Order";
  checks?: {
    __typename: "ModelCheckConnection";
    items: Array<{
      __typename: "Check";
      createdAt: string;
      customer?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      customerDetails?: {
        __typename: "CustomerDetails";
        dob?: string | null;
        email?: string | null;
        firstName: string;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      customerID?: string | null;
      diagnostics?: string | null;
      fees?: Array<{
        __typename: "CheckFee";
        amount: number;
        id: string;
        inclusive?: boolean | null;
        name: string;
        taxed?: boolean | null;
        type: FeeType;
      } | null> | null;
      id: string;
      items?: {
        __typename: "ModelCheckItemConnection";
        nextToken?: string | null;
      } | null;
      logs?: {
        __typename: "ModelLogConnection";
        nextToken?: string | null;
      } | null;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      payments?: {
        __typename: "ModelCheckPaymentConnection";
        nextToken?: string | null;
      } | null;
      readyEstimate?: number | null;
      taxes?: Array<{
        __typename: "CheckTax";
        amount: number;
        name: string;
        taxTypeID?: string | null;
        type: TaxTypeType;
      } | null> | null;
      tenant: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      tenantID: string;
      totals?: {
        __typename: "CheckTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
      } | null;
      transactions?: {
        __typename: "ModelCheckTransactionConnection";
        nextToken?: string | null;
      } | null;
      updatedAt: string;
      venueTotals?: Array<{
        __typename: "VenueTotals";
        ccCaptureTotal?: number | null;
        ccRefundTotal?: number | null;
        ccVoidTotal?: number | null;
        checkPercent?: number | null;
        discountTotal?: number | null;
        feeTotal?: number | null;
        feeTotalInclusive?: number | null;
        feesAndTaxTotal?: number | null;
        paymentTotal?: number | null;
        remainingBalance?: number | null;
        subTotal?: number | null;
        taxTotal?: number | null;
        tipTotal?: number | null;
        total?: number | null;
        venueID: string;
      } | null> | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  chits?: {
    __typename: "ModelChitConnection";
    items: Array<{
      __typename: "Chit";
      createdAt?: string | null;
      errors?: Array<{
        __typename: "Error";
        code?: number | null;
        data?: string | null;
        details?: string | null;
        message: string;
        type?: string | null;
      } | null> | null;
      id: string;
      items?: Array<{
        __typename: "ChitItem";
        alcohol?: boolean | null;
        id: string;
        instructions?: string | null;
        name: string;
        price?: number | null;
        quantity: number;
        status?: ChitItemStatus | null;
        utensils?: boolean | null;
      } | null> | null;
      order?: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      } | null;
      orderID: string;
      status?: ChitStatus | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  clerkID?: number | null;
  courierCheckin?: string | null;
  courierDetails?: {
    __typename: "CourierDetails";
    errorCode?: number | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    lastUpdate?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    message?: string | null;
    phone?: string | null;
    pickupTime?: string | null;
    status?: CourierStatus | null;
    type?: string | null;
  } | null;
  courierPhone?: string | null;
  courierStatus?: CourierStatus | null;
  createdAt?: string | null;
  customer?: {
    __typename: "Customer";
    addresses?: Array<{
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null> | null;
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    createdAt: string;
    defaultPaymentMethodID?: string | null;
    email?: string | null;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    paymentMethods?: {
      __typename: "ModelCustomerPaymentMethodConnection";
      items: Array<{
        __typename: "CustomerPaymentMethod";
        createdAt: string;
        customerID: string;
        id: string;
        paymentMethodID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    phone?: string | null;
    updatedAt: string;
    userName?: string | null;
  } | null;
  customerDetails?: {
    __typename: "CustomerDetails";
    address?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    dob?: string | null;
    email?: string | null;
    firstName: string;
    lastName?: string | null;
    phone?: string | null;
  } | null;
  customerID?: string | null;
  deliveryEstimate?: number | null;
  deliveryID?: string | null;
  deliveryPickupTime?: string | null;
  deliveryProvider?: DeliveryProvider | null;
  deliveryQuoteID?: string | null;
  deliveryTime?: string | null;
  diagnostics?: string | null;
  dueTime?: string | null;
  errors?: Array<{
    __typename: "Error";
    code?: number | null;
    data?: string | null;
    details?: string | null;
    message: string;
    type?: string | null;
  } | null> | null;
  fascilitatedTenantReportExclusions?: Array<string | null> | null;
  id: string;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID: string;
  logs?: {
    __typename: "ModelLogConnection";
    items: Array<{
      __typename: "Log";
      checkID?: string | null;
      createdAt: string;
      employee?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      employeeID?: string | null;
      event?: LogEvent | null;
      id: string;
      message?: string | null;
      orderID?: string | null;
      source?: LogSource | null;
      tenantID?: string | null;
      type: LogType;
      updatedAt: string;
      user?: {
        __typename: "Customer";
        createdAt: string;
        defaultPaymentMethodID?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        updatedAt: string;
        userName?: string | null;
      } | null;
      userID?: string | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  orderLink?: {
    __typename: "OrderLink";
    active?: boolean | null;
    adminEmail?: string | null;
    ccCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    ccCredentialID?: string | null;
    clerkID?: number | null;
    createdAt: string;
    doNotSell?: string | null;
    domain?: string | null;
    exploreHeading?: string | null;
    exploreSubheading?: string | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    featuredRestarauntHeading?: string | null;
    featuredRestarauntSubheading?: string | null;
    footerURL?: string | null;
    heading?: string | null;
    id: string;
    imageS3EntryHeaders?: Array<string | null> | null;
    imageS3EntryHeadersMobile?: Array<string | null> | null;
    imageS3Footer?: string | null;
    isDefault?: boolean | null;
    leadTime?: number | null;
    location: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    };
    locationID: string;
    menuScollerHeading?: string | null;
    menuScollerSubheading?: string | null;
    menus?: {
      __typename: "ModelOrderLinkMenuConnection";
      items: Array<{
        __typename: "OrderLinkMenu";
        createdAt: string;
        id: string;
        menuID: string;
        orderLinkID: string;
        sortOrder?: number | null;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    notice?: string | null;
    noticeAtCollection?: string | null;
    notifications?: {
      __typename: "ModelOrderLinkNotificationConnection";
      items: Array<{
        __typename: "OrderLinkNotification";
        createdAt: string;
        event: NotificationEvent;
        id: string;
        notificationID: string;
        orderLinkID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderMode?: OrderMode | null;
    orderTypes?: {
      __typename: "ModelOrderLinkOrderTypeConnection";
      items: Array<{
        __typename: "OrderLinkOrderType";
        createdAt: string;
        id: string;
        orderLinkID: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    originEmail?: string | null;
    originLinkFacebook?: string | null;
    originLinkIG?: string | null;
    originName?: string | null;
    originPhone?: string | null;
    popularItemsHeading?: string | null;
    popularItemsSubheading?: string | null;
    popularRestarauntHeading?: string | null;
    popularRestarauntSubheading?: string | null;
    privacyPolicy?: string | null;
    schedules?: {
      __typename: "ModelOrderLinkScheduleConnection";
      items: Array<{
        __typename: "OrderLinkSchedule";
        createdAt: string;
        id: string;
        orderLinkID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    searchPlaceholder?: string | null;
    smsCredential?: {
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null;
    smsCredentialID?: string | null;
    subHeading?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tos?: string | null;
    updatedAt: string;
  } | null;
  orderLinkID?: string | null;
  orderNumber?: string | null;
  orderType?: {
    __typename: "OrderType";
    active?: boolean | null;
    asap?: boolean | null;
    createdAt: string;
    deliveryProvider?: DeliveryProvider | null;
    displayName?: string | null;
    fees?: {
      __typename: "ModelOrderTypeFeeConnection";
      items: Array<{
        __typename: "OrderTypeFee";
        createdAt: string;
        feeID: string;
        id: string;
        orderTypeID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    name: string;
    schedule?: boolean | null;
    schedules?: {
      __typename: "ModelOrderTypeScheduleConnection";
      items: Array<{
        __typename: "OrderTypeSchedule";
        createdAt: string;
        id: string;
        orderTypeID: string;
        scheduleID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    serviceType?: ServiceType | null;
    source?: string | null;
    tenantID: string;
    updatedAt: string;
  } | null;
  orderTypeID?: string | null;
  originAddress?: {
    __typename: "Address";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    instructions?: string | null;
    isDefault?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip?: string | null;
  } | null;
  originDetails?: {
    __typename: "OriginDetails";
    city?: string | null;
    country?: string | null;
    distance?: number | null;
    email?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    timezone?: string | null;
    unit?: string | null;
    zip: string;
  } | null;
  queueMode?: OrderQueueMode | null;
  queueTime?: string | null;
  queuedAt?: string | null;
  readyEstimate?: number | null;
  readyTime?: string | null;
  serviceType?: ServiceType | null;
  source?: OrderSource | null;
  status?: OrderStatus | null;
  statusDetails?: string | null;
  statusMessage?: string | null;
  submittedAt?: string | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  tenantName?: string | null;
  thirdPartyOrderID?: string | null;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  venueStatus?: Array<{
    __typename: "VenueStatus";
    status: OrderStatus;
    venueID: string;
  } | null> | null;
};

export type OnUpdateDomainSubscription = {
  __typename: "Domain";
  createdAt: string;
  host: string;
  id: string;
  imageS3Logo?: string | null;
  updatedAt: string;
};

export type OnUpdateTenantSubscription = {
  __typename: "Tenant";
  appDomain?: string | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  email: string;
  id: string;
  menuFacilitations?: {
    __typename: "ModelMenuFacilitatorConnection";
    items: Array<{
      __typename: "MenuFacilitator";
      createdAt: string;
      facilitator: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      };
      facilitatorID: string;
      id: string;
      menu: {
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      };
      menuID: string;
      updatedAt: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  orderLinkDomains?: Array<string | null> | null;
  phone: string;
  shift4ApiKey?: string | null;
  shift4ApiSecret?: string | null;
  type: TenantType;
  updatedAt: string;
  venues?: {
    __typename: "ModelVenueConnection";
    items: Array<{
      __typename: "Venue";
      acceptingOrders?: boolean | null;
      active?: boolean | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      imageS3LogoSquare?: string | null;
      leadTime?: number | null;
      location?: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      } | null;
      locationID?: string | null;
      menuCategories?: {
        __typename: "ModelMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      menuItems?: {
        __typename: "ModelMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuConnection";
        nextToken?: string | null;
      } | null;
      modifierGroups?: {
        __typename: "ModelModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      schedules?: {
        __typename: "ModelVenueScheduleConnection";
        nextToken?: string | null;
      } | null;
      taxRates?: {
        __typename: "ModelTaxRateConnection";
        nextToken?: string | null;
      } | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueOrders?: {
        __typename: "ModelVenueOrderConnection";
        nextToken?: string | null;
      } | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnVenueByTenantIdSubscription = {
  __typename: "Venue";
  acceptingOrders?: boolean | null;
  active?: boolean | null;
  createdAt: string;
  credentials?: {
    __typename: "ModelCredentialConnection";
    items: Array<{
      __typename: "Credential";
      createdAt: string;
      dcEcomMID?: string | null;
      dcMID?: string | null;
      dcTokenKey?: string | null;
      id: string;
      key: string;
      secret: string;
      smsFrom?: string | null;
      tenantID?: string | null;
      type: CredentialType;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  imageS3LogoSquare?: string | null;
  leadTime?: number | null;
  location?: {
    __typename: "Location";
    city: string;
    country?: string | null;
    createdAt: string;
    email?: string | null;
    endOfDay?: string | null;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name: string;
    phone?: string | null;
    state: string;
    street: string;
    tenantID: string;
    timezone?: string | null;
    unit?: string | null;
    updatedAt: string;
    zip: string;
  } | null;
  locationID?: string | null;
  menuCategories?: {
    __typename: "ModelMenuCategoryConnection";
    items: Array<{
      __typename: "MenuCategory";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      menuItems?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      menus?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      tags?: {
        __typename: "ModelMenuCategoryTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menuItems?: {
    __typename: "ModelMenuItemConnection";
    items: Array<{
      __typename: "MenuItem";
      active?: boolean | null;
      alcohol?: boolean | null;
      categories?: {
        __typename: "ModelMenuCategoryMenuItemConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      id: string;
      imageS3?: string | null;
      modifierGroups?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      options?: {
        __typename: "ModelMenuItemOptionConnection";
        nextToken?: string | null;
      } | null;
      parentModifierGroups?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      prepTime?: number | null;
      prices?: {
        __typename: "ModelMenuItemPriceConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuItemTagConnection";
        nextToken?: string | null;
      } | null;
      taxes?: {
        __typename: "ModelMenuItemTaxTypeConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      utensils?: boolean | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  menus?: {
    __typename: "ModelMenuConnection";
    items: Array<{
      __typename: "Menu";
      active?: boolean | null;
      categories?: {
        __typename: "ModelMenuMenuCategoryConnection";
        nextToken?: string | null;
      } | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      facilitators?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      featured?: boolean | null;
      id: string;
      imageS3Featured?: Array<string | null> | null;
      imageS3Headers?: Array<string | null> | null;
      imageS3HeadersMobile?: Array<string | null> | null;
      imageS3LogoSquare?: string | null;
      name: string;
      orderLinks?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      popular?: boolean | null;
      schedules?: {
        __typename: "ModelMenuScheduleConnection";
        nextToken?: string | null;
      } | null;
      tags?: {
        __typename: "ModelMenuTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venue?: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  modifierGroups?: {
    __typename: "ModelModifierGroupConnection";
    items: Array<{
      __typename: "ModifierGroup";
      active?: boolean | null;
      conceptID?: string | null;
      createdAt: string;
      description?: string | null;
      displayName?: string | null;
      id: string;
      max?: number | null;
      menuItems?: {
        __typename: "ModelMenuItemModifierGroupConnection";
        nextToken?: string | null;
      } | null;
      min?: number | null;
      modifiers?: {
        __typename: "ModelModifierGroupMenuItemConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      priceTypeID?: string | null;
      selectionType?: SelectionType | null;
      tags?: {
        __typename: "ModelModifierGroupTagConnection";
        nextToken?: string | null;
      } | null;
      tenantID: string;
      updatedAt: string;
      venueID?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  name: string;
  schedules?: {
    __typename: "ModelVenueScheduleConnection";
    items: Array<{
      __typename: "VenueSchedule";
      createdAt: string;
      id: string;
      schedule: {
        __typename: "Schedule";
        createdAt: string;
        endDate?: string | null;
        id: string;
        name: string;
        startDate?: string | null;
        tenantID: string;
        type: ScheduleType;
        updatedAt: string;
      };
      scheduleID: string;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  taxRates?: {
    __typename: "ModelTaxRateConnection";
    items: Array<{
      __typename: "TaxRate";
      amount?: number | null;
      createdAt: string;
      id: string;
      rate?: number | null;
      taxType?: {
        __typename: "TaxType";
        createdAt: string;
        id: string;
        name: string;
        tenantID: string;
        type: TaxTypeType;
        updatedAt: string;
      } | null;
      taxTypeID: string;
      type: TaxRateType;
      updatedAt: string;
      venueID: string;
    } | null>;
    nextToken?: string | null;
  } | null;
  tenant?: {
    __typename: "Tenant";
    appDomain?: string | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    email: string;
    id: string;
    menuFacilitations?: {
      __typename: "ModelMenuFacilitatorConnection";
      items: Array<{
        __typename: "MenuFacilitator";
        createdAt: string;
        facilitatorID: string;
        id: string;
        menuID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    orderLinkDomains?: Array<string | null> | null;
    phone: string;
    shift4ApiKey?: string | null;
    shift4ApiSecret?: string | null;
    type: TenantType;
    updatedAt: string;
    venues?: {
      __typename: "ModelVenueConnection";
      items: Array<{
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
  tenantID: string;
  updatedAt: string;
  venueOrders?: {
    __typename: "ModelVenueOrderConnection";
    items: Array<{
      __typename: "VenueOrder";
      createdAt?: string | null;
      dueTime?: string | null;
      id: string;
      order: {
        __typename: "Order";
        clerkID?: number | null;
        courierCheckin?: string | null;
        courierPhone?: string | null;
        courierStatus?: CourierStatus | null;
        createdAt?: string | null;
        customerID?: string | null;
        deliveryEstimate?: number | null;
        deliveryID?: string | null;
        deliveryPickupTime?: string | null;
        deliveryProvider?: DeliveryProvider | null;
        deliveryQuoteID?: string | null;
        deliveryTime?: string | null;
        diagnostics?: string | null;
        dueTime?: string | null;
        fascilitatedTenantReportExclusions?: Array<string | null> | null;
        id: string;
        locationID: string;
        orderLinkID?: string | null;
        orderNumber?: string | null;
        orderTypeID?: string | null;
        queueMode?: OrderQueueMode | null;
        queueTime?: string | null;
        queuedAt?: string | null;
        readyEstimate?: number | null;
        readyTime?: string | null;
        serviceType?: ServiceType | null;
        source?: OrderSource | null;
        status?: OrderStatus | null;
        statusDetails?: string | null;
        statusMessage?: string | null;
        submittedAt?: string | null;
        tenantID: string;
        tenantName?: string | null;
        thirdPartyOrderID?: string | null;
        updatedAt: string;
      };
      orderID: string;
      status?: OrderStatus | null;
      updatedAt: string;
      venue: {
        __typename: "Venue";
        acceptingOrders?: boolean | null;
        active?: boolean | null;
        createdAt: string;
        id: string;
        imageS3LogoSquare?: string | null;
        leadTime?: number | null;
        locationID?: string | null;
        name: string;
        tenantID: string;
        updatedAt: string;
      };
      venueID: string;
      venueName?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnVenueOrderByVenueIdSubscription = {
  __typename: "VenueOrder";
  createdAt?: string | null;
  dueTime?: string | null;
  id: string;
  order: {
    __typename: "Order";
    checks?: {
      __typename: "ModelCheckConnection";
      items: Array<{
        __typename: "Check";
        createdAt: string;
        customerID?: string | null;
        diagnostics?: string | null;
        id: string;
        orderID: string;
        readyEstimate?: number | null;
        tenantID: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    chits?: {
      __typename: "ModelChitConnection";
      items: Array<{
        __typename: "Chit";
        createdAt?: string | null;
        id: string;
        orderID: string;
        status?: ChitStatus | null;
        tenantID: string;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    clerkID?: number | null;
    courierCheckin?: string | null;
    courierDetails?: {
      __typename: "CourierDetails";
      errorCode?: number | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      lastUpdate?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      message?: string | null;
      phone?: string | null;
      pickupTime?: string | null;
      status?: CourierStatus | null;
      type?: string | null;
    } | null;
    courierPhone?: string | null;
    courierStatus?: CourierStatus | null;
    createdAt?: string | null;
    customer?: {
      __typename: "Customer";
      addresses?: Array<{
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null> | null;
      checks?: {
        __typename: "ModelCheckConnection";
        nextToken?: string | null;
      } | null;
      createdAt: string;
      defaultPaymentMethodID?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      paymentMethods?: {
        __typename: "ModelCustomerPaymentMethodConnection";
        nextToken?: string | null;
      } | null;
      phone?: string | null;
      updatedAt: string;
      userName?: string | null;
    } | null;
    customerDetails?: {
      __typename: "CustomerDetails";
      address?: {
        __typename: "Address";
        city?: string | null;
        country?: string | null;
        distance?: number | null;
        email?: string | null;
        instructions?: string | null;
        isDefault?: boolean | null;
        latitude?: number | null;
        longitude?: number | null;
        name?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        timezone?: string | null;
        unit?: string | null;
        zip?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      firstName: string;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    customerID?: string | null;
    deliveryEstimate?: number | null;
    deliveryID?: string | null;
    deliveryPickupTime?: string | null;
    deliveryProvider?: DeliveryProvider | null;
    deliveryQuoteID?: string | null;
    deliveryTime?: string | null;
    diagnostics?: string | null;
    dueTime?: string | null;
    errors?: Array<{
      __typename: "Error";
      code?: number | null;
      data?: string | null;
      details?: string | null;
      message: string;
      type?: string | null;
    } | null> | null;
    fascilitatedTenantReportExclusions?: Array<string | null> | null;
    id: string;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID: string;
    logs?: {
      __typename: "ModelLogConnection";
      items: Array<{
        __typename: "Log";
        checkID?: string | null;
        createdAt: string;
        employeeID?: string | null;
        event?: LogEvent | null;
        id: string;
        message?: string | null;
        orderID?: string | null;
        source?: LogSource | null;
        tenantID?: string | null;
        type: LogType;
        updatedAt: string;
        userID?: string | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    orderLink?: {
      __typename: "OrderLink";
      active?: boolean | null;
      adminEmail?: string | null;
      ccCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      ccCredentialID?: string | null;
      clerkID?: number | null;
      createdAt: string;
      doNotSell?: string | null;
      domain?: string | null;
      exploreHeading?: string | null;
      exploreSubheading?: string | null;
      fascilitatedTenantReportExclusions?: Array<string | null> | null;
      featuredRestarauntHeading?: string | null;
      featuredRestarauntSubheading?: string | null;
      footerURL?: string | null;
      heading?: string | null;
      id: string;
      imageS3EntryHeaders?: Array<string | null> | null;
      imageS3EntryHeadersMobile?: Array<string | null> | null;
      imageS3Footer?: string | null;
      isDefault?: boolean | null;
      leadTime?: number | null;
      location: {
        __typename: "Location";
        city: string;
        country?: string | null;
        createdAt: string;
        email?: string | null;
        endOfDay?: string | null;
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        name: string;
        phone?: string | null;
        state: string;
        street: string;
        tenantID: string;
        timezone?: string | null;
        unit?: string | null;
        updatedAt: string;
        zip: string;
      };
      locationID: string;
      menuScollerHeading?: string | null;
      menuScollerSubheading?: string | null;
      menus?: {
        __typename: "ModelOrderLinkMenuConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      notice?: string | null;
      noticeAtCollection?: string | null;
      notifications?: {
        __typename: "ModelOrderLinkNotificationConnection";
        nextToken?: string | null;
      } | null;
      orderMode?: OrderMode | null;
      orderTypes?: {
        __typename: "ModelOrderLinkOrderTypeConnection";
        nextToken?: string | null;
      } | null;
      originEmail?: string | null;
      originLinkFacebook?: string | null;
      originLinkIG?: string | null;
      originName?: string | null;
      originPhone?: string | null;
      popularItemsHeading?: string | null;
      popularItemsSubheading?: string | null;
      popularRestarauntHeading?: string | null;
      popularRestarauntSubheading?: string | null;
      privacyPolicy?: string | null;
      schedules?: {
        __typename: "ModelOrderLinkScheduleConnection";
        nextToken?: string | null;
      } | null;
      searchPlaceholder?: string | null;
      smsCredential?: {
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null;
      smsCredentialID?: string | null;
      subHeading?: string | null;
      tenant?: {
        __typename: "Tenant";
        appDomain?: string | null;
        createdAt: string;
        email: string;
        id: string;
        name: string;
        orderLinkDomains?: Array<string | null> | null;
        phone: string;
        shift4ApiKey?: string | null;
        shift4ApiSecret?: string | null;
        type: TenantType;
        updatedAt: string;
      } | null;
      tenantID: string;
      tos?: string | null;
      updatedAt: string;
    } | null;
    orderLinkID?: string | null;
    orderNumber?: string | null;
    orderType?: {
      __typename: "OrderType";
      active?: boolean | null;
      asap?: boolean | null;
      createdAt: string;
      deliveryProvider?: DeliveryProvider | null;
      displayName?: string | null;
      fees?: {
        __typename: "ModelOrderTypeFeeConnection";
        nextToken?: string | null;
      } | null;
      id: string;
      name: string;
      schedule?: boolean | null;
      schedules?: {
        __typename: "ModelOrderTypeScheduleConnection";
        nextToken?: string | null;
      } | null;
      serviceType?: ServiceType | null;
      source?: string | null;
      tenantID: string;
      updatedAt: string;
    } | null;
    orderTypeID?: string | null;
    originAddress?: {
      __typename: "Address";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      instructions?: string | null;
      isDefault?: boolean | null;
      latitude?: number | null;
      longitude?: number | null;
      name?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip?: string | null;
    } | null;
    originDetails?: {
      __typename: "OriginDetails";
      city?: string | null;
      country?: string | null;
      distance?: number | null;
      email?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      timezone?: string | null;
      unit?: string | null;
      zip: string;
    } | null;
    queueMode?: OrderQueueMode | null;
    queueTime?: string | null;
    queuedAt?: string | null;
    readyEstimate?: number | null;
    readyTime?: string | null;
    serviceType?: ServiceType | null;
    source?: OrderSource | null;
    status?: OrderStatus | null;
    statusDetails?: string | null;
    statusMessage?: string | null;
    submittedAt?: string | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    tenantName?: string | null;
    thirdPartyOrderID?: string | null;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    venueStatus?: Array<{
      __typename: "VenueStatus";
      status: OrderStatus;
      venueID: string;
    } | null> | null;
  };
  orderID: string;
  status?: OrderStatus | null;
  updatedAt: string;
  venue: {
    __typename: "Venue";
    acceptingOrders?: boolean | null;
    active?: boolean | null;
    createdAt: string;
    credentials?: {
      __typename: "ModelCredentialConnection";
      items: Array<{
        __typename: "Credential";
        createdAt: string;
        dcEcomMID?: string | null;
        dcMID?: string | null;
        dcTokenKey?: string | null;
        id: string;
        key: string;
        secret: string;
        smsFrom?: string | null;
        tenantID?: string | null;
        type: CredentialType;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    id: string;
    imageS3LogoSquare?: string | null;
    leadTime?: number | null;
    location?: {
      __typename: "Location";
      city: string;
      country?: string | null;
      createdAt: string;
      email?: string | null;
      endOfDay?: string | null;
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      name: string;
      phone?: string | null;
      state: string;
      street: string;
      tenantID: string;
      timezone?: string | null;
      unit?: string | null;
      updatedAt: string;
      zip: string;
    } | null;
    locationID?: string | null;
    menuCategories?: {
      __typename: "ModelMenuCategoryConnection";
      items: Array<{
        __typename: "MenuCategory";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        name: string;
        priceTypeID?: string | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menuItems?: {
      __typename: "ModelMenuItemConnection";
      items: Array<{
        __typename: "MenuItem";
        active?: boolean | null;
        alcohol?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        id: string;
        imageS3?: string | null;
        name: string;
        popular?: boolean | null;
        prepTime?: number | null;
        tenantID: string;
        updatedAt: string;
        utensils?: boolean | null;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    menus?: {
      __typename: "ModelMenuConnection";
      items: Array<{
        __typename: "Menu";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        featured?: boolean | null;
        id: string;
        imageS3Featured?: Array<string | null> | null;
        imageS3Headers?: Array<string | null> | null;
        imageS3HeadersMobile?: Array<string | null> | null;
        imageS3LogoSquare?: string | null;
        name: string;
        popular?: boolean | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    modifierGroups?: {
      __typename: "ModelModifierGroupConnection";
      items: Array<{
        __typename: "ModifierGroup";
        active?: boolean | null;
        conceptID?: string | null;
        createdAt: string;
        description?: string | null;
        displayName?: string | null;
        id: string;
        max?: number | null;
        min?: number | null;
        name: string;
        priceTypeID?: string | null;
        selectionType?: SelectionType | null;
        tenantID: string;
        updatedAt: string;
        venueID?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    name: string;
    schedules?: {
      __typename: "ModelVenueScheduleConnection";
      items: Array<{
        __typename: "VenueSchedule";
        createdAt: string;
        id: string;
        scheduleID: string;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    taxRates?: {
      __typename: "ModelTaxRateConnection";
      items: Array<{
        __typename: "TaxRate";
        amount?: number | null;
        createdAt: string;
        id: string;
        rate?: number | null;
        taxTypeID: string;
        type: TaxRateType;
        updatedAt: string;
        venueID: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    tenant?: {
      __typename: "Tenant";
      appDomain?: string | null;
      createdAt: string;
      credentials?: {
        __typename: "ModelCredentialConnection";
        nextToken?: string | null;
      } | null;
      email: string;
      id: string;
      menuFacilitations?: {
        __typename: "ModelMenuFacilitatorConnection";
        nextToken?: string | null;
      } | null;
      name: string;
      orderLinkDomains?: Array<string | null> | null;
      phone: string;
      shift4ApiKey?: string | null;
      shift4ApiSecret?: string | null;
      type: TenantType;
      updatedAt: string;
      venues?: {
        __typename: "ModelVenueConnection";
        nextToken?: string | null;
      } | null;
    } | null;
    tenantID: string;
    updatedAt: string;
    venueOrders?: {
      __typename: "ModelVenueOrderConnection";
      items: Array<{
        __typename: "VenueOrder";
        createdAt?: string | null;
        dueTime?: string | null;
        id: string;
        orderID: string;
        status?: OrderStatus | null;
        updatedAt: string;
        venueID: string;
        venueName?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
  };
  venueID: string;
  venueName?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async SaveCheckItemDeep(
    input: SaveCheckItemInput
  ): Promise<SaveCheckItemDeepMutation> {
    const statement = `mutation SaveCheckItemDeep($input: SaveCheckItemInput!) {
        saveCheckItem(input: $input) {
          __typename
          id
          readyEstimate
          totals {
            __typename
            subTotal
            taxTotal
            tipTotal
            feeTotal
            discountTotal
            paymentTotal
            total
            remainingBalance
          }
          items {
            __typename
            items {
              __typename
              id
              venueID
              venueName
              menuID
              menuItemID
              name
              price
              priceTypeID
              quantity
              utensils
              instructions
              taxes {
                __typename
                name
                amount
                type
              }
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                taxes {
                  __typename
                  name
                  amount
                  type
                }
                modifiers {
                  __typename
                  id
                  menuItemID
                  modifierGroupID
                  name
                  price
                  quantity
                  taxes {
                    __typename
                    name
                    amount
                    type
                  }
                  modifiers {
                    __typename
                    id
                    menuItemID
                    modifierGroupID
                    name
                    price
                    quantity
                    taxes {
                      __typename
                      name
                      amount
                      type
                    }
                    modifiers {
                      __typename
                      id
                      menuItemID
                      modifierGroupID
                      name
                      price
                      quantity
                    }
                  }
                }
              }
            }
          }
          payments {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          transactions {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          fees {
            __typename
            name
            amount
            inclusive
          }
          tenantID
          orderID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SaveCheckItemDeepMutation>response.data.saveCheckItem;
  }
  async RemoveCheckItemDeep(
    input: IDInput
  ): Promise<RemoveCheckItemDeepMutation> {
    const statement = `mutation RemoveCheckItemDeep($input: IDInput!) {
        removeCheckItem(input: $input) {
          __typename
          id
          readyEstimate
          totals {
            __typename
            subTotal
            taxTotal
            tipTotal
            feeTotal
            discountTotal
            paymentTotal
            total
            remainingBalance
          }
          items {
            __typename
            items {
              __typename
              id
              venueID
              menuID
              menuItemID
              name
              price
              priceTypeID
              quantity
              utensils
              instructions
              taxes {
                __typename
                name
                amount
                type
              }
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                taxes {
                  __typename
                  name
                  amount
                  type
                }
                modifiers {
                  __typename
                  id
                  menuItemID
                  modifierGroupID
                  name
                  price
                  quantity
                  taxes {
                    __typename
                    name
                    amount
                    type
                  }
                  modifiers {
                    __typename
                    id
                    menuItemID
                    modifierGroupID
                    name
                    price
                    quantity
                    taxes {
                      __typename
                      name
                      amount
                      type
                    }
                    modifiers {
                      __typename
                      id
                      menuItemID
                      modifierGroupID
                      name
                      price
                      quantity
                    }
                  }
                }
              }
            }
          }
          payments {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          transactions {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          fees {
            __typename
            name
            amount
            inclusive
          }
          tenantID
          orderID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RemoveCheckItemDeepMutation>response.data.removeCheckItem;
  }
  async UpdateCheckItemQuantityDeep(
    input: UpdateCheckItemQuantityInput
  ): Promise<UpdateCheckItemQuantityDeepMutation> {
    const statement = `mutation UpdateCheckItemQuantityDeep($input: UpdateCheckItemQuantityInput!) {
        updateCheckItemQuantity(input: $input) {
          __typename
          id
          readyEstimate
          totals {
            __typename
            subTotal
            taxTotal
            tipTotal
            feeTotal
            discountTotal
            paymentTotal
            total
            remainingBalance
          }
          items {
            __typename
            items {
              __typename
              id
              venueID
              menuID
              menuItemID
              name
              price
              priceTypeID
              quantity
              utensils
              instructions
              taxes {
                __typename
                name
                amount
                type
              }
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                taxes {
                  __typename
                  name
                  amount
                  type
                }
                modifiers {
                  __typename
                  id
                  menuItemID
                  modifierGroupID
                  name
                  price
                  quantity
                  taxes {
                    __typename
                    name
                    amount
                    type
                  }
                  modifiers {
                    __typename
                    id
                    menuItemID
                    modifierGroupID
                    name
                    price
                    quantity
                    taxes {
                      __typename
                      name
                      amount
                      type
                    }
                    modifiers {
                      __typename
                      id
                      menuItemID
                      modifierGroupID
                      name
                      price
                      quantity
                    }
                  }
                }
              }
            }
          }
          payments {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          transactions {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          fees {
            __typename
            name
            amount
            inclusive
          }
          tenantID
          orderID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckItemQuantityDeepMutation>(
      response.data.updateCheckItemQuantity
    );
  }
  async GetCheckDeep(id: string): Promise<GetCheckDeepQuery> {
    const statement = `query GetCheckDeep($id: ID!) {
        getCheck(id: $id) {
          __typename
          id
          readyEstimate
          totals {
            __typename
            subTotal
            taxTotal
            tipTotal
            discountTotal
            paymentTotal
            total
            remainingBalance
          }
          items {
            __typename
            items {
              __typename
              id
              venueID
              menuItemID
              name
              price
              quantity
              taxes {
                __typename
                name
                amount
                type
              }
              modifiers {
                __typename
                id
                menuItemID
                name
                price
                quantity
                taxes {
                  __typename
                  name
                  amount
                  type
                }
                modifiers {
                  __typename
                  id
                  menuItemID
                  name
                  price
                  quantity
                  taxes {
                    __typename
                    name
                    amount
                    type
                  }
                  modifiers {
                    __typename
                    id
                    menuItemID
                    name
                    price
                    quantity
                    taxes {
                      __typename
                      name
                      amount
                      type
                    }
                  }
                }
              }
            }
          }
          payments {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          transactions {
            __typename
            items {
              __typename
              id
              checkID
              amount
            }
          }
          tenantID
          orderID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCheckDeepQuery>response.data.getCheck;
  }
  async ListCustomersByEmailCustom(
    email?: string,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCustomersByEmailCustomQuery> {
    const statement = `query ListCustomersByEmailCustom($email: AWSEmail, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCustomersByEmail(
          email: $email
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                id
                tenantID
                orderID
                order {
                  __typename
                  id
                  orderNumber
                  tenantID
                  status
                  dueTime
                  serviceType
                  deliveryProvider
                }
                totals {
                  __typename
                  subTotal
                  taxTotal
                  tipTotal
                  feeTotal
                  discountTotal
                  paymentTotal
                  total
                  remainingBalance
                }
                venueTotals {
                  __typename
                  venueID
                  subTotal
                  taxTotal
                  tipTotal
                  feeTotal
                  discountTotal
                  paymentTotal
                  total
                  remainingBalance
                  checkPercent
                }
              }
            }
            email
            firstName
            id
            lastName
            defaultPaymentMethodID
            paymentMethods {
              __typename
              items {
                __typename
                id
                paymentMethod {
                  __typename
                  token
                  name
                  type
                  cardType
                  cardExpiration
                  zipcode
                  processor
                }
              }
            }
            addresses {
              __typename
              name
              street
              city
              state
              zip
              country
              latitude
              longitude
              email
              phone
              isDefault
              instructions
            }
            phone
            userName
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersByEmailCustomQuery>response.data.listCustomersByEmail;
  }
  async GetMenuItemRecursive(id: string): Promise<GetMenuItemRecursiveQuery> {
    const statement = `query GetMenuItemRecursive($id: ID!) {
        getMenuItem(id: $id) {
          __typename
          id
          conceptID
          venueID
          name
          description
          active
          imageS3
          utensils
          alcohol
          prices {
            __typename
            items {
              __typename
              id
              price
              priceTypeID
              active
            }
          }
          taxes {
            __typename
            items {
              __typename
              taxType {
                __typename
                id
                name
              }
            }
          }
          modifierGroups {
            __typename
            items {
              __typename
              sortOrder
              modifierGroup {
                __typename
                id
                conceptID
                venueID
                name
                displayName
                description
                priceTypeID
                active
                min
                max
                selectionType
                modifiers {
                  __typename
                  items {
                    __typename
                    menuItem {
                      __typename
                      id
                      conceptID
                      venueID
                      name
                      description
                      active
                      imageS3
                      prices {
                        __typename
                        items {
                          __typename
                          id
                          price
                          priceTypeID
                          active
                        }
                      }
                      taxes {
                        __typename
                        items {
                          __typename
                          taxType {
                            __typename
                            id
                            name
                          }
                        }
                      }
                      modifierGroups {
                        __typename
                        items {
                          __typename
                          sortOrder
                          modifierGroup {
                            __typename
                            id
                            conceptID
                            venueID
                            name
                            displayName
                            description
                            priceTypeID
                            active
                            min
                            max
                            selectionType
                            modifiers {
                              __typename
                              items {
                                __typename
                                menuItem {
                                  __typename
                                  id
                                  conceptID
                                  venueID
                                  name
                                  description
                                  active
                                  imageS3
                                  prices {
                                    __typename
                                    items {
                                      __typename
                                      id
                                      price
                                      priceTypeID
                                      active
                                    }
                                  }
                                  taxes {
                                    __typename
                                    items {
                                      __typename
                                      taxType {
                                        __typename
                                        id
                                        name
                                      }
                                    }
                                  }
                                  modifierGroups {
                                    __typename
                                    items {
                                      __typename
                                      sortOrder
                                      modifierGroup {
                                        __typename
                                        id
                                        conceptID
                                        venueID
                                        name
                                        displayName
                                        description
                                        priceTypeID
                                        active
                                        min
                                        max
                                        selectionType
                                        modifiers {
                                          __typename
                                          items {
                                            __typename
                                            menuItem {
                                              __typename
                                              id
                                              conceptID
                                              venueID
                                              name
                                              description
                                              active
                                              imageS3
                                              prices {
                                                __typename
                                                items {
                                                  __typename
                                                  id
                                                  price
                                                  priceTypeID
                                                  active
                                                }
                                              }
                                              taxes {
                                                __typename
                                                items {
                                                  __typename
                                                  taxType {
                                                    __typename
                                                    id
                                                    name
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenuItemRecursiveQuery>response.data.getMenuItem;
  }
  async BumpChit(input: BumpChitInput): Promise<BumpChitMutation> {
    const statement = `mutation BumpChit($input: BumpChitInput!) {
        bumpChit(input: $input) {
          __typename
          id
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BumpChitMutation>response.data.bumpChit;
  }
  async CreateCheck(
    input: CreateCheckInput,
    condition?: ModelCheckConditionInput
  ): Promise<CreateCheckMutation> {
    const statement = `mutation CreateCheck($condition: ModelCheckConditionInput, $input: CreateCheckInput!) {
        createCheck(condition: $condition, input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCheckMutation>response.data.createCheck;
  }
  async CreateCheckItem(
    input: CreateCheckItemInput,
    condition?: ModelCheckItemConditionInput
  ): Promise<CreateCheckItemMutation> {
    const statement = `mutation CreateCheckItem($condition: ModelCheckItemConditionInput, $input: CreateCheckItemInput!) {
        createCheckItem(condition: $condition, input: $input) {
          __typename
          alcohol
          checkID
          chit {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          chitID
          createdAt
          id
          instructions
          menuID
          menuItemID
          modifiers {
            __typename
            id
            menuItemID
            modifierGroupID
            modifiers {
              __typename
              id
              menuItemID
              modifierGroupID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
            }
            name
            price
            quantity
            tags {
              __typename
              id
              name
              type
            }
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            unitPrice
          }
          name
          price
          priceTypeID
          quantity
          tags {
            __typename
            id
            name
            type
          }
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          unitPrice
          updatedAt
          utensils
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCheckItemMutation>response.data.createCheckItem;
  }
  async CreateCheckPayment(
    input: CreateCheckPaymentInput,
    condition?: ModelCheckPaymentConditionInput
  ): Promise<CreateCheckPaymentMutation> {
    const statement = `mutation CreateCheckPayment($condition: ModelCheckPaymentConditionInput, $input: CreateCheckPaymentInput!) {
        createCheckPayment(condition: $condition, input: $input) {
          __typename
          amount
          cardPresent
          checkID
          createdAt
          displayName
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          isAuthed
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          refNo
          status
          tip
          token
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCheckPaymentMutation>response.data.createCheckPayment;
  }
  async CreateCheckTransaction(
    input: CreateCheckTransactionInput,
    condition?: ModelCheckTransactionConditionInput
  ): Promise<CreateCheckTransactionMutation> {
    const statement = `mutation CreateCheckTransaction($condition: ModelCheckTransactionConditionInput, $input: CreateCheckTransactionInput!) {
        createCheckTransaction(condition: $condition, input: $input) {
          __typename
          amount
          amountFees
          amountItems
          amountTax
          amountTip
          authCode
          cardNumber
          cardType
          check {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          checkID
          checkItemIDs
          comp
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          last4
          note
          paymentID
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          processor
          processorCredentialID
          reason
          refNo
          refunds {
            __typename
            amount
            checkItemID
            type
          }
          status
          thirdPartyData
          token
          type
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCheckTransactionMutation>response.data.createCheckTransaction;
  }
  async CreateChit(
    input: CreateChitInput,
    condition?: ModelChitConditionInput
  ): Promise<CreateChitMutation> {
    const statement = `mutation CreateChit($condition: ModelChitConditionInput, $input: CreateChitInput!) {
        createChit(condition: $condition, input: $input) {
          __typename
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          items {
            __typename
            alcohol
            id
            instructions
            modifiers {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            name
            price
            quantity
            status
            utensils
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChitMutation>response.data.createChit;
  }
  async CreateConcept(
    input: CreateConceptInput,
    condition?: ModelConceptConditionInput
  ): Promise<CreateConceptMutation> {
    const statement = `mutation CreateConcept($condition: ModelConceptConditionInput, $input: CreateConceptInput!) {
        createConcept(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConceptMutation>response.data.createConcept;
  }
  async CreateCredential(
    input: CreateCredentialInput,
    condition?: ModelCredentialConditionInput
  ): Promise<CreateCredentialMutation> {
    const statement = `mutation CreateCredential($condition: ModelCredentialConditionInput, $input: CreateCredentialInput!) {
        createCredential(condition: $condition, input: $input) {
          __typename
          createdAt
          dcEcomMID
          dcMID
          dcTokenKey
          id
          key
          secret
          smsFrom
          tenantID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCredentialMutation>response.data.createCredential;
  }
  async CreateCustomer(
    input: CreateCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<CreateCustomerMutation> {
    const statement = `mutation CreateCustomer($condition: ModelCustomerConditionInput, $input: CreateCustomerInput!) {
        createCustomer(condition: $condition, input: $input) {
          __typename
          addresses {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          createdAt
          defaultPaymentMethodID
          email
          firstName
          id
          lastName
          paymentMethods {
            __typename
            items {
              __typename
              createdAt
              customerID
              id
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              updatedAt
            }
            nextToken
          }
          phone
          updatedAt
          userName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerMutation>response.data.createCustomer;
  }
  async CreateCustomerPaymentMethod(
    input: CreateCustomerPaymentMethodInput,
    condition?: ModelCustomerPaymentMethodConditionInput
  ): Promise<CreateCustomerPaymentMethodMutation> {
    const statement = `mutation CreateCustomerPaymentMethod($condition: ModelCustomerPaymentMethodConditionInput, $input: CreateCustomerPaymentMethodInput!) {
        createCustomerPaymentMethod(condition: $condition, input: $input) {
          __typename
          createdAt
          customerID
          id
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerPaymentMethodMutation>(
      response.data.createCustomerPaymentMethod
    );
  }
  async CreateDevice(
    input: CreateDeviceInput,
    condition?: ModelDeviceConditionInput
  ): Promise<CreateDeviceMutation> {
    const statement = `mutation CreateDevice($condition: ModelDeviceConditionInput, $input: CreateDeviceInput!) {
        createDevice(condition: $condition, input: $input) {
          __typename
          active
          autoPrint
          createdAt
          deviceID
          displayName
          host
          id
          ipAddress
          licenseID
          name
          port
          printOnBump
          serialNumber
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDeviceMutation>response.data.createDevice;
  }
  async CreateDeviceTransaction(
    input: CreateDeviceTransactionInput,
    condition?: ModelDeviceTransactionConditionInput
  ): Promise<CreateDeviceTransactionMutation> {
    const statement = `mutation CreateDeviceTransaction($condition: ModelDeviceTransactionConditionInput, $input: CreateDeviceTransactionInput!) {
        createDeviceTransaction(condition: $condition, input: $input) {
          __typename
          createdAt
          data
          deviceID
          event
          expirationUnixTime
          id
          status
          transactionID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDeviceTransactionMutation>(
      response.data.createDeviceTransaction
    );
  }
  async CreateDomain(
    input: CreateDomainInput,
    condition?: ModelDomainConditionInput
  ): Promise<CreateDomainMutation> {
    const statement = `mutation CreateDomain($condition: ModelDomainConditionInput, $input: CreateDomainInput!) {
        createDomain(condition: $condition, input: $input) {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDomainMutation>response.data.createDomain;
  }
  async CreateEmailTemplate(
    input: CreateEmailTemplateInput,
    condition?: ModelEmailTemplateConditionInput
  ): Promise<CreateEmailTemplateMutation> {
    const statement = `mutation CreateEmailTemplate($condition: ModelEmailTemplateConditionInput, $input: CreateEmailTemplateInput!) {
        createEmailTemplate(condition: $condition, input: $input) {
          __typename
          createdAt
          html
          id
          name
          subject
          tenantID
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmailTemplateMutation>response.data.createEmailTemplate;
  }
  async CreateEmployee(
    input: CreateEmployeeInput,
    condition?: ModelEmployeeConditionInput
  ): Promise<CreateEmployeeMutation> {
    const statement = `mutation CreateEmployee($condition: ModelEmployeeConditionInput, $input: CreateEmployeeInput!) {
        createEmployee(condition: $condition, input: $input) {
          __typename
          active
          code
          createdAt
          email
          firstName
          id
          jobs {
            __typename
            items {
              __typename
              createdAt
              employee {
                __typename
                active
                code
                createdAt
                email
                firstName
                id
                lastName
                phone
                tenantID
                updatedAt
              }
              employeeID
              entityType
              id
              payRate
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          lastName
          phone
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmployeeMutation>response.data.createEmployee;
  }
  async CreateFee(
    input: CreateFeeInput,
    condition?: ModelFeeConditionInput
  ): Promise<CreateFeeMutation> {
    const statement = `mutation CreateFee($condition: ModelFeeConditionInput, $input: CreateFeeInput!) {
        createFee(condition: $condition, input: $input) {
          __typename
          amount
          createdAt
          id
          inclusive
          name
          rate
          taxed
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFeeMutation>response.data.createFee;
  }
  async CreateJob(
    input: CreateJobInput,
    condition?: ModelJobConditionInput
  ): Promise<CreateJobMutation> {
    const statement = `mutation CreateJob($condition: ModelJobConditionInput, $input: CreateJobInput!) {
        createJob(condition: $condition, input: $input) {
          __typename
          createdAt
          employee {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          employeeID
          entityType
          id
          payRate
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateJobMutation>response.data.createJob;
  }
  async CreateJobType(
    input: CreateJobTypeInput,
    condition?: ModelJobTypeConditionInput
  ): Promise<CreateJobTypeMutation> {
    const statement = `mutation CreateJobType($condition: ModelJobTypeConditionInput, $input: CreateJobTypeInput!) {
        createJobType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateJobTypeMutation>response.data.createJobType;
  }
  async CreateLicense(
    input: CreateLicenseInput,
    condition?: ModelLicenseConditionInput
  ): Promise<CreateLicenseMutation> {
    const statement = `mutation CreateLicense($condition: ModelLicenseConditionInput, $input: CreateLicenseInput!) {
        createLicense(condition: $condition, input: $input) {
          __typename
          active
          createdAt
          deviceDetails {
            __typename
            deviceID
            displayName
            heartbeat
            initTime
            name
            online
            version
          }
          devices {
            __typename
            items {
              __typename
              active
              autoPrint
              createdAt
              deviceID
              displayName
              host
              id
              ipAddress
              licenseID
              name
              port
              printOnBump
              serialNumber
              type
              updatedAt
            }
            nextToken
          }
          displayName
          guid
          id
          kioskConfig {
            __typename
            orderLinkID
            showSettings
          }
          kitchenConfig {
            __typename
            showSettings
          }
          name
          pollingInterval
          receiptConfig {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          receiptConfigID
          status
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              createdAt
              id
              licenseID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLicenseMutation>response.data.createLicense;
  }
  async CreateLicenseVenue(
    input: CreateLicenseVenueInput,
    condition?: ModelLicenseVenueConditionInput
  ): Promise<CreateLicenseVenueMutation> {
    const statement = `mutation CreateLicenseVenue($condition: ModelLicenseVenueConditionInput, $input: CreateLicenseVenueInput!) {
        createLicenseVenue(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          licenseID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLicenseVenueMutation>response.data.createLicenseVenue;
  }
  async CreateLocation(
    input: CreateLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<CreateLocationMutation> {
    const statement = `mutation CreateLocation($condition: ModelLocationConditionInput, $input: CreateLocationInput!) {
        createLocation(condition: $condition, input: $input) {
          __typename
          city
          country
          createdAt
          email
          endOfDay
          id
          latitude
          longitude
          name
          phone
          state
          street
          tenantID
          timezone
          unit
          updatedAt
          zip
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLocationMutation>response.data.createLocation;
  }
  async CreateLog(
    input: CreateLogInput,
    condition?: ModelLogConditionInput
  ): Promise<CreateLogMutation> {
    const statement = `mutation CreateLog($condition: ModelLogConditionInput, $input: CreateLogInput!) {
        createLog(condition: $condition, input: $input) {
          __typename
          checkID
          createdAt
          employee {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          employeeID
          event
          id
          message
          orderID
          source
          tenantID
          type
          updatedAt
          user {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          userID
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLogMutation>response.data.createLog;
  }
  async CreateMenu(
    input: CreateMenuInput,
    condition?: ModelMenuConditionInput
  ): Promise<CreateMenuMutation> {
    const statement = `mutation CreateMenu($condition: ModelMenuConditionInput, $input: CreateMenuInput!) {
        createMenu(condition: $condition, input: $input) {
          __typename
          active
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          displayName
          facilitators {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          featured
          id
          imageS3Featured
          imageS3Headers
          imageS3HeadersMobile
          imageS3LogoSquare
          name
          orderLinks {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          popular
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuMutation>response.data.createMenu;
  }
  async CreateMenuCategory(
    input: CreateMenuCategoryInput,
    condition?: ModelMenuCategoryConditionInput
  ): Promise<CreateMenuCategoryMutation> {
    const statement = `mutation CreateMenuCategory($condition: ModelMenuCategoryConditionInput, $input: CreateMenuCategoryInput!) {
        createMenuCategory(condition: $condition, input: $input) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategoryID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuCategoryMutation>response.data.createMenuCategory;
  }
  async CreateMenuCategoryMenuItem(
    input: CreateMenuCategoryMenuItemInput,
    condition?: ModelMenuCategoryMenuItemConditionInput
  ): Promise<CreateMenuCategoryMenuItemMutation> {
    const statement = `mutation CreateMenuCategoryMenuItem($condition: ModelMenuCategoryMenuItemConditionInput, $input: CreateMenuCategoryMenuItemInput!) {
        createMenuCategoryMenuItem(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategory {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          menuCategoryID
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuCategoryMenuItemMutation>(
      response.data.createMenuCategoryMenuItem
    );
  }
  async CreateMenuCategoryTag(
    input: CreateMenuCategoryTagInput,
    condition?: ModelMenuCategoryTagConditionInput
  ): Promise<CreateMenuCategoryTagMutation> {
    const statement = `mutation CreateMenuCategoryTag($condition: ModelMenuCategoryTagConditionInput, $input: CreateMenuCategoryTagInput!) {
        createMenuCategoryTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategoryID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuCategoryTagMutation>response.data.createMenuCategoryTag;
  }
  async CreateMenuFacilitator(
    input: CreateMenuFacilitatorInput,
    condition?: ModelMenuFacilitatorConditionInput
  ): Promise<CreateMenuFacilitatorMutation> {
    const statement = `mutation CreateMenuFacilitator($condition: ModelMenuFacilitatorConditionInput, $input: CreateMenuFacilitatorInput!) {
        createMenuFacilitator(condition: $condition, input: $input) {
          __typename
          createdAt
          facilitator {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          facilitatorID
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuFacilitatorMutation>response.data.createMenuFacilitator;
  }
  async CreateMenuItem(
    input: CreateMenuItemInput,
    condition?: ModelMenuItemConditionInput
  ): Promise<CreateMenuItemMutation> {
    const statement = `mutation CreateMenuItem($condition: ModelMenuItemConditionInput, $input: CreateMenuItemInput!) {
        createMenuItem(condition: $condition, input: $input) {
          __typename
          active
          alcohol
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          id
          imageS3
          modifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          options {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              name
              prices {
                __typename
                nextToken
              }
              updatedAt
            }
            nextToken
          }
          parentModifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          popular
          prepTime
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          taxes {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          utensils
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemMutation>response.data.createMenuItem;
  }
  async CreateMenuItemModifierGroup(
    input: CreateMenuItemModifierGroupInput,
    condition?: ModelMenuItemModifierGroupConditionInput
  ): Promise<CreateMenuItemModifierGroupMutation> {
    const statement = `mutation CreateMenuItemModifierGroup($condition: ModelMenuItemModifierGroupConditionInput, $input: CreateMenuItemModifierGroupInput!) {
        createMenuItemModifierGroup(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          modifierGroup {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          modifierGroupID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemModifierGroupMutation>(
      response.data.createMenuItemModifierGroup
    );
  }
  async CreateMenuItemOption(
    input: CreateMenuItemOptionInput,
    condition?: ModelMenuItemOptionConditionInput
  ): Promise<CreateMenuItemOptionMutation> {
    const statement = `mutation CreateMenuItemOption($condition: ModelMenuItemOptionConditionInput, $input: CreateMenuItemOptionInput!) {
        createMenuItemOption(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          name
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemOptionMutation>response.data.createMenuItemOption;
  }
  async CreateMenuItemPrice(
    input: CreateMenuItemPriceInput,
    condition?: ModelMenuItemPriceConditionInput
  ): Promise<CreateMenuItemPriceMutation> {
    const statement = `mutation CreateMenuItemPrice($condition: ModelMenuItemPriceConditionInput, $input: CreateMenuItemPriceInput!) {
        createMenuItemPrice(condition: $condition, input: $input) {
          __typename
          active
          createdAt
          id
          menuItemID
          menuItemOptionID
          price
          priceType {
            __typename
            createdAt
            id
            name
            tenantID
            updatedAt
          }
          priceTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemPriceMutation>response.data.createMenuItemPrice;
  }
  async CreateMenuItemPriceType(
    input: CreateMenuItemPriceTypeInput,
    condition?: ModelMenuItemPriceTypeConditionInput
  ): Promise<CreateMenuItemPriceTypeMutation> {
    const statement = `mutation CreateMenuItemPriceType($condition: ModelMenuItemPriceTypeConditionInput, $input: CreateMenuItemPriceTypeInput!) {
        createMenuItemPriceType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemPriceTypeMutation>(
      response.data.createMenuItemPriceType
    );
  }
  async CreateMenuItemTag(
    input: CreateMenuItemTagInput,
    condition?: ModelMenuItemTagConditionInput
  ): Promise<CreateMenuItemTagMutation> {
    const statement = `mutation CreateMenuItemTag($condition: ModelMenuItemTagConditionInput, $input: CreateMenuItemTagInput!) {
        createMenuItemTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemTagMutation>response.data.createMenuItemTag;
  }
  async CreateMenuItemTaxType(
    input: CreateMenuItemTaxTypeInput,
    condition?: ModelMenuItemTaxTypeConditionInput
  ): Promise<CreateMenuItemTaxTypeMutation> {
    const statement = `mutation CreateMenuItemTaxType($condition: ModelMenuItemTaxTypeConditionInput, $input: CreateMenuItemTaxTypeInput!) {
        createMenuItemTaxType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuItemTaxTypeMutation>response.data.createMenuItemTaxType;
  }
  async CreateMenuMenuCategory(
    input: CreateMenuMenuCategoryInput,
    condition?: ModelMenuMenuCategoryConditionInput
  ): Promise<CreateMenuMenuCategoryMutation> {
    const statement = `mutation CreateMenuMenuCategory($condition: ModelMenuMenuCategoryConditionInput, $input: CreateMenuMenuCategoryInput!) {
        createMenuMenuCategory(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuCategory {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          menuCategoryID
          menuID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuMenuCategoryMutation>response.data.createMenuMenuCategory;
  }
  async CreateMenuSchedule(
    input: CreateMenuScheduleInput,
    condition?: ModelMenuScheduleConditionInput
  ): Promise<CreateMenuScheduleMutation> {
    const statement = `mutation CreateMenuSchedule($condition: ModelMenuScheduleConditionInput, $input: CreateMenuScheduleInput!) {
        createMenuSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuScheduleMutation>response.data.createMenuSchedule;
  }
  async CreateMenuTag(
    input: CreateMenuTagInput,
    condition?: ModelMenuTagConditionInput
  ): Promise<CreateMenuTagMutation> {
    const statement = `mutation CreateMenuTag($condition: ModelMenuTagConditionInput, $input: CreateMenuTagInput!) {
        createMenuTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMenuTagMutation>response.data.createMenuTag;
  }
  async CreateModifierGroup(
    input: CreateModifierGroupInput,
    condition?: ModelModifierGroupConditionInput
  ): Promise<CreateModifierGroupMutation> {
    const statement = `mutation CreateModifierGroup($condition: ModelModifierGroupConditionInput, $input: CreateModifierGroupInput!) {
        createModifierGroup(condition: $condition, input: $input) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          max
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          min
          modifiers {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          selectionType
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              modifierGroupID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateModifierGroupMutation>response.data.createModifierGroup;
  }
  async CreateModifierGroupMenuItem(
    input: CreateModifierGroupMenuItemInput,
    condition?: ModelModifierGroupMenuItemConditionInput
  ): Promise<CreateModifierGroupMenuItemMutation> {
    const statement = `mutation CreateModifierGroupMenuItem($condition: ModelModifierGroupMenuItemConditionInput, $input: CreateModifierGroupMenuItemInput!) {
        createModifierGroupMenuItem(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          modifierGroup {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          modifierGroupID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateModifierGroupMenuItemMutation>(
      response.data.createModifierGroupMenuItem
    );
  }
  async CreateModifierGroupTag(
    input: CreateModifierGroupTagInput,
    condition?: ModelModifierGroupTagConditionInput
  ): Promise<CreateModifierGroupTagMutation> {
    const statement = `mutation CreateModifierGroupTag($condition: ModelModifierGroupTagConditionInput, $input: CreateModifierGroupTagInput!) {
        createModifierGroupTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          modifierGroupID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateModifierGroupTagMutation>response.data.createModifierGroupTag;
  }
  async CreateNotification(
    input: CreateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<CreateNotificationMutation> {
    const statement = `mutation CreateNotification($condition: ModelNotificationConditionInput, $input: CreateNotificationInput!) {
        createNotification(condition: $condition, input: $input) {
          __typename
          createdAt
          emailBCC
          emailCC
          emailFrom
          emailReplyTo
          emailTemplate {
            __typename
            createdAt
            html
            id
            name
            subject
            tenantID
            text
            updatedAt
          }
          emailTemplateData
          emailTemplateID
          emailTo
          id
          message
          name
          smsFrom
          smsTo
          smsToDynamic
          smsToType
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNotificationMutation>response.data.createNotification;
  }
  async CreateOrder(
    input: CreateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<CreateOrderMutation> {
    const statement = `mutation CreateOrder($condition: ModelOrderConditionInput, $input: CreateOrderInput!) {
        createOrder(condition: $condition, input: $input) {
          __typename
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          chits {
            __typename
            items {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          clerkID
          courierCheckin
          courierDetails {
            __typename
            errorCode
            firstName
            id
            lastName
            lastUpdate
            latitude
            longitude
            message
            phone
            pickupTime
            status
            type
          }
          courierPhone
          courierStatus
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          deliveryEstimate
          deliveryID
          deliveryPickupTime
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          diagnostics
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          fascilitatedTenantReportExclusions
          id
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          orderNumber
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          originAddress {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          originDetails {
            __typename
            city
            country
            distance
            email
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          queueMode
          queueTime
          queuedAt
          readyEstimate
          readyTime
          serviceType
          source
          status
          statusDetails
          statusMessage
          submittedAt
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tenantName
          thirdPartyOrderID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          venueStatus {
            __typename
            status
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderMutation>response.data.createOrder;
  }
  async CreateOrderLink(
    input: CreateOrderLinkInput,
    condition?: ModelOrderLinkConditionInput
  ): Promise<CreateOrderLinkMutation> {
    const statement = `mutation CreateOrderLink($condition: ModelOrderLinkConditionInput, $input: CreateOrderLinkInput!) {
        createOrderLink(condition: $condition, input: $input) {
          __typename
          active
          adminEmail
          ccCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          ccCredentialID
          clerkID
          createdAt
          doNotSell
          domain
          exploreHeading
          exploreSubheading
          fascilitatedTenantReportExclusions
          featuredRestarauntHeading
          featuredRestarauntSubheading
          footerURL
          heading
          id
          imageS3EntryHeaders
          imageS3EntryHeadersMobile
          imageS3Footer
          isDefault
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuScollerHeading
          menuScollerSubheading
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          notice
          noticeAtCollection
          notifications {
            __typename
            items {
              __typename
              createdAt
              event
              id
              notification {
                __typename
                createdAt
                emailBCC
                emailCC
                emailFrom
                emailReplyTo
                emailTemplateData
                emailTemplateID
                emailTo
                id
                message
                name
                smsFrom
                smsTo
                smsToDynamic
                smsToType
                tenantID
                type
                updatedAt
              }
              notificationID
              orderLinkID
              updatedAt
            }
            nextToken
          }
          orderMode
          orderTypes {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              updatedAt
            }
            nextToken
          }
          originEmail
          originLinkFacebook
          originLinkIG
          originName
          originPhone
          popularItemsHeading
          popularItemsSubheading
          popularRestarauntHeading
          popularRestarauntSubheading
          privacyPolicy
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          searchPlaceholder
          smsCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          smsCredentialID
          subHeading
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tos
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderLinkMutation>response.data.createOrderLink;
  }
  async CreateOrderLinkMenu(
    input: CreateOrderLinkMenuInput,
    condition?: ModelOrderLinkMenuConditionInput
  ): Promise<CreateOrderLinkMenuMutation> {
    const statement = `mutation CreateOrderLinkMenu($condition: ModelOrderLinkMenuConditionInput, $input: CreateOrderLinkMenuInput!) {
        createOrderLinkMenu(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuID
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderLinkMenuMutation>response.data.createOrderLinkMenu;
  }
  async CreateOrderLinkNotification(
    input: CreateOrderLinkNotificationInput,
    condition?: ModelOrderLinkNotificationConditionInput
  ): Promise<CreateOrderLinkNotificationMutation> {
    const statement = `mutation CreateOrderLinkNotification($condition: ModelOrderLinkNotificationConditionInput, $input: CreateOrderLinkNotificationInput!) {
        createOrderLinkNotification(condition: $condition, input: $input) {
          __typename
          createdAt
          event
          id
          notification {
            __typename
            createdAt
            emailBCC
            emailCC
            emailFrom
            emailReplyTo
            emailTemplate {
              __typename
              createdAt
              html
              id
              name
              subject
              tenantID
              text
              updatedAt
            }
            emailTemplateData
            emailTemplateID
            emailTo
            id
            message
            name
            smsFrom
            smsTo
            smsToDynamic
            smsToType
            tenantID
            type
            updatedAt
          }
          notificationID
          orderLinkID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderLinkNotificationMutation>(
      response.data.createOrderLinkNotification
    );
  }
  async CreateOrderLinkOrderType(
    input: CreateOrderLinkOrderTypeInput,
    condition?: ModelOrderLinkOrderTypeConditionInput
  ): Promise<CreateOrderLinkOrderTypeMutation> {
    const statement = `mutation CreateOrderLinkOrderType($condition: ModelOrderLinkOrderTypeConditionInput, $input: CreateOrderLinkOrderTypeInput!) {
        createOrderLinkOrderType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderLinkID
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderLinkOrderTypeMutation>(
      response.data.createOrderLinkOrderType
    );
  }
  async CreateOrderLinkSchedule(
    input: CreateOrderLinkScheduleInput,
    condition?: ModelOrderLinkScheduleConditionInput
  ): Promise<CreateOrderLinkScheduleMutation> {
    const statement = `mutation CreateOrderLinkSchedule($condition: ModelOrderLinkScheduleConditionInput, $input: CreateOrderLinkScheduleInput!) {
        createOrderLinkSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderLinkID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderLinkScheduleMutation>(
      response.data.createOrderLinkSchedule
    );
  }
  async CreateOrderType(
    input: CreateOrderTypeInput,
    condition?: ModelOrderTypeConditionInput
  ): Promise<CreateOrderTypeMutation> {
    const statement = `mutation CreateOrderType($condition: ModelOrderTypeConditionInput, $input: CreateOrderTypeInput!) {
        createOrderType(condition: $condition, input: $input) {
          __typename
          active
          asap
          createdAt
          deliveryProvider
          displayName
          fees {
            __typename
            items {
              __typename
              createdAt
              fee {
                __typename
                amount
                createdAt
                id
                inclusive
                name
                rate
                taxed
                tenantID
                type
                updatedAt
              }
              feeID
              id
              orderTypeID
              updatedAt
            }
            nextToken
          }
          id
          name
          schedule
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderTypeID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          serviceType
          source
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderTypeMutation>response.data.createOrderType;
  }
  async CreateOrderTypeFee(
    input: CreateOrderTypeFeeInput,
    condition?: ModelOrderTypeFeeConditionInput
  ): Promise<CreateOrderTypeFeeMutation> {
    const statement = `mutation CreateOrderTypeFee($condition: ModelOrderTypeFeeConditionInput, $input: CreateOrderTypeFeeInput!) {
        createOrderTypeFee(condition: $condition, input: $input) {
          __typename
          createdAt
          fee {
            __typename
            amount
            createdAt
            id
            inclusive
            name
            rate
            taxed
            tenantID
            type
            updatedAt
          }
          feeID
          id
          orderTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderTypeFeeMutation>response.data.createOrderTypeFee;
  }
  async CreateOrderTypeSchedule(
    input: CreateOrderTypeScheduleInput,
    condition?: ModelOrderTypeScheduleConditionInput
  ): Promise<CreateOrderTypeScheduleMutation> {
    const statement = `mutation CreateOrderTypeSchedule($condition: ModelOrderTypeScheduleConditionInput, $input: CreateOrderTypeScheduleInput!) {
        createOrderTypeSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderTypeID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderTypeScheduleMutation>(
      response.data.createOrderTypeSchedule
    );
  }
  async CreatePaymentMethod(
    input: CreatePaymentMethodInput,
    condition?: ModelPaymentMethodConditionInput
  ): Promise<CreatePaymentMethodMutation> {
    const statement = `mutation CreatePaymentMethod($condition: ModelPaymentMethodConditionInput, $input: CreatePaymentMethodInput!) {
        createPaymentMethod(condition: $condition, input: $input) {
          __typename
          cardExpiration
          cardPresent
          cardType
          createdAt
          name
          processor
          token
          type
          updatedAt
          zipcode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePaymentMethodMutation>response.data.createPaymentMethod;
  }
  async CreatePrintJob(
    input: CreatePrintJobInput,
    condition?: ModelPrintJobConditionInput
  ): Promise<CreatePrintJobMutation> {
    const statement = `mutation CreatePrintJob($condition: ModelPrintJobConditionInput, $input: CreatePrintJobInput!) {
        createPrintJob(condition: $condition, input: $input) {
          __typename
          createdAt
          data
          expirationUnixTime
          id
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePrintJobMutation>response.data.createPrintJob;
  }
  async CreatePrinter(
    input: CreatePrinterInput,
    condition?: ModelPrinterConditionInput
  ): Promise<CreatePrinterMutation> {
    const statement = `mutation CreatePrinter($condition: ModelPrinterConditionInput, $input: CreatePrinterInput!) {
        createPrinter(condition: $condition, input: $input) {
          __typename
          createdAt
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePrinterMutation>response.data.createPrinter;
  }
  async CreateReceiptConfig(
    input: CreateReceiptConfigInput,
    condition?: ModelReceiptConfigConditionInput
  ): Promise<CreateReceiptConfigMutation> {
    const statement = `mutation CreateReceiptConfig($condition: ModelReceiptConfigConditionInput, $input: CreateReceiptConfigInput!) {
        createReceiptConfig(condition: $condition, input: $input) {
          __typename
          createdAt
          headerSize
          id
          itemSize
          name
          showDiscounts
          showFees
          showPayments
          showPrice
          showQR
          showRemaining
          showSubtotal
          showTaxes
          showTips
          showTransactions
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueSize
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateReceiptConfigMutation>response.data.createReceiptConfig;
  }
  async CreateSMSMessage(
    input: CreateSMSMessageInput,
    condition?: ModelSMSMessageConditionInput
  ): Promise<CreateSMSMessageMutation> {
    const statement = `mutation CreateSMSMessage($condition: ModelSMSMessageConditionInput, $input: CreateSMSMessageInput!) {
        createSMSMessage(condition: $condition, input: $input) {
          __typename
          createdAt
          from
          id
          message
          orderID
          tenantID
          to
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSMSMessageMutation>response.data.createSMSMessage;
  }
  async CreateSchedule(
    input: CreateScheduleInput,
    condition?: ModelScheduleConditionInput
  ): Promise<CreateScheduleMutation> {
    const statement = `mutation CreateSchedule($condition: ModelScheduleConditionInput, $input: CreateScheduleInput!) {
        createSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          endDate
          id
          name
          startDate
          tenantID
          timeRanges {
            __typename
            day
            endTime
            startTime
          }
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateScheduleMutation>response.data.createSchedule;
  }
  async CreateTag(
    input: CreateTagInput,
    condition?: ModelTagConditionInput
  ): Promise<CreateTagMutation> {
    const statement = `mutation CreateTag($condition: ModelTagConditionInput, $input: CreateTagInput!) {
        createTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          imageS3Square
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTagMutation>response.data.createTag;
  }
  async CreateTaxRate(
    input: CreateTaxRateInput,
    condition?: ModelTaxRateConditionInput
  ): Promise<CreateTaxRateMutation> {
    const statement = `mutation CreateTaxRate($condition: ModelTaxRateConditionInput, $input: CreateTaxRateInput!) {
        createTaxRate(condition: $condition, input: $input) {
          __typename
          amount
          createdAt
          id
          rate
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxRateMutation>response.data.createTaxRate;
  }
  async CreateTaxType(
    input: CreateTaxTypeInput,
    condition?: ModelTaxTypeConditionInput
  ): Promise<CreateTaxTypeMutation> {
    const statement = `mutation CreateTaxType($condition: ModelTaxTypeConditionInput, $input: CreateTaxTypeInput!) {
        createTaxType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTaxTypeMutation>response.data.createTaxType;
  }
  async CreateTenant(
    input: CreateTenantInput,
    condition?: ModelTenantConditionInput
  ): Promise<CreateTenantMutation> {
    const statement = `mutation CreateTenant($condition: ModelTenantConditionInput, $input: CreateTenantInput!) {
        createTenant(condition: $condition, input: $input) {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTenantMutation>response.data.createTenant;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($condition: ModelUserConditionInput, $input: CreateUserInput!) {
        createUser(condition: $condition, input: $input) {
          __typename
          createdAt
          email
          firstName
          id
          lastName
          phone
          updatedAt
          userName
          userRoles {
            __typename
            items {
              __typename
              createdAt
              entityType
              id
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              user {
                __typename
                createdAt
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async CreateUserRole(
    input: CreateUserRoleInput,
    condition?: ModelUserRoleConditionInput
  ): Promise<CreateUserRoleMutation> {
    const statement = `mutation CreateUserRole($condition: ModelUserRoleConditionInput, $input: CreateUserRoleInput!) {
        createUserRole(condition: $condition, input: $input) {
          __typename
          createdAt
          entityType
          id
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          user {
            __typename
            createdAt
            email
            firstName
            id
            lastName
            phone
            updatedAt
            userName
            userRoles {
              __typename
              items {
                __typename
                createdAt
                entityType
                id
                tenantID
                typeID
                updatedAt
                userID
                venueID
              }
              nextToken
            }
          }
          userID
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserRoleMutation>response.data.createUserRole;
  }
  async CreateUserRoleType(
    input: CreateUserRoleTypeInput,
    condition?: ModelUserRoleTypeConditionInput
  ): Promise<CreateUserRoleTypeMutation> {
    const statement = `mutation CreateUserRoleType($condition: ModelUserRoleTypeConditionInput, $input: CreateUserRoleTypeInput!) {
        createUserRoleType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserRoleTypeMutation>response.data.createUserRoleType;
  }
  async CreateVenue(
    input: CreateVenueInput,
    condition?: ModelVenueConditionInput
  ): Promise<CreateVenueMutation> {
    const statement = `mutation CreateVenue($condition: ModelVenueConditionInput, $input: CreateVenueInput!) {
        createVenue(condition: $condition, input: $input) {
          __typename
          acceptingOrders
          active
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          id
          imageS3LogoSquare
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
              venueID
            }
            nextToken
          }
          taxRates {
            __typename
            items {
              __typename
              amount
              createdAt
              id
              rate
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVenueMutation>response.data.createVenue;
  }
  async CreateVenueOrder(
    input: CreateVenueOrderInput,
    condition?: ModelVenueOrderConditionInput
  ): Promise<CreateVenueOrderMutation> {
    const statement = `mutation CreateVenueOrder($condition: ModelVenueOrderConditionInput, $input: CreateVenueOrderInput!) {
        createVenueOrder(condition: $condition, input: $input) {
          __typename
          createdAt
          dueTime
          id
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVenueOrderMutation>response.data.createVenueOrder;
  }
  async CreateVenueSchedule(
    input: CreateVenueScheduleInput,
    condition?: ModelVenueScheduleConditionInput
  ): Promise<CreateVenueScheduleMutation> {
    const statement = `mutation CreateVenueSchedule($condition: ModelVenueScheduleConditionInput, $input: CreateVenueScheduleInput!) {
        createVenueSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVenueScheduleMutation>response.data.createVenueSchedule;
  }
  async DcPayAuthOnly(
    input: DCPayAuthOnlyInput
  ): Promise<DcPayAuthOnlyMutation> {
    const statement = `mutation DcPayAuthOnly($input: DCPayAuthOnlyInput!) {
        dcPayAuthOnly(input: $input) {
          __typename
          Account
          AuthCode
          Brand
          RefNo
          Token
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DcPayAuthOnlyMutation>response.data.dcPayAuthOnly;
  }
  async DeleteCheck(
    input: DeleteCheckInput,
    condition?: ModelCheckConditionInput
  ): Promise<DeleteCheckMutation> {
    const statement = `mutation DeleteCheck($condition: ModelCheckConditionInput, $input: DeleteCheckInput!) {
        deleteCheck(condition: $condition, input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCheckMutation>response.data.deleteCheck;
  }
  async DeleteCheckItem(
    input: DeleteCheckItemInput,
    condition?: ModelCheckItemConditionInput
  ): Promise<DeleteCheckItemMutation> {
    const statement = `mutation DeleteCheckItem($condition: ModelCheckItemConditionInput, $input: DeleteCheckItemInput!) {
        deleteCheckItem(condition: $condition, input: $input) {
          __typename
          alcohol
          checkID
          chit {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          chitID
          createdAt
          id
          instructions
          menuID
          menuItemID
          modifiers {
            __typename
            id
            menuItemID
            modifierGroupID
            modifiers {
              __typename
              id
              menuItemID
              modifierGroupID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
            }
            name
            price
            quantity
            tags {
              __typename
              id
              name
              type
            }
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            unitPrice
          }
          name
          price
          priceTypeID
          quantity
          tags {
            __typename
            id
            name
            type
          }
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          unitPrice
          updatedAt
          utensils
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCheckItemMutation>response.data.deleteCheckItem;
  }
  async DeleteCheckPayment(
    input: DeleteCheckPaymentInput,
    condition?: ModelCheckPaymentConditionInput
  ): Promise<DeleteCheckPaymentMutation> {
    const statement = `mutation DeleteCheckPayment($condition: ModelCheckPaymentConditionInput, $input: DeleteCheckPaymentInput!) {
        deleteCheckPayment(condition: $condition, input: $input) {
          __typename
          amount
          cardPresent
          checkID
          createdAt
          displayName
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          isAuthed
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          refNo
          status
          tip
          token
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCheckPaymentMutation>response.data.deleteCheckPayment;
  }
  async DeleteCheckTransaction(
    input: DeleteCheckTransactionInput,
    condition?: ModelCheckTransactionConditionInput
  ): Promise<DeleteCheckTransactionMutation> {
    const statement = `mutation DeleteCheckTransaction($condition: ModelCheckTransactionConditionInput, $input: DeleteCheckTransactionInput!) {
        deleteCheckTransaction(condition: $condition, input: $input) {
          __typename
          amount
          amountFees
          amountItems
          amountTax
          amountTip
          authCode
          cardNumber
          cardType
          check {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          checkID
          checkItemIDs
          comp
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          last4
          note
          paymentID
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          processor
          processorCredentialID
          reason
          refNo
          refunds {
            __typename
            amount
            checkItemID
            type
          }
          status
          thirdPartyData
          token
          type
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCheckTransactionMutation>response.data.deleteCheckTransaction;
  }
  async DeleteChit(
    input: DeleteChitInput,
    condition?: ModelChitConditionInput
  ): Promise<DeleteChitMutation> {
    const statement = `mutation DeleteChit($condition: ModelChitConditionInput, $input: DeleteChitInput!) {
        deleteChit(condition: $condition, input: $input) {
          __typename
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          items {
            __typename
            alcohol
            id
            instructions
            modifiers {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            name
            price
            quantity
            status
            utensils
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChitMutation>response.data.deleteChit;
  }
  async DeleteConcept(
    input: DeleteConceptInput,
    condition?: ModelConceptConditionInput
  ): Promise<DeleteConceptMutation> {
    const statement = `mutation DeleteConcept($condition: ModelConceptConditionInput, $input: DeleteConceptInput!) {
        deleteConcept(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConceptMutation>response.data.deleteConcept;
  }
  async DeleteCredential(
    input: DeleteCredentialInput,
    condition?: ModelCredentialConditionInput
  ): Promise<DeleteCredentialMutation> {
    const statement = `mutation DeleteCredential($condition: ModelCredentialConditionInput, $input: DeleteCredentialInput!) {
        deleteCredential(condition: $condition, input: $input) {
          __typename
          createdAt
          dcEcomMID
          dcMID
          dcTokenKey
          id
          key
          secret
          smsFrom
          tenantID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCredentialMutation>response.data.deleteCredential;
  }
  async DeleteCustomer(
    input: DeleteCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<DeleteCustomerMutation> {
    const statement = `mutation DeleteCustomer($condition: ModelCustomerConditionInput, $input: DeleteCustomerInput!) {
        deleteCustomer(condition: $condition, input: $input) {
          __typename
          addresses {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          createdAt
          defaultPaymentMethodID
          email
          firstName
          id
          lastName
          paymentMethods {
            __typename
            items {
              __typename
              createdAt
              customerID
              id
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              updatedAt
            }
            nextToken
          }
          phone
          updatedAt
          userName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerMutation>response.data.deleteCustomer;
  }
  async DeleteCustomerPaymentMethod(
    input: DeleteCustomerPaymentMethodInput,
    condition?: ModelCustomerPaymentMethodConditionInput
  ): Promise<DeleteCustomerPaymentMethodMutation> {
    const statement = `mutation DeleteCustomerPaymentMethod($condition: ModelCustomerPaymentMethodConditionInput, $input: DeleteCustomerPaymentMethodInput!) {
        deleteCustomerPaymentMethod(condition: $condition, input: $input) {
          __typename
          createdAt
          customerID
          id
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerPaymentMethodMutation>(
      response.data.deleteCustomerPaymentMethod
    );
  }
  async DeleteDevice(
    input: DeleteDeviceInput,
    condition?: ModelDeviceConditionInput
  ): Promise<DeleteDeviceMutation> {
    const statement = `mutation DeleteDevice($condition: ModelDeviceConditionInput, $input: DeleteDeviceInput!) {
        deleteDevice(condition: $condition, input: $input) {
          __typename
          active
          autoPrint
          createdAt
          deviceID
          displayName
          host
          id
          ipAddress
          licenseID
          name
          port
          printOnBump
          serialNumber
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDeviceMutation>response.data.deleteDevice;
  }
  async DeleteDeviceTransaction(
    input: DeleteDeviceTransactionInput,
    condition?: ModelDeviceTransactionConditionInput
  ): Promise<DeleteDeviceTransactionMutation> {
    const statement = `mutation DeleteDeviceTransaction($condition: ModelDeviceTransactionConditionInput, $input: DeleteDeviceTransactionInput!) {
        deleteDeviceTransaction(condition: $condition, input: $input) {
          __typename
          createdAt
          data
          deviceID
          event
          expirationUnixTime
          id
          status
          transactionID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDeviceTransactionMutation>(
      response.data.deleteDeviceTransaction
    );
  }
  async DeleteDomain(
    input: DeleteDomainInput,
    condition?: ModelDomainConditionInput
  ): Promise<DeleteDomainMutation> {
    const statement = `mutation DeleteDomain($condition: ModelDomainConditionInput, $input: DeleteDomainInput!) {
        deleteDomain(condition: $condition, input: $input) {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDomainMutation>response.data.deleteDomain;
  }
  async DeleteEmailTemplate(
    input: DeleteEmailTemplateInput,
    condition?: ModelEmailTemplateConditionInput
  ): Promise<DeleteEmailTemplateMutation> {
    const statement = `mutation DeleteEmailTemplate($condition: ModelEmailTemplateConditionInput, $input: DeleteEmailTemplateInput!) {
        deleteEmailTemplate(condition: $condition, input: $input) {
          __typename
          createdAt
          html
          id
          name
          subject
          tenantID
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmailTemplateMutation>response.data.deleteEmailTemplate;
  }
  async DeleteEmployee(
    input: DeleteEmployeeInput,
    condition?: ModelEmployeeConditionInput
  ): Promise<DeleteEmployeeMutation> {
    const statement = `mutation DeleteEmployee($condition: ModelEmployeeConditionInput, $input: DeleteEmployeeInput!) {
        deleteEmployee(condition: $condition, input: $input) {
          __typename
          active
          code
          createdAt
          email
          firstName
          id
          jobs {
            __typename
            items {
              __typename
              createdAt
              employee {
                __typename
                active
                code
                createdAt
                email
                firstName
                id
                lastName
                phone
                tenantID
                updatedAt
              }
              employeeID
              entityType
              id
              payRate
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          lastName
          phone
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmployeeMutation>response.data.deleteEmployee;
  }
  async DeleteFee(
    input: DeleteFeeInput,
    condition?: ModelFeeConditionInput
  ): Promise<DeleteFeeMutation> {
    const statement = `mutation DeleteFee($condition: ModelFeeConditionInput, $input: DeleteFeeInput!) {
        deleteFee(condition: $condition, input: $input) {
          __typename
          amount
          createdAt
          id
          inclusive
          name
          rate
          taxed
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteFeeMutation>response.data.deleteFee;
  }
  async DeleteJob(
    input: DeleteJobInput,
    condition?: ModelJobConditionInput
  ): Promise<DeleteJobMutation> {
    const statement = `mutation DeleteJob($condition: ModelJobConditionInput, $input: DeleteJobInput!) {
        deleteJob(condition: $condition, input: $input) {
          __typename
          createdAt
          employee {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          employeeID
          entityType
          id
          payRate
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteJobMutation>response.data.deleteJob;
  }
  async DeleteJobType(
    input: DeleteJobTypeInput,
    condition?: ModelJobTypeConditionInput
  ): Promise<DeleteJobTypeMutation> {
    const statement = `mutation DeleteJobType($condition: ModelJobTypeConditionInput, $input: DeleteJobTypeInput!) {
        deleteJobType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteJobTypeMutation>response.data.deleteJobType;
  }
  async DeleteLicense(
    input: DeleteLicenseInput,
    condition?: ModelLicenseConditionInput
  ): Promise<DeleteLicenseMutation> {
    const statement = `mutation DeleteLicense($condition: ModelLicenseConditionInput, $input: DeleteLicenseInput!) {
        deleteLicense(condition: $condition, input: $input) {
          __typename
          active
          createdAt
          deviceDetails {
            __typename
            deviceID
            displayName
            heartbeat
            initTime
            name
            online
            version
          }
          devices {
            __typename
            items {
              __typename
              active
              autoPrint
              createdAt
              deviceID
              displayName
              host
              id
              ipAddress
              licenseID
              name
              port
              printOnBump
              serialNumber
              type
              updatedAt
            }
            nextToken
          }
          displayName
          guid
          id
          kioskConfig {
            __typename
            orderLinkID
            showSettings
          }
          kitchenConfig {
            __typename
            showSettings
          }
          name
          pollingInterval
          receiptConfig {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          receiptConfigID
          status
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              createdAt
              id
              licenseID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLicenseMutation>response.data.deleteLicense;
  }
  async DeleteLicenseVenue(
    input: DeleteLicenseVenueInput,
    condition?: ModelLicenseVenueConditionInput
  ): Promise<DeleteLicenseVenueMutation> {
    const statement = `mutation DeleteLicenseVenue($condition: ModelLicenseVenueConditionInput, $input: DeleteLicenseVenueInput!) {
        deleteLicenseVenue(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          licenseID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLicenseVenueMutation>response.data.deleteLicenseVenue;
  }
  async DeleteLocation(
    input: DeleteLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<DeleteLocationMutation> {
    const statement = `mutation DeleteLocation($condition: ModelLocationConditionInput, $input: DeleteLocationInput!) {
        deleteLocation(condition: $condition, input: $input) {
          __typename
          city
          country
          createdAt
          email
          endOfDay
          id
          latitude
          longitude
          name
          phone
          state
          street
          tenantID
          timezone
          unit
          updatedAt
          zip
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLocationMutation>response.data.deleteLocation;
  }
  async DeleteLog(
    input: DeleteLogInput,
    condition?: ModelLogConditionInput
  ): Promise<DeleteLogMutation> {
    const statement = `mutation DeleteLog($condition: ModelLogConditionInput, $input: DeleteLogInput!) {
        deleteLog(condition: $condition, input: $input) {
          __typename
          checkID
          createdAt
          employee {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          employeeID
          event
          id
          message
          orderID
          source
          tenantID
          type
          updatedAt
          user {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          userID
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLogMutation>response.data.deleteLog;
  }
  async DeleteMenu(
    input: DeleteMenuInput,
    condition?: ModelMenuConditionInput
  ): Promise<DeleteMenuMutation> {
    const statement = `mutation DeleteMenu($condition: ModelMenuConditionInput, $input: DeleteMenuInput!) {
        deleteMenu(condition: $condition, input: $input) {
          __typename
          active
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          displayName
          facilitators {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          featured
          id
          imageS3Featured
          imageS3Headers
          imageS3HeadersMobile
          imageS3LogoSquare
          name
          orderLinks {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          popular
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuMutation>response.data.deleteMenu;
  }
  async DeleteMenuCategory(
    input: DeleteMenuCategoryInput,
    condition?: ModelMenuCategoryConditionInput
  ): Promise<DeleteMenuCategoryMutation> {
    const statement = `mutation DeleteMenuCategory($condition: ModelMenuCategoryConditionInput, $input: DeleteMenuCategoryInput!) {
        deleteMenuCategory(condition: $condition, input: $input) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategoryID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuCategoryMutation>response.data.deleteMenuCategory;
  }
  async DeleteMenuCategoryMenuItem(
    input: DeleteMenuCategoryMenuItemInput,
    condition?: ModelMenuCategoryMenuItemConditionInput
  ): Promise<DeleteMenuCategoryMenuItemMutation> {
    const statement = `mutation DeleteMenuCategoryMenuItem($condition: ModelMenuCategoryMenuItemConditionInput, $input: DeleteMenuCategoryMenuItemInput!) {
        deleteMenuCategoryMenuItem(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategory {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          menuCategoryID
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuCategoryMenuItemMutation>(
      response.data.deleteMenuCategoryMenuItem
    );
  }
  async DeleteMenuCategoryTag(
    input: DeleteMenuCategoryTagInput,
    condition?: ModelMenuCategoryTagConditionInput
  ): Promise<DeleteMenuCategoryTagMutation> {
    const statement = `mutation DeleteMenuCategoryTag($condition: ModelMenuCategoryTagConditionInput, $input: DeleteMenuCategoryTagInput!) {
        deleteMenuCategoryTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategoryID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuCategoryTagMutation>response.data.deleteMenuCategoryTag;
  }
  async DeleteMenuFacilitator(
    input: DeleteMenuFacilitatorInput,
    condition?: ModelMenuFacilitatorConditionInput
  ): Promise<DeleteMenuFacilitatorMutation> {
    const statement = `mutation DeleteMenuFacilitator($condition: ModelMenuFacilitatorConditionInput, $input: DeleteMenuFacilitatorInput!) {
        deleteMenuFacilitator(condition: $condition, input: $input) {
          __typename
          createdAt
          facilitator {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          facilitatorID
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuFacilitatorMutation>response.data.deleteMenuFacilitator;
  }
  async DeleteMenuItem(
    input: DeleteMenuItemInput,
    condition?: ModelMenuItemConditionInput
  ): Promise<DeleteMenuItemMutation> {
    const statement = `mutation DeleteMenuItem($condition: ModelMenuItemConditionInput, $input: DeleteMenuItemInput!) {
        deleteMenuItem(condition: $condition, input: $input) {
          __typename
          active
          alcohol
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          id
          imageS3
          modifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          options {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              name
              prices {
                __typename
                nextToken
              }
              updatedAt
            }
            nextToken
          }
          parentModifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          popular
          prepTime
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          taxes {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          utensils
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemMutation>response.data.deleteMenuItem;
  }
  async DeleteMenuItemModifierGroup(
    input: DeleteMenuItemModifierGroupInput,
    condition?: ModelMenuItemModifierGroupConditionInput
  ): Promise<DeleteMenuItemModifierGroupMutation> {
    const statement = `mutation DeleteMenuItemModifierGroup($condition: ModelMenuItemModifierGroupConditionInput, $input: DeleteMenuItemModifierGroupInput!) {
        deleteMenuItemModifierGroup(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          modifierGroup {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          modifierGroupID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemModifierGroupMutation>(
      response.data.deleteMenuItemModifierGroup
    );
  }
  async DeleteMenuItemOption(
    input: DeleteMenuItemOptionInput,
    condition?: ModelMenuItemOptionConditionInput
  ): Promise<DeleteMenuItemOptionMutation> {
    const statement = `mutation DeleteMenuItemOption($condition: ModelMenuItemOptionConditionInput, $input: DeleteMenuItemOptionInput!) {
        deleteMenuItemOption(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          name
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemOptionMutation>response.data.deleteMenuItemOption;
  }
  async DeleteMenuItemPrice(
    input: DeleteMenuItemPriceInput,
    condition?: ModelMenuItemPriceConditionInput
  ): Promise<DeleteMenuItemPriceMutation> {
    const statement = `mutation DeleteMenuItemPrice($condition: ModelMenuItemPriceConditionInput, $input: DeleteMenuItemPriceInput!) {
        deleteMenuItemPrice(condition: $condition, input: $input) {
          __typename
          active
          createdAt
          id
          menuItemID
          menuItemOptionID
          price
          priceType {
            __typename
            createdAt
            id
            name
            tenantID
            updatedAt
          }
          priceTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemPriceMutation>response.data.deleteMenuItemPrice;
  }
  async DeleteMenuItemPriceType(
    input: DeleteMenuItemPriceTypeInput,
    condition?: ModelMenuItemPriceTypeConditionInput
  ): Promise<DeleteMenuItemPriceTypeMutation> {
    const statement = `mutation DeleteMenuItemPriceType($condition: ModelMenuItemPriceTypeConditionInput, $input: DeleteMenuItemPriceTypeInput!) {
        deleteMenuItemPriceType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemPriceTypeMutation>(
      response.data.deleteMenuItemPriceType
    );
  }
  async DeleteMenuItemTag(
    input: DeleteMenuItemTagInput,
    condition?: ModelMenuItemTagConditionInput
  ): Promise<DeleteMenuItemTagMutation> {
    const statement = `mutation DeleteMenuItemTag($condition: ModelMenuItemTagConditionInput, $input: DeleteMenuItemTagInput!) {
        deleteMenuItemTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemTagMutation>response.data.deleteMenuItemTag;
  }
  async DeleteMenuItemTaxType(
    input: DeleteMenuItemTaxTypeInput,
    condition?: ModelMenuItemTaxTypeConditionInput
  ): Promise<DeleteMenuItemTaxTypeMutation> {
    const statement = `mutation DeleteMenuItemTaxType($condition: ModelMenuItemTaxTypeConditionInput, $input: DeleteMenuItemTaxTypeInput!) {
        deleteMenuItemTaxType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuItemTaxTypeMutation>response.data.deleteMenuItemTaxType;
  }
  async DeleteMenuMenuCategory(
    input: DeleteMenuMenuCategoryInput,
    condition?: ModelMenuMenuCategoryConditionInput
  ): Promise<DeleteMenuMenuCategoryMutation> {
    const statement = `mutation DeleteMenuMenuCategory($condition: ModelMenuMenuCategoryConditionInput, $input: DeleteMenuMenuCategoryInput!) {
        deleteMenuMenuCategory(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuCategory {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          menuCategoryID
          menuID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuMenuCategoryMutation>response.data.deleteMenuMenuCategory;
  }
  async DeleteMenuSchedule(
    input: DeleteMenuScheduleInput,
    condition?: ModelMenuScheduleConditionInput
  ): Promise<DeleteMenuScheduleMutation> {
    const statement = `mutation DeleteMenuSchedule($condition: ModelMenuScheduleConditionInput, $input: DeleteMenuScheduleInput!) {
        deleteMenuSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuScheduleMutation>response.data.deleteMenuSchedule;
  }
  async DeleteMenuTag(
    input: DeleteMenuTagInput,
    condition?: ModelMenuTagConditionInput
  ): Promise<DeleteMenuTagMutation> {
    const statement = `mutation DeleteMenuTag($condition: ModelMenuTagConditionInput, $input: DeleteMenuTagInput!) {
        deleteMenuTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMenuTagMutation>response.data.deleteMenuTag;
  }
  async DeleteModifierGroup(
    input: DeleteModifierGroupInput,
    condition?: ModelModifierGroupConditionInput
  ): Promise<DeleteModifierGroupMutation> {
    const statement = `mutation DeleteModifierGroup($condition: ModelModifierGroupConditionInput, $input: DeleteModifierGroupInput!) {
        deleteModifierGroup(condition: $condition, input: $input) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          max
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          min
          modifiers {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          selectionType
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              modifierGroupID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteModifierGroupMutation>response.data.deleteModifierGroup;
  }
  async DeleteModifierGroupMenuItem(
    input: DeleteModifierGroupMenuItemInput,
    condition?: ModelModifierGroupMenuItemConditionInput
  ): Promise<DeleteModifierGroupMenuItemMutation> {
    const statement = `mutation DeleteModifierGroupMenuItem($condition: ModelModifierGroupMenuItemConditionInput, $input: DeleteModifierGroupMenuItemInput!) {
        deleteModifierGroupMenuItem(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          modifierGroup {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          modifierGroupID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteModifierGroupMenuItemMutation>(
      response.data.deleteModifierGroupMenuItem
    );
  }
  async DeleteModifierGroupTag(
    input: DeleteModifierGroupTagInput,
    condition?: ModelModifierGroupTagConditionInput
  ): Promise<DeleteModifierGroupTagMutation> {
    const statement = `mutation DeleteModifierGroupTag($condition: ModelModifierGroupTagConditionInput, $input: DeleteModifierGroupTagInput!) {
        deleteModifierGroupTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          modifierGroupID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteModifierGroupTagMutation>response.data.deleteModifierGroupTag;
  }
  async DeleteNotification(
    input: DeleteNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<DeleteNotificationMutation> {
    const statement = `mutation DeleteNotification($condition: ModelNotificationConditionInput, $input: DeleteNotificationInput!) {
        deleteNotification(condition: $condition, input: $input) {
          __typename
          createdAt
          emailBCC
          emailCC
          emailFrom
          emailReplyTo
          emailTemplate {
            __typename
            createdAt
            html
            id
            name
            subject
            tenantID
            text
            updatedAt
          }
          emailTemplateData
          emailTemplateID
          emailTo
          id
          message
          name
          smsFrom
          smsTo
          smsToDynamic
          smsToType
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNotificationMutation>response.data.deleteNotification;
  }
  async DeleteOrder(
    input: DeleteOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<DeleteOrderMutation> {
    const statement = `mutation DeleteOrder($condition: ModelOrderConditionInput, $input: DeleteOrderInput!) {
        deleteOrder(condition: $condition, input: $input) {
          __typename
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          chits {
            __typename
            items {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          clerkID
          courierCheckin
          courierDetails {
            __typename
            errorCode
            firstName
            id
            lastName
            lastUpdate
            latitude
            longitude
            message
            phone
            pickupTime
            status
            type
          }
          courierPhone
          courierStatus
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          deliveryEstimate
          deliveryID
          deliveryPickupTime
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          diagnostics
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          fascilitatedTenantReportExclusions
          id
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          orderNumber
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          originAddress {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          originDetails {
            __typename
            city
            country
            distance
            email
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          queueMode
          queueTime
          queuedAt
          readyEstimate
          readyTime
          serviceType
          source
          status
          statusDetails
          statusMessage
          submittedAt
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tenantName
          thirdPartyOrderID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          venueStatus {
            __typename
            status
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderMutation>response.data.deleteOrder;
  }
  async DeleteOrderLink(
    input: DeleteOrderLinkInput,
    condition?: ModelOrderLinkConditionInput
  ): Promise<DeleteOrderLinkMutation> {
    const statement = `mutation DeleteOrderLink($condition: ModelOrderLinkConditionInput, $input: DeleteOrderLinkInput!) {
        deleteOrderLink(condition: $condition, input: $input) {
          __typename
          active
          adminEmail
          ccCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          ccCredentialID
          clerkID
          createdAt
          doNotSell
          domain
          exploreHeading
          exploreSubheading
          fascilitatedTenantReportExclusions
          featuredRestarauntHeading
          featuredRestarauntSubheading
          footerURL
          heading
          id
          imageS3EntryHeaders
          imageS3EntryHeadersMobile
          imageS3Footer
          isDefault
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuScollerHeading
          menuScollerSubheading
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          notice
          noticeAtCollection
          notifications {
            __typename
            items {
              __typename
              createdAt
              event
              id
              notification {
                __typename
                createdAt
                emailBCC
                emailCC
                emailFrom
                emailReplyTo
                emailTemplateData
                emailTemplateID
                emailTo
                id
                message
                name
                smsFrom
                smsTo
                smsToDynamic
                smsToType
                tenantID
                type
                updatedAt
              }
              notificationID
              orderLinkID
              updatedAt
            }
            nextToken
          }
          orderMode
          orderTypes {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              updatedAt
            }
            nextToken
          }
          originEmail
          originLinkFacebook
          originLinkIG
          originName
          originPhone
          popularItemsHeading
          popularItemsSubheading
          popularRestarauntHeading
          popularRestarauntSubheading
          privacyPolicy
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          searchPlaceholder
          smsCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          smsCredentialID
          subHeading
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tos
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderLinkMutation>response.data.deleteOrderLink;
  }
  async DeleteOrderLinkMenu(
    input: DeleteOrderLinkMenuInput,
    condition?: ModelOrderLinkMenuConditionInput
  ): Promise<DeleteOrderLinkMenuMutation> {
    const statement = `mutation DeleteOrderLinkMenu($condition: ModelOrderLinkMenuConditionInput, $input: DeleteOrderLinkMenuInput!) {
        deleteOrderLinkMenu(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuID
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderLinkMenuMutation>response.data.deleteOrderLinkMenu;
  }
  async DeleteOrderLinkNotification(
    input: DeleteOrderLinkNotificationInput,
    condition?: ModelOrderLinkNotificationConditionInput
  ): Promise<DeleteOrderLinkNotificationMutation> {
    const statement = `mutation DeleteOrderLinkNotification($condition: ModelOrderLinkNotificationConditionInput, $input: DeleteOrderLinkNotificationInput!) {
        deleteOrderLinkNotification(condition: $condition, input: $input) {
          __typename
          createdAt
          event
          id
          notification {
            __typename
            createdAt
            emailBCC
            emailCC
            emailFrom
            emailReplyTo
            emailTemplate {
              __typename
              createdAt
              html
              id
              name
              subject
              tenantID
              text
              updatedAt
            }
            emailTemplateData
            emailTemplateID
            emailTo
            id
            message
            name
            smsFrom
            smsTo
            smsToDynamic
            smsToType
            tenantID
            type
            updatedAt
          }
          notificationID
          orderLinkID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderLinkNotificationMutation>(
      response.data.deleteOrderLinkNotification
    );
  }
  async DeleteOrderLinkOrderType(
    input: DeleteOrderLinkOrderTypeInput,
    condition?: ModelOrderLinkOrderTypeConditionInput
  ): Promise<DeleteOrderLinkOrderTypeMutation> {
    const statement = `mutation DeleteOrderLinkOrderType($condition: ModelOrderLinkOrderTypeConditionInput, $input: DeleteOrderLinkOrderTypeInput!) {
        deleteOrderLinkOrderType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderLinkID
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderLinkOrderTypeMutation>(
      response.data.deleteOrderLinkOrderType
    );
  }
  async DeleteOrderLinkSchedule(
    input: DeleteOrderLinkScheduleInput,
    condition?: ModelOrderLinkScheduleConditionInput
  ): Promise<DeleteOrderLinkScheduleMutation> {
    const statement = `mutation DeleteOrderLinkSchedule($condition: ModelOrderLinkScheduleConditionInput, $input: DeleteOrderLinkScheduleInput!) {
        deleteOrderLinkSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderLinkID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderLinkScheduleMutation>(
      response.data.deleteOrderLinkSchedule
    );
  }
  async DeleteOrderType(
    input: DeleteOrderTypeInput,
    condition?: ModelOrderTypeConditionInput
  ): Promise<DeleteOrderTypeMutation> {
    const statement = `mutation DeleteOrderType($condition: ModelOrderTypeConditionInput, $input: DeleteOrderTypeInput!) {
        deleteOrderType(condition: $condition, input: $input) {
          __typename
          active
          asap
          createdAt
          deliveryProvider
          displayName
          fees {
            __typename
            items {
              __typename
              createdAt
              fee {
                __typename
                amount
                createdAt
                id
                inclusive
                name
                rate
                taxed
                tenantID
                type
                updatedAt
              }
              feeID
              id
              orderTypeID
              updatedAt
            }
            nextToken
          }
          id
          name
          schedule
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderTypeID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          serviceType
          source
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderTypeMutation>response.data.deleteOrderType;
  }
  async DeleteOrderTypeFee(
    input: DeleteOrderTypeFeeInput,
    condition?: ModelOrderTypeFeeConditionInput
  ): Promise<DeleteOrderTypeFeeMutation> {
    const statement = `mutation DeleteOrderTypeFee($condition: ModelOrderTypeFeeConditionInput, $input: DeleteOrderTypeFeeInput!) {
        deleteOrderTypeFee(condition: $condition, input: $input) {
          __typename
          createdAt
          fee {
            __typename
            amount
            createdAt
            id
            inclusive
            name
            rate
            taxed
            tenantID
            type
            updatedAt
          }
          feeID
          id
          orderTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderTypeFeeMutation>response.data.deleteOrderTypeFee;
  }
  async DeleteOrderTypeSchedule(
    input: DeleteOrderTypeScheduleInput,
    condition?: ModelOrderTypeScheduleConditionInput
  ): Promise<DeleteOrderTypeScheduleMutation> {
    const statement = `mutation DeleteOrderTypeSchedule($condition: ModelOrderTypeScheduleConditionInput, $input: DeleteOrderTypeScheduleInput!) {
        deleteOrderTypeSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderTypeID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderTypeScheduleMutation>(
      response.data.deleteOrderTypeSchedule
    );
  }
  async DeletePaymentMethod(
    input: DeletePaymentMethodInput,
    condition?: ModelPaymentMethodConditionInput
  ): Promise<DeletePaymentMethodMutation> {
    const statement = `mutation DeletePaymentMethod($condition: ModelPaymentMethodConditionInput, $input: DeletePaymentMethodInput!) {
        deletePaymentMethod(condition: $condition, input: $input) {
          __typename
          cardExpiration
          cardPresent
          cardType
          createdAt
          name
          processor
          token
          type
          updatedAt
          zipcode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePaymentMethodMutation>response.data.deletePaymentMethod;
  }
  async DeletePrintJob(
    input: DeletePrintJobInput,
    condition?: ModelPrintJobConditionInput
  ): Promise<DeletePrintJobMutation> {
    const statement = `mutation DeletePrintJob($condition: ModelPrintJobConditionInput, $input: DeletePrintJobInput!) {
        deletePrintJob(condition: $condition, input: $input) {
          __typename
          createdAt
          data
          expirationUnixTime
          id
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePrintJobMutation>response.data.deletePrintJob;
  }
  async DeletePrinter(
    input: DeletePrinterInput,
    condition?: ModelPrinterConditionInput
  ): Promise<DeletePrinterMutation> {
    const statement = `mutation DeletePrinter($condition: ModelPrinterConditionInput, $input: DeletePrinterInput!) {
        deletePrinter(condition: $condition, input: $input) {
          __typename
          createdAt
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePrinterMutation>response.data.deletePrinter;
  }
  async DeleteReceiptConfig(
    input: DeleteReceiptConfigInput,
    condition?: ModelReceiptConfigConditionInput
  ): Promise<DeleteReceiptConfigMutation> {
    const statement = `mutation DeleteReceiptConfig($condition: ModelReceiptConfigConditionInput, $input: DeleteReceiptConfigInput!) {
        deleteReceiptConfig(condition: $condition, input: $input) {
          __typename
          createdAt
          headerSize
          id
          itemSize
          name
          showDiscounts
          showFees
          showPayments
          showPrice
          showQR
          showRemaining
          showSubtotal
          showTaxes
          showTips
          showTransactions
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueSize
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteReceiptConfigMutation>response.data.deleteReceiptConfig;
  }
  async DeleteSMSMessage(
    input: DeleteSMSMessageInput,
    condition?: ModelSMSMessageConditionInput
  ): Promise<DeleteSMSMessageMutation> {
    const statement = `mutation DeleteSMSMessage($condition: ModelSMSMessageConditionInput, $input: DeleteSMSMessageInput!) {
        deleteSMSMessage(condition: $condition, input: $input) {
          __typename
          createdAt
          from
          id
          message
          orderID
          tenantID
          to
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSMSMessageMutation>response.data.deleteSMSMessage;
  }
  async DeleteSchedule(
    input: DeleteScheduleInput,
    condition?: ModelScheduleConditionInput
  ): Promise<DeleteScheduleMutation> {
    const statement = `mutation DeleteSchedule($condition: ModelScheduleConditionInput, $input: DeleteScheduleInput!) {
        deleteSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          endDate
          id
          name
          startDate
          tenantID
          timeRanges {
            __typename
            day
            endTime
            startTime
          }
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteScheduleMutation>response.data.deleteSchedule;
  }
  async DeleteTag(
    input: DeleteTagInput,
    condition?: ModelTagConditionInput
  ): Promise<DeleteTagMutation> {
    const statement = `mutation DeleteTag($condition: ModelTagConditionInput, $input: DeleteTagInput!) {
        deleteTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          imageS3Square
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTagMutation>response.data.deleteTag;
  }
  async DeleteTaxRate(
    input: DeleteTaxRateInput,
    condition?: ModelTaxRateConditionInput
  ): Promise<DeleteTaxRateMutation> {
    const statement = `mutation DeleteTaxRate($condition: ModelTaxRateConditionInput, $input: DeleteTaxRateInput!) {
        deleteTaxRate(condition: $condition, input: $input) {
          __typename
          amount
          createdAt
          id
          rate
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxRateMutation>response.data.deleteTaxRate;
  }
  async DeleteTaxType(
    input: DeleteTaxTypeInput,
    condition?: ModelTaxTypeConditionInput
  ): Promise<DeleteTaxTypeMutation> {
    const statement = `mutation DeleteTaxType($condition: ModelTaxTypeConditionInput, $input: DeleteTaxTypeInput!) {
        deleteTaxType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTaxTypeMutation>response.data.deleteTaxType;
  }
  async DeleteTenant(
    input: DeleteTenantInput,
    condition?: ModelTenantConditionInput
  ): Promise<DeleteTenantMutation> {
    const statement = `mutation DeleteTenant($condition: ModelTenantConditionInput, $input: DeleteTenantInput!) {
        deleteTenant(condition: $condition, input: $input) {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTenantMutation>response.data.deleteTenant;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($condition: ModelUserConditionInput, $input: DeleteUserInput!) {
        deleteUser(condition: $condition, input: $input) {
          __typename
          createdAt
          email
          firstName
          id
          lastName
          phone
          updatedAt
          userName
          userRoles {
            __typename
            items {
              __typename
              createdAt
              entityType
              id
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              user {
                __typename
                createdAt
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async DeleteUserRole(
    input: DeleteUserRoleInput,
    condition?: ModelUserRoleConditionInput
  ): Promise<DeleteUserRoleMutation> {
    const statement = `mutation DeleteUserRole($condition: ModelUserRoleConditionInput, $input: DeleteUserRoleInput!) {
        deleteUserRole(condition: $condition, input: $input) {
          __typename
          createdAt
          entityType
          id
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          user {
            __typename
            createdAt
            email
            firstName
            id
            lastName
            phone
            updatedAt
            userName
            userRoles {
              __typename
              items {
                __typename
                createdAt
                entityType
                id
                tenantID
                typeID
                updatedAt
                userID
                venueID
              }
              nextToken
            }
          }
          userID
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserRoleMutation>response.data.deleteUserRole;
  }
  async DeleteUserRoleType(
    input: DeleteUserRoleTypeInput,
    condition?: ModelUserRoleTypeConditionInput
  ): Promise<DeleteUserRoleTypeMutation> {
    const statement = `mutation DeleteUserRoleType($condition: ModelUserRoleTypeConditionInput, $input: DeleteUserRoleTypeInput!) {
        deleteUserRoleType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserRoleTypeMutation>response.data.deleteUserRoleType;
  }
  async DeleteVenue(
    input: DeleteVenueInput,
    condition?: ModelVenueConditionInput
  ): Promise<DeleteVenueMutation> {
    const statement = `mutation DeleteVenue($condition: ModelVenueConditionInput, $input: DeleteVenueInput!) {
        deleteVenue(condition: $condition, input: $input) {
          __typename
          acceptingOrders
          active
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          id
          imageS3LogoSquare
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
              venueID
            }
            nextToken
          }
          taxRates {
            __typename
            items {
              __typename
              amount
              createdAt
              id
              rate
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVenueMutation>response.data.deleteVenue;
  }
  async DeleteVenueOrder(
    input: DeleteVenueOrderInput,
    condition?: ModelVenueOrderConditionInput
  ): Promise<DeleteVenueOrderMutation> {
    const statement = `mutation DeleteVenueOrder($condition: ModelVenueOrderConditionInput, $input: DeleteVenueOrderInput!) {
        deleteVenueOrder(condition: $condition, input: $input) {
          __typename
          createdAt
          dueTime
          id
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVenueOrderMutation>response.data.deleteVenueOrder;
  }
  async DeleteVenueSchedule(
    input: DeleteVenueScheduleInput,
    condition?: ModelVenueScheduleConditionInput
  ): Promise<DeleteVenueScheduleMutation> {
    const statement = `mutation DeleteVenueSchedule($condition: ModelVenueScheduleConditionInput, $input: DeleteVenueScheduleInput!) {
        deleteVenueSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVenueScheduleMutation>response.data.deleteVenueSchedule;
  }
  async DeviceTransaction(
    input: DeviceTransactionInput
  ): Promise<DeviceTransactionMutation> {
    const statement = `mutation DeviceTransaction($input: DeviceTransactionInput!) {
        deviceTransaction(input: $input) {
          __typename
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeviceTransactionMutation>response.data.deviceTransaction;
  }
  async GetOrderDeliveryFee(
    input: GetOrderDeliveryFeeInput
  ): Promise<GetOrderDeliveryFeeMutation> {
    const statement = `mutation GetOrderDeliveryFee($input: GetOrderDeliveryFeeInput!) {
        getOrderDeliveryFee(input: $input) {
          __typename
          duration
          fee
          pickupTime
          quoteId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderDeliveryFeeMutation>response.data.getOrderDeliveryFee;
  }
  async I4GoPreAuth(input: I4GoPreAuthInput): Promise<I4GoPreAuthMutation> {
    const statement = `mutation I4GoPreAuth($input: I4GoPreAuthInput!) {
        i4GoPreAuth(input: $input) {
          __typename
          i4go_accessblock
          i4go_countrycode
          i4go_f21e
          i4go_i4m_url
          i4go_response
          i4go_responsecode
          i4go_server
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <I4GoPreAuthMutation>response.data.i4GoPreAuth;
  }
  async PaymentRefund(
    input: PaymentRefundInput
  ): Promise<PaymentRefundMutation> {
    const statement = `mutation PaymentRefund($input: PaymentRefundInput!) {
        paymentRefund(input: $input) {
          __typename
          response
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PaymentRefundMutation>response.data.paymentRefund;
  }
  async RemoveCheckItem(input: IDInput): Promise<RemoveCheckItemMutation> {
    const statement = `mutation RemoveCheckItem($input: IDInput!) {
        removeCheckItem(input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RemoveCheckItemMutation>response.data.removeCheckItem;
  }
  async SaveCheckItem(
    input: SaveCheckItemInput
  ): Promise<SaveCheckItemMutation> {
    const statement = `mutation SaveCheckItem($input: SaveCheckItemInput!) {
        saveCheckItem(input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SaveCheckItemMutation>response.data.saveCheckItem;
  }
  async SesCreateTemplate(input: SESInput): Promise<SesCreateTemplateMutation> {
    const statement = `mutation SesCreateTemplate($input: SESInput!) {
        sesCreateTemplate(input: $input) {
          __typename
          response
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SesCreateTemplateMutation>response.data.sesCreateTemplate;
  }
  async SesDeleteTemplate(input: SESInput): Promise<SesDeleteTemplateMutation> {
    const statement = `mutation SesDeleteTemplate($input: SESInput!) {
        sesDeleteTemplate(input: $input) {
          __typename
          response
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SesDeleteTemplateMutation>response.data.sesDeleteTemplate;
  }
  async SesGetTemplate(input: SESInput): Promise<SesGetTemplateMutation> {
    const statement = `mutation SesGetTemplate($input: SESInput!) {
        sesGetTemplate(input: $input) {
          __typename
          response
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SesGetTemplateMutation>response.data.sesGetTemplate;
  }
  async SesListTemplates(input: SESInput): Promise<SesListTemplatesMutation> {
    const statement = `mutation SesListTemplates($input: SESInput!) {
        sesListTemplates(input: $input) {
          __typename
          response
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SesListTemplatesMutation>response.data.sesListTemplates;
  }
  async SesUpdateTemplate(input: SESInput): Promise<SesUpdateTemplateMutation> {
    const statement = `mutation SesUpdateTemplate($input: SESInput!) {
        sesUpdateTemplate(input: $input) {
          __typename
          response
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SesUpdateTemplateMutation>response.data.sesUpdateTemplate;
  }
  async SubmitOrder(input: SubmitOrderInput): Promise<SubmitOrderMutation> {
    const statement = `mutation SubmitOrder($input: SubmitOrderInput!) {
        submitOrder(input: $input) {
          __typename
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          deliveryEstimate
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          dueTime
          id
          queueTime
          readyEstimate
          readyTime
          serviceType
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SubmitOrderMutation>response.data.submitOrder;
  }
  async UpdateCheck(
    input: UpdateCheckInput,
    condition?: ModelCheckConditionInput
  ): Promise<UpdateCheckMutation> {
    const statement = `mutation UpdateCheck($condition: ModelCheckConditionInput, $input: UpdateCheckInput!) {
        updateCheck(condition: $condition, input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckMutation>response.data.updateCheck;
  }
  async UpdateCheckItem(
    input: UpdateCheckItemInput,
    condition?: ModelCheckItemConditionInput
  ): Promise<UpdateCheckItemMutation> {
    const statement = `mutation UpdateCheckItem($condition: ModelCheckItemConditionInput, $input: UpdateCheckItemInput!) {
        updateCheckItem(condition: $condition, input: $input) {
          __typename
          alcohol
          checkID
          chit {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          chitID
          createdAt
          id
          instructions
          menuID
          menuItemID
          modifiers {
            __typename
            id
            menuItemID
            modifierGroupID
            modifiers {
              __typename
              id
              menuItemID
              modifierGroupID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
            }
            name
            price
            quantity
            tags {
              __typename
              id
              name
              type
            }
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            unitPrice
          }
          name
          price
          priceTypeID
          quantity
          tags {
            __typename
            id
            name
            type
          }
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          unitPrice
          updatedAt
          utensils
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckItemMutation>response.data.updateCheckItem;
  }
  async UpdateCheckItemQuantity(
    input: UpdateCheckItemQuantityInput
  ): Promise<UpdateCheckItemQuantityMutation> {
    const statement = `mutation UpdateCheckItemQuantity($input: UpdateCheckItemQuantityInput!) {
        updateCheckItemQuantity(input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckItemQuantityMutation>(
      response.data.updateCheckItemQuantity
    );
  }
  async UpdateCheckPayment(
    input: UpdateCheckPaymentInput,
    condition?: ModelCheckPaymentConditionInput
  ): Promise<UpdateCheckPaymentMutation> {
    const statement = `mutation UpdateCheckPayment($condition: ModelCheckPaymentConditionInput, $input: UpdateCheckPaymentInput!) {
        updateCheckPayment(condition: $condition, input: $input) {
          __typename
          amount
          cardPresent
          checkID
          createdAt
          displayName
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          isAuthed
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          refNo
          status
          tip
          token
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckPaymentMutation>response.data.updateCheckPayment;
  }
  async UpdateCheckTotals(input: IDInput): Promise<UpdateCheckTotalsMutation> {
    const statement = `mutation UpdateCheckTotals($input: IDInput!) {
        updateCheckTotals(input: $input) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckTotalsMutation>response.data.updateCheckTotals;
  }
  async UpdateCheckTransaction(
    input: UpdateCheckTransactionInput,
    condition?: ModelCheckTransactionConditionInput
  ): Promise<UpdateCheckTransactionMutation> {
    const statement = `mutation UpdateCheckTransaction($condition: ModelCheckTransactionConditionInput, $input: UpdateCheckTransactionInput!) {
        updateCheckTransaction(condition: $condition, input: $input) {
          __typename
          amount
          amountFees
          amountItems
          amountTax
          amountTip
          authCode
          cardNumber
          cardType
          check {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          checkID
          checkItemIDs
          comp
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          last4
          note
          paymentID
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          processor
          processorCredentialID
          reason
          refNo
          refunds {
            __typename
            amount
            checkItemID
            type
          }
          status
          thirdPartyData
          token
          type
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCheckTransactionMutation>response.data.updateCheckTransaction;
  }
  async UpdateChit(
    input: UpdateChitInput,
    condition?: ModelChitConditionInput
  ): Promise<UpdateChitMutation> {
    const statement = `mutation UpdateChit($condition: ModelChitConditionInput, $input: UpdateChitInput!) {
        updateChit(condition: $condition, input: $input) {
          __typename
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          items {
            __typename
            alcohol
            id
            instructions
            modifiers {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            name
            price
            quantity
            status
            utensils
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChitMutation>response.data.updateChit;
  }
  async UpdateConcept(
    input: UpdateConceptInput,
    condition?: ModelConceptConditionInput
  ): Promise<UpdateConceptMutation> {
    const statement = `mutation UpdateConcept($condition: ModelConceptConditionInput, $input: UpdateConceptInput!) {
        updateConcept(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConceptMutation>response.data.updateConcept;
  }
  async UpdateCredential(
    input: UpdateCredentialInput,
    condition?: ModelCredentialConditionInput
  ): Promise<UpdateCredentialMutation> {
    const statement = `mutation UpdateCredential($condition: ModelCredentialConditionInput, $input: UpdateCredentialInput!) {
        updateCredential(condition: $condition, input: $input) {
          __typename
          createdAt
          dcEcomMID
          dcMID
          dcTokenKey
          id
          key
          secret
          smsFrom
          tenantID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCredentialMutation>response.data.updateCredential;
  }
  async UpdateCustomer(
    input: UpdateCustomerInput,
    condition?: ModelCustomerConditionInput
  ): Promise<UpdateCustomerMutation> {
    const statement = `mutation UpdateCustomer($condition: ModelCustomerConditionInput, $input: UpdateCustomerInput!) {
        updateCustomer(condition: $condition, input: $input) {
          __typename
          addresses {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          createdAt
          defaultPaymentMethodID
          email
          firstName
          id
          lastName
          paymentMethods {
            __typename
            items {
              __typename
              createdAt
              customerID
              id
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              updatedAt
            }
            nextToken
          }
          phone
          updatedAt
          userName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerMutation>response.data.updateCustomer;
  }
  async UpdateCustomerPaymentMethod(
    input: UpdateCustomerPaymentMethodInput,
    condition?: ModelCustomerPaymentMethodConditionInput
  ): Promise<UpdateCustomerPaymentMethodMutation> {
    const statement = `mutation UpdateCustomerPaymentMethod($condition: ModelCustomerPaymentMethodConditionInput, $input: UpdateCustomerPaymentMethodInput!) {
        updateCustomerPaymentMethod(condition: $condition, input: $input) {
          __typename
          createdAt
          customerID
          id
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerPaymentMethodMutation>(
      response.data.updateCustomerPaymentMethod
    );
  }
  async UpdateDevice(
    input: UpdateDeviceInput,
    condition?: ModelDeviceConditionInput
  ): Promise<UpdateDeviceMutation> {
    const statement = `mutation UpdateDevice($condition: ModelDeviceConditionInput, $input: UpdateDeviceInput!) {
        updateDevice(condition: $condition, input: $input) {
          __typename
          active
          autoPrint
          createdAt
          deviceID
          displayName
          host
          id
          ipAddress
          licenseID
          name
          port
          printOnBump
          serialNumber
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDeviceMutation>response.data.updateDevice;
  }
  async UpdateDeviceTransaction(
    input: UpdateDeviceTransactionInput,
    condition?: ModelDeviceTransactionConditionInput
  ): Promise<UpdateDeviceTransactionMutation> {
    const statement = `mutation UpdateDeviceTransaction($condition: ModelDeviceTransactionConditionInput, $input: UpdateDeviceTransactionInput!) {
        updateDeviceTransaction(condition: $condition, input: $input) {
          __typename
          createdAt
          data
          deviceID
          event
          expirationUnixTime
          id
          status
          transactionID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDeviceTransactionMutation>(
      response.data.updateDeviceTransaction
    );
  }
  async UpdateDomain(
    input: UpdateDomainInput,
    condition?: ModelDomainConditionInput
  ): Promise<UpdateDomainMutation> {
    const statement = `mutation UpdateDomain($condition: ModelDomainConditionInput, $input: UpdateDomainInput!) {
        updateDomain(condition: $condition, input: $input) {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDomainMutation>response.data.updateDomain;
  }
  async UpdateEmailTemplate(
    input: UpdateEmailTemplateInput,
    condition?: ModelEmailTemplateConditionInput
  ): Promise<UpdateEmailTemplateMutation> {
    const statement = `mutation UpdateEmailTemplate($condition: ModelEmailTemplateConditionInput, $input: UpdateEmailTemplateInput!) {
        updateEmailTemplate(condition: $condition, input: $input) {
          __typename
          createdAt
          html
          id
          name
          subject
          tenantID
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmailTemplateMutation>response.data.updateEmailTemplate;
  }
  async UpdateEmployee(
    input: UpdateEmployeeInput,
    condition?: ModelEmployeeConditionInput
  ): Promise<UpdateEmployeeMutation> {
    const statement = `mutation UpdateEmployee($condition: ModelEmployeeConditionInput, $input: UpdateEmployeeInput!) {
        updateEmployee(condition: $condition, input: $input) {
          __typename
          active
          code
          createdAt
          email
          firstName
          id
          jobs {
            __typename
            items {
              __typename
              createdAt
              employee {
                __typename
                active
                code
                createdAt
                email
                firstName
                id
                lastName
                phone
                tenantID
                updatedAt
              }
              employeeID
              entityType
              id
              payRate
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          lastName
          phone
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmployeeMutation>response.data.updateEmployee;
  }
  async UpdateFee(
    input: UpdateFeeInput,
    condition?: ModelFeeConditionInput
  ): Promise<UpdateFeeMutation> {
    const statement = `mutation UpdateFee($condition: ModelFeeConditionInput, $input: UpdateFeeInput!) {
        updateFee(condition: $condition, input: $input) {
          __typename
          amount
          createdAt
          id
          inclusive
          name
          rate
          taxed
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFeeMutation>response.data.updateFee;
  }
  async UpdateJob(
    input: UpdateJobInput,
    condition?: ModelJobConditionInput
  ): Promise<UpdateJobMutation> {
    const statement = `mutation UpdateJob($condition: ModelJobConditionInput, $input: UpdateJobInput!) {
        updateJob(condition: $condition, input: $input) {
          __typename
          createdAt
          employee {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          employeeID
          entityType
          id
          payRate
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateJobMutation>response.data.updateJob;
  }
  async UpdateJobType(
    input: UpdateJobTypeInput,
    condition?: ModelJobTypeConditionInput
  ): Promise<UpdateJobTypeMutation> {
    const statement = `mutation UpdateJobType($condition: ModelJobTypeConditionInput, $input: UpdateJobTypeInput!) {
        updateJobType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateJobTypeMutation>response.data.updateJobType;
  }
  async UpdateLicense(
    input: UpdateLicenseInput,
    condition?: ModelLicenseConditionInput
  ): Promise<UpdateLicenseMutation> {
    const statement = `mutation UpdateLicense($condition: ModelLicenseConditionInput, $input: UpdateLicenseInput!) {
        updateLicense(condition: $condition, input: $input) {
          __typename
          active
          createdAt
          deviceDetails {
            __typename
            deviceID
            displayName
            heartbeat
            initTime
            name
            online
            version
          }
          devices {
            __typename
            items {
              __typename
              active
              autoPrint
              createdAt
              deviceID
              displayName
              host
              id
              ipAddress
              licenseID
              name
              port
              printOnBump
              serialNumber
              type
              updatedAt
            }
            nextToken
          }
          displayName
          guid
          id
          kioskConfig {
            __typename
            orderLinkID
            showSettings
          }
          kitchenConfig {
            __typename
            showSettings
          }
          name
          pollingInterval
          receiptConfig {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          receiptConfigID
          status
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              createdAt
              id
              licenseID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLicenseMutation>response.data.updateLicense;
  }
  async UpdateLicenseVenue(
    input: UpdateLicenseVenueInput,
    condition?: ModelLicenseVenueConditionInput
  ): Promise<UpdateLicenseVenueMutation> {
    const statement = `mutation UpdateLicenseVenue($condition: ModelLicenseVenueConditionInput, $input: UpdateLicenseVenueInput!) {
        updateLicenseVenue(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          licenseID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLicenseVenueMutation>response.data.updateLicenseVenue;
  }
  async UpdateLocation(
    input: UpdateLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<UpdateLocationMutation> {
    const statement = `mutation UpdateLocation($condition: ModelLocationConditionInput, $input: UpdateLocationInput!) {
        updateLocation(condition: $condition, input: $input) {
          __typename
          city
          country
          createdAt
          email
          endOfDay
          id
          latitude
          longitude
          name
          phone
          state
          street
          tenantID
          timezone
          unit
          updatedAt
          zip
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLocationMutation>response.data.updateLocation;
  }
  async UpdateLog(
    input: UpdateLogInput,
    condition?: ModelLogConditionInput
  ): Promise<UpdateLogMutation> {
    const statement = `mutation UpdateLog($condition: ModelLogConditionInput, $input: UpdateLogInput!) {
        updateLog(condition: $condition, input: $input) {
          __typename
          checkID
          createdAt
          employee {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          employeeID
          event
          id
          message
          orderID
          source
          tenantID
          type
          updatedAt
          user {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          userID
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLogMutation>response.data.updateLog;
  }
  async UpdateMenu(
    input: UpdateMenuInput,
    condition?: ModelMenuConditionInput
  ): Promise<UpdateMenuMutation> {
    const statement = `mutation UpdateMenu($condition: ModelMenuConditionInput, $input: UpdateMenuInput!) {
        updateMenu(condition: $condition, input: $input) {
          __typename
          active
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          displayName
          facilitators {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          featured
          id
          imageS3Featured
          imageS3Headers
          imageS3HeadersMobile
          imageS3LogoSquare
          name
          orderLinks {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          popular
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuMutation>response.data.updateMenu;
  }
  async UpdateMenuCategory(
    input: UpdateMenuCategoryInput,
    condition?: ModelMenuCategoryConditionInput
  ): Promise<UpdateMenuCategoryMutation> {
    const statement = `mutation UpdateMenuCategory($condition: ModelMenuCategoryConditionInput, $input: UpdateMenuCategoryInput!) {
        updateMenuCategory(condition: $condition, input: $input) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategoryID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuCategoryMutation>response.data.updateMenuCategory;
  }
  async UpdateMenuCategoryMenuItem(
    input: UpdateMenuCategoryMenuItemInput,
    condition?: ModelMenuCategoryMenuItemConditionInput
  ): Promise<UpdateMenuCategoryMenuItemMutation> {
    const statement = `mutation UpdateMenuCategoryMenuItem($condition: ModelMenuCategoryMenuItemConditionInput, $input: UpdateMenuCategoryMenuItemInput!) {
        updateMenuCategoryMenuItem(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategory {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          menuCategoryID
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuCategoryMenuItemMutation>(
      response.data.updateMenuCategoryMenuItem
    );
  }
  async UpdateMenuCategoryTag(
    input: UpdateMenuCategoryTagInput,
    condition?: ModelMenuCategoryTagConditionInput
  ): Promise<UpdateMenuCategoryTagMutation> {
    const statement = `mutation UpdateMenuCategoryTag($condition: ModelMenuCategoryTagConditionInput, $input: UpdateMenuCategoryTagInput!) {
        updateMenuCategoryTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuCategoryID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuCategoryTagMutation>response.data.updateMenuCategoryTag;
  }
  async UpdateMenuFacilitator(
    input: UpdateMenuFacilitatorInput,
    condition?: ModelMenuFacilitatorConditionInput
  ): Promise<UpdateMenuFacilitatorMutation> {
    const statement = `mutation UpdateMenuFacilitator($condition: ModelMenuFacilitatorConditionInput, $input: UpdateMenuFacilitatorInput!) {
        updateMenuFacilitator(condition: $condition, input: $input) {
          __typename
          createdAt
          facilitator {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          facilitatorID
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuFacilitatorMutation>response.data.updateMenuFacilitator;
  }
  async UpdateMenuItem(
    input: UpdateMenuItemInput,
    condition?: ModelMenuItemConditionInput
  ): Promise<UpdateMenuItemMutation> {
    const statement = `mutation UpdateMenuItem($condition: ModelMenuItemConditionInput, $input: UpdateMenuItemInput!) {
        updateMenuItem(condition: $condition, input: $input) {
          __typename
          active
          alcohol
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          id
          imageS3
          modifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          options {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              name
              prices {
                __typename
                nextToken
              }
              updatedAt
            }
            nextToken
          }
          parentModifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          popular
          prepTime
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          taxes {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          utensils
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemMutation>response.data.updateMenuItem;
  }
  async UpdateMenuItemModifierGroup(
    input: UpdateMenuItemModifierGroupInput,
    condition?: ModelMenuItemModifierGroupConditionInput
  ): Promise<UpdateMenuItemModifierGroupMutation> {
    const statement = `mutation UpdateMenuItemModifierGroup($condition: ModelMenuItemModifierGroupConditionInput, $input: UpdateMenuItemModifierGroupInput!) {
        updateMenuItemModifierGroup(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          modifierGroup {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          modifierGroupID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemModifierGroupMutation>(
      response.data.updateMenuItemModifierGroup
    );
  }
  async UpdateMenuItemOption(
    input: UpdateMenuItemOptionInput,
    condition?: ModelMenuItemOptionConditionInput
  ): Promise<UpdateMenuItemOptionMutation> {
    const statement = `mutation UpdateMenuItemOption($condition: ModelMenuItemOptionConditionInput, $input: UpdateMenuItemOptionInput!) {
        updateMenuItemOption(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          name
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemOptionMutation>response.data.updateMenuItemOption;
  }
  async UpdateMenuItemPrice(
    input: UpdateMenuItemPriceInput,
    condition?: ModelMenuItemPriceConditionInput
  ): Promise<UpdateMenuItemPriceMutation> {
    const statement = `mutation UpdateMenuItemPrice($condition: ModelMenuItemPriceConditionInput, $input: UpdateMenuItemPriceInput!) {
        updateMenuItemPrice(condition: $condition, input: $input) {
          __typename
          active
          createdAt
          id
          menuItemID
          menuItemOptionID
          price
          priceType {
            __typename
            createdAt
            id
            name
            tenantID
            updatedAt
          }
          priceTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemPriceMutation>response.data.updateMenuItemPrice;
  }
  async UpdateMenuItemPriceType(
    input: UpdateMenuItemPriceTypeInput,
    condition?: ModelMenuItemPriceTypeConditionInput
  ): Promise<UpdateMenuItemPriceTypeMutation> {
    const statement = `mutation UpdateMenuItemPriceType($condition: ModelMenuItemPriceTypeConditionInput, $input: UpdateMenuItemPriceTypeInput!) {
        updateMenuItemPriceType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemPriceTypeMutation>(
      response.data.updateMenuItemPriceType
    );
  }
  async UpdateMenuItemTag(
    input: UpdateMenuItemTagInput,
    condition?: ModelMenuItemTagConditionInput
  ): Promise<UpdateMenuItemTagMutation> {
    const statement = `mutation UpdateMenuItemTag($condition: ModelMenuItemTagConditionInput, $input: UpdateMenuItemTagInput!) {
        updateMenuItemTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemTagMutation>response.data.updateMenuItemTag;
  }
  async UpdateMenuItemTaxType(
    input: UpdateMenuItemTaxTypeInput,
    condition?: ModelMenuItemTaxTypeConditionInput
  ): Promise<UpdateMenuItemTaxTypeMutation> {
    const statement = `mutation UpdateMenuItemTaxType($condition: ModelMenuItemTaxTypeConditionInput, $input: UpdateMenuItemTaxTypeInput!) {
        updateMenuItemTaxType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItemID
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuItemTaxTypeMutation>response.data.updateMenuItemTaxType;
  }
  async UpdateMenuMenuCategory(
    input: UpdateMenuMenuCategoryInput,
    condition?: ModelMenuMenuCategoryConditionInput
  ): Promise<UpdateMenuMenuCategoryMutation> {
    const statement = `mutation UpdateMenuMenuCategory($condition: ModelMenuMenuCategoryConditionInput, $input: UpdateMenuMenuCategoryInput!) {
        updateMenuMenuCategory(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuCategory {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          menuCategoryID
          menuID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuMenuCategoryMutation>response.data.updateMenuMenuCategory;
  }
  async UpdateMenuSchedule(
    input: UpdateMenuScheduleInput,
    condition?: ModelMenuScheduleConditionInput
  ): Promise<UpdateMenuScheduleMutation> {
    const statement = `mutation UpdateMenuSchedule($condition: ModelMenuScheduleConditionInput, $input: UpdateMenuScheduleInput!) {
        updateMenuSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuScheduleMutation>response.data.updateMenuSchedule;
  }
  async UpdateMenuTag(
    input: UpdateMenuTagInput,
    condition?: ModelMenuTagConditionInput
  ): Promise<UpdateMenuTagMutation> {
    const statement = `mutation UpdateMenuTag($condition: ModelMenuTagConditionInput, $input: UpdateMenuTagInput!) {
        updateMenuTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMenuTagMutation>response.data.updateMenuTag;
  }
  async UpdateModifierGroup(
    input: UpdateModifierGroupInput,
    condition?: ModelModifierGroupConditionInput
  ): Promise<UpdateModifierGroupMutation> {
    const statement = `mutation UpdateModifierGroup($condition: ModelModifierGroupConditionInput, $input: UpdateModifierGroupInput!) {
        updateModifierGroup(condition: $condition, input: $input) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          max
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          min
          modifiers {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          selectionType
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              modifierGroupID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateModifierGroupMutation>response.data.updateModifierGroup;
  }
  async UpdateModifierGroupMenuItem(
    input: UpdateModifierGroupMenuItemInput,
    condition?: ModelModifierGroupMenuItemConditionInput
  ): Promise<UpdateModifierGroupMenuItemMutation> {
    const statement = `mutation UpdateModifierGroupMenuItem($condition: ModelModifierGroupMenuItemConditionInput, $input: UpdateModifierGroupMenuItemInput!) {
        updateModifierGroupMenuItem(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menuItem {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          menuItemID
          modifierGroup {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          modifierGroupID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateModifierGroupMenuItemMutation>(
      response.data.updateModifierGroupMenuItem
    );
  }
  async UpdateModifierGroupTag(
    input: UpdateModifierGroupTagInput,
    condition?: ModelModifierGroupTagConditionInput
  ): Promise<UpdateModifierGroupTagMutation> {
    const statement = `mutation UpdateModifierGroupTag($condition: ModelModifierGroupTagConditionInput, $input: UpdateModifierGroupTagInput!) {
        updateModifierGroupTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          modifierGroupID
          tag {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          tagID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateModifierGroupTagMutation>response.data.updateModifierGroupTag;
  }
  async UpdateNotification(
    input: UpdateNotificationInput,
    condition?: ModelNotificationConditionInput
  ): Promise<UpdateNotificationMutation> {
    const statement = `mutation UpdateNotification($condition: ModelNotificationConditionInput, $input: UpdateNotificationInput!) {
        updateNotification(condition: $condition, input: $input) {
          __typename
          createdAt
          emailBCC
          emailCC
          emailFrom
          emailReplyTo
          emailTemplate {
            __typename
            createdAt
            html
            id
            name
            subject
            tenantID
            text
            updatedAt
          }
          emailTemplateData
          emailTemplateID
          emailTo
          id
          message
          name
          smsFrom
          smsTo
          smsToDynamic
          smsToType
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNotificationMutation>response.data.updateNotification;
  }
  async UpdateOrder(
    input: UpdateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<UpdateOrderMutation> {
    const statement = `mutation UpdateOrder($condition: ModelOrderConditionInput, $input: UpdateOrderInput!) {
        updateOrder(condition: $condition, input: $input) {
          __typename
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          chits {
            __typename
            items {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          clerkID
          courierCheckin
          courierDetails {
            __typename
            errorCode
            firstName
            id
            lastName
            lastUpdate
            latitude
            longitude
            message
            phone
            pickupTime
            status
            type
          }
          courierPhone
          courierStatus
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          deliveryEstimate
          deliveryID
          deliveryPickupTime
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          diagnostics
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          fascilitatedTenantReportExclusions
          id
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          orderNumber
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          originAddress {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          originDetails {
            __typename
            city
            country
            distance
            email
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          queueMode
          queueTime
          queuedAt
          readyEstimate
          readyTime
          serviceType
          source
          status
          statusDetails
          statusMessage
          submittedAt
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tenantName
          thirdPartyOrderID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          venueStatus {
            __typename
            status
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderMutation>response.data.updateOrder;
  }
  async UpdateOrderLink(
    input: UpdateOrderLinkInput,
    condition?: ModelOrderLinkConditionInput
  ): Promise<UpdateOrderLinkMutation> {
    const statement = `mutation UpdateOrderLink($condition: ModelOrderLinkConditionInput, $input: UpdateOrderLinkInput!) {
        updateOrderLink(condition: $condition, input: $input) {
          __typename
          active
          adminEmail
          ccCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          ccCredentialID
          clerkID
          createdAt
          doNotSell
          domain
          exploreHeading
          exploreSubheading
          fascilitatedTenantReportExclusions
          featuredRestarauntHeading
          featuredRestarauntSubheading
          footerURL
          heading
          id
          imageS3EntryHeaders
          imageS3EntryHeadersMobile
          imageS3Footer
          isDefault
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuScollerHeading
          menuScollerSubheading
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          notice
          noticeAtCollection
          notifications {
            __typename
            items {
              __typename
              createdAt
              event
              id
              notification {
                __typename
                createdAt
                emailBCC
                emailCC
                emailFrom
                emailReplyTo
                emailTemplateData
                emailTemplateID
                emailTo
                id
                message
                name
                smsFrom
                smsTo
                smsToDynamic
                smsToType
                tenantID
                type
                updatedAt
              }
              notificationID
              orderLinkID
              updatedAt
            }
            nextToken
          }
          orderMode
          orderTypes {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              updatedAt
            }
            nextToken
          }
          originEmail
          originLinkFacebook
          originLinkIG
          originName
          originPhone
          popularItemsHeading
          popularItemsSubheading
          popularRestarauntHeading
          popularRestarauntSubheading
          privacyPolicy
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          searchPlaceholder
          smsCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          smsCredentialID
          subHeading
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tos
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderLinkMutation>response.data.updateOrderLink;
  }
  async UpdateOrderLinkMenu(
    input: UpdateOrderLinkMenuInput,
    condition?: ModelOrderLinkMenuConditionInput
  ): Promise<UpdateOrderLinkMenuMutation> {
    const statement = `mutation UpdateOrderLinkMenu($condition: ModelOrderLinkMenuConditionInput, $input: UpdateOrderLinkMenuInput!) {
        updateOrderLinkMenu(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          menu {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          menuID
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          sortOrder
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderLinkMenuMutation>response.data.updateOrderLinkMenu;
  }
  async UpdateOrderLinkNotification(
    input: UpdateOrderLinkNotificationInput,
    condition?: ModelOrderLinkNotificationConditionInput
  ): Promise<UpdateOrderLinkNotificationMutation> {
    const statement = `mutation UpdateOrderLinkNotification($condition: ModelOrderLinkNotificationConditionInput, $input: UpdateOrderLinkNotificationInput!) {
        updateOrderLinkNotification(condition: $condition, input: $input) {
          __typename
          createdAt
          event
          id
          notification {
            __typename
            createdAt
            emailBCC
            emailCC
            emailFrom
            emailReplyTo
            emailTemplate {
              __typename
              createdAt
              html
              id
              name
              subject
              tenantID
              text
              updatedAt
            }
            emailTemplateData
            emailTemplateID
            emailTo
            id
            message
            name
            smsFrom
            smsTo
            smsToDynamic
            smsToType
            tenantID
            type
            updatedAt
          }
          notificationID
          orderLinkID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderLinkNotificationMutation>(
      response.data.updateOrderLinkNotification
    );
  }
  async UpdateOrderLinkOrderType(
    input: UpdateOrderLinkOrderTypeInput,
    condition?: ModelOrderLinkOrderTypeConditionInput
  ): Promise<UpdateOrderLinkOrderTypeMutation> {
    const statement = `mutation UpdateOrderLinkOrderType($condition: ModelOrderLinkOrderTypeConditionInput, $input: UpdateOrderLinkOrderTypeInput!) {
        updateOrderLinkOrderType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderLinkID
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderLinkOrderTypeMutation>(
      response.data.updateOrderLinkOrderType
    );
  }
  async UpdateOrderLinkSchedule(
    input: UpdateOrderLinkScheduleInput,
    condition?: ModelOrderLinkScheduleConditionInput
  ): Promise<UpdateOrderLinkScheduleMutation> {
    const statement = `mutation UpdateOrderLinkSchedule($condition: ModelOrderLinkScheduleConditionInput, $input: UpdateOrderLinkScheduleInput!) {
        updateOrderLinkSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderLinkID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderLinkScheduleMutation>(
      response.data.updateOrderLinkSchedule
    );
  }
  async UpdateOrderOrderType(
    input: UpdateOrderOrderTypeInput
  ): Promise<UpdateOrderOrderTypeMutation> {
    const statement = `mutation UpdateOrderOrderType($input: UpdateOrderOrderTypeInput!) {
        updateOrderOrderType(input: $input) {
          __typename
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          chits {
            __typename
            items {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          clerkID
          courierCheckin
          courierDetails {
            __typename
            errorCode
            firstName
            id
            lastName
            lastUpdate
            latitude
            longitude
            message
            phone
            pickupTime
            status
            type
          }
          courierPhone
          courierStatus
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          deliveryEstimate
          deliveryID
          deliveryPickupTime
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          diagnostics
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          fascilitatedTenantReportExclusions
          id
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          orderNumber
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          originAddress {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          originDetails {
            __typename
            city
            country
            distance
            email
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          queueMode
          queueTime
          queuedAt
          readyEstimate
          readyTime
          serviceType
          source
          status
          statusDetails
          statusMessage
          submittedAt
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tenantName
          thirdPartyOrderID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          venueStatus {
            __typename
            status
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderOrderTypeMutation>response.data.updateOrderOrderType;
  }
  async UpdateOrderType(
    input: UpdateOrderTypeInput,
    condition?: ModelOrderTypeConditionInput
  ): Promise<UpdateOrderTypeMutation> {
    const statement = `mutation UpdateOrderType($condition: ModelOrderTypeConditionInput, $input: UpdateOrderTypeInput!) {
        updateOrderType(condition: $condition, input: $input) {
          __typename
          active
          asap
          createdAt
          deliveryProvider
          displayName
          fees {
            __typename
            items {
              __typename
              createdAt
              fee {
                __typename
                amount
                createdAt
                id
                inclusive
                name
                rate
                taxed
                tenantID
                type
                updatedAt
              }
              feeID
              id
              orderTypeID
              updatedAt
            }
            nextToken
          }
          id
          name
          schedule
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderTypeID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          serviceType
          source
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderTypeMutation>response.data.updateOrderType;
  }
  async UpdateOrderTypeFee(
    input: UpdateOrderTypeFeeInput,
    condition?: ModelOrderTypeFeeConditionInput
  ): Promise<UpdateOrderTypeFeeMutation> {
    const statement = `mutation UpdateOrderTypeFee($condition: ModelOrderTypeFeeConditionInput, $input: UpdateOrderTypeFeeInput!) {
        updateOrderTypeFee(condition: $condition, input: $input) {
          __typename
          createdAt
          fee {
            __typename
            amount
            createdAt
            id
            inclusive
            name
            rate
            taxed
            tenantID
            type
            updatedAt
          }
          feeID
          id
          orderTypeID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderTypeFeeMutation>response.data.updateOrderTypeFee;
  }
  async UpdateOrderTypeSchedule(
    input: UpdateOrderTypeScheduleInput,
    condition?: ModelOrderTypeScheduleConditionInput
  ): Promise<UpdateOrderTypeScheduleMutation> {
    const statement = `mutation UpdateOrderTypeSchedule($condition: ModelOrderTypeScheduleConditionInput, $input: UpdateOrderTypeScheduleInput!) {
        updateOrderTypeSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          orderTypeID
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderTypeScheduleMutation>(
      response.data.updateOrderTypeSchedule
    );
  }
  async UpdatePaymentMethod(
    input: UpdatePaymentMethodInput,
    condition?: ModelPaymentMethodConditionInput
  ): Promise<UpdatePaymentMethodMutation> {
    const statement = `mutation UpdatePaymentMethod($condition: ModelPaymentMethodConditionInput, $input: UpdatePaymentMethodInput!) {
        updatePaymentMethod(condition: $condition, input: $input) {
          __typename
          cardExpiration
          cardPresent
          cardType
          createdAt
          name
          processor
          token
          type
          updatedAt
          zipcode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePaymentMethodMutation>response.data.updatePaymentMethod;
  }
  async UpdatePrintJob(
    input: UpdatePrintJobInput,
    condition?: ModelPrintJobConditionInput
  ): Promise<UpdatePrintJobMutation> {
    const statement = `mutation UpdatePrintJob($condition: ModelPrintJobConditionInput, $input: UpdatePrintJobInput!) {
        updatePrintJob(condition: $condition, input: $input) {
          __typename
          createdAt
          data
          expirationUnixTime
          id
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePrintJobMutation>response.data.updatePrintJob;
  }
  async UpdatePrinter(
    input: UpdatePrinterInput,
    condition?: ModelPrinterConditionInput
  ): Promise<UpdatePrinterMutation> {
    const statement = `mutation UpdatePrinter($condition: ModelPrinterConditionInput, $input: UpdatePrinterInput!) {
        updatePrinter(condition: $condition, input: $input) {
          __typename
          createdAt
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePrinterMutation>response.data.updatePrinter;
  }
  async UpdateReceiptConfig(
    input: UpdateReceiptConfigInput,
    condition?: ModelReceiptConfigConditionInput
  ): Promise<UpdateReceiptConfigMutation> {
    const statement = `mutation UpdateReceiptConfig($condition: ModelReceiptConfigConditionInput, $input: UpdateReceiptConfigInput!) {
        updateReceiptConfig(condition: $condition, input: $input) {
          __typename
          createdAt
          headerSize
          id
          itemSize
          name
          showDiscounts
          showFees
          showPayments
          showPrice
          showQR
          showRemaining
          showSubtotal
          showTaxes
          showTips
          showTransactions
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueSize
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateReceiptConfigMutation>response.data.updateReceiptConfig;
  }
  async UpdateSMSMessage(
    input: UpdateSMSMessageInput,
    condition?: ModelSMSMessageConditionInput
  ): Promise<UpdateSMSMessageMutation> {
    const statement = `mutation UpdateSMSMessage($condition: ModelSMSMessageConditionInput, $input: UpdateSMSMessageInput!) {
        updateSMSMessage(condition: $condition, input: $input) {
          __typename
          createdAt
          from
          id
          message
          orderID
          tenantID
          to
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSMSMessageMutation>response.data.updateSMSMessage;
  }
  async UpdateSchedule(
    input: UpdateScheduleInput,
    condition?: ModelScheduleConditionInput
  ): Promise<UpdateScheduleMutation> {
    const statement = `mutation UpdateSchedule($condition: ModelScheduleConditionInput, $input: UpdateScheduleInput!) {
        updateSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          endDate
          id
          name
          startDate
          tenantID
          timeRanges {
            __typename
            day
            endTime
            startTime
          }
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateScheduleMutation>response.data.updateSchedule;
  }
  async UpdateTag(
    input: UpdateTagInput,
    condition?: ModelTagConditionInput
  ): Promise<UpdateTagMutation> {
    const statement = `mutation UpdateTag($condition: ModelTagConditionInput, $input: UpdateTagInput!) {
        updateTag(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          imageS3Square
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTagMutation>response.data.updateTag;
  }
  async UpdateTaxRate(
    input: UpdateTaxRateInput,
    condition?: ModelTaxRateConditionInput
  ): Promise<UpdateTaxRateMutation> {
    const statement = `mutation UpdateTaxRate($condition: ModelTaxRateConditionInput, $input: UpdateTaxRateInput!) {
        updateTaxRate(condition: $condition, input: $input) {
          __typename
          amount
          createdAt
          id
          rate
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxRateMutation>response.data.updateTaxRate;
  }
  async UpdateTaxType(
    input: UpdateTaxTypeInput,
    condition?: ModelTaxTypeConditionInput
  ): Promise<UpdateTaxTypeMutation> {
    const statement = `mutation UpdateTaxType($condition: ModelTaxTypeConditionInput, $input: UpdateTaxTypeInput!) {
        updateTaxType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTaxTypeMutation>response.data.updateTaxType;
  }
  async UpdateTenant(
    input: UpdateTenantInput,
    condition?: ModelTenantConditionInput
  ): Promise<UpdateTenantMutation> {
    const statement = `mutation UpdateTenant($condition: ModelTenantConditionInput, $input: UpdateTenantInput!) {
        updateTenant(condition: $condition, input: $input) {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTenantMutation>response.data.updateTenant;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($condition: ModelUserConditionInput, $input: UpdateUserInput!) {
        updateUser(condition: $condition, input: $input) {
          __typename
          createdAt
          email
          firstName
          id
          lastName
          phone
          updatedAt
          userName
          userRoles {
            __typename
            items {
              __typename
              createdAt
              entityType
              id
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              user {
                __typename
                createdAt
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async UpdateUserRole(
    input: UpdateUserRoleInput,
    condition?: ModelUserRoleConditionInput
  ): Promise<UpdateUserRoleMutation> {
    const statement = `mutation UpdateUserRole($condition: ModelUserRoleConditionInput, $input: UpdateUserRoleInput!) {
        updateUserRole(condition: $condition, input: $input) {
          __typename
          createdAt
          entityType
          id
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          user {
            __typename
            createdAt
            email
            firstName
            id
            lastName
            phone
            updatedAt
            userName
            userRoles {
              __typename
              items {
                __typename
                createdAt
                entityType
                id
                tenantID
                typeID
                updatedAt
                userID
                venueID
              }
              nextToken
            }
          }
          userID
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserRoleMutation>response.data.updateUserRole;
  }
  async UpdateUserRoleType(
    input: UpdateUserRoleTypeInput,
    condition?: ModelUserRoleTypeConditionInput
  ): Promise<UpdateUserRoleTypeMutation> {
    const statement = `mutation UpdateUserRoleType($condition: ModelUserRoleTypeConditionInput, $input: UpdateUserRoleTypeInput!) {
        updateUserRoleType(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserRoleTypeMutation>response.data.updateUserRoleType;
  }
  async UpdateVenue(
    input: UpdateVenueInput,
    condition?: ModelVenueConditionInput
  ): Promise<UpdateVenueMutation> {
    const statement = `mutation UpdateVenue($condition: ModelVenueConditionInput, $input: UpdateVenueInput!) {
        updateVenue(condition: $condition, input: $input) {
          __typename
          acceptingOrders
          active
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          id
          imageS3LogoSquare
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
              venueID
            }
            nextToken
          }
          taxRates {
            __typename
            items {
              __typename
              amount
              createdAt
              id
              rate
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVenueMutation>response.data.updateVenue;
  }
  async UpdateVenueOrder(
    input: UpdateVenueOrderInput,
    condition?: ModelVenueOrderConditionInput
  ): Promise<UpdateVenueOrderMutation> {
    const statement = `mutation UpdateVenueOrder($condition: ModelVenueOrderConditionInput, $input: UpdateVenueOrderInput!) {
        updateVenueOrder(condition: $condition, input: $input) {
          __typename
          createdAt
          dueTime
          id
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVenueOrderMutation>response.data.updateVenueOrder;
  }
  async UpdateVenueSchedule(
    input: UpdateVenueScheduleInput,
    condition?: ModelVenueScheduleConditionInput
  ): Promise<UpdateVenueScheduleMutation> {
    const statement = `mutation UpdateVenueSchedule($condition: ModelVenueScheduleConditionInput, $input: UpdateVenueScheduleInput!) {
        updateVenueSchedule(condition: $condition, input: $input) {
          __typename
          createdAt
          id
          schedule {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          scheduleID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVenueScheduleMutation>response.data.updateVenueSchedule;
  }
  async GetOrderLinkCustom(id: string): Promise<GetOrderLinkCustomQuery> {
    const statement = `query GetOrderLinkCustom($id: ID!) {
        getOrderLink(id: $id) {
          __typename
          active
          locationID
          location {
            __typename
            street
            city
            state
            zip
            name
            email
            phone
            timezone
          }
          originName
          originEmail
          originPhone
          heading
          subHeading
          notice
          searchPlaceholder
          id
          imageS3EntryHeaders
          imageS3EntryHeadersMobile
          imageS3Footer
          privacyPolicy
          tos
          footerURL
          menuScollerHeading
          menuScollerSubheading
          popularItemsHeading
          popularItemsSubheading
          featuredRestarauntHeading
          featuredRestarauntSubheading
          popularRestarauntHeading
          popularRestarauntSubheading
          exploreHeading
          exploreSubheading
          ccCredential {
            __typename
            id
            type
            dcTokenKey
          }
          schedules {
            __typename
            items {
              __typename
              schedule {
                __typename
                id
                name
                startDate
                endDate
                timeRanges {
                  __typename
                  day
                  startTime
                  endTime
                }
              }
            }
          }
          menus {
            __typename
            items {
              __typename
              id
              menu {
                __typename
                active
                conceptID
                id
                name
                displayName
                description
                imageS3LogoSquare
                imageS3Headers
                imageS3HeadersMobile
                imageS3Featured
                venueID
                featured
                popular
                venue {
                  __typename
                  id
                  name
                  imageS3LogoSquare
                }
                categories {
                  __typename
                  items {
                    __typename
                    id
                    sortOrder
                    menuCategory {
                      __typename
                      active
                      conceptID
                      id
                      name
                      displayName
                      description
                      venueID
                      priceTypeID
                      menuItems {
                        __typename
                        items {
                          __typename
                          id
                          sortOrder
                          menuCategoryID
                          menuItem {
                            __typename
                            active
                            conceptID
                            description
                            id
                            imageS3
                            name
                            venueID
                            popular
                            prices {
                              __typename
                              items {
                                __typename
                                id
                                price
                                priceTypeID
                                active
                              }
                            }
                          }
                          menuItemID
                        }
                      }
                    }
                    menuCategoryID
                    menuID
                  }
                }
                tags {
                  __typename
                  items {
                    __typename
                    tag {
                      __typename
                      id
                      imageS3Square
                      name
                      type
                    }
                  }
                }
                schedules {
                  __typename
                  items {
                    __typename
                    schedule {
                      __typename
                      id
                      name
                      startDate
                      endDate
                      timeRanges {
                        __typename
                        day
                        startTime
                        endTime
                      }
                    }
                  }
                }
              }
              menuID
              orderLinkID
              sortOrder
            }
          }
          name
          orderTypes {
            __typename
            items {
              __typename
              orderType {
                __typename
                active
                id
                name
                displayName
                asap
                schedule
                serviceType
                deliveryProvider
              }
              orderTypeID
            }
          }
          tenantID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderLinkCustomQuery>response.data.getOrderLink;
  }
  async GetOrderCustom(id: string): Promise<GetOrderCustomQuery> {
    const statement = `query GetOrderCustom($id: ID!) {
        getOrder(id: $id) {
          __typename
          checks {
            __typename
            items {
              __typename
              customerID
              customerDetails {
                __typename
                firstName
                lastName
                email
                phone
                address {
                  __typename
                  street
                  city
                  state
                  zip
                  distance
                  latitude
                  longitude
                }
              }
              fees {
                __typename
                id
                name
                type
                amount
                inclusive
                taxed
              }
              id
              items {
                __typename
                items {
                  __typename
                  name
                  price
                  quantity
                  venueID
                  venueName
                  instructions
                  utensils
                  modifiers {
                    __typename
                    name
                    price
                    quantity
                    modifiers {
                      __typename
                      name
                      price
                      quantity
                    }
                  }
                }
              }
              payments {
                __typename
                items {
                  __typename
                  amount
                  displayName
                  id
                  status
                  tip
                }
              }
              readyEstimate
              totals {
                __typename
                discountTotal
                feeTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                items {
                  __typename
                  amount
                  amountItems
                  amountFees
                  amountTax
                  amountTip
                  authCode
                  cardNumber
                  cardType
                  createdAt
                  id
                  status
                  type
                  invoice
                  venueID
                  refunds {
                    __typename
                    amount
                    type
                    checkItemID
                  }
                  checkItemIDs
                }
              }
              updatedAt
              venueTotals {
                __typename
                checkPercent
                discountTotal
                feeTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
          }
          createdAt
          submittedAt
          queuedAt
          customerID
          customerDetails {
            __typename
            firstName
            lastName
            email
            phone
            address {
              __typename
              street
              city
              state
              zip
              distance
              latitude
              longitude
            }
          }
          originDetails {
            __typename
            name
            email
            phone
            street
            city
            state
            zip
          }
          serviceType
          deliveryProvider
          deliveryQuoteID
          deliveryID
          deliveryPickupTime
          deliveryEstimate
          deliveryTime
          courierCheckin
          courierDetails {
            __typename
            id
            firstName
            lastName
            phone
            type
            status
            latitude
            longitude
            lastUpdate
            message
            pickupTime
            errorCode
          }
          courierPhone
          courierStatus
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          orderLink {
            __typename
            active
            leadTime
            name
            locationID
            location {
              __typename
              street
              city
              state
              zip
              name
              email
              phone
              timezone
            }
            originName
            originEmail
            originPhone
            originLinkFacebook
            originLinkIG
            privacyPolicy
            tos
          }
          orderLinkID
          orderNumber
          orderTypeID
          orderType {
            __typename
            active
            displayName
            id
            name
            asap
            schedule
            serviceType
            deliveryProvider
          }
          queueMode
          queueTime
          readyEstimate
          readyTime
          status
          statusDetails
          statusMessage
          locationID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderCustomQuery>response.data.getOrder;
  }
  async ListOrderLinksByDomainMin(
    domain?: string,
    filter?: ModelOrderLinkFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListOrderLinksByDomainMinQuery> {
    const statement = `query ListOrderLinksByDomainMin($domain: AWSURL, $filter: ModelOrderLinkFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listOrderLinksByDomain(
          domain: $domain
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            id
            isDefault
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (domain) {
      gqlAPIServiceArguments.domain = domain;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderLinksByDomainMinQuery>response.data.listOrderLinksByDomain;
  }
  async GetCheck(id: string): Promise<GetCheckQuery> {
    const statement = `query GetCheck($id: ID!) {
        getCheck(id: $id) {
          __typename
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          diagnostics
          fees {
            __typename
            amount
            id
            inclusive
            name
            taxed
            type
          }
          id
          items {
            __typename
            items {
              __typename
              alcohol
              checkID
              chit {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              chitID
              createdAt
              id
              instructions
              menuID
              menuItemID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              priceTypeID
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
              updatedAt
              utensils
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          payments {
            __typename
            items {
              __typename
              amount
              cardPresent
              checkID
              createdAt
              displayName
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              isAuthed
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              refNo
              status
              tip
              token
              updatedAt
            }
            nextToken
          }
          readyEstimate
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          totals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            discountTotal
            feeTotal
            feeTotalInclusive
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            tipTotal
            total
          }
          transactions {
            __typename
            items {
              __typename
              amount
              amountFees
              amountItems
              amountTax
              amountTip
              authCode
              cardNumber
              cardType
              check {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              checkID
              checkItemIDs
              comp
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              invoice
              last4
              note
              paymentID
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              paymentMethodType
              processor
              processorCredentialID
              reason
              refNo
              refunds {
                __typename
                amount
                checkItemID
                type
              }
              status
              thirdPartyData
              token
              type
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          updatedAt
          venueTotals {
            __typename
            ccCaptureTotal
            ccRefundTotal
            ccVoidTotal
            checkPercent
            discountTotal
            feeTotal
            feeTotalInclusive
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            feesAndTaxTotal
            paymentTotal
            remainingBalance
            subTotal
            taxTotal
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tipTotal
            total
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCheckQuery>response.data.getCheck;
  }
  async GetCheckItem(id: string): Promise<GetCheckItemQuery> {
    const statement = `query GetCheckItem($id: ID!) {
        getCheckItem(id: $id) {
          __typename
          alcohol
          checkID
          chit {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          chitID
          createdAt
          id
          instructions
          menuID
          menuItemID
          modifiers {
            __typename
            id
            menuItemID
            modifierGroupID
            modifiers {
              __typename
              id
              menuItemID
              modifierGroupID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
            }
            name
            price
            quantity
            tags {
              __typename
              id
              name
              type
            }
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            unitPrice
          }
          name
          price
          priceTypeID
          quantity
          tags {
            __typename
            id
            name
            type
          }
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          unitPrice
          updatedAt
          utensils
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCheckItemQuery>response.data.getCheckItem;
  }
  async GetCheckTransaction(id: string): Promise<GetCheckTransactionQuery> {
    const statement = `query GetCheckTransaction($id: ID!) {
        getCheckTransaction(id: $id) {
          __typename
          amount
          amountFees
          amountItems
          amountTax
          amountTip
          authCode
          cardNumber
          cardType
          check {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          checkID
          checkItemIDs
          comp
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          invoice
          last4
          note
          paymentID
          paymentMethod {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          paymentMethodID
          paymentMethodType
          processor
          processorCredentialID
          reason
          refNo
          refunds {
            __typename
            amount
            checkItemID
            type
          }
          status
          thirdPartyData
          token
          type
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCheckTransactionQuery>response.data.getCheckTransaction;
  }
  async GetChit(id: string): Promise<GetChitQuery> {
    const statement = `query GetChit($id: ID!) {
        getChit(id: $id) {
          __typename
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          items {
            __typename
            alcohol
            id
            instructions
            modifiers {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            name
            price
            quantity
            status
            utensils
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetChitQuery>response.data.getChit;
  }
  async GetConcept(id: string): Promise<GetConceptQuery> {
    const statement = `query GetConcept($id: ID!) {
        getConcept(id: $id) {
          __typename
          createdAt
          id
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConceptQuery>response.data.getConcept;
  }
  async GetCredential(id: string): Promise<GetCredentialQuery> {
    const statement = `query GetCredential($id: ID!) {
        getCredential(id: $id) {
          __typename
          createdAt
          dcEcomMID
          dcMID
          dcTokenKey
          id
          key
          secret
          smsFrom
          tenantID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCredentialQuery>response.data.getCredential;
  }
  async GetCustomer(id: string): Promise<GetCustomerQuery> {
    const statement = `query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
          __typename
          addresses {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          createdAt
          defaultPaymentMethodID
          email
          firstName
          id
          lastName
          paymentMethods {
            __typename
            items {
              __typename
              createdAt
              customerID
              id
              paymentMethod {
                __typename
                cardExpiration
                cardPresent
                cardType
                createdAt
                name
                processor
                token
                type
                updatedAt
                zipcode
              }
              paymentMethodID
              updatedAt
            }
            nextToken
          }
          phone
          updatedAt
          userName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerQuery>response.data.getCustomer;
  }
  async GetDevice(id: string): Promise<GetDeviceQuery> {
    const statement = `query GetDevice($id: ID!) {
        getDevice(id: $id) {
          __typename
          active
          autoPrint
          createdAt
          deviceID
          displayName
          host
          id
          ipAddress
          licenseID
          name
          port
          printOnBump
          serialNumber
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDeviceQuery>response.data.getDevice;
  }
  async GetDeviceTransaction(id: string): Promise<GetDeviceTransactionQuery> {
    const statement = `query GetDeviceTransaction($id: ID!) {
        getDeviceTransaction(id: $id) {
          __typename
          createdAt
          data
          deviceID
          event
          expirationUnixTime
          id
          status
          transactionID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDeviceTransactionQuery>response.data.getDeviceTransaction;
  }
  async GetDomain(id: string): Promise<GetDomainQuery> {
    const statement = `query GetDomain($id: ID!) {
        getDomain(id: $id) {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDomainQuery>response.data.getDomain;
  }
  async GetEmailTemplate(id: string): Promise<GetEmailTemplateQuery> {
    const statement = `query GetEmailTemplate($id: ID!) {
        getEmailTemplate(id: $id) {
          __typename
          createdAt
          html
          id
          name
          subject
          tenantID
          text
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmailTemplateQuery>response.data.getEmailTemplate;
  }
  async GetEmployee(id: string): Promise<GetEmployeeQuery> {
    const statement = `query GetEmployee($id: ID!) {
        getEmployee(id: $id) {
          __typename
          active
          code
          createdAt
          email
          firstName
          id
          jobs {
            __typename
            items {
              __typename
              createdAt
              employee {
                __typename
                active
                code
                createdAt
                email
                firstName
                id
                lastName
                phone
                tenantID
                updatedAt
              }
              employeeID
              entityType
              id
              payRate
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          lastName
          phone
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmployeeQuery>response.data.getEmployee;
  }
  async GetFee(id: string): Promise<GetFeeQuery> {
    const statement = `query GetFee($id: ID!) {
        getFee(id: $id) {
          __typename
          amount
          createdAt
          id
          inclusive
          name
          rate
          taxed
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFeeQuery>response.data.getFee;
  }
  async GetJob(id: string): Promise<GetJobQuery> {
    const statement = `query GetJob($id: ID!) {
        getJob(id: $id) {
          __typename
          createdAt
          employee {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          employeeID
          entityType
          id
          payRate
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetJobQuery>response.data.getJob;
  }
  async GetJobType(id: string): Promise<GetJobTypeQuery> {
    const statement = `query GetJobType($id: ID!) {
        getJobType(id: $id) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetJobTypeQuery>response.data.getJobType;
  }
  async GetLicense(id: string): Promise<GetLicenseQuery> {
    const statement = `query GetLicense($id: ID!) {
        getLicense(id: $id) {
          __typename
          active
          createdAt
          deviceDetails {
            __typename
            deviceID
            displayName
            heartbeat
            initTime
            name
            online
            version
          }
          devices {
            __typename
            items {
              __typename
              active
              autoPrint
              createdAt
              deviceID
              displayName
              host
              id
              ipAddress
              licenseID
              name
              port
              printOnBump
              serialNumber
              type
              updatedAt
            }
            nextToken
          }
          displayName
          guid
          id
          kioskConfig {
            __typename
            orderLinkID
            showSettings
          }
          kitchenConfig {
            __typename
            showSettings
          }
          name
          pollingInterval
          receiptConfig {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          receiptConfigID
          status
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              createdAt
              id
              licenseID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLicenseQuery>response.data.getLicense;
  }
  async GetLocation(id: string): Promise<GetLocationQuery> {
    const statement = `query GetLocation($id: ID!) {
        getLocation(id: $id) {
          __typename
          city
          country
          createdAt
          email
          endOfDay
          id
          latitude
          longitude
          name
          phone
          state
          street
          tenantID
          timezone
          unit
          updatedAt
          zip
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLocationQuery>response.data.getLocation;
  }
  async GetLog(id: string): Promise<GetLogQuery> {
    const statement = `query GetLog($id: ID!) {
        getLog(id: $id) {
          __typename
          checkID
          createdAt
          employee {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          employeeID
          event
          id
          message
          orderID
          source
          tenantID
          type
          updatedAt
          user {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          userID
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLogQuery>response.data.getLog;
  }
  async GetMenu(id: string): Promise<GetMenuQuery> {
    const statement = `query GetMenu($id: ID!) {
        getMenu(id: $id) {
          __typename
          active
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          displayName
          facilitators {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          featured
          id
          imageS3Featured
          imageS3Headers
          imageS3HeadersMobile
          imageS3LogoSquare
          name
          orderLinks {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          popular
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenuQuery>response.data.getMenu;
  }
  async GetMenuCategory(id: string): Promise<GetMenuCategoryQuery> {
    const statement = `query GetMenuCategory($id: ID!) {
        getMenuCategory(id: $id) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategoryID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenuCategoryQuery>response.data.getMenuCategory;
  }
  async GetMenuItem(id: string): Promise<GetMenuItemQuery> {
    const statement = `query GetMenuItem($id: ID!) {
        getMenuItem(id: $id) {
          __typename
          active
          alcohol
          categories {
            __typename
            items {
              __typename
              createdAt
              id
              menuCategory {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              menuCategoryID
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              sortOrder
              updatedAt
            }
            nextToken
          }
          conceptID
          createdAt
          description
          id
          imageS3
          modifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          options {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              name
              prices {
                __typename
                nextToken
              }
              updatedAt
            }
            nextToken
          }
          parentModifierGroups {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          popular
          prepTime
          prices {
            __typename
            items {
              __typename
              active
              createdAt
              id
              menuItemID
              menuItemOptionID
              price
              priceType {
                __typename
                createdAt
                id
                name
                tenantID
                updatedAt
              }
              priceTypeID
              updatedAt
            }
            nextToken
          }
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          taxes {
            __typename
            items {
              __typename
              createdAt
              id
              menuItemID
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          utensils
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenuItemQuery>response.data.getMenuItem;
  }
  async GetMenuItemPriceType(id: string): Promise<GetMenuItemPriceTypeQuery> {
    const statement = `query GetMenuItemPriceType($id: ID!) {
        getMenuItemPriceType(id: $id) {
          __typename
          createdAt
          id
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMenuItemPriceTypeQuery>response.data.getMenuItemPriceType;
  }
  async GetModifierGroup(id: string): Promise<GetModifierGroupQuery> {
    const statement = `query GetModifierGroup($id: ID!) {
        getModifierGroup(id: $id) {
          __typename
          active
          conceptID
          createdAt
          description
          displayName
          id
          max
          menuItems {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              sortOrder
              updatedAt
            }
            nextToken
          }
          min
          modifiers {
            __typename
            items {
              __typename
              createdAt
              id
              menuItem {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              menuItemID
              modifierGroup {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              modifierGroupID
              updatedAt
            }
            nextToken
          }
          name
          priceTypeID
          selectionType
          tags {
            __typename
            items {
              __typename
              createdAt
              id
              modifierGroupID
              tag {
                __typename
                createdAt
                id
                imageS3Square
                name
                tenantID
                type
                updatedAt
              }
              tagID
              updatedAt
            }
            nextToken
          }
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetModifierGroupQuery>response.data.getModifierGroup;
  }
  async GetNotification(id: string): Promise<GetNotificationQuery> {
    const statement = `query GetNotification($id: ID!) {
        getNotification(id: $id) {
          __typename
          createdAt
          emailBCC
          emailCC
          emailFrom
          emailReplyTo
          emailTemplate {
            __typename
            createdAt
            html
            id
            name
            subject
            tenantID
            text
            updatedAt
          }
          emailTemplateData
          emailTemplateID
          emailTo
          id
          message
          name
          smsFrom
          smsTo
          smsToDynamic
          smsToType
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNotificationQuery>response.data.getNotification;
  }
  async GetOrder(id: string): Promise<GetOrderQuery> {
    const statement = `query GetOrder($id: ID!) {
        getOrder(id: $id) {
          __typename
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          chits {
            __typename
            items {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          clerkID
          courierCheckin
          courierDetails {
            __typename
            errorCode
            firstName
            id
            lastName
            lastUpdate
            latitude
            longitude
            message
            phone
            pickupTime
            status
            type
          }
          courierPhone
          courierStatus
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          deliveryEstimate
          deliveryID
          deliveryPickupTime
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          diagnostics
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          fascilitatedTenantReportExclusions
          id
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          orderNumber
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          originAddress {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          originDetails {
            __typename
            city
            country
            distance
            email
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          queueMode
          queueTime
          queuedAt
          readyEstimate
          readyTime
          serviceType
          source
          status
          statusDetails
          statusMessage
          submittedAt
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tenantName
          thirdPartyOrderID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          venueStatus {
            __typename
            status
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderQuery>response.data.getOrder;
  }
  async GetOrderLink(id: string): Promise<GetOrderLinkQuery> {
    const statement = `query GetOrderLink($id: ID!) {
        getOrderLink(id: $id) {
          __typename
          active
          adminEmail
          ccCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          ccCredentialID
          clerkID
          createdAt
          doNotSell
          domain
          exploreHeading
          exploreSubheading
          fascilitatedTenantReportExclusions
          featuredRestarauntHeading
          featuredRestarauntSubheading
          footerURL
          heading
          id
          imageS3EntryHeaders
          imageS3EntryHeadersMobile
          imageS3Footer
          isDefault
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuScollerHeading
          menuScollerSubheading
          menus {
            __typename
            items {
              __typename
              createdAt
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              sortOrder
              updatedAt
            }
            nextToken
          }
          name
          notice
          noticeAtCollection
          notifications {
            __typename
            items {
              __typename
              createdAt
              event
              id
              notification {
                __typename
                createdAt
                emailBCC
                emailCC
                emailFrom
                emailReplyTo
                emailTemplateData
                emailTemplateID
                emailTo
                id
                message
                name
                smsFrom
                smsTo
                smsToDynamic
                smsToType
                tenantID
                type
                updatedAt
              }
              notificationID
              orderLinkID
              updatedAt
            }
            nextToken
          }
          orderMode
          orderTypes {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              updatedAt
            }
            nextToken
          }
          originEmail
          originLinkFacebook
          originLinkIG
          originName
          originPhone
          popularItemsHeading
          popularItemsSubheading
          popularRestarauntHeading
          popularRestarauntSubheading
          privacyPolicy
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderLinkID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          searchPlaceholder
          smsCredential {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          smsCredentialID
          subHeading
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tos
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderLinkQuery>response.data.getOrderLink;
  }
  async GetOrderType(id: string): Promise<GetOrderTypeQuery> {
    const statement = `query GetOrderType($id: ID!) {
        getOrderType(id: $id) {
          __typename
          active
          asap
          createdAt
          deliveryProvider
          displayName
          fees {
            __typename
            items {
              __typename
              createdAt
              fee {
                __typename
                amount
                createdAt
                id
                inclusive
                name
                rate
                taxed
                tenantID
                type
                updatedAt
              }
              feeID
              id
              orderTypeID
              updatedAt
            }
            nextToken
          }
          id
          name
          schedule
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              orderTypeID
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
            }
            nextToken
          }
          serviceType
          source
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderTypeQuery>response.data.getOrderType;
  }
  async GetPaymentMethod(token: string): Promise<GetPaymentMethodQuery> {
    const statement = `query GetPaymentMethod($token: ID!) {
        getPaymentMethod(token: $token) {
          __typename
          cardExpiration
          cardPresent
          cardType
          createdAt
          name
          processor
          token
          type
          updatedAt
          zipcode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      token
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPaymentMethodQuery>response.data.getPaymentMethod;
  }
  async GetPrintJob(id: string): Promise<GetPrintJobQuery> {
    const statement = `query GetPrintJob($id: ID!) {
        getPrintJob(id: $id) {
          __typename
          createdAt
          data
          expirationUnixTime
          id
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPrintJobQuery>response.data.getPrintJob;
  }
  async GetPrinter(serialNumber: string): Promise<GetPrinterQuery> {
    const statement = `query GetPrinter($serialNumber: ID!) {
        getPrinter(serialNumber: $serialNumber) {
          __typename
          createdAt
          serialNumber
          status
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      serialNumber
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPrinterQuery>response.data.getPrinter;
  }
  async GetReceiptConfig(id: string): Promise<GetReceiptConfigQuery> {
    const statement = `query GetReceiptConfig($id: ID!) {
        getReceiptConfig(id: $id) {
          __typename
          createdAt
          headerSize
          id
          itemSize
          name
          showDiscounts
          showFees
          showPayments
          showPrice
          showQR
          showRemaining
          showSubtotal
          showTaxes
          showTips
          showTransactions
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueSize
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReceiptConfigQuery>response.data.getReceiptConfig;
  }
  async GetSMSMessage(id: string): Promise<GetSMSMessageQuery> {
    const statement = `query GetSMSMessage($id: ID!) {
        getSMSMessage(id: $id) {
          __typename
          createdAt
          from
          id
          message
          orderID
          tenantID
          to
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSMSMessageQuery>response.data.getSMSMessage;
  }
  async GetSchedule(id: string): Promise<GetScheduleQuery> {
    const statement = `query GetSchedule($id: ID!) {
        getSchedule(id: $id) {
          __typename
          createdAt
          endDate
          id
          name
          startDate
          tenantID
          timeRanges {
            __typename
            day
            endTime
            startTime
          }
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetScheduleQuery>response.data.getSchedule;
  }
  async GetTag(id: string): Promise<GetTagQuery> {
    const statement = `query GetTag($id: ID!) {
        getTag(id: $id) {
          __typename
          createdAt
          id
          imageS3Square
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTagQuery>response.data.getTag;
  }
  async GetTaxRate(id: string): Promise<GetTaxRateQuery> {
    const statement = `query GetTaxRate($id: ID!) {
        getTaxRate(id: $id) {
          __typename
          amount
          createdAt
          id
          rate
          taxType {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          taxTypeID
          type
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxRateQuery>response.data.getTaxRate;
  }
  async GetTaxType(id: string): Promise<GetTaxTypeQuery> {
    const statement = `query GetTaxType($id: ID!) {
        getTaxType(id: $id) {
          __typename
          createdAt
          id
          name
          tenantID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxTypeQuery>response.data.getTaxType;
  }
  async GetTenant(id: string): Promise<GetTenantQuery> {
    const statement = `query GetTenant($id: ID!) {
        getTenant(id: $id) {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTenantQuery>response.data.getTenant;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          createdAt
          email
          firstName
          id
          lastName
          phone
          updatedAt
          userName
          userRoles {
            __typename
            items {
              __typename
              createdAt
              entityType
              id
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              type {
                __typename
                createdAt
                id
                name
                permissions
                tenantID
                updatedAt
                venueID
              }
              typeID
              updatedAt
              user {
                __typename
                createdAt
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async GetUserRole(id: string): Promise<GetUserRoleQuery> {
    const statement = `query GetUserRole($id: ID!) {
        getUserRole(id: $id) {
          __typename
          createdAt
          entityType
          id
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          typeID
          updatedAt
          user {
            __typename
            createdAt
            email
            firstName
            id
            lastName
            phone
            updatedAt
            userName
            userRoles {
              __typename
              items {
                __typename
                createdAt
                entityType
                id
                tenantID
                typeID
                updatedAt
                userID
                venueID
              }
              nextToken
            }
          }
          userID
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserRoleQuery>response.data.getUserRole;
  }
  async GetUserRoleType(id: string): Promise<GetUserRoleTypeQuery> {
    const statement = `query GetUserRoleType($id: ID!) {
        getUserRoleType(id: $id) {
          __typename
          createdAt
          id
          name
          permissions
          tenantID
          updatedAt
          venueID
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserRoleTypeQuery>response.data.getUserRoleType;
  }
  async GetVenue(id: string): Promise<GetVenueQuery> {
    const statement = `query GetVenue($id: ID!) {
        getVenue(id: $id) {
          __typename
          acceptingOrders
          active
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          id
          imageS3LogoSquare
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
              venueID
            }
            nextToken
          }
          taxRates {
            __typename
            items {
              __typename
              amount
              createdAt
              id
              rate
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVenueQuery>response.data.getVenue;
  }
  async GetVenueOrder(id: string): Promise<GetVenueOrderQuery> {
    const statement = `query GetVenueOrder($id: ID!) {
        getVenueOrder(id: $id) {
          __typename
          createdAt
          dueTime
          id
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVenueOrderQuery>response.data.getVenueOrder;
  }
  async ListCheckItems(
    filter?: ModelCheckItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCheckItemsQuery> {
    const statement = `query ListCheckItems($filter: ModelCheckItemFilterInput, $limit: Int, $nextToken: String) {
        listCheckItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            alcohol
            checkID
            chit {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            chitID
            createdAt
            id
            instructions
            menuID
            menuItemID
            modifiers {
              __typename
              id
              menuItemID
              modifierGroupID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
            }
            name
            price
            priceTypeID
            quantity
            tags {
              __typename
              id
              name
              type
            }
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            unitPrice
            updatedAt
            utensils
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCheckItemsQuery>response.data.listCheckItems;
  }
  async ListCheckTransactions(
    filter?: ModelCheckTransactionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCheckTransactionsQuery> {
    const statement = `query ListCheckTransactions($filter: ModelCheckTransactionFilterInput, $limit: Int, $nextToken: String) {
        listCheckTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            amount
            amountFees
            amountItems
            amountTax
            amountTip
            authCode
            cardNumber
            cardType
            check {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            checkID
            checkItemIDs
            comp
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            invoice
            last4
            note
            paymentID
            paymentMethod {
              __typename
              cardExpiration
              cardPresent
              cardType
              createdAt
              name
              processor
              token
              type
              updatedAt
              zipcode
            }
            paymentMethodID
            paymentMethodType
            processor
            processorCredentialID
            reason
            refNo
            refunds {
              __typename
              amount
              checkItemID
              type
            }
            status
            thirdPartyData
            token
            type
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCheckTransactionsQuery>response.data.listCheckTransactions;
  }
  async ListCheckTransactionsByVenueIdByTypeBydate(
    filter?: ModelCheckTransactionFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    typeCreatedAt?: ModelCheckTransactionByVenueByTypeByDateCompositeKeyConditionInput,
    venueID?: string
  ): Promise<ListCheckTransactionsByVenueIdByTypeBydateQuery> {
    const statement = `query ListCheckTransactionsByVenueIdByTypeBydate($filter: ModelCheckTransactionFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $typeCreatedAt: ModelCheckTransactionByVenueByTypeByDateCompositeKeyConditionInput, $venueID: ID) {
        listCheckTransactionsByVenueIdByTypeBydate(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          typeCreatedAt: $typeCreatedAt
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            amount
            amountFees
            amountItems
            amountTax
            amountTip
            authCode
            cardNumber
            cardType
            check {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            checkID
            checkItemIDs
            comp
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            invoice
            last4
            note
            paymentID
            paymentMethod {
              __typename
              cardExpiration
              cardPresent
              cardType
              createdAt
              name
              processor
              token
              type
              updatedAt
              zipcode
            }
            paymentMethodID
            paymentMethodType
            processor
            processorCredentialID
            reason
            refNo
            refunds {
              __typename
              amount
              checkItemID
              type
            }
            status
            thirdPartyData
            token
            type
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (typeCreatedAt) {
      gqlAPIServiceArguments.typeCreatedAt = typeCreatedAt;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCheckTransactionsByVenueIdByTypeBydateQuery>(
      response.data.listCheckTransactionsByVenueIdByTypeBydate
    );
  }
  async ListChecks(
    filter?: ModelCheckFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListChecksQuery> {
    const statement = `query ListChecks($filter: ModelCheckFilterInput, $limit: Int, $nextToken: String) {
        listChecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChecksQuery>response.data.listChecks;
  }
  async ListChecksByCustomer(
    customerID?: string,
    filter?: ModelCheckFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListChecksByCustomerQuery> {
    const statement = `query ListChecksByCustomer($customerID: ID, $filter: ModelCheckFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listChecksByCustomer(
          customerID: $customerID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customerID) {
      gqlAPIServiceArguments.customerID = customerID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChecksByCustomerQuery>response.data.listChecksByCustomer;
  }
  async ListChecksByTenantId(
    filter?: ModelCheckFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListChecksByTenantIdQuery> {
    const statement = `query ListChecksByTenantId($filter: ModelCheckFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listChecksByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            diagnostics
            fees {
              __typename
              amount
              id
              inclusive
              name
              taxed
              type
            }
            id
            items {
              __typename
              items {
                __typename
                alcohol
                checkID
                chitID
                createdAt
                id
                instructions
                menuID
                menuItemID
                name
                price
                priceTypeID
                quantity
                unitPrice
                updatedAt
                utensils
                venueID
                venueName
              }
              nextToken
            }
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            payments {
              __typename
              items {
                __typename
                amount
                cardPresent
                checkID
                createdAt
                displayName
                id
                invoice
                isAuthed
                paymentMethodID
                paymentMethodType
                refNo
                status
                tip
                token
                updatedAt
              }
              nextToken
            }
            readyEstimate
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            totals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              discountTotal
              feeTotal
              feeTotalInclusive
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              tipTotal
              total
            }
            transactions {
              __typename
              items {
                __typename
                amount
                amountFees
                amountItems
                amountTax
                amountTip
                authCode
                cardNumber
                cardType
                checkID
                checkItemIDs
                comp
                createdAt
                id
                invoice
                last4
                note
                paymentID
                paymentMethodID
                paymentMethodType
                processor
                processorCredentialID
                reason
                refNo
                status
                thirdPartyData
                token
                type
                updatedAt
                venueID
              }
              nextToken
            }
            updatedAt
            venueTotals {
              __typename
              ccCaptureTotal
              ccRefundTotal
              ccVoidTotal
              checkPercent
              discountTotal
              feeTotal
              feeTotalInclusive
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              feesAndTaxTotal
              paymentTotal
              remainingBalance
              subTotal
              taxTotal
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tipTotal
              total
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChecksByTenantIdQuery>response.data.listChecksByTenantId;
  }
  async ListChits(
    filter?: ModelChitFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListChitsQuery> {
    const statement = `query ListChits($filter: ModelChitFilterInput, $limit: Int, $nextToken: String) {
        listChits(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChitsQuery>response.data.listChits;
  }
  async ListChitsByVenueIdByStatusByDate(
    filter?: ModelChitFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    statusCreatedAt?: ModelChitByVenueByStatusByDateCompositeKeyConditionInput,
    venueID?: string
  ): Promise<ListChitsByVenueIdByStatusByDateQuery> {
    const statement = `query ListChitsByVenueIdByStatusByDate($filter: ModelChitFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $statusCreatedAt: ModelChitByVenueByStatusByDateCompositeKeyConditionInput, $venueID: ID) {
        listChitsByVenueIdByStatusByDate(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          statusCreatedAt: $statusCreatedAt
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (statusCreatedAt) {
      gqlAPIServiceArguments.statusCreatedAt = statusCreatedAt;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChitsByVenueIdByStatusByDateQuery>(
      response.data.listChitsByVenueIdByStatusByDate
    );
  }
  async ListConcepts(
    filter?: ModelConceptFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConceptsQuery> {
    const statement = `query ListConcepts($filter: ModelConceptFilterInput, $limit: Int, $nextToken: String) {
        listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            id
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConceptsQuery>response.data.listConcepts;
  }
  async ListConceptsByTenantId(
    filter?: ModelConceptFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListConceptsByTenantIdQuery> {
    const statement = `query ListConceptsByTenantId($filter: ModelConceptFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listConceptsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConceptsByTenantIdQuery>response.data.listConceptsByTenantId;
  }
  async ListCredentials(
    filter?: ModelCredentialFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCredentialsQuery> {
    const statement = `query ListCredentials($filter: ModelCredentialFilterInput, $limit: Int, $nextToken: String) {
        listCredentials(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCredentialsQuery>response.data.listCredentials;
  }
  async ListCredentialsByTenantId(
    filter?: ModelCredentialFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListCredentialsByTenantIdQuery> {
    const statement = `query ListCredentialsByTenantId($filter: ModelCredentialFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listCredentialsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCredentialsByTenantIdQuery>(
      response.data.listCredentialsByTenantId
    );
  }
  async ListCredentialsByVenueId(
    filter?: ModelCredentialFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListCredentialsByVenueIdQuery> {
    const statement = `query ListCredentialsByVenueId($filter: ModelCredentialFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listCredentialsByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            dcEcomMID
            dcMID
            dcTokenKey
            id
            key
            secret
            smsFrom
            tenantID
            type
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCredentialsByVenueIdQuery>(
      response.data.listCredentialsByVenueId
    );
  }
  async ListCustomers(
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomersQuery> {
    const statement = `query ListCustomers($filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String) {
        listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersQuery>response.data.listCustomers;
  }
  async ListCustomersByEmail(
    email?: string,
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCustomersByEmailQuery> {
    const statement = `query ListCustomersByEmail($email: AWSEmail, $filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCustomersByEmail(
          email: $email
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersByEmailQuery>response.data.listCustomersByEmail;
  }
  async ListCustomersByPhone(
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string,
    phone?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListCustomersByPhoneQuery> {
    const statement = `query ListCustomersByPhone($filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String, $phone: AWSPhone, $sortDirection: ModelSortDirection) {
        listCustomersByPhone(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          phone: $phone
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (phone) {
      gqlAPIServiceArguments.phone = phone;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersByPhoneQuery>response.data.listCustomersByPhone;
  }
  async ListCustomersByUserName(
    filter?: ModelCustomerFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    userName?: string
  ): Promise<ListCustomersByUserNameQuery> {
    const statement = `query ListCustomersByUserName($filter: ModelCustomerFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $userName: String) {
        listCustomersByUserName(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          userName: $userName
        ) {
          __typename
          items {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (userName) {
      gqlAPIServiceArguments.userName = userName;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomersByUserNameQuery>response.data.listCustomersByUserName;
  }
  async ListDeviceTransactions(
    filter?: ModelDeviceTransactionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDeviceTransactionsQuery> {
    const statement = `query ListDeviceTransactions($filter: ModelDeviceTransactionFilterInput, $limit: Int, $nextToken: String) {
        listDeviceTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            data
            deviceID
            event
            expirationUnixTime
            id
            status
            transactionID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDeviceTransactionsQuery>response.data.listDeviceTransactions;
  }
  async ListDevices(
    filter?: ModelDeviceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDevicesQuery> {
    const statement = `query ListDevices($filter: ModelDeviceFilterInput, $limit: Int, $nextToken: String) {
        listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            autoPrint
            createdAt
            deviceID
            displayName
            host
            id
            ipAddress
            licenseID
            name
            port
            printOnBump
            serialNumber
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevicesQuery>response.data.listDevices;
  }
  async ListDevicesByDeviceId(
    deviceID?: string,
    filter?: ModelDeviceFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDevicesByDeviceIdQuery> {
    const statement = `query ListDevicesByDeviceId($deviceID: ID, $filter: ModelDeviceFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDevicesByDeviceId(
          deviceID: $deviceID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            autoPrint
            createdAt
            deviceID
            displayName
            host
            id
            ipAddress
            licenseID
            name
            port
            printOnBump
            serialNumber
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (deviceID) {
      gqlAPIServiceArguments.deviceID = deviceID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDevicesByDeviceIdQuery>response.data.listDevicesByDeviceId;
  }
  async ListDomains(
    filter?: ModelDomainFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDomainsQuery> {
    const statement = `query ListDomains($filter: ModelDomainFilterInput, $limit: Int, $nextToken: String) {
        listDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            host
            id
            imageS3Logo
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDomainsQuery>response.data.listDomains;
  }
  async ListDomainsByHost(
    filter?: ModelDomainFilterInput,
    host?: string,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListDomainsByHostQuery> {
    const statement = `query ListDomainsByHost($filter: ModelDomainFilterInput, $host: AWSURL, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listDomainsByHost(
          filter: $filter
          host: $host
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            createdAt
            host
            id
            imageS3Logo
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (host) {
      gqlAPIServiceArguments.host = host;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDomainsByHostQuery>response.data.listDomainsByHost;
  }
  async ListEmailTemplates(
    filter?: ModelEmailTemplateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEmailTemplatesQuery> {
    const statement = `query ListEmailTemplates($filter: ModelEmailTemplateFilterInput, $limit: Int, $nextToken: String) {
        listEmailTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            html
            id
            name
            subject
            tenantID
            text
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmailTemplatesQuery>response.data.listEmailTemplates;
  }
  async ListEmailTemplatesByTenantId(
    filter?: ModelEmailTemplateFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListEmailTemplatesByTenantIdQuery> {
    const statement = `query ListEmailTemplatesByTenantId($filter: ModelEmailTemplateFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listEmailTemplatesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            html
            id
            name
            subject
            tenantID
            text
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmailTemplatesByTenantIdQuery>(
      response.data.listEmailTemplatesByTenantId
    );
  }
  async ListEmployees(
    filter?: ModelEmployeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEmployeesQuery> {
    const statement = `query ListEmployees($filter: ModelEmployeeFilterInput, $limit: Int, $nextToken: String) {
        listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeesQuery>response.data.listEmployees;
  }
  async ListEmployeesByTenantId(
    filter?: ModelEmployeeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListEmployeesByTenantIdQuery> {
    const statement = `query ListEmployeesByTenantId($filter: ModelEmployeeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listEmployeesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeesByTenantIdQuery>response.data.listEmployeesByTenantId;
  }
  async ListEmployeesByTenantIdByCode(
    code?: ModelStringKeyConditionInput,
    filter?: ModelEmployeeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListEmployeesByTenantIdByCodeQuery> {
    const statement = `query ListEmployeesByTenantIdByCode($code: ModelStringKeyConditionInput, $filter: ModelEmployeeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listEmployeesByTenantIdByCode(
          code: $code
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            code
            createdAt
            email
            firstName
            id
            jobs {
              __typename
              items {
                __typename
                createdAt
                employeeID
                entityType
                id
                payRate
                tenantID
                typeID
                updatedAt
                venueID
              }
              nextToken
            }
            lastName
            phone
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (code) {
      gqlAPIServiceArguments.code = code;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmployeesByTenantIdByCodeQuery>(
      response.data.listEmployeesByTenantIdByCode
    );
  }
  async ListFees(
    filter?: ModelFeeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFeesQuery> {
    const statement = `query ListFees($filter: ModelFeeFilterInput, $limit: Int, $nextToken: String) {
        listFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            amount
            createdAt
            id
            inclusive
            name
            rate
            taxed
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFeesQuery>response.data.listFees;
  }
  async ListFeesByTenantId(
    filter?: ModelFeeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListFeesByTenantIdQuery> {
    const statement = `query ListFeesByTenantId($filter: ModelFeeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listFeesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            amount
            createdAt
            id
            inclusive
            name
            rate
            taxed
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFeesByTenantIdQuery>response.data.listFeesByTenantId;
  }
  async ListJobTypes(
    filter?: ModelJobTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListJobTypesQuery> {
    const statement = `query ListJobTypes($filter: ModelJobTypeFilterInput, $limit: Int, $nextToken: String) {
        listJobTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListJobTypesQuery>response.data.listJobTypes;
  }
  async ListJobTypesByTenantId(
    filter?: ModelJobTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListJobTypesByTenantIdQuery> {
    const statement = `query ListJobTypesByTenantId($filter: ModelJobTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listJobTypesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListJobTypesByTenantIdQuery>response.data.listJobTypesByTenantId;
  }
  async ListJobTypesByVenueId(
    filter?: ModelJobTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListJobTypesByVenueIdQuery> {
    const statement = `query ListJobTypesByVenueId($filter: ModelJobTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listJobTypesByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListJobTypesByVenueIdQuery>response.data.listJobTypesByVenueId;
  }
  async ListJobs(
    filter?: ModelJobFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListJobsQuery> {
    const statement = `query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
        listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            employee {
              __typename
              active
              code
              createdAt
              email
              firstName
              id
              jobs {
                __typename
                nextToken
              }
              lastName
              phone
              tenantID
              updatedAt
            }
            employeeID
            entityType
            id
            payRate
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            type {
              __typename
              createdAt
              id
              name
              permissions
              tenantID
              updatedAt
              venueID
            }
            typeID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListJobsQuery>response.data.listJobs;
  }
  async ListLicenses(
    filter?: ModelLicenseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLicensesQuery> {
    const statement = `query ListLicenses($filter: ModelLicenseFilterInput, $limit: Int, $nextToken: String) {
        listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            createdAt
            deviceDetails {
              __typename
              deviceID
              displayName
              heartbeat
              initTime
              name
              online
              version
            }
            devices {
              __typename
              items {
                __typename
                active
                autoPrint
                createdAt
                deviceID
                displayName
                host
                id
                ipAddress
                licenseID
                name
                port
                printOnBump
                serialNumber
                type
                updatedAt
              }
              nextToken
            }
            displayName
            guid
            id
            kioskConfig {
              __typename
              orderLinkID
              showSettings
            }
            kitchenConfig {
              __typename
              showSettings
            }
            name
            pollingInterval
            receiptConfig {
              __typename
              createdAt
              headerSize
              id
              itemSize
              name
              showDiscounts
              showFees
              showPayments
              showPrice
              showQR
              showRemaining
              showSubtotal
              showTaxes
              showTips
              showTransactions
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueSize
            }
            receiptConfigID
            status
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                createdAt
                id
                licenseID
                updatedAt
                venueID
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLicensesQuery>response.data.listLicenses;
  }
  async ListLicensesByGuid(
    filter?: ModelLicenseFilterInput,
    guid?: string,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListLicensesByGuidQuery> {
    const statement = `query ListLicensesByGuid($filter: ModelLicenseFilterInput, $guid: ID, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listLicensesByGuid(
          filter: $filter
          guid: $guid
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            createdAt
            deviceDetails {
              __typename
              deviceID
              displayName
              heartbeat
              initTime
              name
              online
              version
            }
            devices {
              __typename
              items {
                __typename
                active
                autoPrint
                createdAt
                deviceID
                displayName
                host
                id
                ipAddress
                licenseID
                name
                port
                printOnBump
                serialNumber
                type
                updatedAt
              }
              nextToken
            }
            displayName
            guid
            id
            kioskConfig {
              __typename
              orderLinkID
              showSettings
            }
            kitchenConfig {
              __typename
              showSettings
            }
            name
            pollingInterval
            receiptConfig {
              __typename
              createdAt
              headerSize
              id
              itemSize
              name
              showDiscounts
              showFees
              showPayments
              showPrice
              showQR
              showRemaining
              showSubtotal
              showTaxes
              showTips
              showTransactions
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueSize
            }
            receiptConfigID
            status
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                createdAt
                id
                licenseID
                updatedAt
                venueID
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (guid) {
      gqlAPIServiceArguments.guid = guid;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLicensesByGuidQuery>response.data.listLicensesByGuid;
  }
  async ListLicensesByTenantId(
    filter?: ModelLicenseFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListLicensesByTenantIdQuery> {
    const statement = `query ListLicensesByTenantId($filter: ModelLicenseFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listLicensesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            createdAt
            deviceDetails {
              __typename
              deviceID
              displayName
              heartbeat
              initTime
              name
              online
              version
            }
            devices {
              __typename
              items {
                __typename
                active
                autoPrint
                createdAt
                deviceID
                displayName
                host
                id
                ipAddress
                licenseID
                name
                port
                printOnBump
                serialNumber
                type
                updatedAt
              }
              nextToken
            }
            displayName
            guid
            id
            kioskConfig {
              __typename
              orderLinkID
              showSettings
            }
            kitchenConfig {
              __typename
              showSettings
            }
            name
            pollingInterval
            receiptConfig {
              __typename
              createdAt
              headerSize
              id
              itemSize
              name
              showDiscounts
              showFees
              showPayments
              showPrice
              showQR
              showRemaining
              showSubtotal
              showTaxes
              showTips
              showTransactions
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueSize
            }
            receiptConfigID
            status
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                createdAt
                id
                licenseID
                updatedAt
                venueID
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLicensesByTenantIdQuery>response.data.listLicensesByTenantId;
  }
  async ListLocations(
    filter?: ModelLocationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLocationsQuery> {
    const statement = `query ListLocations($filter: ModelLocationFilterInput, $limit: Int, $nextToken: String) {
        listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocationsQuery>response.data.listLocations;
  }
  async ListLocationsByEndOfDay(
    endOfDay?: string,
    filter?: ModelLocationFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListLocationsByEndOfDayQuery> {
    const statement = `query ListLocationsByEndOfDay($endOfDay: AWSTime, $filter: ModelLocationFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listLocationsByEndOfDay(
          endOfDay: $endOfDay
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (endOfDay) {
      gqlAPIServiceArguments.endOfDay = endOfDay;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocationsByEndOfDayQuery>response.data.listLocationsByEndOfDay;
  }
  async ListLocationsByTenantId(
    filter?: ModelLocationFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListLocationsByTenantIdQuery> {
    const statement = `query ListLocationsByTenantId($filter: ModelLocationFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listLocationsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocationsByTenantIdQuery>response.data.listLocationsByTenantId;
  }
  async ListLogs(
    filter?: ModelLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLogsQuery> {
    const statement = `query ListLogs($filter: ModelLogFilterInput, $limit: Int, $nextToken: String) {
        listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            checkID
            createdAt
            employee {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            employeeID
            event
            id
            message
            orderID
            source
            tenantID
            type
            updatedAt
            user {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            userID
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLogsQuery>response.data.listLogs;
  }
  async ListLogsByCheckId(
    checkID?: string,
    filter?: ModelLogFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListLogsByCheckIdQuery> {
    const statement = `query ListLogsByCheckId($checkID: ID, $filter: ModelLogFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listLogsByCheckId(
          checkID: $checkID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            checkID
            createdAt
            employee {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            employeeID
            event
            id
            message
            orderID
            source
            tenantID
            type
            updatedAt
            user {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            userID
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (checkID) {
      gqlAPIServiceArguments.checkID = checkID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLogsByCheckIdQuery>response.data.listLogsByCheckId;
  }
  async ListLogsByOrderId(
    filter?: ModelLogFilterInput,
    limit?: number,
    nextToken?: string,
    orderID?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListLogsByOrderIdQuery> {
    const statement = `query ListLogsByOrderId($filter: ModelLogFilterInput, $limit: Int, $nextToken: String, $orderID: ID, $sortDirection: ModelSortDirection) {
        listLogsByOrderId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          orderID: $orderID
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            checkID
            createdAt
            employee {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            employeeID
            event
            id
            message
            orderID
            source
            tenantID
            type
            updatedAt
            user {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            userID
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (orderID) {
      gqlAPIServiceArguments.orderID = orderID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLogsByOrderIdQuery>response.data.listLogsByOrderId;
  }
  async ListLogsByTenantId(
    filter?: ModelLogFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListLogsByTenantIdQuery> {
    const statement = `query ListLogsByTenantId($filter: ModelLogFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listLogsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            checkID
            createdAt
            employee {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            employeeID
            event
            id
            message
            orderID
            source
            tenantID
            type
            updatedAt
            user {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            userID
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLogsByTenantIdQuery>response.data.listLogsByTenantId;
  }
  async ListLogsByVenueId(
    filter?: ModelLogFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListLogsByVenueIdQuery> {
    const statement = `query ListLogsByVenueId($filter: ModelLogFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listLogsByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            checkID
            createdAt
            employee {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            employeeID
            event
            id
            message
            orderID
            source
            tenantID
            type
            updatedAt
            user {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            userID
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLogsByVenueIdQuery>response.data.listLogsByVenueId;
  }
  async ListMenuCategorys(
    filter?: ModelMenuCategoryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenuCategorysQuery> {
    const statement = `query ListMenuCategorys($filter: ModelMenuCategoryFilterInput, $limit: Int, $nextToken: String) {
        listMenuCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuCategorysQuery>response.data.listMenuCategorys;
  }
  async ListMenuCategorysByConceptId(
    conceptID?: string,
    filter?: ModelMenuCategoryFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMenuCategorysByConceptIdQuery> {
    const statement = `query ListMenuCategorysByConceptId($conceptID: ID, $filter: ModelMenuCategoryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMenuCategorysByConceptId(
          conceptID: $conceptID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (conceptID) {
      gqlAPIServiceArguments.conceptID = conceptID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuCategorysByConceptIdQuery>(
      response.data.listMenuCategorysByConceptId
    );
  }
  async ListMenuCategorysByTenantId(
    filter?: ModelMenuCategoryFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListMenuCategorysByTenantIdQuery> {
    const statement = `query ListMenuCategorysByTenantId($filter: ModelMenuCategoryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listMenuCategorysByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuCategorysByTenantIdQuery>(
      response.data.listMenuCategorysByTenantId
    );
  }
  async ListMenuCategorysByVenueId(
    filter?: ModelMenuCategoryFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListMenuCategorysByVenueIdQuery> {
    const statement = `query ListMenuCategorysByVenueId($filter: ModelMenuCategoryFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listMenuCategorysByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuCategorysByVenueIdQuery>(
      response.data.listMenuCategorysByVenueId
    );
  }
  async ListMenuItemPriceTypes(
    filter?: ModelMenuItemPriceTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenuItemPriceTypesQuery> {
    const statement = `query ListMenuItemPriceTypes($filter: ModelMenuItemPriceTypeFilterInput, $limit: Int, $nextToken: String) {
        listMenuItemPriceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuItemPriceTypesQuery>response.data.listMenuItemPriceTypes;
  }
  async ListMenuItemPriceTypesByTenantId(
    filter?: ModelMenuItemPriceTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListMenuItemPriceTypesByTenantIdQuery> {
    const statement = `query ListMenuItemPriceTypesByTenantId($filter: ModelMenuItemPriceTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listMenuItemPriceTypesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuItemPriceTypesByTenantIdQuery>(
      response.data.listMenuItemPriceTypesByTenantId
    );
  }
  async ListMenuItems(
    filter?: ModelMenuItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenuItemsQuery> {
    const statement = `query ListMenuItems($filter: ModelMenuItemFilterInput, $limit: Int, $nextToken: String) {
        listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuItemsQuery>response.data.listMenuItems;
  }
  async ListMenuItemsByConceptId(
    conceptID?: string,
    filter?: ModelMenuItemFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMenuItemsByConceptIdQuery> {
    const statement = `query ListMenuItemsByConceptId($conceptID: ID, $filter: ModelMenuItemFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMenuItemsByConceptId(
          conceptID: $conceptID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (conceptID) {
      gqlAPIServiceArguments.conceptID = conceptID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuItemsByConceptIdQuery>(
      response.data.listMenuItemsByConceptId
    );
  }
  async ListMenuItemsByTenantId(
    filter?: ModelMenuItemFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListMenuItemsByTenantIdQuery> {
    const statement = `query ListMenuItemsByTenantId($filter: ModelMenuItemFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listMenuItemsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuItemsByTenantIdQuery>response.data.listMenuItemsByTenantId;
  }
  async ListMenuItemsByVenueId(
    filter?: ModelMenuItemFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListMenuItemsByVenueIdQuery> {
    const statement = `query ListMenuItemsByVenueId($filter: ModelMenuItemFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listMenuItemsByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            active
            alcohol
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuItemID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            id
            imageS3
            modifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            options {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                name
                updatedAt
              }
              nextToken
            }
            parentModifierGroups {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            popular
            prepTime
            prices {
              __typename
              items {
                __typename
                active
                createdAt
                id
                menuItemID
                menuItemOptionID
                price
                priceTypeID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                tagID
                updatedAt
              }
              nextToken
            }
            taxes {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                taxTypeID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            utensils
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenuItemsByVenueIdQuery>response.data.listMenuItemsByVenueId;
  }
  async ListMenus(
    filter?: ModelMenuFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMenusQuery> {
    const statement = `query ListMenus($filter: ModelMenuFilterInput, $limit: Int, $nextToken: String) {
        listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenusQuery>response.data.listMenus;
  }
  async ListMenusByConceptId(
    conceptID?: string,
    filter?: ModelMenuFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListMenusByConceptIdQuery> {
    const statement = `query ListMenusByConceptId($conceptID: ID, $filter: ModelMenuFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listMenusByConceptId(
          conceptID: $conceptID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (conceptID) {
      gqlAPIServiceArguments.conceptID = conceptID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenusByConceptIdQuery>response.data.listMenusByConceptId;
  }
  async ListMenusByTenantId(
    filter?: ModelMenuFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListMenusByTenantIdQuery> {
    const statement = `query ListMenusByTenantId($filter: ModelMenuFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listMenusByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenusByTenantIdQuery>response.data.listMenusByTenantId;
  }
  async ListMenusByVenueId(
    filter?: ModelMenuFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListMenusByVenueIdQuery> {
    const statement = `query ListMenusByVenueId($filter: ModelMenuFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listMenusByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            active
            categories {
              __typename
              items {
                __typename
                createdAt
                id
                menuCategoryID
                menuID
                sortOrder
                updatedAt
              }
              nextToken
            }
            conceptID
            createdAt
            description
            displayName
            facilitators {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            featured
            id
            imageS3Featured
            imageS3Headers
            imageS3HeadersMobile
            imageS3LogoSquare
            name
            orderLinks {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            popular
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                scheduleID
                updatedAt
              }
              nextToken
            }
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMenusByVenueIdQuery>response.data.listMenusByVenueId;
  }
  async ListModifierGroups(
    filter?: ModelModifierGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListModifierGroupsQuery> {
    const statement = `query ListModifierGroups($filter: ModelModifierGroupFilterInput, $limit: Int, $nextToken: String) {
        listModifierGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListModifierGroupsQuery>response.data.listModifierGroups;
  }
  async ListModifierGroupsByConceptId(
    conceptID?: string,
    filter?: ModelModifierGroupFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListModifierGroupsByConceptIdQuery> {
    const statement = `query ListModifierGroupsByConceptId($conceptID: ID, $filter: ModelModifierGroupFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listModifierGroupsByConceptId(
          conceptID: $conceptID
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (conceptID) {
      gqlAPIServiceArguments.conceptID = conceptID;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListModifierGroupsByConceptIdQuery>(
      response.data.listModifierGroupsByConceptId
    );
  }
  async ListModifierGroupsByTenantId(
    filter?: ModelModifierGroupFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListModifierGroupsByTenantIdQuery> {
    const statement = `query ListModifierGroupsByTenantId($filter: ModelModifierGroupFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listModifierGroupsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListModifierGroupsByTenantIdQuery>(
      response.data.listModifierGroupsByTenantId
    );
  }
  async ListModifierGroupsByVenueId(
    filter?: ModelModifierGroupFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListModifierGroupsByVenueIdQuery> {
    const statement = `query ListModifierGroupsByVenueId($filter: ModelModifierGroupFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listModifierGroupsByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            active
            conceptID
            createdAt
            description
            displayName
            id
            max
            menuItems {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                sortOrder
                updatedAt
              }
              nextToken
            }
            min
            modifiers {
              __typename
              items {
                __typename
                createdAt
                id
                menuItemID
                modifierGroupID
                updatedAt
              }
              nextToken
            }
            name
            priceTypeID
            selectionType
            tags {
              __typename
              items {
                __typename
                createdAt
                id
                modifierGroupID
                tagID
                updatedAt
              }
              nextToken
            }
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListModifierGroupsByVenueIdQuery>(
      response.data.listModifierGroupsByVenueId
    );
  }
  async ListNotifications(
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotificationsQuery> {
    const statement = `query ListNotifications($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String) {
        listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            emailBCC
            emailCC
            emailFrom
            emailReplyTo
            emailTemplate {
              __typename
              createdAt
              html
              id
              name
              subject
              tenantID
              text
              updatedAt
            }
            emailTemplateData
            emailTemplateID
            emailTo
            id
            message
            name
            smsFrom
            smsTo
            smsToDynamic
            smsToType
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationsQuery>response.data.listNotifications;
  }
  async ListNotificationsByTenantId(
    filter?: ModelNotificationFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListNotificationsByTenantIdQuery> {
    const statement = `query ListNotificationsByTenantId($filter: ModelNotificationFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listNotificationsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            emailBCC
            emailCC
            emailFrom
            emailReplyTo
            emailTemplate {
              __typename
              createdAt
              html
              id
              name
              subject
              tenantID
              text
              updatedAt
            }
            emailTemplateData
            emailTemplateID
            emailTo
            id
            message
            name
            smsFrom
            smsTo
            smsToDynamic
            smsToType
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotificationsByTenantIdQuery>(
      response.data.listNotificationsByTenantId
    );
  }
  async ListOrderLinks(
    filter?: ModelOrderLinkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrderLinksQuery> {
    const statement = `query ListOrderLinks($filter: ModelOrderLinkFilterInput, $limit: Int, $nextToken: String) {
        listOrderLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderLinksQuery>response.data.listOrderLinks;
  }
  async ListOrderLinksByDomain(
    domain?: string,
    filter?: ModelOrderLinkFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListOrderLinksByDomainQuery> {
    const statement = `query ListOrderLinksByDomain($domain: AWSURL, $filter: ModelOrderLinkFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listOrderLinksByDomain(
          domain: $domain
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (domain) {
      gqlAPIServiceArguments.domain = domain;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderLinksByDomainQuery>response.data.listOrderLinksByDomain;
  }
  async ListOrderLinksByTenantId(
    filter?: ModelOrderLinkFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListOrderLinksByTenantIdQuery> {
    const statement = `query ListOrderLinksByTenantId($filter: ModelOrderLinkFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listOrderLinksByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderLinksByTenantIdQuery>(
      response.data.listOrderLinksByTenantId
    );
  }
  async ListOrderTypes(
    filter?: ModelOrderTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrderTypesQuery> {
    const statement = `query ListOrderTypes($filter: ModelOrderTypeFilterInput, $limit: Int, $nextToken: String) {
        listOrderTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderTypesQuery>response.data.listOrderTypes;
  }
  async ListOrderTypesByTenantId(
    filter?: ModelOrderTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListOrderTypesByTenantIdQuery> {
    const statement = `query ListOrderTypesByTenantId($filter: ModelOrderTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listOrderTypesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderTypesByTenantIdQuery>(
      response.data.listOrderTypesByTenantId
    );
  }
  async ListOrders(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrdersQuery> {
    const statement = `query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersQuery>response.data.listOrders;
  }
  async ListOrdersByLocationIdByServiceTypeByStatus(
    filter?: ModelOrderFilterInput,
    limit?: number,
    locationID?: string,
    nextToken?: string,
    serviceTypeStatus?: ModelOrderByLocationByServiceTypeByStatusCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection
  ): Promise<ListOrdersByLocationIdByServiceTypeByStatusQuery> {
    const statement = `query ListOrdersByLocationIdByServiceTypeByStatus($filter: ModelOrderFilterInput, $limit: Int, $locationID: ID, $nextToken: String, $serviceTypeStatus: ModelOrderByLocationByServiceTypeByStatusCompositeKeyConditionInput, $sortDirection: ModelSortDirection) {
        listOrdersByLocationIdByServiceTypeByStatus(
          filter: $filter
          limit: $limit
          locationID: $locationID
          nextToken: $nextToken
          serviceTypeStatus: $serviceTypeStatus
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (locationID) {
      gqlAPIServiceArguments.locationID = locationID;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (serviceTypeStatus) {
      gqlAPIServiceArguments.serviceTypeStatus = serviceTypeStatus;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersByLocationIdByServiceTypeByStatusQuery>(
      response.data.listOrdersByLocationIdByServiceTypeByStatus
    );
  }
  async ListOrdersByOrderNumber(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string,
    orderNumber?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListOrdersByOrderNumberQuery> {
    const statement = `query ListOrdersByOrderNumber($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $orderNumber: String, $sortDirection: ModelSortDirection) {
        listOrdersByOrderNumber(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          orderNumber: $orderNumber
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (orderNumber) {
      gqlAPIServiceArguments.orderNumber = orderNumber;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersByOrderNumberQuery>response.data.listOrdersByOrderNumber;
  }
  async ListOrdersByStatusByQueueTime(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string,
    queueTime?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    status?: OrderStatus
  ): Promise<ListOrdersByStatusByQueueTimeQuery> {
    const statement = `query ListOrdersByStatusByQueueTime($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $queueTime: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $status: OrderStatus) {
        listOrdersByStatusByQueueTime(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          queueTime: $queueTime
          sortDirection: $sortDirection
          status: $status
        ) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (queueTime) {
      gqlAPIServiceArguments.queueTime = queueTime;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersByStatusByQueueTimeQuery>(
      response.data.listOrdersByStatusByQueueTime
    );
  }
  async ListOrdersByTenantId(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListOrdersByTenantIdQuery> {
    const statement = `query ListOrdersByTenantId($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listOrdersByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersByTenantIdQuery>response.data.listOrdersByTenantId;
  }
  async ListOrdersByTenantIdByStatusByDueTime(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    statusDueTime?: ModelOrderByTenantByStatusByDueTimeCompositeKeyConditionInput,
    tenantID?: string
  ): Promise<ListOrdersByTenantIdByStatusByDueTimeQuery> {
    const statement = `query ListOrdersByTenantIdByStatusByDueTime($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $statusDueTime: ModelOrderByTenantByStatusByDueTimeCompositeKeyConditionInput, $tenantID: ID) {
        listOrdersByTenantIdByStatusByDueTime(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          statusDueTime: $statusDueTime
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (statusDueTime) {
      gqlAPIServiceArguments.statusDueTime = statusDueTime;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersByTenantIdByStatusByDueTimeQuery>(
      response.data.listOrdersByTenantIdByStatusByDueTime
    );
  }
  async ListPaymentMethods(
    filter?: ModelPaymentMethodFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    token?: string
  ): Promise<ListPaymentMethodsQuery> {
    const statement = `query ListPaymentMethods($filter: ModelPaymentMethodFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $token: ID) {
        listPaymentMethods(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          token: $token
        ) {
          __typename
          items {
            __typename
            cardExpiration
            cardPresent
            cardType
            createdAt
            name
            processor
            token
            type
            updatedAt
            zipcode
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (token) {
      gqlAPIServiceArguments.token = token;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPaymentMethodsQuery>response.data.listPaymentMethods;
  }
  async ListPrintJobs(
    filter?: ModelPrintJobFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPrintJobsQuery> {
    const statement = `query ListPrintJobs($filter: ModelPrintJobFilterInput, $limit: Int, $nextToken: String) {
        listPrintJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            data
            expirationUnixTime
            id
            serialNumber
            status
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPrintJobsQuery>response.data.listPrintJobs;
  }
  async ListPrintJobsBySerialNumberByStatus(
    filter?: ModelPrintJobFilterInput,
    limit?: number,
    nextToken?: string,
    serialNumber?: string,
    sortDirection?: ModelSortDirection,
    status?: ModelStringKeyConditionInput
  ): Promise<ListPrintJobsBySerialNumberByStatusQuery> {
    const statement = `query ListPrintJobsBySerialNumberByStatus($filter: ModelPrintJobFilterInput, $limit: Int, $nextToken: String, $serialNumber: ID, $sortDirection: ModelSortDirection, $status: ModelStringKeyConditionInput) {
        listPrintJobsBySerialNumberByStatus(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          serialNumber: $serialNumber
          sortDirection: $sortDirection
          status: $status
        ) {
          __typename
          items {
            __typename
            createdAt
            data
            expirationUnixTime
            id
            serialNumber
            status
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (serialNumber) {
      gqlAPIServiceArguments.serialNumber = serialNumber;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPrintJobsBySerialNumberByStatusQuery>(
      response.data.listPrintJobsBySerialNumberByStatus
    );
  }
  async ListPrinters(
    filter?: ModelPrinterFilterInput,
    limit?: number,
    nextToken?: string,
    serialNumber?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListPrintersQuery> {
    const statement = `query ListPrinters($filter: ModelPrinterFilterInput, $limit: Int, $nextToken: String, $serialNumber: ID, $sortDirection: ModelSortDirection) {
        listPrinters(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          serialNumber: $serialNumber
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            createdAt
            serialNumber
            status
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (serialNumber) {
      gqlAPIServiceArguments.serialNumber = serialNumber;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPrintersQuery>response.data.listPrinters;
  }
  async ListReceiptConfigs(
    filter?: ModelReceiptConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListReceiptConfigsQuery> {
    const statement = `query ListReceiptConfigs($filter: ModelReceiptConfigFilterInput, $limit: Int, $nextToken: String) {
        listReceiptConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReceiptConfigsQuery>response.data.listReceiptConfigs;
  }
  async ListReceiptConfigsByTenantId(
    filter?: ModelReceiptConfigFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListReceiptConfigsByTenantIdQuery> {
    const statement = `query ListReceiptConfigsByTenantId($filter: ModelReceiptConfigFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listReceiptConfigsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListReceiptConfigsByTenantIdQuery>(
      response.data.listReceiptConfigsByTenantId
    );
  }
  async ListSMSMessages(
    filter?: ModelSMSMessageFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSMSMessagesQuery> {
    const statement = `query ListSMSMessages($filter: ModelSMSMessageFilterInput, $limit: Int, $nextToken: String) {
        listSMSMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            from
            id
            message
            orderID
            tenantID
            to
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSMSMessagesQuery>response.data.listSMSMessages;
  }
  async ListSchedules(
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchedulesQuery> {
    const statement = `query ListSchedules($filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String) {
        listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesQuery>response.data.listSchedules;
  }
  async ListSchedulesByTenantId(
    filter?: ModelScheduleFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListSchedulesByTenantIdQuery> {
    const statement = `query ListSchedulesByTenantId($filter: ModelScheduleFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listSchedulesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            endDate
            id
            name
            startDate
            tenantID
            timeRanges {
              __typename
              day
              endTime
              startTime
            }
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchedulesByTenantIdQuery>response.data.listSchedulesByTenantId;
  }
  async ListSmsByTenantId(
    filter?: ModelSMSMessageFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListSmsByTenantIdQuery> {
    const statement = `query ListSmsByTenantId($filter: ModelSMSMessageFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listSmsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            from
            id
            message
            orderID
            tenantID
            to
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSmsByTenantIdQuery>response.data.listSmsByTenantId;
  }
  async ListTags(
    filter?: ModelTagFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTagsQuery> {
    const statement = `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
        listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTagsQuery>response.data.listTags;
  }
  async ListTagsByTenantId(
    filter?: ModelTagFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListTagsByTenantIdQuery> {
    const statement = `query ListTagsByTenantId($filter: ModelTagFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listTagsByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            imageS3Square
            name
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTagsByTenantIdQuery>response.data.listTagsByTenantId;
  }
  async ListTaxRates(
    filter?: ModelTaxRateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaxRatesQuery> {
    const statement = `query ListTaxRates($filter: ModelTaxRateFilterInput, $limit: Int, $nextToken: String) {
        listTaxRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            amount
            createdAt
            id
            rate
            taxType {
              __typename
              createdAt
              id
              name
              tenantID
              type
              updatedAt
            }
            taxTypeID
            type
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxRatesQuery>response.data.listTaxRates;
  }
  async ListTaxRatesByVenueId(
    filter?: ModelTaxRateFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListTaxRatesByVenueIdQuery> {
    const statement = `query ListTaxRatesByVenueId($filter: ModelTaxRateFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listTaxRatesByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            amount
            createdAt
            id
            rate
            taxType {
              __typename
              createdAt
              id
              name
              tenantID
              type
              updatedAt
            }
            taxTypeID
            type
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxRatesByVenueIdQuery>response.data.listTaxRatesByVenueId;
  }
  async ListTaxTypes(
    filter?: ModelTaxTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaxTypesQuery> {
    const statement = `query ListTaxTypes($filter: ModelTaxTypeFilterInput, $limit: Int, $nextToken: String) {
        listTaxTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxTypesQuery>response.data.listTaxTypes;
  }
  async ListTaxTypesByTenantId(
    filter?: ModelTaxTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListTaxTypesByTenantIdQuery> {
    const statement = `query ListTaxTypesByTenantId($filter: ModelTaxTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listTaxTypesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            tenantID
            type
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTaxTypesByTenantIdQuery>response.data.listTaxTypesByTenantId;
  }
  async ListTenants(
    filter?: ModelTenantFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTenantsQuery> {
    const statement = `query ListTenants($filter: ModelTenantFilterInput, $limit: Int, $nextToken: String) {
        listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTenantsQuery>response.data.listTenants;
  }
  async ListTenantsByDomain(
    appDomain?: string,
    filter?: ModelTenantFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListTenantsByDomainQuery> {
    const statement = `query ListTenantsByDomain($appDomain: AWSURL, $filter: ModelTenantFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listTenantsByDomain(
          appDomain: $appDomain
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (appDomain) {
      gqlAPIServiceArguments.appDomain = appDomain;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTenantsByDomainQuery>response.data.listTenantsByDomain;
  }
  async ListTenantsByType(
    filter?: ModelTenantFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    type?: TenantType
  ): Promise<ListTenantsByTypeQuery> {
    const statement = `query ListTenantsByType($filter: ModelTenantFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $type: TenantType) {
        listTenantsByType(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          type: $type
        ) {
          __typename
          items {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTenantsByTypeQuery>response.data.listTenantsByType;
  }
  async ListUserRoleTypes(
    filter?: ModelUserRoleTypeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserRoleTypesQuery> {
    const statement = `query ListUserRoleTypes($filter: ModelUserRoleTypeFilterInput, $limit: Int, $nextToken: String) {
        listUserRoleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserRoleTypesQuery>response.data.listUserRoleTypes;
  }
  async ListUserRoleTypesByTenantId(
    filter?: ModelUserRoleTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListUserRoleTypesByTenantIdQuery> {
    const statement = `query ListUserRoleTypesByTenantId($filter: ModelUserRoleTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listUserRoleTypesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserRoleTypesByTenantIdQuery>(
      response.data.listUserRoleTypesByTenantId
    );
  }
  async ListUserRoleTypesByVenueId(
    filter?: ModelUserRoleTypeFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListUserRoleTypesByVenueIdQuery> {
    const statement = `query ListUserRoleTypesByVenueId($filter: ModelUserRoleTypeFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listUserRoleTypesByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            id
            name
            permissions
            tenantID
            updatedAt
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserRoleTypesByVenueIdQuery>(
      response.data.listUserRoleTypesByVenueId
    );
  }
  async ListUserRoles(
    filter?: ModelUserRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserRolesQuery> {
    const statement = `query ListUserRoles($filter: ModelUserRoleFilterInput, $limit: Int, $nextToken: String) {
        listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            entityType
            id
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            type {
              __typename
              createdAt
              id
              name
              permissions
              tenantID
              updatedAt
              venueID
            }
            typeID
            updatedAt
            user {
              __typename
              createdAt
              email
              firstName
              id
              lastName
              phone
              updatedAt
              userName
              userRoles {
                __typename
                nextToken
              }
            }
            userID
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserRolesQuery>response.data.listUserRoles;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            email
            firstName
            id
            lastName
            phone
            updatedAt
            userName
            userRoles {
              __typename
              items {
                __typename
                createdAt
                entityType
                id
                tenantID
                typeID
                updatedAt
                userID
                venueID
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async ListUsersByUserName(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    userName?: string
  ): Promise<ListUsersByUserNameQuery> {
    const statement = `query ListUsersByUserName($filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $userName: String) {
        listUsersByUserName(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          userName: $userName
        ) {
          __typename
          items {
            __typename
            createdAt
            email
            firstName
            id
            lastName
            phone
            updatedAt
            userName
            userRoles {
              __typename
              items {
                __typename
                createdAt
                entityType
                id
                tenantID
                typeID
                updatedAt
                userID
                venueID
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (userName) {
      gqlAPIServiceArguments.userName = userName;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersByUserNameQuery>response.data.listUsersByUserName;
  }
  async ListVenueOrders(
    filter?: ModelVenueOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVenueOrdersQuery> {
    const statement = `query ListVenueOrders($filter: ModelVenueOrderFilterInput, $limit: Int, $nextToken: String) {
        listVenueOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            dueTime
            id
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenueOrdersQuery>response.data.listVenueOrders;
  }
  async ListVenueOrdersByOrderId(
    filter?: ModelVenueOrderFilterInput,
    limit?: number,
    nextToken?: string,
    orderID?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListVenueOrdersByOrderIdQuery> {
    const statement = `query ListVenueOrdersByOrderId($filter: ModelVenueOrderFilterInput, $limit: Int, $nextToken: String, $orderID: ID, $sortDirection: ModelSortDirection) {
        listVenueOrdersByOrderId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          orderID: $orderID
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            createdAt
            dueTime
            id
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (orderID) {
      gqlAPIServiceArguments.orderID = orderID;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenueOrdersByOrderIdQuery>(
      response.data.listVenueOrdersByOrderId
    );
  }
  async ListVenueOrdersByVenueId(
    filter?: ModelVenueOrderFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListVenueOrdersByVenueIdQuery> {
    const statement = `query ListVenueOrdersByVenueId($filter: ModelVenueOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listVenueOrdersByVenueId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            dueTime
            id
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenueOrdersByVenueIdQuery>(
      response.data.listVenueOrdersByVenueId
    );
  }
  async ListVenueOrdersByVenueIdByDate(
    createdAt?: ModelStringKeyConditionInput,
    filter?: ModelVenueOrderFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    venueID?: string
  ): Promise<ListVenueOrdersByVenueIdByDateQuery> {
    const statement = `query ListVenueOrdersByVenueIdByDate($createdAt: ModelStringKeyConditionInput, $filter: ModelVenueOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $venueID: ID) {
        listVenueOrdersByVenueIdByDate(
          createdAt: $createdAt
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            dueTime
            id
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenueOrdersByVenueIdByDateQuery>(
      response.data.listVenueOrdersByVenueIdByDate
    );
  }
  async ListVenueOrdersByVenueIdByStatusByDueTime(
    filter?: ModelVenueOrderFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    statusDueTime?: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyConditionInput,
    venueID?: string
  ): Promise<ListVenueOrdersByVenueIdByStatusByDueTimeQuery> {
    const statement = `query ListVenueOrdersByVenueIdByStatusByDueTime($filter: ModelVenueOrderFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $statusDueTime: ModelVenueOrderByVenueByStatusByDueTimeCompositeKeyConditionInput, $venueID: ID) {
        listVenueOrdersByVenueIdByStatusByDueTime(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          statusDueTime: $statusDueTime
          venueID: $venueID
        ) {
          __typename
          items {
            __typename
            createdAt
            dueTime
            id
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (statusDueTime) {
      gqlAPIServiceArguments.statusDueTime = statusDueTime;
    }
    if (venueID) {
      gqlAPIServiceArguments.venueID = venueID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenueOrdersByVenueIdByStatusByDueTimeQuery>(
      response.data.listVenueOrdersByVenueIdByStatusByDueTime
    );
  }
  async ListVenues(
    filter?: ModelVenueFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVenuesQuery> {
    const statement = `query ListVenues($filter: ModelVenueFilterInput, $limit: Int, $nextToken: String) {
        listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenuesQuery>response.data.listVenues;
  }
  async ListVenuesByTenantId(
    filter?: ModelVenueFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection,
    tenantID?: string
  ): Promise<ListVenuesByTenantIdQuery> {
    const statement = `query ListVenuesByTenantId($filter: ModelVenueFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection, $tenantID: ID) {
        listVenuesByTenantId(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          tenantID: $tenantID
        ) {
          __typename
          items {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (tenantID) {
      gqlAPIServiceArguments.tenantID = tenantID;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVenuesByTenantIdQuery>response.data.listVenuesByTenantId;
  }
  OnCheckItemByIdListener(
    id: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCheckItemById">>
  > {
    const statement = `subscription OnCheckItemById($id: ID!) {
        onCheckItemById(id: $id) {
          __typename
          alcohol
          checkID
          chit {
            __typename
            createdAt
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            id
            items {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            order {
              __typename
              checks {
                __typename
                nextToken
              }
              chits {
                __typename
                nextToken
              }
              clerkID
              courierCheckin
              courierDetails {
                __typename
                errorCode
                firstName
                id
                lastName
                lastUpdate
                latitude
                longitude
                message
                phone
                pickupTime
                status
                type
              }
              courierPhone
              courierStatus
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              deliveryEstimate
              deliveryID
              deliveryPickupTime
              deliveryProvider
              deliveryQuoteID
              deliveryTime
              diagnostics
              dueTime
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              fascilitatedTenantReportExclusions
              id
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              logs {
                __typename
                nextToken
              }
              orderLink {
                __typename
                active
                adminEmail
                ccCredentialID
                clerkID
                createdAt
                doNotSell
                domain
                exploreHeading
                exploreSubheading
                fascilitatedTenantReportExclusions
                featuredRestarauntHeading
                featuredRestarauntSubheading
                footerURL
                heading
                id
                imageS3EntryHeaders
                imageS3EntryHeadersMobile
                imageS3Footer
                isDefault
                leadTime
                locationID
                menuScollerHeading
                menuScollerSubheading
                name
                notice
                noticeAtCollection
                orderMode
                originEmail
                originLinkFacebook
                originLinkIG
                originName
                originPhone
                popularItemsHeading
                popularItemsSubheading
                popularRestarauntHeading
                popularRestarauntSubheading
                privacyPolicy
                searchPlaceholder
                smsCredentialID
                subHeading
                tenantID
                tos
                updatedAt
              }
              orderLinkID
              orderNumber
              orderType {
                __typename
                active
                asap
                createdAt
                deliveryProvider
                displayName
                id
                name
                schedule
                serviceType
                source
                tenantID
                updatedAt
              }
              orderTypeID
              originAddress {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              originDetails {
                __typename
                city
                country
                distance
                email
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              queueMode
              queueTime
              queuedAt
              readyEstimate
              readyTime
              serviceType
              source
              status
              statusDetails
              statusMessage
              submittedAt
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tenantName
              thirdPartyOrderID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
              venueStatus {
                __typename
                status
                venueID
              }
            }
            orderID
            status
            tenantID
            updatedAt
            venue {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            venueID
            venueName
          }
          chitID
          createdAt
          id
          instructions
          menuID
          menuItemID
          modifiers {
            __typename
            id
            menuItemID
            modifierGroupID
            modifiers {
              __typename
              id
              menuItemID
              modifierGroupID
              modifiers {
                __typename
                id
                menuItemID
                modifierGroupID
                name
                price
                quantity
                unitPrice
              }
              name
              price
              quantity
              tags {
                __typename
                id
                name
                type
              }
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              unitPrice
            }
            name
            price
            quantity
            tags {
              __typename
              id
              name
              type
            }
            taxes {
              __typename
              amount
              name
              taxTypeID
              type
            }
            unitPrice
          }
          name
          price
          priceTypeID
          quantity
          tags {
            __typename
            id
            name
            type
          }
          taxes {
            __typename
            amount
            name
            taxTypeID
            type
          }
          unitPrice
          updatedAt
          utensils
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCheckItemById">>
    >;
  }

  OnChitByIdListener(
    id: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onChitById">>
  > {
    const statement = `subscription OnChitById($id: ID!) {
        onChitById(id: $id) {
          __typename
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          items {
            __typename
            alcohol
            id
            instructions
            modifiers {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            name
            price
            quantity
            status
            utensils
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onChitById">>
    >;
  }

  OnChitByVenueIdListener(
    venueID: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onChitByVenueId">>
  > {
    const statement = `subscription OnChitByVenueId($venueID: ID!) {
        onChitByVenueId(venueID: $venueID) {
          __typename
          createdAt
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          id
          items {
            __typename
            alcohol
            id
            instructions
            modifiers {
              __typename
              alcohol
              id
              instructions
              modifiers {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              name
              price
              quantity
              status
              utensils
            }
            name
            price
            quantity
            status
            utensils
          }
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          tenantID
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      venueID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onChitByVenueId">>
    >;
  }

  OnConceptByTenantIdListener(
    tenantID: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onConceptByTenantId">>
  > {
    const statement = `subscription OnConceptByTenantId($tenantID: ID!) {
        onConceptByTenantId(tenantID: $tenantID) {
          __typename
          createdAt
          id
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          tenantID
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      tenantID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onConceptByTenantId">>
    >;
  }

  OnCreateDomainListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDomain">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDomain {
        onCreateDomain {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateDomain">>
  >;

  OnCreateTenantListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTenant">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTenant {
        onCreateTenant {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateTenant">>
  >;

  OnDeleteDomainListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDomain">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDomain {
        onDeleteDomain {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteDomain">>
  >;

  OnDeleteTenantListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTenant">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTenant {
        onDeleteTenant {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteTenant">>
  >;

  OnDeviceTransactionByDeviceIdListener(
    deviceID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeviceTransactionByDeviceId">
    >
  > {
    const statement = `subscription OnDeviceTransactionByDeviceId($deviceID: ID!) {
        onDeviceTransactionByDeviceId(deviceID: $deviceID) {
          __typename
          createdAt
          data
          deviceID
          event
          expirationUnixTime
          id
          status
          transactionID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      deviceID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeviceTransactionByDeviceId">
      >
    >;
  }

  OnDeviceTransactionByTransactionIdListener(
    transactionID: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeviceTransactionByTransactionId">
    >
  > {
    const statement = `subscription OnDeviceTransactionByTransactionId($transactionID: ID!) {
        onDeviceTransactionByTransactionId(transactionID: $transactionID) {
          __typename
          createdAt
          data
          deviceID
          event
          expirationUnixTime
          id
          status
          transactionID
          type
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      transactionID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeviceTransactionByTransactionId">
      >
    >;
  }

  OnLicenseByDeviceIdListener(
    guid: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onLicenseByDeviceId">>
  > {
    const statement = `subscription OnLicenseByDeviceId($guid: ID!) {
        onLicenseByDeviceId(guid: $guid) {
          __typename
          active
          createdAt
          deviceDetails {
            __typename
            deviceID
            displayName
            heartbeat
            initTime
            name
            online
            version
          }
          devices {
            __typename
            items {
              __typename
              active
              autoPrint
              createdAt
              deviceID
              displayName
              host
              id
              ipAddress
              licenseID
              name
              port
              printOnBump
              serialNumber
              type
              updatedAt
            }
            nextToken
          }
          displayName
          guid
          id
          kioskConfig {
            __typename
            orderLinkID
            showSettings
          }
          kitchenConfig {
            __typename
            showSettings
          }
          name
          pollingInterval
          receiptConfig {
            __typename
            createdAt
            headerSize
            id
            itemSize
            name
            showDiscounts
            showFees
            showPayments
            showPrice
            showQR
            showRemaining
            showSubtotal
            showTaxes
            showTips
            showTransactions
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueSize
          }
          receiptConfigID
          status
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              createdAt
              id
              licenseID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      guid
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onLicenseByDeviceId">>
    >;
  }

  OnOrderByIdListener(
    id: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onOrderById">>
  > {
    const statement = `subscription OnOrderById($id: ID!) {
        onOrderById(id: $id) {
          __typename
          checks {
            __typename
            items {
              __typename
              createdAt
              customer {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              customerDetails {
                __typename
                dob
                email
                firstName
                lastName
                phone
              }
              customerID
              diagnostics
              fees {
                __typename
                amount
                id
                inclusive
                name
                taxed
                type
              }
              id
              items {
                __typename
                nextToken
              }
              logs {
                __typename
                nextToken
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              payments {
                __typename
                nextToken
              }
              readyEstimate
              taxes {
                __typename
                amount
                name
                taxTypeID
                type
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              totals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
              }
              transactions {
                __typename
                nextToken
              }
              updatedAt
              venueTotals {
                __typename
                ccCaptureTotal
                ccRefundTotal
                ccVoidTotal
                checkPercent
                discountTotal
                feeTotal
                feeTotalInclusive
                feesAndTaxTotal
                paymentTotal
                remainingBalance
                subTotal
                taxTotal
                tipTotal
                total
                venueID
              }
            }
            nextToken
          }
          chits {
            __typename
            items {
              __typename
              createdAt
              errors {
                __typename
                code
                data
                details
                message
                type
              }
              id
              items {
                __typename
                alcohol
                id
                instructions
                name
                price
                quantity
                status
                utensils
              }
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          clerkID
          courierCheckin
          courierDetails {
            __typename
            errorCode
            firstName
            id
            lastName
            lastUpdate
            latitude
            longitude
            message
            phone
            pickupTime
            status
            type
          }
          courierPhone
          courierStatus
          createdAt
          customer {
            __typename
            addresses {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            createdAt
            defaultPaymentMethodID
            email
            firstName
            id
            lastName
            paymentMethods {
              __typename
              items {
                __typename
                createdAt
                customerID
                id
                paymentMethodID
                updatedAt
              }
              nextToken
            }
            phone
            updatedAt
            userName
          }
          customerDetails {
            __typename
            address {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            dob
            email
            firstName
            lastName
            phone
          }
          customerID
          deliveryEstimate
          deliveryID
          deliveryPickupTime
          deliveryProvider
          deliveryQuoteID
          deliveryTime
          diagnostics
          dueTime
          errors {
            __typename
            code
            data
            details
            message
            type
          }
          fascilitatedTenantReportExclusions
          id
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          logs {
            __typename
            items {
              __typename
              checkID
              createdAt
              employee {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              employeeID
              event
              id
              message
              orderID
              source
              tenantID
              type
              updatedAt
              user {
                __typename
                createdAt
                defaultPaymentMethodID
                email
                firstName
                id
                lastName
                phone
                updatedAt
                userName
              }
              userID
              venueID
            }
            nextToken
          }
          orderLink {
            __typename
            active
            adminEmail
            ccCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            ccCredentialID
            clerkID
            createdAt
            doNotSell
            domain
            exploreHeading
            exploreSubheading
            fascilitatedTenantReportExclusions
            featuredRestarauntHeading
            featuredRestarauntSubheading
            footerURL
            heading
            id
            imageS3EntryHeaders
            imageS3EntryHeadersMobile
            imageS3Footer
            isDefault
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuScollerHeading
            menuScollerSubheading
            menus {
              __typename
              items {
                __typename
                createdAt
                id
                menuID
                orderLinkID
                sortOrder
                updatedAt
              }
              nextToken
            }
            name
            notice
            noticeAtCollection
            notifications {
              __typename
              items {
                __typename
                createdAt
                event
                id
                notificationID
                orderLinkID
                updatedAt
              }
              nextToken
            }
            orderMode
            orderTypes {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                orderTypeID
                updatedAt
              }
              nextToken
            }
            originEmail
            originLinkFacebook
            originLinkIG
            originName
            originPhone
            popularItemsHeading
            popularItemsSubheading
            popularRestarauntHeading
            popularRestarauntSubheading
            privacyPolicy
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderLinkID
                scheduleID
                updatedAt
              }
              nextToken
            }
            searchPlaceholder
            smsCredential {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            smsCredentialID
            subHeading
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tos
            updatedAt
          }
          orderLinkID
          orderNumber
          orderType {
            __typename
            active
            asap
            createdAt
            deliveryProvider
            displayName
            fees {
              __typename
              items {
                __typename
                createdAt
                feeID
                id
                orderTypeID
                updatedAt
              }
              nextToken
            }
            id
            name
            schedule
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                orderTypeID
                scheduleID
                updatedAt
              }
              nextToken
            }
            serviceType
            source
            tenantID
            updatedAt
          }
          orderTypeID
          originAddress {
            __typename
            city
            country
            distance
            email
            instructions
            isDefault
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          originDetails {
            __typename
            city
            country
            distance
            email
            latitude
            longitude
            name
            phone
            state
            street
            timezone
            unit
            zip
          }
          queueMode
          queueTime
          queuedAt
          readyEstimate
          readyTime
          serviceType
          source
          status
          statusDetails
          statusMessage
          submittedAt
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          tenantName
          thirdPartyOrderID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
          venueStatus {
            __typename
            status
            venueID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onOrderById">>
    >;
  }

  OnUpdateDomainListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDomain">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDomain {
        onUpdateDomain {
          __typename
          createdAt
          host
          id
          imageS3Logo
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateDomain">>
  >;

  OnUpdateTenantListener: Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTenant">>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTenant {
        onUpdateTenant {
          __typename
          appDomain
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          email
          id
          menuFacilitations {
            __typename
            items {
              __typename
              createdAt
              facilitator {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              facilitatorID
              id
              menu {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              menuID
              updatedAt
            }
            nextToken
          }
          name
          orderLinkDomains
          phone
          shift4ApiKey
          shift4ApiSecret
          type
          updatedAt
          venues {
            __typename
            items {
              __typename
              acceptingOrders
              active
              createdAt
              credentials {
                __typename
                nextToken
              }
              id
              imageS3LogoSquare
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuCategories {
                __typename
                nextToken
              }
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              modifierGroups {
                __typename
                nextToken
              }
              name
              schedules {
                __typename
                nextToken
              }
              taxRates {
                __typename
                nextToken
              }
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              updatedAt
              venueOrders {
                __typename
                nextToken
              }
            }
            nextToken
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateTenant">>
  >;

  OnVenueByTenantIdListener(
    tenantID: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onVenueByTenantId">>
  > {
    const statement = `subscription OnVenueByTenantId($tenantID: ID!) {
        onVenueByTenantId(tenantID: $tenantID) {
          __typename
          acceptingOrders
          active
          createdAt
          credentials {
            __typename
            items {
              __typename
              createdAt
              dcEcomMID
              dcMID
              dcTokenKey
              id
              key
              secret
              smsFrom
              tenantID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          id
          imageS3LogoSquare
          leadTime
          location {
            __typename
            city
            country
            createdAt
            email
            endOfDay
            id
            latitude
            longitude
            name
            phone
            state
            street
            tenantID
            timezone
            unit
            updatedAt
            zip
          }
          locationID
          menuCategories {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              menuItems {
                __typename
                nextToken
              }
              menus {
                __typename
                nextToken
              }
              name
              priceTypeID
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          menuItems {
            __typename
            items {
              __typename
              active
              alcohol
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              id
              imageS3
              modifierGroups {
                __typename
                nextToken
              }
              name
              options {
                __typename
                nextToken
              }
              parentModifierGroups {
                __typename
                nextToken
              }
              popular
              prepTime
              prices {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              taxes {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              utensils
              venueID
            }
            nextToken
          }
          menus {
            __typename
            items {
              __typename
              active
              categories {
                __typename
                nextToken
              }
              conceptID
              createdAt
              description
              displayName
              facilitators {
                __typename
                nextToken
              }
              featured
              id
              imageS3Featured
              imageS3Headers
              imageS3HeadersMobile
              imageS3LogoSquare
              name
              orderLinks {
                __typename
                nextToken
              }
              popular
              schedules {
                __typename
                nextToken
              }
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
            }
            nextToken
          }
          modifierGroups {
            __typename
            items {
              __typename
              active
              conceptID
              createdAt
              description
              displayName
              id
              max
              menuItems {
                __typename
                nextToken
              }
              min
              modifiers {
                __typename
                nextToken
              }
              name
              priceTypeID
              selectionType
              tags {
                __typename
                nextToken
              }
              tenantID
              updatedAt
              venueID
            }
            nextToken
          }
          name
          schedules {
            __typename
            items {
              __typename
              createdAt
              id
              schedule {
                __typename
                createdAt
                endDate
                id
                name
                startDate
                tenantID
                type
                updatedAt
              }
              scheduleID
              updatedAt
              venueID
            }
            nextToken
          }
          taxRates {
            __typename
            items {
              __typename
              amount
              createdAt
              id
              rate
              taxType {
                __typename
                createdAt
                id
                name
                tenantID
                type
                updatedAt
              }
              taxTypeID
              type
              updatedAt
              venueID
            }
            nextToken
          }
          tenant {
            __typename
            appDomain
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            email
            id
            menuFacilitations {
              __typename
              items {
                __typename
                createdAt
                facilitatorID
                id
                menuID
                updatedAt
              }
              nextToken
            }
            name
            orderLinkDomains
            phone
            shift4ApiKey
            shift4ApiSecret
            type
            updatedAt
            venues {
              __typename
              items {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              nextToken
            }
          }
          tenantID
          updatedAt
          venueOrders {
            __typename
            items {
              __typename
              createdAt
              dueTime
              id
              order {
                __typename
                clerkID
                courierCheckin
                courierPhone
                courierStatus
                createdAt
                customerID
                deliveryEstimate
                deliveryID
                deliveryPickupTime
                deliveryProvider
                deliveryQuoteID
                deliveryTime
                diagnostics
                dueTime
                fascilitatedTenantReportExclusions
                id
                locationID
                orderLinkID
                orderNumber
                orderTypeID
                queueMode
                queueTime
                queuedAt
                readyEstimate
                readyTime
                serviceType
                source
                status
                statusDetails
                statusMessage
                submittedAt
                tenantID
                tenantName
                thirdPartyOrderID
                updatedAt
              }
              orderID
              status
              updatedAt
              venue {
                __typename
                acceptingOrders
                active
                createdAt
                id
                imageS3LogoSquare
                leadTime
                locationID
                name
                tenantID
                updatedAt
              }
              venueID
              venueName
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      tenantID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onVenueByTenantId">>
    >;
  }

  OnVenueOrderByVenueIdListener(
    venueID: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onVenueOrderByVenueId">>
  > {
    const statement = `subscription OnVenueOrderByVenueId($venueID: ID!) {
        onVenueOrderByVenueId(venueID: $venueID) {
          __typename
          createdAt
          dueTime
          id
          order {
            __typename
            checks {
              __typename
              items {
                __typename
                createdAt
                customerID
                diagnostics
                id
                orderID
                readyEstimate
                tenantID
                updatedAt
              }
              nextToken
            }
            chits {
              __typename
              items {
                __typename
                createdAt
                id
                orderID
                status
                tenantID
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            clerkID
            courierCheckin
            courierDetails {
              __typename
              errorCode
              firstName
              id
              lastName
              lastUpdate
              latitude
              longitude
              message
              phone
              pickupTime
              status
              type
            }
            courierPhone
            courierStatus
            createdAt
            customer {
              __typename
              addresses {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              checks {
                __typename
                nextToken
              }
              createdAt
              defaultPaymentMethodID
              email
              firstName
              id
              lastName
              paymentMethods {
                __typename
                nextToken
              }
              phone
              updatedAt
              userName
            }
            customerDetails {
              __typename
              address {
                __typename
                city
                country
                distance
                email
                instructions
                isDefault
                latitude
                longitude
                name
                phone
                state
                street
                timezone
                unit
                zip
              }
              dob
              email
              firstName
              lastName
              phone
            }
            customerID
            deliveryEstimate
            deliveryID
            deliveryPickupTime
            deliveryProvider
            deliveryQuoteID
            deliveryTime
            diagnostics
            dueTime
            errors {
              __typename
              code
              data
              details
              message
              type
            }
            fascilitatedTenantReportExclusions
            id
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            logs {
              __typename
              items {
                __typename
                checkID
                createdAt
                employeeID
                event
                id
                message
                orderID
                source
                tenantID
                type
                updatedAt
                userID
                venueID
              }
              nextToken
            }
            orderLink {
              __typename
              active
              adminEmail
              ccCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              ccCredentialID
              clerkID
              createdAt
              doNotSell
              domain
              exploreHeading
              exploreSubheading
              fascilitatedTenantReportExclusions
              featuredRestarauntHeading
              featuredRestarauntSubheading
              footerURL
              heading
              id
              imageS3EntryHeaders
              imageS3EntryHeadersMobile
              imageS3Footer
              isDefault
              leadTime
              location {
                __typename
                city
                country
                createdAt
                email
                endOfDay
                id
                latitude
                longitude
                name
                phone
                state
                street
                tenantID
                timezone
                unit
                updatedAt
                zip
              }
              locationID
              menuScollerHeading
              menuScollerSubheading
              menus {
                __typename
                nextToken
              }
              name
              notice
              noticeAtCollection
              notifications {
                __typename
                nextToken
              }
              orderMode
              orderTypes {
                __typename
                nextToken
              }
              originEmail
              originLinkFacebook
              originLinkIG
              originName
              originPhone
              popularItemsHeading
              popularItemsSubheading
              popularRestarauntHeading
              popularRestarauntSubheading
              privacyPolicy
              schedules {
                __typename
                nextToken
              }
              searchPlaceholder
              smsCredential {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              smsCredentialID
              subHeading
              tenant {
                __typename
                appDomain
                createdAt
                email
                id
                name
                orderLinkDomains
                phone
                shift4ApiKey
                shift4ApiSecret
                type
                updatedAt
              }
              tenantID
              tos
              updatedAt
            }
            orderLinkID
            orderNumber
            orderType {
              __typename
              active
              asap
              createdAt
              deliveryProvider
              displayName
              fees {
                __typename
                nextToken
              }
              id
              name
              schedule
              schedules {
                __typename
                nextToken
              }
              serviceType
              source
              tenantID
              updatedAt
            }
            orderTypeID
            originAddress {
              __typename
              city
              country
              distance
              email
              instructions
              isDefault
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            originDetails {
              __typename
              city
              country
              distance
              email
              latitude
              longitude
              name
              phone
              state
              street
              timezone
              unit
              zip
            }
            queueMode
            queueTime
            queuedAt
            readyEstimate
            readyTime
            serviceType
            source
            status
            statusDetails
            statusMessage
            submittedAt
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            tenantName
            thirdPartyOrderID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
            venueStatus {
              __typename
              status
              venueID
            }
          }
          orderID
          status
          updatedAt
          venue {
            __typename
            acceptingOrders
            active
            createdAt
            credentials {
              __typename
              items {
                __typename
                createdAt
                dcEcomMID
                dcMID
                dcTokenKey
                id
                key
                secret
                smsFrom
                tenantID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            id
            imageS3LogoSquare
            leadTime
            location {
              __typename
              city
              country
              createdAt
              email
              endOfDay
              id
              latitude
              longitude
              name
              phone
              state
              street
              tenantID
              timezone
              unit
              updatedAt
              zip
            }
            locationID
            menuCategories {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                name
                priceTypeID
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            menuItems {
              __typename
              items {
                __typename
                active
                alcohol
                conceptID
                createdAt
                description
                id
                imageS3
                name
                popular
                prepTime
                tenantID
                updatedAt
                utensils
                venueID
              }
              nextToken
            }
            menus {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                featured
                id
                imageS3Featured
                imageS3Headers
                imageS3HeadersMobile
                imageS3LogoSquare
                name
                popular
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            modifierGroups {
              __typename
              items {
                __typename
                active
                conceptID
                createdAt
                description
                displayName
                id
                max
                min
                name
                priceTypeID
                selectionType
                tenantID
                updatedAt
                venueID
              }
              nextToken
            }
            name
            schedules {
              __typename
              items {
                __typename
                createdAt
                id
                scheduleID
                updatedAt
                venueID
              }
              nextToken
            }
            taxRates {
              __typename
              items {
                __typename
                amount
                createdAt
                id
                rate
                taxTypeID
                type
                updatedAt
                venueID
              }
              nextToken
            }
            tenant {
              __typename
              appDomain
              createdAt
              credentials {
                __typename
                nextToken
              }
              email
              id
              menuFacilitations {
                __typename
                nextToken
              }
              name
              orderLinkDomains
              phone
              shift4ApiKey
              shift4ApiSecret
              type
              updatedAt
              venues {
                __typename
                nextToken
              }
            }
            tenantID
            updatedAt
            venueOrders {
              __typename
              items {
                __typename
                createdAt
                dueTime
                id
                orderID
                status
                updatedAt
                venueID
                venueName
              }
              nextToken
            }
          }
          venueID
          venueName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      venueID
    };
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onVenueOrderByVenueId">
      >
    >;
  }
}
