<div class="scroller masked" *ngIf="layout === 'horizontal'">
    <drag-scroll #ds [scrollbar-hidden]="true" [scroll-x-wheel-enabled]="true" [drag-scroll-y-disabled]="true">
        <div class="item-wrapper" drag-scroll-item *ngFor="let option of options">
            <div class="item"
                [ngClass]="{'active': option.menuCategory.id === this.menuSvc.activeCategoryOption?.menuCategory.id}"
                (click)="select(option.menuCategory.id)">
                {{option.menuCategory?.name}}
            </div>
        </div>
    </drag-scroll>

    <!-- <div class="scrolling-wrapper">
        <div class="item" *ngFor="let option of options"
            [ngClass]="{'active': option.menuCategory.id === this.menuSvc.activeCategoryOption?.menuCategory.id}"
            (click)="select(option.menuCategory.id)">
            {{option.menuCategory?.name}}
        </div>
    </div> -->
</div>

<div class="list" *ngIf="layout === 'vertical'">
    <div class="menu">
        <div class="name">
            {{menu?.name}}
        </div>
        <div class="hours">

        </div>
    </div>
    <div *ngFor="let option of options" class="item"
        [ngClass]="{'active': option.menuCategory.id === this.menuSvc.activeCategoryOption?.menuCategory.id}"
        (click)="select(option.menuCategory.id)">
        <div class="name">
            {{option.menuCategory?.name}}
        </div>
        <div class="count">
            {{option.items?.length}}
        </div>
    </div>
</div>