import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { OrderMode } from 'src/app/services/API.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ImageService } from 'src/app/services/image.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { MenuCategoryScrollerComponent } from 'src/app/shared/components/menu-category-scroller/menu-category-scroller.component';

@Component({
  selector: 'app-order-menu',
  templateUrl: './order-menu.component.html',
  styleUrls: ['./order-menu.component.scss']
})
export class OrderMenuComponent implements OnInit, AfterViewInit {
  bannerImage: string
  bannerImageMobile: string
  @ViewChildren('categoryElements') categoryElements: QueryList<ElementRef>
  @ViewChild('categoryScroller1') categoryScroller1: MenuCategoryScrollerComponent
  layout = 'grid'
  offset: number
  offsets = []
  @ViewChild('opMenuCategories') opMenuCategories
  @Input() orderMode: OrderMode
  orderModeKiosk = OrderMode.KIOSK
  orderModeOnlineOrder = OrderMode.ONLINE_ORDER
  showOpMenuCategories: boolean
  showTos: boolean

  constructor(
    public app: AppStateService,
    public menuSvc: MenuService,
    public imgSvc: ImageService,
    private viewportScroller: ViewportScroller,
    public orderSvc: OrderService,
    private bpService: BreakpointService) { }

  public onClickCategory(elementId: string): void {
    this.viewportScroller.setOffset([100, 115])
    this.viewportScroller.scrollToAnchor(elementId)
    this.opMenuCategories.hide()
  }

  ngAfterViewInit() {
    this.updateOffsets()
    this.categoryElements.changes.subscribe(_ => {
      this.updateOffsets()
    })
  }

  ngOnInit() {
    this.bpService.isMobile.subscribe(data => {
      if (data) this.layout = 'list'
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateOffsets()
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // const offsetOffset = this.bp.isSize('xs') ? 130 : 130
    const offsetOffset = 130
    const offset = window.pageYOffset + offsetOffset
    if (offset !== this.offset) {
      this.offset = offset
      let active = this.offsets[0]?.id
      let activeName = this.offsets[0]?.name

      this.offsets.forEach((o, idx) => {
        const current = o.offset
        const next = this.offsets[idx + 1]?.offset

        if (current && next) {
          if (offset >= current && offset < next) {
            active = o.id
            activeName = o.name
          }
        } else if (current && !next) {
          if (offset >= current) {
            active = o.id
            activeName = o.name
          }
        }
        // console.log(offset, current, next, o.name)
      })

      // console.log('ACTIVE', activeName)
      this.categoryScroller1.select(active, false)
    }
  }

  updateOffsets() {
    setTimeout(() => {
      this.offsets = []
      this.categoryElements.forEach(e => {
        this.offsets.push({
          id: e.nativeElement.id,
          offset: e.nativeElement.offsetTop,
          name: this.menuSvc.menuCategoryOptions.find(o => o.menuCategory.id === e.nativeElement.id)?.menuCategory.name
        })
      })
      let active = this.offsets[0]?.id
      this.categoryScroller1.select(active, false)
    });
    // console.log('updateOffsets', this.offsets)
  }
}
