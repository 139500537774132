import { Pipe, PipeTransform } from '@angular/core';
import * as short from 'short-uuid'

@Pipe({
  name: 'toLongUUID'
})
export class ToLongUUIDPipe implements PipeTransform {

  transform(value: string): any {
    try {
      const translator = short();
      return translator.toUUID(value)
    } catch (error) {
      return value;
    }
  }

}