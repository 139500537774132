import { Injectable } from '@angular/core';
import Auth, { SignUpParams } from '@aws-amplify/auth';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Address, APIService, CCProcessor, CreateCustomerPaymentMethodInput, CredentialType, Customer, OrderStatus, PaymentMethodType } from './API.service';
import { DataCapService } from './datacap.service';
import { I4GoService } from './i4go.service';
import { OrderService } from './order.service';
import { PaymentService } from './payment.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    addresses: Address[] = []
    authState: BehaviorSubject<AuthState> = new BehaviorSubject(AuthState.SIGNEDOUT)
    checks: Check[] = []
    checksActive: Check[] = []
    checksPast: Check[] = []
    checksPending: Check[] = []
    customer: Customer
    customerInitials: string
    paymentMethods: PaymentMethod[] = []
    showCustomerAccount: boolean
    signInData: any
    username: string

    constructor(
        private messageSvc: MessageService,
        private api: APIService,
        private paymentSvc: PaymentService,
        private orderSvc: OrderService,
        private confirmationService: ConfirmationService) {
        this.paymentSvc.newPaymentMethod.subscribe(paymentMethod => {
            if (paymentMethod) {
                this.addCustomerPaymentMethod(paymentMethod.token)
            }
        })
    }

    async addAddress(address: Address) {
        this.customer.addresses = this.customer.addresses || []
        if (this.customer.addresses.length === 0) {
            address.isDefault = true
        }
        this.customer.addresses.push(address)
        await this.updateCustomer(this.customer)
    }

    async addCustomerPaymentMethod(token: string) {
        const existing = this.customer?.paymentMethods?.items.find(p => p.paymentMethod.token === token)
        if (!existing && this.customer) {
            const input: CreateCustomerPaymentMethodInput = {
                customerID: this.customer.id,
                paymentMethodID: token
            }
            await this.api.CreateCustomerPaymentMethod(input)
            const customer = await this.getCustomer({ customerID: this.customer.id })
            this.loadPaymentMethods(customer)
            if (!this.customer.defaultPaymentMethodID) {
                await this.setDefaultPaymentMethod(token)
            }
        }
    }

    async forgotPassword(email: string) {
        try {
            await Auth.forgotPassword(email)
            this.messageSvc.add({ severity: 'info', detail: 'Please check your email for a confirmation code.' })
        } catch (error) {
            console.error(error)
            this.messageSvc.add({ severity: 'error', detail: error.message })
            throw error
        }
    }

    async forgotPasswordSubmit(email: string, code: string, password: string) {
        try {
            await Auth.forgotPasswordSubmit(email, code, password)
            this.messageSvc.add({ severity: 'info', detail: 'Password successfully changed, please sign in.' })
        } catch (error) {
            console.error(error)
            this.messageSvc.add({ severity: 'error', detail: error.message })
            throw error
        }
    }

    async getCustomer(params: { customerID?: string, email?: string, userName?: string, phone?: string }): Promise<Customer> {
        let customer: Customer

        if (params.customerID) {
            customer = await this.api.GetCustomer(params.customerID) as Customer
        }

        if (!customer && params.email) {
            const data = await this.api.ListCustomersByEmailCustom(params.email)
            customer = data.items[0] as Customer
        }

        // if (!customer && params.userName) {
        //     const data = await this.api.ListCustomersByUserName(params.userName)
        //     customer = data[0]
        // }

        // if (!customer && params.phone) {
        //     const data = await this.api.ListCustomersByUserName(params.userName)
        //     customer = data[0]
        // }

        return customer
    }

    loadAddresses(customer: Customer) {
        this.customer.addresses = this.customer.addresses || []
        this.customer.addresses.forEach(a => {
            delete a.__typename
        })
        this.addresses = customer.addresses
    }

    loadChecks(customer: Customer) {
        this.checks = customer.checks.items.map(c => ({
            id: c.id,
            orderID: c.order.id,
            orderNumber: c.order.orderNumber,
            total: c.totals.total,
            dueTime: c.order.dueTime,
            status: c.order.status,
            serviceType: c.order.serviceType
        }))

        this.checksActive = customer.checks.items.filter(c =>
            c.order.status === OrderStatus.QUEUED ||
            c.order.status === OrderStatus.SUBMITTED ||
            c.order.status === OrderStatus.PREPARED ||
            c.order.status === OrderStatus.COLLECTED ||
            c.order.status === OrderStatus.READY ||
            c.order.status === OrderStatus.ENROUTE).map(c => ({
                id: c.id,
                orderID: c.order.id,
                orderNumber: c.order.orderNumber,
                total: c.totals.total,
                dueTime: c.order.dueTime,
                status: c.order.status,
                serviceType: c.order.serviceType
            }))

            this.checksActive.sort((a, b) => b.dueTime.localeCompare(a.dueTime))
            
        this.checksPast = customer.checks.items.filter(c =>
            c.order.status === OrderStatus.DELIVERED ||
            c.order.status === OrderStatus.ERROR).map(c => ({
                id: c.id,
                orderID: c.order.id,
                orderNumber: c.order.orderNumber,
                total: c.totals.total,
                dueTime: c.order.dueTime,
                status: c.order.status,
                serviceType: c.order.serviceType
            }))

            this.checksPast.sort((a, b) => b.dueTime.localeCompare(a.dueTime))

        this.checksPending = customer.checks.items.filter(c =>
            c.order.status === OrderStatus.PENDING).map(c => ({
                id: c.id,
                orderID: c.order.id,
                orderNumber: c.order.orderNumber,
                total: c.totals.total,
                dueTime: c.order.dueTime,
                status: c.order.status,
                serviceType: c.order.serviceType
            }))

            this.checksPending.sort((a, b) => b.dueTime.localeCompare(a.dueTime))
    }

    loadPaymentMethods(customer: Customer) {
        this.paymentMethods = customer.paymentMethods.items.map(p => ({
            id: p.id,
            token: p.paymentMethod.token,
            type: p.paymentMethod.type,
            name: p.paymentMethod.name,
            zipcode: p.paymentMethod.zipcode,
            cardType: p.paymentMethod.cardType,
            cardExpiration: p.paymentMethod.cardExpiration,
            processor: p.paymentMethod.processor
        }))

        this.paymentMethods.forEach(p => {
            const paymentMethod = {
                token: p.token,
                name: p.name,
                zipcode: p.zipcode,
                type: p.type,
                cardType: p.cardType,
                cardExpiration: p.cardExpiration,
                processor: p.processor
            } as PaymentMethod
            if (this.orderSvc.orderLink.ccCredential.type === CredentialType.SHIFT4 && paymentMethod.processor !== CCProcessor.DATACAP) {
                this.paymentSvc.addPaymentMethod(paymentMethod, true)
            }
            if (this.orderSvc.orderLink.ccCredential.type === CredentialType.DATACAP && paymentMethod.processor === CCProcessor.DATACAP) {
                this.paymentSvc.addPaymentMethod(paymentMethod, true)
            }
        })

        if (customer.defaultPaymentMethodID) {
            const defaultPaymentMethod = this.paymentMethods.find(p => p.token = customer.defaultPaymentMethodID)
            if (defaultPaymentMethod) {
                this.paymentSvc.selectPaymentMethod(defaultPaymentMethod.token)
            }
        }
    }

    async removeAddress(idx: number) {
        this.confirmationService.confirm({
            header: 'Remove Address',
            message: 'Are you sure that you want to remove this address?',
            accept: async () => {
                try {
                    this.customer.addresses.splice(idx, 1)
                    if (this.customer.addresses.length > 0) {
                        let def
                        this.customer.addresses.forEach(a => {
                            if (a.isDefault) def = true
                        })
                        if (!def) {
                            this.customer.addresses[0].isDefault = true
                        }
                    }
                    await this.updateCustomer(this.customer)
                } catch (error) {
                    this.messageSvc.add({ severity: 'error', detail: error.message })
                    throw error
                }
            }
        });
    }

    async removeCustomerPaymentMethod(id: string) {
        this.confirmationService.confirm({
            header: 'Remove Payment Method',
            message: 'Are you sure that you want to remove this payment method?',
            accept: async () => {
                try {
                    await this.api.DeleteCustomerPaymentMethod({ id })
                    let idx = this.paymentMethods.findIndex(p => p.id === id)
                    this.paymentMethods.splice(idx, 1)
                    idx = this.customer.paymentMethods.items.findIndex(p => p.id === id)
                    this.customer.paymentMethods.items.splice(idx, 1)
                    this.paymentSvc.clearPaymentMethods()
                    this.loadPaymentMethods(this.customer)
                } catch (error) {
                    this.messageSvc.add({ severity: 'error', detail: error.message })
                    throw error
                }
            }
        });
    }

    async resendSignUp(email: string) {
        try {
            await Auth.resendSignUp(email)
            this.messageSvc.add({ severity: 'info', detail: 'Please check your email for a new confirmation code.' })
        } catch (error) {
            console.error(error)
            this.messageSvc.add({ severity: 'error', detail: error.message })
            throw error
        }
    }

    async setDefaultAddress(idx: number) {
        this.customer.addresses.forEach((address, index) => {
            if (index === idx) {
                address.isDefault = true
            } else {
                address.isDefault = false
            }
        })
        await this.updateCustomer(this.customer)
    }

    async setDefaultPaymentMethod(token: string) {
        this.customer.defaultPaymentMethodID = token
        await this.updateCustomer(this.customer)
    }

    async signIn(email: string, password: string) {
        try {
            const data = await Auth.signIn(email, password, {
                sessionType: 'CUSTOMER'
            })

            // Amplify.configure({
            //     aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
            //     API: {
            //         graphql_headers: async () => {
            //             const session = await Auth.currentSession()
            //             const token = session.getIdToken().getJwtToken()
            //             return {
            //                 Authorization: token
            //             }
            //         },
            //     }
            // })

            this.authState.next(AuthState.SIGNEDIN)
            this.signInData = data
            this.username = data.username

            this.customer = await this.getCustomer({ email: email })
            // console.log(this.customer)
            this.customerInitials = null
            if (this.customer.firstName && this.customer.lastName) {
                this.customerInitials = `${this.customer.firstName.charAt(0)}${this.customer.lastName.charAt(0)}`
            }
            this.loadChecks(this.customer)
            this.loadPaymentMethods(this.customer)
            this.loadAddresses(this.customer)

            this.showCustomerAccount = true
            // this.dialogSvc.open(CustomerDetailsComponent, {
            //     styleClass: 'dialog-customer dialog-dynamic',
            // })
        } catch (error) {
            console.error(error)
            this.messageSvc.add({ severity: 'error', detail: error.message })
            throw error
        }
    }

    async signOut() {
        await Auth.signOut()

        // Amplify.configure({
        //     aws_appsync_authenticationType: 'AWS_IAM',
        //     API: {
        //         graphql_headers: undefined
        //     }
        // })

        this.authState.next(AuthState.SIGNEDOUT)
        this.signInData = null
        this.username = null
        this.customer = null
        this.showCustomerAccount = false
    }

    async signUp(email: string, password: string) {
        try {
            const params: SignUpParams = {
                username: email,
                password: password
            }
            await Auth.signUp(params)
            this.messageSvc.add({ severity: 'info', detail: 'Please check your email for a confirmation code.' })
        } catch (error) {
            console.error(error)
            this.messageSvc.add({ severity: 'error', detail: error.message })
            throw error
        }
    }

    async signUpConfirm(email: string, code: string) {
        try {
            await Auth.confirmSignUp(email, code)
            this.messageSvc.add({ severity: 'info', detail: 'Account confirmed, please sign in.' })
        } catch (error) {
            console.error(error)
            this.messageSvc.add({ severity: 'error', detail: error.message })
            throw error
        }
    }

    async updateAddress(address: Address, idx: number) {
        this.customer.addresses[idx] = address
        await this.updateCustomer(this.customer)
    }

    async updateCustomer(customer: Customer) {
        this.customer = await this.api.UpdateCustomer({
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone,
            addresses: customer.addresses,
            defaultPaymentMethodID: customer.defaultPaymentMethodID
        }) as Customer
        this.loadAddresses(this.customer)
    }
}

export enum AuthState {
    SIGNEDIN = 'SIGNEDIN',
    SIGNEDOUT = 'SIGNEDOUT'
}

class Check {
    id: string
    orderID: string
    orderNumber: string
    status: OrderStatus
    total: number
    dueTime: string
}

class PaymentMethod {
    id: string
    token: string
    type: PaymentMethodType
    name: string
    zipcode: string
    cardType: string
    cardExpiration: string
    processor: CCProcessor
}
