import { MenuItem, SelectionType } from "src/app/services/API.service";

export class MenuItemDTO {
    id: string;
    name: string;
    description?: string
    quantity?: number
    imageS3?: string
    modifierGroups?: ModifierGroupDTO[]
    venueID?: string
    price: number
    prices?: { price: number, priceTypeID: string }[]
    priceTypeID?: string
    utensils?: boolean
    alcohol?: boolean

    constructor(init?: Partial<MenuItemDTO>) {
        Object.assign(this, init)
    }

    static async fromQuery(query: MenuItem, priceTypeID?: string) {
        const dto = new this()
        Object.keys(query).forEach(k => {
            if (!(query[k] instanceof Array)) {
                dto[k] = query[k]
            }
        })

        dto.priceTypeID = priceTypeID

        // const injector = Injector.create({
        //     providers: [
        //         { provide: ImageService },
        //     ]
        // });
        // const imgSvc = injector.get(ImageService);
        // if (dto.imageS3) {
        //     dto.imageURL = await imgSvc.getSignedURL(dto.imageS3)
        // }

        if (query.prices) dto.prices = []
        for (let p of query.prices.items) {
            dto.prices.push({
                price: p.price,
                priceTypeID: p.priceTypeID
            })
        }

        dto.price = getMenuItemPrice(dto)

        if (query.modifierGroups) {
            dto.modifierGroups = []
            query.modifierGroups.items.sort((a,b) => (a.sortOrder || 0) - (b.sortOrder || 0))
            for (let item of query.modifierGroups.items.filter(i => i.modifierGroup.active).map(i => i.modifierGroup)) {
                dto.modifierGroups.push(await ModifierGroupDTO.fromQuery(item, priceTypeID))
            }
        }
        // console.log(query, dto)
        return dto
    }
}

export class ModifierGroupDTO {
    id: string
    name: string
    displayName?: string
    modifiers?: MenuItemDTO[]
    min?: number
    max?: number
    selected?: MenuItemDTO[]
    priceTypeID?: string
    selectionType?: SelectionType

    static async fromQuery(queryItem: any, parentPriceTypeID?: string) {
        const dto = new this()
        Object.keys(queryItem).forEach(k => {
            if (!(queryItem[k] instanceof Array)) {
                dto[k] = queryItem[k]
            }
        })

        if (queryItem.modifiers) dto.modifiers = []
        let i = 0
        for (let item of queryItem.modifiers.items.map(i => i.menuItem)) {
            const menuItemDTO = await MenuItemDTO.fromQuery(item, queryItem.priceTypeID || parentPriceTypeID)
            if (queryItem.selectionType === SelectionType.QUANTITY) menuItemDTO.quantity = 0
            menuItemDTO && dto.modifiers.push(menuItemDTO)
        }

        dto.modifiers.sort((a, b) => a.name.localeCompare(b.name))

        return dto
    }

    constructor(init?: Partial<ModifierGroupDTO>) {
        Object.assign(this, init)
    }
}

function getMenuItemPrice(item: MenuItemDTO) {
    let price = 0

    if (item.prices?.length > 0) {
        price = item.prices.find(p => !p.priceTypeID)?.price || 0
        if (item.priceTypeID) {
            const p = item.prices.find(p => p.priceTypeID === item.priceTypeID)
            if (p) price = p.price
        }
    }

    return price
}