<div *ngIf="customerSvc.addresses.length > 0" class="formgrid grid">
    <div class="field col-12">
        <label for="address-book">My Address Book</label><br>
        <p-dropdown class="inputfield w-full" id="address-book" name="address-book" [options]="customerSvc.addresses" optionLabel="name" (onChange)="selectAddress($event)"
            [autoDisplayFirst]="false" placeholder="Select address"></p-dropdown>
    </div>
</div>

<form ngForm #f="ngForm">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="address">Street</label>
            <input class="inputfield w-full" id="address" name="address" type="text" pInputText placeholder="Street"
                [(ngModel)]="model.address" [required]="true">
        </div>
        <div class="field col-12 md:col-6">
            <label for="city">City</label>
            <input class="inputfield w-full" id="city" name="city" type="text" pInputText placeholder="City"
                [(ngModel)]="model.city" [required]="true">
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 md:col-6">
            <label for="state">State</label>
            <input class="inputfield w-full" id="state" name="state" type="text" pInputText placeholder="State"
                [(ngModel)]="model.state" [required]="true">
        </div>
        <div class="field col-12 md:col-6">
            <label for="zip">Postal Code</label>
            <input class="inputfield w-full" id="zip" name="zip" type="text" pInputText placeholder="Postal Code"
                [(ngModel)]="model.zip" [required]="true">
        </div>
    </div>

    <div class="flex" style="width: 100%;">
        <button type="button" class="btn-confirm ml-auto" [disabled]="f.invalid" (click)="save()" [ladda]="loading">SAVE</button>
    </div>
</form>