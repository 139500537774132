import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Customer } from 'src/app/services/API.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-account-details',
  templateUrl: './customer-account-details.component.html',
  styleUrls: ['./customer-account-details.component.scss']
})
export class CustomerAccountDetailsComponent implements OnInit {
  code: string
  confirmPassword: boolean
  loading: boolean
  loadingPassword: boolean
  password: string

  constructor(public customerSvc: CustomerService, private messageSvc: MessageService) { }

  ngOnInit(): void {
  }

  async changePassword() {
    this.loadingPassword = true
    try {
      await this.customerSvc.forgotPassword(this.customerSvc.customer.email)
      this.loadingPassword = false
      this.confirmPassword = true
    } catch (error) {
      this.loadingPassword = false
    }
  }

  async changePasswordSubmit(code: string, password: string) {
    this.loadingPassword = true
    try {
      await this.customerSvc.forgotPasswordSubmit(this.customerSvc.customer.email, code, password)
      this.loadingPassword = false
      this.confirmPassword = false
      this.code = null
      this.password = null
      this.customerSvc.signOut()
    } catch (error) {
      this.loadingPassword = false
    }
  }

  async updateCustomer(customer: Customer) {
    this.loading = true
    try {
      await this.customerSvc.updateCustomer(customer)
      this.loading = false
    } catch (error) {
      console.error(error)
      this.loading = false
      this.messageSvc.add({ severity: 'error', detail: 'Error saving customer' })
    }
  }
}
