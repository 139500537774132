<div class="empty-cart" *ngIf="!byVenue || byVenue.length === 0">
    Your order is empty<br>
    Add items to get started
</div>

<div class="check-items" *ngIf="byVenue?.length > 0"
    [ngClass]="{ 'summary': mode === 'ORDER_SUMMARY', 'tracking': mode === 'ORDER_TRACKING'}">
    <div class="venue" *ngFor="let venue of byVenue">
        <div class="heading" (click)="venue.show = !venue.show">
            <div class="name">{{venue.name}}</div>
            <div class="toggle">
                <fa-icon [icon]="['fas', 'chevron-up']" *ngIf="venue.show"></fa-icon>
                <fa-icon [icon]="['fas', 'chevron-down']" *ngIf="!venue.show"></fa-icon>
            </div>
        </div>
        <div class="items" *ngIf="venue.show">
            <div class="item" *ngFor="let item of venue.items">

                <div *ngIf="mode === 'ORDER_EDIT'" class="image">
                    <img *ngIf="item.imageS3" [src]="item.imageS3 | s3URL | async" (click)="editCheckItem(item)" />
                </div>
                <div *ngIf="mode === 'ORDER_SUMMARY' || mode === 'ORDER_TRACKING'" class="quantity-label">
                    {{item.quantity}}</div>

                <div style="flex: 1;">
                    <div class="flex" style="width: 100%;">
                        <div class="flex flex-column">
                            <div class="name" (click)="editCheckItem(item)">{{item.name}}</div>
                            <p-badge class="my-1" *ngIf="item.unavailable" value="Unavailable" severity="danger">
                            </p-badge>
                            <div class="modifiers-text" *ngIf="item.modifiersText">{{item.modifiersText}}</div>
                            <div class="modifiers-text" *ngIf="item.utensils">Utensils</div>
                            <div class="modifiers-text" *ngIf="item.instructions">{{item.instructions}}</div>
                        </div>
                        <div class="price">{{(item.priceTotal || item.priceWithModifiers) | currency}}</div>
                    </div>
                    <div class="flex" style="width: 100%;" *ngIf="mode === 'ORDER_EDIT'">
                        <div class="quantity">
                            <p-dropdown [options]="quantityOptions" [(ngModel)]="item.quantity"
                                (ngModelChange)="updateCheckItemQuantity(item.id, item.quantity)" appendTo="body">
                            </p-dropdown>
                        </div>
                        <div class="flex flex-column">
                            <div class="edit" (click)="editCheckItem(item)">edit</div>
                            <div class="remove" (click)="removeCheckItem(item.id)">remove</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="modifiers" *ngIf="item.modifiers?.length > 0">
                    <div *ngFor="let modifier of item.modifiers" class="modifier">
                        <div class="flex">
                            <div class="modifier-name">{{modifier.name}}</div>
                            <div class="modifier-price">{{modifier.price | currency}}</div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="totals" *ngIf="mode === 'ORDER_TRACKING'">
                <div class="total">
                    <div class="left">
                        <div class="name">Subtotal</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.subTotal | currency}}</div>
                    </div>
                </div>
                <div class="total" *ngIf="venue.totals?.feeTotal > 0">
                    <div class="left">
                        <div class="name">Fees</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.feeTotal | currency}}</div>
                    </div>
                </div>
                <div class="total" *ngIf="venue.totals?.taxTotal > 0">
                    <div class="left">
                        <div class="name">Taxes</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.taxTotal | currency}}</div>
                    </div>
                </div>
                <div class="total" *ngIf="venue.totals?.discountTotal > 0 || venue.totals?.discountTotal < 0">
                    <div class="left">
                        <div class="name">Discounts</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.discountTotal | currency}}</div>
                    </div>
                </div>
                <div class="total bold">
                    <div class="left">
                        <div class="name">Total</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.total | currency}}</div>
                    </div>
                </div>
                <div class="total" *ngIf="venue.totals?.paymentTotal > 0">
                    <div class="left">
                        <div class="name">Payments</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.paymentTotal | currency}}</div>
                    </div>
                </div>
                <div class="total" *ngIf="venue.totals?.tipTotal > 0">
                    <div class="left">
                        <div class="name">Tips</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.tipTotal | currency}}</div>
                    </div>
                </div>
                <div class="total" *ngIf="venue.totals?.remainingBalance > 0">
                    <div class="left">
                        <div class="name">Remaining Balance</div>
                    </div>
                    <div class="right">
                        <div class="amount">{{venue.totals?.remainingBalance | currency}}</div>
                    </div>
                </div>
            </div>

            <div class="transactions" *ngIf="mode === 'ORDER_TRACKING'">
                <ng-container *ngFor="let transaction of venue.transactions">
                    <ng-container *ngIf="orderSvc.debug">
                        <div class="transaction">
                            <div class="left">
                                <div class="type">{{transaction.type}}</div>
                                <div class="auth">{{transaction.cardNumber}} - {{transaction.authCode}}</div>
                                <div class="auth">{{transaction.cardType}} - {{transaction.invoice}} -
                                    {{transaction.status}}</div>
                                <div class="date">{{transaction.createdAt | amLocal | amDateFormat:'l LT'}}</div>
                            </div>
                            <div class="right">
                                <div class="amount">{{transaction.amount | currency}}</div>
                                <div class="tip" *ngIf="transaction.tip > 0">Tip: {{transaction.tip | currency}}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!orderSvc.debug">
                        <ng-container *ngIf="transaction.type === 'CAPTURE'">
                            <div class="transaction">
                                <div class="left">
                                    <div class="type">Credit Card Payment</div>
                                    <div class="auth">{{transaction.cardNumber}} - {{transaction.authCode}}</div>
                                    <div class="date">{{transaction.createdAt | amLocal | amDateFormat:'l LT'}}
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="amount">{{transaction.amount | currency}}</div>
                                    <div class="tip" *ngIf="transaction.tip > 0">Tip: {{transaction.tip | currency}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>

        </div>
    </div>
</div>