<div class="items" *ngIf="bp.gteSize('s')">
    <div *ngFor="let item of items" class="item" (click)="onClickItem(item)">
        <div class="images grid">
            <div class="col" *ngFor="let imageS3 of item.imageS3s; let idx = index">
                <div class="image">
                    <img [src]="imageS3 | s3URL | async">
                </div>
            </div>
        </div>
        <div class="name">
            {{item.name}}
        </div>
        <div class="tags">
            {{item.tags}}
        </div>
    </div>
</div>

<div class="items mobile" *ngIf="bp.lteSize('xs')">
    <div *ngFor="let item of items" class="item" (click)="onClickItem(item)">
        <div class="images grid">
            <div class="col">
                <div class="image">
                    <img [src]="item.imageS3s[0] | s3URL | async">
                </div>
            </div>
        </div>
        <div class="name">
            {{item.name}}
        </div>
        <div class="tags">
            {{item.tags}}
        </div>
    </div>
</div>