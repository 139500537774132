import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { APIService, Order, OrderMode, OrderStatus, ServiceType } from 'src/app/services/API.service';
import { GMapsService } from 'src/app/services/gmaps.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  debug: boolean
  @Input() mode: string = 'ORDER_SUMMARY'
  @Input() orderMode: OrderMode
  orderModeKiosk = OrderMode.KIOSK
  orderModeOnlineOrder = OrderMode.ONLINE_ORDER
  order: Order
  @Input() orderID: string
  statusDetails: string
  statusMessage: string
  subOrder

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageSvc: MessageService,
    private dConfig: DynamicDialogConfig,
    private api: APIService,
    public gmapsSvc: GMapsService,
    public orderSvc: OrderService
  ) { }

  async init() {
    this.orderID = this.orderID || this.dConfig.data?.orderID || this.activatedRoute.snapshot.params.orderId
    this.debug = this.activatedRoute.snapshot.queryParams.debug === 'true' ? true : false

    if (this.dConfig.data?.orderID) {
      this.mode = 'ORDER_TRACKING'
    }

    if (this.activatedRoute.snapshot.params.orderId) {
      this.mode = 'ORDER_TRACKING'
    }

    try {
      await this.loadOrder(this.orderID)
    } catch (error) {
      console.error(error)
      this.messageSvc.add({ severity: 'info', detail: 'Error loading order' })
    }
  }

  async loadOrder(id: string) {
    await this.gmapsSvc.initGMaps()

    this.order = await this.api.GetOrderCustom(id) as Order
    this.updateStatus(this.order)

    await this.gmapsSvc.gMapsMarkOrigin({
      name: this.order.originDetails.name,
      street: this.order.originDetails.street,
      city: this.order.originDetails.city,
      state: this.order.originDetails.state,
      zip: this.order.originDetails.zip
    })

    if (this.order.serviceType === ServiceType.DELIVERY && this.order.customerDetails?.address) {
      await this.gmapsSvc.gMapsDirections(
        {
          street: this.order.originDetails.street,
          city: this.order.originDetails.city,
          state: this.order.originDetails.state,
          zip: this.order.originDetails.zip
        },
        {
          street: this.order.customerDetails.address.street,
          city: this.order.customerDetails.address.city,
          state: this.order.customerDetails.address.state,
          zip: this.order.customerDetails.address.zip
        }
      )
    }

    this.subscribeToOrder(id)
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.orderID?.currentValue) {
      await this.loadOrder(changes.orderID.currentValue)
    }
  }

  ngOnDestroy() {
    this.subOrder?.unsubscribe()
  }

  ngOnInit(): void {
    this.init()
  }

  subscribeToOrder(id: string) {
    this.subOrder = this.api.OnOrderByIdListener(id).subscribe(data => {
      console.log('OnOrderByIdListener', data.value)
      const item = (data.value.data as any).onOrderById
      if (item) {
        this.order.status = item.status
        this.order.courierStatus = item.courierStatus
        this.order.courierPhone = item.courierPhone
        this.order.courierCheckin = item.courierCheckin
        this.order.courierDetails = item.courierDetails
        this.updateStatus(this.order)
      }
    })
  }

  updateStatus(order) {
    switch (order.status) {
      case OrderStatus.PENDING: {
        this.statusMessage = 'Order Pending'
        this.statusDetails = ''
        break;
      }

      case OrderStatus.QUEUED: {
        this.statusMessage = 'Order Scheduled'
        this.statusDetails = 'Your order has been scheduled.'
        break;
      }

      case OrderStatus.SUBMITTED: {
        this.statusMessage = 'Order Received'
        this.statusDetails = 'Your order is placed and being prepared.'

        if (order.orderType?.serviceType === ServiceType.DELIVERY) {
          // this.gMapsDirections(order.customerDetails)
        }
        break;
      }

      case OrderStatus.PREPARED: {
        this.statusMessage = 'Almost Ready'
        this.statusDetails = 'Your order is almost ready.'
        break;
      }

      case OrderStatus.COLLECTED: {
        this.statusMessage = 'Packing your Order'
        this.statusDetails = 'We are packing your order for pickup.'
        break;
      }

      case OrderStatus.READY: {
        if (order.orderType?.serviceType === ServiceType.PICKUP) {
          this.statusMessage = 'Order Ready for Pickup'
          this.statusDetails = 'Please see the cashier in the front lobby to receive your order.'
        }
        if (order.orderType?.serviceType === ServiceType.DELIVERY) {
          this.statusMessage = 'Order Ready for Pickup'
          this.statusDetails = 'Your order is ready for pickup by your courier.'
        }
        break;
      }

      case OrderStatus.ENROUTE: {
        this.statusMessage = 'Heading to You'
        this.statusDetails = 'Your order is on the way.'
        break;
      }

      case OrderStatus.DELIVERED: {
        if (order.orderType?.serviceType === ServiceType.PICKUP) {
          this.statusMessage = 'Order Complete'
          this.statusDetails = 'Your order is complete. Enjoy!'
        }
        if (order.orderType?.serviceType === ServiceType.DELIVERY) {
          this.statusMessage = 'Order Complete'
          this.statusDetails = 'Your order has been delivered. Enjoy!'
        }
        break;
      }

      case OrderStatus.ERROR: {
        this.statusMessage = 'Order Error'
        this.statusDetails = "We're sorry, there was an error with your order."
        break;
      }
    }
  }
}
