import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Address } from 'src/app/services/API.service';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {
  idx: number
  model: Address = {} as Address
  showCountry = false
  showEmail = false
  showInstructions = true
  showName = true
  showPhone = true

  constructor(private dConfig: DynamicDialogConfig, private dRef: DynamicDialogRef) { }

  ngOnInit(): void {
    if (this.dConfig.data) {
      Object.keys(this.dConfig.data).forEach(k => {
        this[k] = this.dConfig.data[k]
      })
    }
  }

  save(model: any) {
    if (this.idx >= 0) model.idx = this.idx
    this.dRef.close(model)
  }
}
