<p-dataView [value]="checks" [layout]="'list'">
    <ng-template let-item pTemplate="listItem">
        <div class="item">
            <div class="flex flex-column" style="flex: 1">
                <div class="due-date">{{item.dueTime | amLocal | amDateFormat: 'LLL'}} • {{item.total | currency}}</div>
                <div class="order-number">Order# {{item.orderNumber}}</div>
                <div class="service-type">{{item.serviceType}}</div>
            </div>

            <div style="flex: .3">
                <app-order-status-badge [status]="item.status"></app-order-status-badge>
            </div>

            <div style="flex: .3">
                <button type="button" pButton class="btn-secondary" label="View Details" (click)="selectCheck(item)"></button>
            </div>
        </div>
    </ng-template>
</p-dataView>