import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { LaddaModule } from 'angular2-ladda';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MomentModule } from 'ngx-moment';
import { NgxSpinnerModule } from "ngx-spinner";
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddressInputComponent } from './shared/components/address-input/address-input.component';
import { CheckComponent } from './shared/components/check/check.component';
import { CheckoutComponent } from './shared/components/checkout/checkout.component';
import { CustomerAddressComponent } from './shared/components/customer-address/customer-address.component';
import { CustomerDetailsComponent } from './shared/components/customer-details/customer-details.component';
import { CustomerLoginComponent } from './shared/components/customer-login/customer-login.component';
import { DatetimeSelectorComponent } from './shared/components/datetime-selector/datetime-selector.component';
import { FeaturedMenusComponent } from './shared/components/featured-menus/featured-menus.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { I4goComponent } from './shared/components/i4go/i4go.component';
import { MenuCategoryScrollerComponent } from './shared/components/menu-category-scroller/menu-category-scroller.component';
import { MenuItemSearchComponent } from './shared/components/menu-item-search/menu-item-search.component';
import { MenuItemComponent } from './shared/components/menu-item/menu-item.component';
import { MenuScrollerComponent } from './shared/components/menu-scroller/menu-scroller.component';
import { OrderDetailsComponent } from './shared/components/order-details/order-details.component';
import { OrderStatusBadgeComponent } from './shared/components/order-status-badge/order-status-badge.component';
import { OrderTopBarComponent } from './shared/components/order-top-bar/order-top-bar.component';
import { PopularMenuItemsComponent } from './shared/components/popular-menu-items/popular-menu-items.component';
import { PopularMenusComponent } from './shared/components/popular-menus/popular-menus.component';
import { SidebarFooterComponent } from './shared/components/sidebar-footer/sidebar-footer.component';
import { TaggedMenusComponent } from './shared/components/tagged-menus/tagged-menus.component';
import { PhonePipe } from './shared/pipes/phone.pipe';
import { S3URLPipe } from './shared/pipes/s3-url.pipe';
import { ToLongUUIDPipe } from './shared/pipes/to-long-uuid';
import { ToShortUUIDPipe } from './shared/pipes/to-short-uuid.pipe';
import { CustomerAccountDetailsComponent } from './views/customer-account/customer-account-details/customer-account-details.component';
import { CustomerAccountComponent } from './views/customer-account/customer-account.component';
import { CustomerAddressBookComponent } from './views/customer-account/customer-address-book/customer-address-book.component';
import { CustomerOrdersListComponent } from './views/customer-account/customer-orders-list/customer-orders-list.component';
import { CustomerWalletComponent } from './views/customer-account/customer-wallet/customer-wallet.component';
import { OrderEntryComponent } from './views/order-entry/order-entry.component';
import { OrderMenuComponent } from './views/order-menu/order-menu.component';
import { OrderComponent } from './views/order/order.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { OrderEditComponent } from './shared/components/order-edit/order-edit.component';
import { DataCapComponent } from './shared/components/datacap/datacap.component';

@NgModule({
    declarations: [
        AppComponent,
        CheckComponent,
        MenuItemComponent,
        MenuItemSearchComponent,
        OrderComponent,
        OrderEntryComponent,
        OrderMenuComponent,
        OrderTopBarComponent,
        MenuScrollerComponent,
        MenuCategoryScrollerComponent,
        CheckoutComponent,
        CustomerAddressComponent,
        FooterComponent,
        SidebarFooterComponent,
        PhonePipe,
        ToShortUUIDPipe,
        ToLongUUIDPipe,
        PopularMenuItemsComponent,
        S3URLPipe,
        PopularMenusComponent,
        TaggedMenusComponent,
        I4goComponent,
        DataCapComponent,
        FeaturedMenusComponent,
        OrderDetailsComponent,
        CustomerLoginComponent,
        CustomerDetailsComponent,
        OrderStatusBadgeComponent,
        CustomerAccountComponent,
        CustomerOrdersListComponent,
        CustomerWalletComponent,
        CustomerAddressBookComponent,
        CustomerAccountDetailsComponent,
        AddressInputComponent,
        DatetimeSelectorComponent,
        OrderEditComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AmplifyUIAngularModule,
        FormsModule,
        TabViewModule,
        PanelModule,
        RadioButtonModule,
        InputSwitchModule,
        TableModule,
        ToolbarModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        InputMaskModule,
        CheckboxModule,
        InputSwitchModule,
        DropdownModule,
        SidebarModule,
        ImageCropperModule,
        InputNumberModule,
        NgxSpinnerModule,
        MultiSelectModule,
        ConfirmDialogModule,
        SelectButtonModule,
        CardModule,
        ChipModule,
        DataViewModule,
        FontAwesomeModule,
        ToastModule,
        DragScrollModule,
        ListboxModule,
        DynamicDialogModule,
        LayoutModule,
        OverlayPanelModule,
        HttpClientModule,
        HttpClientJsonpModule,
        GoogleMapsModule,
        MessagesModule,
        MessageModule,
        MomentModule,
        LaddaModule,
        BadgeModule,
        VirtualScrollerModule,
        PasswordModule,
        CalendarModule,
        InputTextareaModule,
        ProgressSpinnerModule
    ],
    providers: [ConfirmationService, MessageService, DialogService, DynamicDialogConfig],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fal, fad);
  }
}
