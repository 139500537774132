import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Check, OrderMode } from 'src/app/services/API.service';
import { CheckService } from 'src/app/services/check.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { CheckDTO, CheckItemDTO } from 'src/models/DTO/check.dto';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit, OnChanges {
  byVenue: any[] = []
  @Input() check: Check
  @Input() checkDTO: CheckDTO
  @Input() mode: string = 'ORDER_EDIT'
  @Input() orderMode: OrderMode
  orderModeKiosk = OrderMode.KIOSK
  orderModeOnlineOrder = OrderMode.ONLINE_ORDER
  quantityOptions = []

  constructor(
    public orderSvc: OrderService,
    private spinner: NgxSpinnerService,
    private checkSvc: CheckService,
    private menuSvc: MenuService) { }

    editCheckItem(checkItemDTO: CheckItemDTO) {
      this.menuSvc.editMenuItem(checkItemDTO)
    }

  ngOnChanges(changes: SimpleChanges) {
    this.byVenue = []
    if (changes.checkDTO?.currentValue) {
      this.checkDTO.items.forEach(i => {
        if (!this.byVenue.some(v => v.venueID === i.venueID)) {
          this.byVenue.push({
            venueID: i.venueID,
            name: i.venueName,
            show: true,
            items: []
          })
        }

        const obj: any = i
        if (i.modifiers?.length > 0) {
          const modifierNames = []
          i.modifiers.forEach(m => {
            let name = m.name
            if (m.quantity > 1) {
              name = `(${m.quantity}) ${m.name}`
            }
            modifierNames.push(name)
          })
          obj.modifiersText = modifierNames.join(', ')
        }

        const v = this.byVenue.find(v => v.venueID === i.venueID)
        v.items.push(i)
      })
    }

    if (changes.check?.currentValue) {
      this.byVenue = this.checkSvc.getByVenue(this.check)
    }
  }

  ngOnInit(): void {
    for (let i = 1; i <= 100; i++) {
      this.quantityOptions.push(i)
    }
  }

  async removeCheckItem(id: string) {
    this.spinner.show('app-check')
    try {
      await this.orderSvc.removeCheckItem(id)
      this.spinner.hide('app-check')
    } catch (error) {
      this.spinner.hide('app-check')
    }
  }

  async updateCheckItemQuantity(id: string, quantity: number) {
    this.spinner.show('app-check')
    try {
      await this.orderSvc.updateCheckItemQuantity({ id, quantity })
      this.spinner.hide('app-check')
    } catch (error) {
      this.spinner.hide('app-check')
    }
  }
}
