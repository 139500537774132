import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderMode } from 'src/app/services/API.service';
import { CheckService } from 'src/app/services/check.service';
import { GMapsService } from 'src/app/services/gmaps.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CustomerAddressComponent } from '../customer-address/customer-address.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild(GoogleMap) gMap: GoogleMap
  @Input() orderMode: OrderMode
  orderModeKiosk = OrderMode.KIOSK
  orderModeOnlineOrder = OrderMode.ONLINE_ORDER

  constructor(
    public orderSvc: OrderService,
    public menuSvc: MenuService,
    private dialogSvc: DialogService,
    public paymentSvc: PaymentService,
    public checkSvc: CheckService,
    public gmapsSvc: GMapsService) {
  }

  editAddress() {
    const ref = this.dialogSvc.open(CustomerAddressComponent, {
      header: 'Address',
      styleClass: 'dialog-address dialog-dynamic'
    })
  }

  ngOnInit(): void {
  }

  async submitOrder() {
    await this.orderSvc.submitOrder()
  }
}
