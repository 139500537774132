import { Injectable } from '@angular/core';
import { CheckDTO, CheckItemDTO } from 'src/models/DTO/check.dto';
import { Check, CheckItem, CheckItemModifier } from './API.service';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class CheckService {
  check: Check
  checkDTO: CheckDTO

  constructor(private menuSvc: MenuService) {
  }

  getByVenue(checks: Check | Check[]) {
    if (!(checks instanceof Array)) {
      checks = [checks]
    }

    const byVenue = []

    for (let check of checks) {
      for (let checkItem of check.items.items) {
        if (!byVenue.some(v => v.venueID === checkItem.venueID)) {
          byVenue.push({
            venueID: checkItem.venueID,
            name: checkItem.venueName,
            show: true,
            items: [],
            transactions: []
          })
        }

        const item: any = checkItem
        item.priceTotal = this.getCheckItemPriceTotal(checkItem)
        if (checkItem.modifiers.length > 0) {
          item.modifiersText = checkItem.modifiers.map(m => m.name).join(', ')
        }

        const v = byVenue.find(v => v.venueID === checkItem.venueID)
        v.items.push(item)
      }

      for (let transaction of check.transactions.items) {
        const v = byVenue.find(v => v.venueID === transaction.venueID)
        v.transactions.push(transaction)
        v.transactions.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }

      if (check.venueTotals) {
        for (let totals of check.venueTotals) {
          const v = byVenue.find(v => v.venueID === totals.venueID)
          v.totals = totals
        }
      }
    }

    return byVenue
  }

  getCheckItemPriceTotal(item: CheckItem | CheckItemModifier) {
    let price = item.price || 0
    if (item.modifiers) {
      item.modifiers.forEach(modifier => {
        const modifierPrice = this.getCheckItemPriceTotal(modifier)
        price = price + modifierPrice
      })
    }
    price = price * item.quantity
    return price
  }

  validateItems() {
    if (this.checkDTO) {
      this.checkDTO.valid = true
      this.checkDTO.items.forEach(checkItemDTO => {
        checkItemDTO.unavailable = false
        const menuOption = this.menuSvc.menuEntryOptions.find(m => m.menuID === checkItemDTO.menuID)
        if (menuOption && !menuOption.open) {
          checkItemDTO.unavailable = true
          this.checkDTO.valid = false
        }
      })
    }
  }
}