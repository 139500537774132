import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Address } from 'src/app/services/API.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AddressInputComponent } from 'src/app/shared/components/address-input/address-input.component';

@Component({
  selector: 'app-customer-address-book',
  templateUrl: './customer-address-book.component.html',
  styleUrls: ['./customer-address-book.component.scss']
})
export class CustomerAddressBookComponent implements OnInit {
  @Input() addresses: Address[]

  constructor(private dialogSvc: DialogService, public customerSvc: CustomerService) { }

  ngOnInit(): void {
  }

  openEditAddress(address: Address, idx: number, ) {
    const ref = this.dialogSvc.open(AddressInputComponent, {
      header: 'Edit Address',
      styleClass: 'dialog-address dialog-dynamic',
      data: { model: address, idx: idx }
    })
    ref.onClose.subscribe(async data => {
      if (data) {
        const idx = data.idx
        delete data.idx
        this.customerSvc.updateAddress(data, idx)
      }
    })
  }

  openNewAddress() {
    const ref = this.dialogSvc.open(AddressInputComponent, {
      header: 'Add a New Address',
      styleClass: 'dialog-address dialog-dynamic',
      data: {}
    })
    ref.onClose.subscribe(async data => {
      if (data) {
        this.customerSvc.addAddress(data)
      }
    })
  }
}
