import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { APIService, OrderMode } from 'src/app/services/API.service';
import { CheckService } from 'src/app/services/check.service';
import { AuthState, CustomerService } from 'src/app/services/customer.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService, OrderSvcStatus } from 'src/app/services/order.service';
import { CustomerLoginComponent } from 'src/app/shared/components/customer-login/customer-login.component';
import * as LogRocket from 'logrocket';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  api = new APIService()
  debug: boolean
  orderMode = OrderMode.ONLINE_ORDER

  constructor(
    private activatedRoute: ActivatedRoute,
    public orderSvc: OrderService,
    public checkSvc: CheckService,
    public menuSvc: MenuService,
    private spinner: NgxSpinnerService,
    private messageSvc: MessageService,
    public customerSvc: CustomerService,
    private dialogSvc: DialogService
  ) { }

  hideSpinners() {
    this.spinner.hide('app-checkout')
    this.spinner.hide('app-order-progress')
  }

  async init() {
    let id = this.activatedRoute.snapshot.params.id
    if (!id) {
      const domain = `https://${window.location.hostname}`
      const orderLinks = await this.api.ListOrderLinksByDomainMin(domain)
      const orderLink = orderLinks.items.find(l => l.isDefault)
      if (orderLink) id = orderLink.id
      if (!id) return
    }

    const menuID = this.activatedRoute.snapshot.queryParams.menuID
    const action = this.activatedRoute.snapshot.queryParams.action
    
    if (action === 'account') {
      setTimeout(() => {
        this.dialogSvc.dialogComponentRefMap.forEach(r => {
          r.instance.close()
        })
        if (this.customerSvc.authState.value === AuthState.SIGNEDOUT) {
          this.dialogSvc.open(CustomerLoginComponent, {
            styleClass: 'dialog-login dialog-dynamic',
            showHeader: false,
            dismissableMask: true
          })
        }

        if (this.customerSvc.authState.value === AuthState.SIGNEDIN) {
          this.customerSvc.showCustomerAccount = true
          // this.dialogSvc.open(CustomerDetailsComponent, {
          //   styleClass: 'dialog-customer dialog-dynamic',
          // })
        }
      }, 3000);
    }

    this.debug = this.activatedRoute.snapshot.queryParams.debug === 'true' ? true : false
    this.orderSvc.debug = this.debug

    try {
      await this.orderSvc.loadOrderLink(id, menuID)
      LogRocket.init('fwefqg/ptx-colony')
    } catch (error) {
      console.error(error)
      this.messageSvc.add({ severity: 'info', detail: 'Error loading order link.' })
    }
  }

  ngOnInit() {
    this.init()
  }

  onHideOrderProgress() {
    if (this.orderSvc.status === OrderSvcStatus.SUBMITTED) {
      this.orderSvc.reset()
    }
  }

  openCheckout() {
    this.orderSvc.showCheckout = true
  }

  openOrderProgress() {
    this.orderSvc.showOrderProgress = true
  }

  showSpinners() {
    this.spinner.show('app-checkout')
    this.spinner.show('app-order-progress')
  }
}
