<p-tabView styleClass="customer-details" (onChange)="onChange($event)" [scrollable]="true">
    <p-tabPanel header="My Orders">
        <div class="orders">
            <p-table [value]="customerSvc.checks" responsiveLayout="scroll" selectionMode="single" dataKey="id" (onRowSelect)="selectCheck($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="orderNumber">Order # <p-sortIcon field="orderNumber"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dueTime">Due Time <p-sortIcon field="dueTime"></p-sortIcon>
                        </th>
                        <th pSortableColumn="total">Total <p-sortIcon field="total"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr [pSelectableRow]="item">
                        <td>{{item.orderNumber}}</td>
                        <td>{{item.dueTime | amLocal | amDateFormat: 'LLL'}}</td>
                        <td>{{item.total | currency}}</td>
                        <td>
                            <app-order-status-badge [status]="item.status"></app-order-status-badge>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>
    <p-tabPanel header="My Wallet">
        <div class="wallet">
            <p-table [value]="customerSvc.paymentMethods" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Card <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cardType">Type <p-sortIcon field="cardType"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cardExpiration">Expiration <p-sortIcon field="cardExpiration"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr [pSelectableRow]="item">
                        <td>{{item.name}}</td>
                        <td>{{item.cardType}}</td>
                        <td>{{item.cardExpiration}}</td>
                        <td>
                            <fa-icon [icon]="['far', 'times-circle']" style="cursor: pointer; color: red;" (click)='customerSvc.removeCustomerPaymentMethod(item.id)'></fa-icon>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Address Book">
        <div class="address-book">
            <p-table [value]="customerSvc.addresses" responsiveLayout="scroll" selectionMode="single" dataKey="id">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="orstreetderNumber">Street <p-sortIcon field="street"></p-sortIcon>
                        </th>
                        <th pSortableColumn="city">City <p-sortIcon field="city"></p-sortIcon>
                        </th>
                        <th pSortableColumn="state">State <p-sortIcon field="state"></p-sortIcon>
                        </th>
                        <th pSortableColumn="zipcode">Zipcode <p-sortIcon field="zipcode"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr [pSelectableRow]="item">
                        <td>{{item.name}}</td>
                        <td>{{item.street}}</td>
                        <td>{{item.city}}</td>
                        <td>{{item.state}}</td>
                        <td>{{item.zipcode}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-tabPanel>
    <p-tabPanel header="My Account">
        <div class="account p-3">
            <div class="field">
                <label for="firstName">Email</label>
                <div>{{customerSvc.customer?.email}}</div>
            </div>

            <form ngForm #f="ngForm">
                <div class="field">
                    <label for="firstName">First Name</label>
                    <input required id="firstName" name="firstName" type="text" class="inputfield w-full" pInputText
                        [(ngModel)]="customerSvc.customer.firstName">
                </div>
                <div class="field">
                    <label for="lastName">Last Name</label>
                    <input required id="lastName" name="lastName" type="text" class="inputfield w-full" pInputText
                        [(ngModel)]="customerSvc.customer.lastName">
                </div>
                <div class="field">
                    <label for="phone">Phone</label>
                    <input required id="phone" name="phone" type="phone" class="inputfield w-full" pInputText
                        [(ngModel)]="customerSvc.customer.phone">
                </div>

                <button [disabled]="f.invalid" class="p-button-info w-full mt-3" pButton label="Save Changes"
                    (click)="updateCustomer(this.customerSvc.customer)" [ladda]="loading"></button>
            </form>

        </div>
    </p-tabPanel>
    <p-tabPanel header="Sign Out">
    </p-tabPanel>
</p-tabView>