import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { APIService } from 'src/app/services/API.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { OrderDetailsComponent } from 'src/app/shared/components/order-details/order-details.component';

@Component({
  selector: 'app-customer-orders-list',
  templateUrl: './customer-orders-list.component.html',
  styleUrls: ['./customer-orders-list.component.scss']
})
export class CustomerOrdersListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() checks = []
  @Input() id: string
  tag: string


  constructor(private dialogSvc: DialogService, private subSvc: SubscriptionService, private api: APIService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.tag = this.id ? `orders_${this.id}` : 'orders'
    if (changes.checks?.currentValue) {
      this.subSvc.unsubscribe({ tag: this.tag })
      this.checks.forEach(c => {
        this.subSvc.addSubscription({
          key: c.orderID,
          tag: this.tag,
          subscription: this.api.OnOrderByIdListener(c.orderID).subscribe(data => {
            console.log('OnOrderByIdListener', data.value)
            const item = (data.value.data as any).onOrderById
            if (item) {
              const order = this.checks.find(c => c.orderID === item.id)
              if (order) {
                order.status = item.status
              }
            }
          })
        })
      })
    }
  }

  ngOnDestroy(): void {
    this.subSvc.unsubscribe({ tag: this.tag })
  }

  ngOnInit(): void {
  }

  selectCheck(check) {
    const orderID = check.orderID
    this.dialogSvc.open(OrderDetailsComponent, {
      header: `Order #${check.orderNumber}`,
      styleClass: 'dialog-order-details dialog-dynamic',
      data: { orderID: orderID }
    })
  }

}
