<ngx-spinner name="app-menu-item"></ngx-spinner>

<img *ngIf="menuItemDTO.imageS3" [src]="menuItemDTO.imageS3 | s3URL | async" />

<div class="details">
    <div class="flex">
        <div class="menu-name">
            {{menuName}}
        </div>
        <p-badge *ngIf="!menuOpen" value="Not Accepting Orders" severity="danger"></p-badge>
    </div>

    <div class="name">
        {{menuItemDTO.name}}
    </div>
    
    <div class="description">
        {{menuItemDTO.description}}
    </div>

    <div class="tags">
    </div>
    
    <div class="modifier-groups">
        <ng-container *ngIf="menuItemDTO.modifierGroups?.length > 0">
            <div *ngFor="let group of menuItemDTO.modifierGroups, let groupIdx = index" class="group-container">
                <div class="flex">
                    <div class="group-name">{{group.displayName || group.name}}</div>
                    <p-badge *ngIf="group.min > 0" value="Required" severity="info"></p-badge>
                </div>
                <span class="group-requirement" *ngIf="group.min === 0 && group.max > 0">Select up to {{group.max}}</span>
                <span class="group-requirement" *ngIf="group.min > 0">Select {{group.min}}<span *ngIf="group.max > 1"> to {{group.max}}</span></span>
                <div class="flex flex-column">
                    <div *ngFor="let item of group.modifiers; let modIdx = index" class="group-item">
                        <p-checkbox *ngIf="group.selectionType !== 'QUANTITY'" [name]="'modifiers'" [value]="item" [label]="item.name" [required]="group.min > 0" class="mr-1" [ngModel]="group.selected" (onChange)="selectModifier(group, $event.checked, item)" labelStyleClass="modifier-name">
                        </p-checkbox>
    
                        <div *ngIf="group.selectionType === 'QUANTITY'" class="flex align-items-center">
                            <div>
                                <p-inputNumber [ngModel]="item.quantity" [size]="1" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [min]="0" [max]="group.max || 100" (ngModelChange)="adjustModifier(group, modIdx, $event, item)"></p-inputNumber>
                            </div>
                            <div class="ml-3">{{item.name}}</div>
                        </div>
    
                        <div class="modifier-price" *ngIf="item.price > 0">
                            + {{item.price | currency}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="group-container" *ngIf="model && menuItemDTO.utensils">
            <div class="group-name">Additional Items</div>
            <div class="group-item">
                <p-checkbox [name]="'utensils'" label="Do you need utensils?" [binary]="true" class="mr-1" [(ngModel)]="model.utensils" labelStyleClass="modifier-name">
                </p-checkbox>
            </div>
        </div>
        <div class="group-container" *ngIf="model">
            <div class="group-name">Special Instructions</div>
            <div class="group-item">
                <textarea [rows]="5" class="w-full" pInputTextarea [autoResize]="true" [(ngModel)]="model.instructions"></textarea>
            </div>
        </div>
    </div>
</div>

<div class="footer">
    <div class="quantity">
        <p-inputNumber name="quantity" [(ngModel)]="quantity" [min]="1" [max]="100" [showButtons]="true" buttonLayout="horizontal"
            (ngModelChange)="this.updateCheckItem()" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" [size]="1">
        </p-inputNumber>
    </div>
    <button pButton pRipple (click)="onClickAdd()" [disabled]="!valid" class="btn-submit" [ladda]="loading">
        <div class="wrapper">
            <div *ngIf="mode === 'ADD'">
                Add Item
            </div>
            <div *ngIf="mode === 'EDIT'">
                Update Item
            </div>
            <div class="price">
                {{total | currency}}
            </div>
        </div>
    </button>
</div>