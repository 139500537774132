<div class="top">
    <div class="btn-back" *ngIf="orderSvc.orderMode === orderModeOnlineOrder">
        <fa-icon [icon]="['fas', 'arrow-left']" size="lg" (click)="clickBack()"></fa-icon>
    </div>
    <div class="your-order">Your Order</div>
    <div class="count ml-auto" *ngIf="checkDTO?.items?.length > 0">{{checkDTO?.items?.length}} Item<span
            *ngIf="checkDTO?.items?.length > 1 || checkDTO?.items?.length === 0">s</span></div>
</div>

<div class="middle">
    <app-check *ngIf="!orderSvc.showCheckout && !orderSvc.showOrderProgress" [checkDTO]="checkSvc.checkDTO"
        [orderMode]="orderMode"></app-check>
</div>

<div class="bottom" *ngIf="checkDTO?.items?.length > 0">
    <button *ngIf="orderMode === orderModeKiosk" class="btn-primary w-full mb-3" (click)="orderSvc.startNewOrder()">Cancel
        Order</button>

    <button class="btn-checkout btn-cta" [disabled]="!checkDTO || checkDTO.items?.length === 0 || !checkDTO.valid"
        (click)="checkout()">
        <div>
            CHECKOUT
        </div>
        <div class="total">
            {{checkDTO?.totals?.total | currency}}
        </div>
    </button>
</div>