<div class="business-name">
    {{origin?.name}}
</div>
<div class="socials">

</div>
<div>
    VISIT US
</div>
<div class="address">
    <div>{{origin?.street}}</div>
    <div>{{origin?.city}}, {{origin?.state}} {{origin?.zip}}
    </div>
</div>

<div class="copyright">
    © 2021 {{origin?.name}}
</div>
<!-- <div>
    Terms of Service | Privacy
</div>
<div>
    Notice at Collection
</div>
<div>
    Do not sell my personal information (California)
</div> -->