import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-menu-scroller',
  templateUrl: './menu-scroller.component.html',
  styleUrls: ['./menu-scroller.component.scss']
})
export class MenuScrollerComponent implements OnInit {
  constructor(public menuSvc: MenuService, public app: AppStateService) { }

  ngOnInit(): void {
  }

  selectMenuOption(option) {
    this.menuSvc.selectMenu(option.menuID)
  }
}
