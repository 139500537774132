import { Injectable } from "@angular/core"
import * as LogRocket from 'logrocket'
import * as moment from "moment"
import { MessageService } from "primeng/api"
import { DialogService } from "primeng/dynamicdialog"
import { Subject } from "rxjs"
import { CheckDTO, CheckFeeDTO, CheckItemDTO, CheckTotalsDTO } from "src/models/DTO/check.dto"
import { CustomerAddressComponent } from "../shared/components/customer-address/customer-address.component"
import { DatetimeSelectorComponent } from "../shared/components/datetime-selector/datetime-selector.component"
import { APIService, Check, CheckItem, CredentialType, FeeType, GetOrderDeliveryFeeInput, GetOrderTypeQuery, Order, OrderLink, OrderMode, OrderQueueMode, OrderSource, ServiceType, UpdateCheckItemQuantityInput } from "./API.service"
import { CheckService } from "./check.service"
import { DataCapService } from "./datacap.service"
import { GMapsService } from "./gmaps.service"
import { MenuService } from "./menu.service"
import { ScheduleService } from "./schedule.service"

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    customer = {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        dob: null,
        address: {
            street: null,
            city: null,
            state: null,
            zip: null,
            latitude: null,
            longitude: null,
            distance: null
        }
    }
    debug: boolean
    deliveryEstimate = 0
    deliveryFee = 0
    deliveryQuoteID: string
    deliveryPickupTime: string
    dueTime: string
    fees: CheckFeeDTO[] = []
    menuID: string
    onPaymentDeclined = new Subject<boolean>()
    onReset = new Subject<boolean>()
    order: Order
    orderLink: OrderLink
    orderLinkOpen: boolean
    orderMode: OrderMode = OrderMode.ONLINE_ORDER
    orderQueueMode: OrderQueueMode
    orderType: GetOrderTypeQuery
    orderTypeID: string
    orderTypes: any[]
    paymentMode = 'I4GO'
    resetTimer
    showCheck: boolean
    showCheckout: boolean
    showOrderProgress: boolean
    status: OrderSvcStatus = OrderSvcStatus.PENDING
    taxAndFeeTotal = 0
    timeEstimate = 0
    tip = 0
    tipCustom = null
    tipOption
    tipOptions = [
        { label: '10%', value: 0.1 },
        { label: '15%', value: 0.15 },
        { label: '20%', value: 0.2 },
        { label: '25%', value: 0.25 },
        { label: 'Other', value: 'other' },
    ]
    total = 0
    working: boolean

    constructor(
        private api: APIService,
        private menuSvc: MenuService,
        private messageSvc: MessageService,
        private dialogSvc: DialogService,
        private scheduleSvc: ScheduleService,
        private checkSvc: CheckService,
        private gmapsSvc: GMapsService,
        private datacapSvc: DataCapService) { }

    async addCheckItem(checkItemDTO: CheckItemDTO, venueID: string) {
        if (!this.order) {
            await this.newOrder(this.orderLink.tenantID, this.orderLink.id)
        }

        this.recursiveSetProperty(checkItemDTO, 'orderLinkID', 'modifiers', this.orderLink.id)
        this.recursiveSetProperty(checkItemDTO, 'checkID', 'modifiers', this.checkSvc.checkDTO.id)
        this.recursiveSetProperty(checkItemDTO, 'venueID', 'modifiers', venueID)

        const saved = await this.api.SaveCheckItemDeep(checkItemDTO)
        this.checkSvc.checkDTO = CheckDTO.fromQuery(saved as Check)
        this.checkSvc.validateItems()
        this.updateOrderTotal()
        await this.loadCheckItemImages()
    }

    async getDeliveryFee() {
        if (!this.showCheckout) return
        if (this.orderType.serviceType !== ServiceType.DELIVERY) return
        if (!this.orderType.deliveryProvider) return
        if (!this.customer.address?.street) return

        const input: GetOrderDeliveryFeeInput = {
            deliveryProvider: this.orderType.deliveryProvider,
            origin: {
                street: this.orderLink.location.street,
                city: this.orderLink.location.city,
                state: this.orderLink.location.state,
                zip: this.orderLink.location.zip,
                latitude: this.orderLink.location.latitude,
                longitude: this.orderLink.location.longitude
            },
            destination: {
                street: this.customer.address.street,
                city: this.customer.address.city,
                state: this.customer.address.state,
                zip: this.customer.address.zip,
                latitude: this.customer.address.latitude,
                longitude: this.customer.address.longitude,
                // distance: this.customer.address.distance
            },
            pickupTime: this.order.dueTime,
            tenantID: this.orderLink.tenantID,
            orderID: this.order.id,
            total: this.total - this.deliveryFee,
            items: []
        }

        try {
            const res = await this.api.GetOrderDeliveryFee(input)
            if (res) {
                this.deliveryFee = res.fee
                const fee: CheckFeeDTO = {
                    amount: this.deliveryFee,
                    id: 'COCO_DELIVERY_FEE',
                    name: 'Coco Delivery Fee',
                    type: FeeType.THIRD_PARTY,
                    taxed: true,
                    inclusive: false
                }
                this.fees = [fee]
                this.deliveryQuoteID = res.quoteId
                this.deliveryPickupTime = res.pickupTime
                this.deliveryEstimate = res.duration
            } else {
                throw new Error
            }
        } catch (error) {
            this.deliveryFee = 0
            this.fees = []
            this.deliveryQuoteID = null
            this.deliveryPickupTime = null
            this.deliveryEstimate = 0
            this.customer.address.street = null
            this.customer.address.city = null
            this.customer.address.state = null
            this.customer.address.zip = null

            let detail = null
            if (error.errors && error.errors[0]?.message) {
                detail = error.errors[0].message
            }
            this.messageSvc.add({ severity: 'error', summary: 'Error getting delivery quote', detail: detail, life: 10000 })
        }

        this.updateOrderTotal()
    }

    getNowUTCNearestMin(minute = 5) {
        const start = moment()
        const remainder = minute - (start.minute() % minute)
        const now = moment(start).add(remainder, "minutes").utc().toDate()
        return now
    }

    async loadCheckItemImages() {
        for (let item of this.checkSvc.checkDTO.items) {
            const mi = this.menuSvc.getMenuItem(item.menuItemID)
            item.imageS3 = mi?.imageS3
        }
    }

    async loadOrderLink(id: string, menuID?: string) {
        await this.gmapsSvc.initGMaps()

        this.orderLink = await this.api.GetOrderLinkCustom(id) as OrderLink
        this.orderTypes = this.orderLink.orderTypes?.items.filter(i => i.orderType.active).map(i => i.orderType) || []
        this.menuID = menuID

        this.selectOrderType(this.orderTypes[0])

        // let timeRanges = this.orderLink.schedules.items.map(i => i.schedule.timeRanges).flat()
        // this.orderLinkOpen = this.scheduleSvc.isOpen(timeRanges, this.orderLink.location.timezone)
        // await this.menuSvc.loadMenus(this.orderLink.menus.items.map(i => i.menu) as any, menuID, this.orderLink.location.timezone)

        this.paymentMode = 'I4GO'
        if (this.orderLink.ccCredential?.type === CredentialType.DATACAP) {
            this.paymentMode = 'DATACAP'
            this.datacapSvc.tokenKey = this.orderLink.ccCredential.dcTokenKey
        }
    }

    async newOrder(tenantID: string, orderLinkID: string) {
        let source
        switch (this.orderMode) {
            case OrderMode.ONLINE_ORDER:
                source = OrderSource.ONLINE_ORDER
                break
            case OrderMode.KIOSK:
                source = OrderSource.KIOSK
                break
            case OrderMode.POS:
                source = OrderSource.POS
                break
        }
        this.order = await this.api.CreateOrder({
            tenantID: tenantID,
            orderLinkID: orderLinkID,
            dueTime: this.dueTime || this.getNowUTCNearestMin(5).toISOString(),
            orderTypeID: this.orderTypeID,
            locationID: this.orderLink.locationID,
            serviceType: this.orderType?.serviceType,
            source: source,
            orderNumber: Date.now().toString()
        }) as Order

        LogRocket.track('NEW_ORDER', {
            tenantID: this.order.tenantID,
            orderId: this.order.id,
            orderNumber: this.order.orderNumber,
            source: this.orderMode
         })

        const check = await this.api.CreateCheck({
            tenantID: tenantID,
            orderID: this.order.id
        })
        return this.checkSvc.checkDTO = CheckDTO.fromQuery(check as Check)
    }

    recursiveSetProperty(obj: any, property: string, children: string, value: any) {
        obj[property] = value
        if (obj[children] instanceof Array) {
            for (let child of obj[children]) {
                this.recursiveSetProperty(child, property, children, value)
            }
        }
        return obj
    }

    async removeCheckItem(id: string) {
        const saved = await this.api.RemoveCheckItemDeep({ id })
        this.checkSvc.checkDTO = CheckDTO.fromQuery(saved as Check)
        this.checkSvc.validateItems()
        this.updateOrderTotal()
        await this.loadCheckItemImages()
    }

    reset() {
        console.log('reset')
        if (this.resetTimer) {
            clearTimeout(this.resetTimer)
        }
        this.tipOption = null
        this.tipCustom = null
        this.selectTip(0)
        this.menuSvc.reset()
        this.order = null
        this.fees = []
        this.checkSvc.checkDTO = null
        this.status = OrderSvcStatus.PENDING
        LogRocket.startNewSession()
        this.onReset.next(true)
    }

    roundCurrency(value: number = 0) {
        return Math.round((value + Number.EPSILON) * 100) / 100
    }

    async selectOrderDueTime(ISOStringUTC: string) {
        this.dueTime = moment.utc(ISOStringUTC).format()
        this.order && (this.order.dueTime = this.dueTime)

        const ISOStringLocal = moment.utc(ISOStringUTC).local().toISOString()

        const timeRanges = this.orderLink.schedules.items.map(i => i.schedule.timeRanges).flat()
        const menuID = this.menuSvc.menuID || this.menuID
        this.orderLinkOpen = this.scheduleSvc.isOpen(timeRanges, this.orderLink.location.timezone, ISOStringLocal)
        const menus = this.orderLink.menus.items.map(i => i.menu)
        let sortOrder = []
        if (this.orderLink.menus.items[0].sortOrder >= -1) {
            sortOrder = this.orderLink.menus.items.sort((a, b) => a.sortOrder - b.sortOrder).map(i => i.menuID)
        }
        await this.menuSvc.loadMenus(menus, menuID, this.orderLink.location.timezone, ISOStringLocal, sortOrder)
        this.checkSvc.validateItems()

        if (this.showCheckout && !this.checkSvc.checkDTO?.valid) {
            this.showCheckout = false
            this.showCheck = true
        }
    }

    async selectOrderQueueMode(queueMode: OrderQueueMode | string) {
        this.orderQueueMode = queueMode as OrderQueueMode
        if (queueMode === OrderQueueMode.ASAP) {
            await this.selectOrderDueTime(this.getNowUTCNearestMin(5).toISOString())
        }
        if (queueMode === OrderQueueMode.SCHEDULE) {
            const ref = this.dialogSvc.open(DatetimeSelectorComponent, {
                header: 'Date & Time',
                styleClass: 'dialog-dynamic',
                data: { value: this.dueTime ? moment.utc(this.dueTime).toDate() : this.getNowUTCNearestMin(5) }
            })
            ref.onClose.subscribe(async data => {
                if (data) {
                    await this.selectOrderDueTime(moment(data).utc().toISOString())
                }
            })
        }
    }

    async selectOrderType(orderType, skip?: boolean) {
        this.orderTypeID = orderType.id
        this.orderType = orderType

        if (this.order) {
            this.working = true
            const order = await this.api.UpdateOrderOrderType({
                id: this.order.id,
                orderTypeID: this.orderTypeID
            })
            this.working = false
            this.checkSvc.checkDTO.totals = new CheckTotalsDTO(order.checks.items[0].totals)
            this.checkSvc.checkDTO.fees = order.checks.items[0].fees.map(f => new CheckFeeDTO(f))
        }

        if (this.orderMode === OrderMode.KIOSK) {
            await this.selectOrderQueueMode(OrderQueueMode.ASAP)
        }

        if (this.orderMode === OrderMode.ONLINE_ORDER) {
            let orderQueueMode = this.orderType.asap && OrderQueueMode.ASAP
            if (!orderQueueMode && this.orderType.schedule) {
                orderQueueMode = OrderQueueMode.SCHEDULE
            }
            await this.selectOrderQueueMode(orderQueueMode)

            this.gmapsSvc.gMapsMarkOrigin({
                name: this.orderLink.originName || this.orderLink.location.name,
                street: this.orderLink.location.street,
                city: this.orderLink.location.city,
                state: this.orderLink.location.state,
                zip: this.orderLink.location.zip
            },
                this.orderLink.location)

            if (this.orderType.serviceType === ServiceType.DELIVERY && this.customer?.address && this.order) {
                await this.gmapsSvc.gMapsDirections(
                    {
                        street: this.orderLink.location.street,
                        city: this.orderLink.location.city,
                        state: this.orderLink.location.state,
                        zip: this.orderLink.location.zip
                    },
                    {
                        street: this.customer.address.street,
                        city: this.customer.address.city,
                        state: this.customer.address.state,
                        zip: this.customer.address.zip
                    },
                    this.orderLink.location,
                    this.customer
                )
            }

            this.order && await this.getDeliveryFee()

            if (this.orderType.serviceType === ServiceType.DELIVERY && !this.customer.address && !skip) {
                this.dialogSvc.open(CustomerAddressComponent, {
                    header: 'Address',
                    styleClass: 'dialog-address dialog-dynamic',
                })
            }
        }

        this.updateOrderTotal()
    }

    selectTip(value) {
        this.tip = 0
        if (value > 0) {
            const tip = this.checkSvc.checkDTO.totals.subTotal * value
            this.tip = this.roundCurrency(tip)
        }
        if (value === 'other') {
            if (this.tipCustom === null) {
                this.tipCustom = this.checkSvc.checkDTO.totals.subTotal * .15
                this.tipCustom = this.roundCurrency(this.tipCustom)
            }
            const tip = this.tipCustom
            this.tip = this.roundCurrency(tip)
            this.tipCustom = Math.abs(this.tipCustom)
        }
        this.tip = Math.abs(this.tip)

        this.updateOrderTotal()
    }

    startNewOrder() {
        this.reset()
        this.showCheck = false
        this.showCheckout = false
        this.showOrderProgress = false
    }

    async submitOrder() {
        LogRocket.track('SUBMIT_ORDER', {
            tenantID: this.order.tenantID,
            orderId: this.order.id,
            orderNumber: this.order.orderNumber,
            source: this.orderMode,
            name: `${this.customer.firstName} ${this.customer.lastName}`,
            email: this.customer.email,
            firstName: this.customer.firstName,
            lastName: this.customer.lastName,
            phone: this.customer.phone,
            serviceType: this.orderType.serviceType
         })
         
        this.working = true
        try {
            delete this.orderLink.location.__typename
            let dueTime = this.order.dueTime
            if (this.orderQueueMode === OrderQueueMode.ASAP) {
                dueTime = this.getNowUTCNearestMin(5).toISOString()
            }
            const saved = await this.api.SubmitOrder({
                id: this.order.id,
                orderTypeID: this.orderTypeID,
                serviceType: this.orderType.serviceType,
                deliveryProvider: this.orderType.deliveryProvider,
                deliveryQuoteID: this.deliveryQuoteID,
                deliveryPickupTime: this.deliveryPickupTime,
                deliveryEstimate: this.deliveryEstimate,
                queueMode: this.orderQueueMode,
                dueTime: dueTime,
                originDetails: this.orderLink.location,
                customerDetails: {
                    firstName: this.customer.firstName,
                    lastName: this.customer.lastName,
                    phone: this.customer.phone,
                    email: this.customer.email,
                    dob: this.customer.dob,
                    address: {
                        street: this.customer.address.street,
                        city: this.customer.address.city,
                        state: this.customer.address.state,
                        zip: this.customer.address.zip
                    }
                },
                clerkID: this.order.orderLink.clerkID,
                checks: [{
                    id: this.checkSvc.checkDTO.id,
                    payments: this.checkSvc.checkDTO.payments,
                    fees: this.fees,
                    customerDetails: {
                        firstName: this.customer.firstName,
                        lastName: this.customer.lastName,
                        phone: this.customer.phone,
                        email: this.customer.email,
                        dob: this.customer.dob,
                        address: {
                            street: this.customer.address.street,
                            city: this.customer.address.city,
                            state: this.customer.address.state,
                            zip: this.customer.address.zip
                        }
                    }
                }]
            })
            this.working = false
            this.showCheckout = false
            this.showOrderProgress = true
            this.status = OrderSvcStatus.SUBMITTED
            LogRocket.startNewSession()
        } catch (error) {
            console.error(error)
            let summay = 'Error Submitting Order'
            let detail = null
            if (error.errors && error.errors[0]?.message) {
                detail = error.errors[0].message
            } 
            if (detail?.includes('response code (D)')) {
                summay = 'Error Submitting Order: Payment Declined'
                detail = 'Please try a different card'
                this.onPaymentDeclined.next(true)
            }
            this.messageSvc.add({ severity: 'error', summary: summay, detail: detail, life: 10000 })
            this.working = false
        }
    }

    async toggleOrderQueueMode() {
        if (this.orderQueueMode === 'ASAP' && this.orderType.schedule) {
            this.orderQueueMode = OrderQueueMode.SCHEDULE
        } else if (this.orderQueueMode === 'SCHEDULE' && this.orderType.asap) {
            this.orderQueueMode = OrderQueueMode.ASAP
        }
        await this.selectOrderQueueMode(this.orderQueueMode)
    }

    async updateCheckItemQuantity(checkItem: Partial<CheckItem>) {
        const input: UpdateCheckItemQuantityInput = {
            id: checkItem.id,
            quantity: checkItem.quantity
        }

        const saved = await this.api.UpdateCheckItemQuantityDeep(input)
        this.checkSvc.checkDTO = CheckDTO.fromQuery(saved as Check)
        this.checkSvc.validateItems()
        this.updateOrderTotal()
        await this.loadCheckItemImages()

    }

    updateOrderTotal() {
        if (this.checkSvc.checkDTO) {
            if (this.orderType.serviceType === ServiceType.DELIVERY) {
                this.total = this.checkSvc.checkDTO.totals.total + this.deliveryFee + this.tip
                this.taxAndFeeTotal = this.checkSvc.checkDTO.totals.taxTotal + this.checkSvc.checkDTO.totals.feeTotal + this.deliveryFee
                this.timeEstimate = this.checkSvc.checkDTO.readyEstimate + this.deliveryEstimate
            } else {
                this.total = this.checkSvc.checkDTO.totals.total + this.tip
                this.taxAndFeeTotal = this.checkSvc.checkDTO.totals.taxTotal + this.checkSvc.checkDTO.totals.feeTotal
                this.timeEstimate = this.checkSvc.checkDTO.readyEstimate
            }
            this.total = this.roundCurrency(this.total)
            this.timeEstimate = Math.round(this.timeEstimate)
        }
    }
}

export enum OrderSvcStatus {
    PENDING = 'PENDING',
    SUBMITTED = 'SUBMITTED'
}