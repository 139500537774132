import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomerService } from 'src/app/services/customer.service';
import { GMapsService } from 'src/app/services/gmaps.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {
  activeIndex: number
  code: string
  confirmAccount: boolean
  confirmPassword: boolean
  email: string
  loading: boolean
  password: string

  constructor(
    public customerSvc: CustomerService, 
    public dRef: DynamicDialogRef, 
    private orderSvc: OrderService, 
    private gmapsSvc: GMapsService) { }

  ngOnInit(): void {
  }

  async forgotPassword(email: string) {
    this.loading = true
    try {
      await this.customerSvc.forgotPassword(email)
      this.loading = false
      this.confirmPassword = true
    } catch (error) {
      this.loading = false
    }
  }

  async forgotPasswordSubmit(email: string, code: string, password: string) {
    this.loading = true
    try {
      await this.customerSvc.forgotPasswordSubmit(email, code, password)
      this.loading = false
      this.confirmPassword = false
    } catch (error) {
      this.loading = false
    }
  }

  async resendSignUp(email: string) {
    this.loading = true
    try {
      await this.customerSvc.resendSignUp(email)
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  async signIn(email: string, password: string) {
    this.loading = true
    try {
      await this.customerSvc.signIn(email, password)
      this.loading = false
      this.dRef.close()
      this.orderSvc.customer.firstName = this.customerSvc.customer.firstName
      this.orderSvc.customer.lastName = this.customerSvc.customer.lastName
      this.orderSvc.customer.email = this.customerSvc.customer.email
      this.orderSvc.customer.phone = this.customerSvc.customer.phone
      const address = this.customerSvc.addresses?.find(a => a.isDefault)
      if (address) {
        this.orderSvc.customer.address.street = address.street
        this.orderSvc.customer.address.city = address.city
        this.orderSvc.customer.address.state = address.state
        this.orderSvc.customer.address.zip = address.zip
        if (this.orderSvc.order) {
          this.gmapsSvc.gMapsDirections({
            street: this.orderSvc.orderLink.location.street,
            city: this.orderSvc.orderLink.location.city,
            state: this.orderSvc.orderLink.location.state,
            zip: this.orderSvc.orderLink.location.zip
          },
            this.orderSvc.customer.address,
            this.orderSvc.orderLink.location,
            this.orderSvc.customer)
        }
      }
    } catch (error) {
      this.loading = false
    }
  }

  async signUp(email: string, password: string) {
    this.loading = true
    try {
      await this.customerSvc.signUp(email, password)
      this.loading = false
      this.confirmAccount = true
    } catch (error) {
      this.loading = false
    }
  }

  async signUpConfirm(email: string, code: string) {
    this.loading = true
    try {
      await this.customerSvc.signUpConfirm(email, code)
      this.loading = false
      this.activeIndex = 0
    } catch (error) {
      this.loading = false
    }
  }
}
