

<div class="grid">
    <div class="col-12 md:col-3">
        <div class="business-name">
            {{orderSvc.orderLink?.originName}}
        </div>
        <div class="socials">
        
        </div>
    </div>

    <div class="col-12 md:col-9 col-right">
        <div>
            VISIT US
        </div>
        <div class="address">
            <div>{{orderSvc.orderLink?.location.street}}</div>
            <div>{{orderSvc.orderLink?.location.city}}, {{orderSvc.orderLink?.location.state}} {{orderSvc.orderLink?.location.zip}}
            </div>
        </div>
    </div>
</div>

<div class="grid">
    <div class="col-12 md:col-3">
        © 2021 {{orderSvc.orderLink?.originName}}
    </div>
    <div class="col-12 md:col-9 col-right">
        <span class="tos" (click)="showTos = true">Terms of Service</span> | <span class="tos" (click)="showPrivacyPolicy = true">Privacy</span> | <span class="tos" (click)="showDoNotSell = true">Do not sell my personal information (California)</span>
    </div>
</div>

<p-dialog header="Terms of Service" [(visible)]="showTos" styleClass="dialog-dynamic" [modal]="true">
    <div [innerHTML]="orderSvc.orderLink.tos"></div>
</p-dialog>

<p-dialog header="Privacy Policy" [(visible)]="showPrivacyPolicy" styleClass="dialog-dynamic" [modal]="true">
    <div [innerHTML]="orderSvc.orderLink.privacyPolicy"></div>
</p-dialog>

<p-dialog header="Notice at Collection" [(visible)]="showNoticeAtCollection" styleClass="dialog-dynamic" [modal]="true">
</p-dialog>

<p-dialog header="Do Not Sell My Personal Information" [(visible)]="showDoNotSell" styleClass="dialog-dynamic" [modal]="true">
</p-dialog>