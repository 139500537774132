/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:250a3714-8774-4c13-8fdc-b3e685ca9009",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_9VaqPChGh",
    "aws_user_pools_web_client_id": "148ldcbsekmovenoe5ov4i46vi",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://k5gatjutxzd73ihzsmnarmzp44.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "paytraxREST",
            "endpoint": "https://lgu85sn819.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "paytrax7c24ac076fcb499db1a5567c0754148894842-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
