import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Storage } from '@aws-amplify/storage';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    images: CachedImage[] = []
    safeUrls = {}
    urls = {}

    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
    }

    async fetchImage(URL): Promise<Blob> {
        return this.httpClient.get(URL, {
            responseType: "blob"
        }).toPromise()
    }

    async getSafeURL(s3Key: string) {
        if (s3Key) {
            let image: CachedImage = this.images.find(i => i.s3Key === s3Key)
            if (!image) {
                const url = await Storage.get(s3Key) as string
                const expiration = moment().add(14, 'minutes')
                const blob: Blob = await this.fetchImage(url)
                const blobUrl = URL.createObjectURL(blob)
                const safeUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl)

                image = { s3Key, expiration, url, blob, blobUrl, safeUrl }
                this.images.push(image)

                this.urls[s3Key] = blobUrl
                this.safeUrls[s3Key] = safeUrl
            }

            // if (moment().isSameOrAfter(image.expiration)) {
            //     image.url = await Storage.get(s3Key) as string
            //     image.expiration = moment().add(14, 'minutes')
            //     image.blob = await this.fetchImage(image.url)
            // }

            return image.safeUrl
        }

        return null
    }

    async getURL(s3Key: string) {
        if (s3Key) {
            let image: CachedImage = this.images.find(i => i.s3Key === s3Key)
            if (!image) {
                const url = await Storage.get(s3Key) as string
                const expiration = moment().add(14, 'minutes')
                const blob: Blob = await this.fetchImage(url)
                const blobUrl = URL.createObjectURL(blob)
                const safeUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl)

                image = { s3Key, expiration, url, blob, blobUrl, safeUrl }
                this.images.push(image)

                this.urls[s3Key] = blobUrl
                this.safeUrls[s3Key] = safeUrl
            }

            // if (moment().isSameOrAfter(image.expiration)) {
            //     image.url = await Storage.get(s3Key) as string
            //     image.expiration = moment().add(14, 'minutes')
            //     image.blob = await this.fetchImage(image.url)
            // }

            return image.blobUrl
        }

        return null
    }
}

interface CachedImage {
    url: string
    blobUrl: string
    safeUrl: SafeUrl
    blob: Blob
    s3Key: string
    expiration: moment.Moment
}