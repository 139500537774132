<div class="items">
    <div class="item" *ngFor="let item of items" (click)="onClickItem(item)">
        <div class="image">
            <img [src]="item.imageS3 | s3URL | async">
        </div>
        <div class="item-name">
            {{item.name}}
        </div>
        <div class="item-details">
        </div>
    </div>
</div>
