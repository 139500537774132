<div class="w-full flex">
    <button type="button" pButton class="btn-primary ml-auto" label="Add a new Address"
        (click)="openNewAddress()"></button>
</div>

<p-dataView [value]="addresses" [layout]="'grid'">
    <ng-template let-item pTemplate="gridItem" let-index="rowIndex">
        <div class="wrapper col-4">
            <div class="item">
                <div class="header active" *ngIf="item.isDefault">
                    Default Address
                </div>
                <div class="header" *ngIf="!item.isDefault" (click)="customerSvc.setDefaultAddress(index)">
                    Set as Default
                </div>

                <div class="main">
                    <div class="name">
                        {{item.name}}
                    </div>
                    <div>
                        {{item.street}}
                    </div>
                    <div>
                        {{item.city}}, {{item.state}} {{item.zip}}
                    </div>
                    <div>
                        Phone: {{item.phone}}
                    </div>
                    <div class="instructions" *ngIf="item.instructions">
                        Instructions: {{item.instructions}}
                    </div>
                    <div class="instructions action" *ngIf="!item.instructions" (click)="openEditAddress(item, index)">
                        Add delviery instructions
                    </div>
                </div>

                <div class="footer">
                    <div class="action" (click)="openEditAddress(item, index)">
                        Edit Address
                    </div>
                    <div class="action ml-auto" (click)="customerSvc.removeAddress(index)">
                        Remove
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dataView>