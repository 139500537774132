<div class="w-full flex">
    <button type="button" pButton class="btn-primary ml-auto" label="Add a new Payment Method" (click)="paymentSvc.openCCForm(orderSvc.orderLink.id, null, 1, orderSvc.customer)" [ladda]="paymentSvc.loading"></button>
</div>

<p-dataView [value]="paymentMethods" [layout]="'list'">
    <ng-template let-item pTemplate="listItem" let-index="rowIndex">
        <div class="wrapper">
            <div class="item">

                <div class="header active" *ngIf="customerSvc.customer.defaultPaymentMethodID === item.token">
                    Default Payment Method
                </div>
                <div class="header" *ngIf="customerSvc.customer.defaultPaymentMethodID !== item.token" (click)="customerSvc.setDefaultPaymentMethod(item.token)">
                    Set as Default
                </div>

                <div class="main">
                    <div style="width: 75px;">
                        <span *ngIf="item.cardType !== 'VS'">{{getCardTypeDisplayName(item.cardType)}}</span>
                        <img *ngIf="item.cardType === 'VS'" src="../../../../assets/logos/ccVisa.png" />
                    </div>

                    <div class="flex flex-column" style="flex: 1">
                        <div>
                            {{item.name}}
                        </div>
                        <div>
                            Exp: {{item.cardExpiration}}
                        </div>
                    </div>

                    <div class="ml-auto">
                        <fa-icon [icon]="['far', 'times-circle']" size="2x" style="cursor: pointer; color: red;"
                            (click)='customerSvc.removeCustomerPaymentMethod(item.id)' pTooltip="Remove"></fa-icon>
                    </div>
                </div>

            </div>
        </div>

    </ng-template>
</p-dataView>