interface String {
    toProperCase(): string;
    pluralize(): string;
}

String.prototype.toProperCase = function (): string {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
}

String.prototype.pluralize = function (): string {
    const pluralize = require('pluralize')
    return pluralize(this)
}