
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapDirectionsService, MapGeocoder } from '@angular/google-maps';
import { Location } from './API.service';

@Injectable({
    providedIn: 'root'
})
export class GMapsService {
    directions = []
    gMapApiLoaded: boolean
    gMapCenter: google.maps.LatLngLiteral
    gMapDirections: google.maps.DirectionsResult
    gMapMarkers
    gMapOptions: google.maps.MapOptions = {
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        zoomControl: false,
        scaleControl: false,
        fullscreenControl: false,
        streetViewControl: false
    }
    gMapZoom: number = 12
    locations = []

    constructor(
        private http: HttpClient,
        private geocoder: MapGeocoder,
        private mapDirectionsService: MapDirectionsService
    ) { }

    async gMapsAddressLookup(params: { address?: string, city?: string, state?: string, zip?: string }): Promise<google.maps.LatLng> {
        if (!params.address || !params.city || !params.state || !params.zip) return null

        const address = `${params.address} ${params.city} ${params.state} ${params.zip}`

        const cache = this.locations.find(l => l.address === address)
        if (cache) {
            console.log('gMapsAddressLookup:cache', params)
            return cache.location
        }

        console.log('gMapsAddressLookup', params)
        const data = await this.geocoder.geocode({
            address: address
        }).toPromise()

        if (data.results?.[0]) {
            const location = data.results?.[0].geometry.location
            this.locations.push({
                address: address,
                location: location
            })
            return location
        }
        return null
    }

    async gMapsDirections(origin: { street?: string, city?: string, state?: string, zip?: string }, destination: { address?: string, street?: string, city?: string, state?: string, zip?: string }, location?: Location, customer?: any) {
        if (!destination.street || !destination.city || !destination.state || !destination.zip) return null

        const originLocation = await this.gMapsAddressLookup({
            address: origin.street,
            city: origin.city,
            state: origin.state,
            zip: origin.zip
        })
        const destinationLocation = await this.gMapsAddressLookup({
            address: destination.street,
            city: destination.city,
            state: destination.state,
            zip: destination.zip
        })

        if (location) {
            location.latitude = originLocation.lat()
            location.longitude = originLocation.lng()
        }

        if (customer) {
            customer.address.latitude = destinationLocation.lat()
            customer.address.longitude = destinationLocation.lng()
        }

        if (originLocation && destinationLocation) {
            this.gMapDirections = null

            console.log(this.directions)
            const existing = this.directions.find(d => d.origin === originLocation.toString() && d.destination === destinationLocation.toString())
            if (existing) {
                console.log('gMapsDirections:cache', origin, destination)
                this.gMapDirections = existing.directions
            } else {
                const request: google.maps.DirectionsRequest = {
                    destination: { lat: destinationLocation.lat(), lng: destinationLocation.lng() },
                    origin: { lat: originLocation.lat(), lng: originLocation.lng() },
                    travelMode: google.maps.TravelMode.DRIVING
                }
                const data = await this.mapDirectionsService.route(request).toPromise()
                if (data.result) {
                    console.log('gMapsDirections', origin, destination)
                    this.gMapDirections = data.result
                    this.directions.push({
                        origin: originLocation.toString(),
                        destination: destinationLocation.toString(),
                        directions: this.gMapDirections
                    })
                }
            }

            if (this.gMapDirections) {
                const duration = this.gMapDirections.routes[0].legs[0].duration.value

                if (customer) {
                    customer.address.distance = this.gMapDirections.routes[0].legs[0].distance.value
                }

                this.gMapMarkers = []

                // this.deliveryEstimate = Math.floor(duration / 60)
                // const deliveryFee = await this.getDeliveryFee()
                // this.checkSvc.checkDTO.fees = []
                // this.checkSvc.checkDTO.fees.push({
                //     name: 'Delivery Fee',
                //     amount: deliveryFee,
                //     taxType: TaxTypeType.ORDER_FEE
                // })
                // this.deliveryFee = deliveryFee
                // this.updateOrderTotal()
            }
        }
    }

    async gMapsMarkOrigin(origin: { name: string, street: string, city: string, state: string, zip: string }, location?: Location) {
        console.log('gMapsMarkOrigin', origin)
        const originLocation = await this.gMapsAddressLookup({
            address: origin.street,
            city: origin.city,
            state: origin.state,
            zip: origin.zip
        })
        this.gMapMarkers = []
        if (originLocation) {
            this.gMapMarkers.push({
                position: {
                    lat: originLocation.lat(),
                    lng: originLocation.lng(),
                },
                label: {
                    // color: 'blue',
                    text: origin.name,
                },
                options: { animation: google.maps.Animation.BOUNCE }
            })
            if (location) {
                location.latitude = originLocation.lat()
                location.longitude = originLocation.lng()
            }
        }

        this.gMapCenter = {
            lat: originLocation.lat(),
            lng: originLocation.lng()
        }
    }

    async initGMaps() {
        if (!this.gMapApiLoaded) {
            console.log('gMapsInit')
            await this.http.jsonp('https://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyAEgvSL3MuX96IY9JNYlYeey5KQf0BXX2M&libraries=drawing,geometry,places', 'callback').toPromise()
            this.gMapApiLoaded = true
        }
    }
}
