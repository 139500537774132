<div *ngIf="!orderSvc.orderLink" style="height: 100vh; width: 100vw;" class="flex justify-content-center align-items-center p-5">
    <p-progressSpinner [style]="{width: '100px', height: '100px'}" styleClass="custom-spinner" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
</div>

<ng-container *ngIf="!customerSvc.showCustomerAccount">
    <app-order-top-bar></app-order-top-bar>
    <app-order-entry [orderMode]="orderMode" *ngIf="orderSvc.orderLink && !menuSvc.menu"></app-order-entry>
    <app-order-menu [orderMode]="orderMode" *ngIf="orderSvc.orderLink && menuSvc.menu"></app-order-menu>
</ng-container>

<app-customer-account *ngIf="customerSvc.showCustomerAccount"></app-customer-account>

<p-sidebar [(visible)]="orderSvc.showCheck" position="right" styleClass="sidebar-check sidebar-dynamic" [showCloseIcon]="false"
    [blockScroll]="true">
    <ngx-spinner name="app-check" [fullScreen]="false"></ngx-spinner>
    <app-order-edit [checkDTO]="checkSvc.checkDTO" [orderMode]="orderMode"></app-order-edit>
</p-sidebar>

<p-sidebar [(visible)]="orderSvc.showCheckout" position="right" styleClass="sidebar-check sidebar-dynamic" [showCloseIcon]="false"
    [blockScroll]="true">
    <ngx-spinner name="app-checkout" [fullScreen]="false"></ngx-spinner>
    <app-checkout [orderMode]="orderMode"></app-checkout>
</p-sidebar>

<p-sidebar [(visible)]="orderSvc.showOrderProgress" position="right" styleClass="sidebar-check sidebar-dynamic" [showCloseIcon]="false"
    [blockScroll]="true" (onHide)="onHideOrderProgress()">
    <ngx-spinner name="app-order-progress" [fullScreen]="false"></ngx-spinner>
    <app-order-details [orderMode]="orderMode" *ngIf="orderSvc.order && orderSvc.status === 'SUBMITTED'" [orderID]="orderSvc.order.id"></app-order-details>
</p-sidebar>

<div *ngIf="debug" class="debug">
    <button class="btn-primary" (click)="openCheckout()">Checkout</button>
    <button class="btn-primary" (click)="openOrderProgress()">Order Progress</button>
    <button class="btn-primary" (click)="showSpinners()">Show Spinners</button>
    <button class="btn-primary" (click)="hideSpinners()">Hide Spinners</button>
    <button class="btn-primary" (click)="orderSvc.working = !orderSvc.working">Toggle Working</button>
</div>