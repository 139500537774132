import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ImageService } from 'src/app/services/image.service';


@Pipe({
  name: 's3URL'
})
export class S3URLPipe implements PipeTransform {
    constructor(private imgService: ImageService) {
        
    }

  async transform(value: string): Promise<SafeUrl> {
    try {
      return await this.imgService.getSafeURL(value)
    } catch (error) {
      return value;
    }
  }

}