import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { APIService, CCProcessor, DCPayAuthOnlyInput, PaymentMethodType } from 'src/app/services/API.service';
import { CheckService } from 'src/app/services/check.service';
import { DataCapService } from 'src/app/services/datacap.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentMethod } from 'src/app/services/payment.service';
import { CheckPaymentDTO } from 'src/models/DTO/check.dto';

declare var DatacapHostedWebToken: any;

@Component({
  selector: 'app-datacap',
  templateUrl: './datacap.component.html',
  styleUrls: ['./datacap.component.scss'],
})
export class DataCapComponent implements OnInit, AfterViewInit {
  amount: number;
  loading: boolean
  tip: number;

  constructor(
    private datacapSvc: DataCapService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private checkSvc: CheckService,
    private orderSvc: OrderService,
    private api: APIService
  ) {
    this.datacapSvc.newPaymentMethod.subscribe((paymentMethod) => {
      if (paymentMethod) {
        this.ref.close(paymentMethod);
      }
    });
  }

  ngAfterViewInit() {
    DatacapHostedWebToken.init(
      this.datacapSvc.tokenKey,
      'token-iframe',
      (response) => {
        this.tokenCallback(response, this.amount, this.tip, this.checkSvc, this.orderSvc, this.api)
      }
    );
  }

  ngOnInit(): void {
    this.amount = this.config.data.amountWithTip || 0;
    this.tip = this.config.data.tip || 0;
    console.log(this.checkSvc)
  }

  requestToken() {
    this.loading = true
    DatacapHostedWebToken.requestToken();
  }

  async tokenCallback(response, amount, tip, checkSvc: CheckService, orderSvc: OrderService, api: APIService) {
    if (response.Error) {
      console.error('Token error: ' + response.Error);
      this.loading = false
    } else {
      const dcPayAuthOnlyInput: DCPayAuthOnlyInput = {
        token: response.Token,
        orderLinkID: orderSvc.orderLink.id,
        orderID: orderSvc.order?.id
      }
      const dcPayAuthOnlyRes = await api.DcPayAuthOnly(dcPayAuthOnlyInput)

      if (checkSvc.checkDTO) {
        checkSvc.checkDTO.payments = [];
        const payment: CheckPaymentDTO = {
          amount: amount - tip,
          tip: tip,
          token: dcPayAuthOnlyRes.Token,
          displayName: response.Last4,
        };
        this.checkSvc.checkDTO.payments.push(payment);
        console.log(this.checkSvc.checkDTO)
      }

      console.log('datacap', response);
      const paymentMethod = new PaymentMethod({
        token: response.Token,
        name: `${response.Brand} - ${response.Last4}`,
        type: PaymentMethodType.CREDIT,
        cardType: response.Brand,
        cardExpiration: `${response.ExpirationMonth}/${response.ExpirationYear}`,
        amount: amount,
        tip: tip,
        processor: CCProcessor.DATACAP
      });
      this.datacapSvc.newPaymentMethod.next(paymentMethod);
      this.datacapSvc.newPaymentMethod.next(null);
      this.loading = false
    }
  }
}

