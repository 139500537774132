<ng-container *ngIf="orderMode === orderModeOnlineOrder">
    <div *ngIf="app.isDesktop" class="header" [style.background-image]="'url(' + bannerImage + ')'">
        <div class="heading">{{orderSvc.orderLink?.heading}}</div>
        <div class="subheading">{{orderSvc.orderLink?.subHeading}}</div>
        <div class="mt-3" style="width: 90%; max-width: 500px;">
            <app-menu-item-search [menus]="menuSvc.menus"></app-menu-item-search>
        </div>
        <p-badge class="mt-3" *ngIf="!orderSvc.orderLinkOpen" value="Not Accepting Orders" severity="danger">
        </p-badge>
    </div>

    <div *ngIf="app.isMobile" class="header mobile" [style.background-image]="'url(' + bannerImageMobile + ')'">
        <div class="heading">{{orderSvc.orderLink?.heading}}</div>
        <div class="subheading">{{orderSvc.orderLink?.subHeading}}</div>
        <div class="mt-3" style="width: 90%; max-width: 500px;">
            <app-menu-item-search [menus]="menuSvc.menus"></app-menu-item-search>
        </div>
        <p-badge class="mt-3" *ngIf="!orderSvc.orderLinkOpen" value="Not Accepting Orders" severity="danger">
        </p-badge>
    </div>
</ng-container>

<ng-container *ngIf="orderMode === orderModeOnlineOrder">
    <div class="section menu-scroller" *ngIf="orderSvc.orderLink.menuScollerHeading">
        <div class="section-heading">{{orderSvc.orderLink.menuScollerHeading}}</div>
        <div class="section-subheading" *ngIf="orderSvc.orderLink.menuScollerSubheading">
            {{orderSvc.orderLink.menuScollerSubheading}}</div>
        <app-menu-scroller></app-menu-scroller>
    </div>
</ng-container>

<ng-container *ngIf="orderMode === orderModeOnlineOrder">
    <div class="section popular-items" *ngIf="orderSvc.orderLink.popularItemsHeading">
        <div class="section-heading">{{orderSvc.orderLink.popularItemsHeading}}</div>
        <div class="section-subheading" *ngIf="orderSvc.orderLink.popularItemsSubheading">
            {{orderSvc.orderLink.popularItemsSubheading}}</div>
        <app-popular-menu-items [orderLink]="orderSvc.orderLink"></app-popular-menu-items>
    </div>
</ng-container>

<ng-container *ngIf="orderMode === orderModeOnlineOrder">
    <div class="section featured-menus" *ngIf="orderSvc.orderLink.featuredRestarauntHeading">
        <div class="grid">
            <div class="col-12 md:col-4 p-0 pr-3">
                <div class="section-heading">{{orderSvc.orderLink.featuredRestarauntHeading}}</div>
                <div class="section-subheading" *ngIf="orderSvc.orderLink.featuredRestarauntSubheading">
                    {{orderSvc.orderLink.featuredRestarauntSubheading}}</div>
            </div>
            <div class="col-12 md:col-8 p-0">
                <app-featured-menus [orderLink]="orderSvc.orderLink"></app-featured-menus>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderMode === orderModeOnlineOrder">
    <div class="section popular-menus" *ngIf="orderSvc.orderLink.popularRestarauntHeading">
        <div class="grid">
            <div class="col-12 md:col-4 p-0 pr-3">
                <div class="section-heading">{{orderSvc.orderLink.popularRestarauntHeading}}</div>
                <div class="section-subheading" *ngIf="orderSvc.orderLink.popularRestarauntSubheading">
                    {{orderSvc.orderLink.popularRestarauntSubheading}}</div>
            </div>
            <div class="col-12 md:col-8 p-0">
                <app-popular-menus [orderLink]="orderSvc.orderLink"></app-popular-menus>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderMode === orderModeOnlineOrder || orderMode === orderModeKiosk">
    <div class="section explore" *ngIf="orderSvc.orderLink.exploreHeading && menuSvc.menuTagOptions.length > 0">
        <div class="grid">
            <div class="col-12 md:col-4 p-0 pr-3 pb-3">
                <div class="section-heading">{{orderSvc.orderLink.exploreHeading}}</div>
                <div class="section-subheading" *ngIf="orderSvc.orderLink.exploreSubheading">
                    {{orderSvc.orderLink.exploreSubheading}}</div>
                <div class="menu-tags">
                    <div class="tag">
                        <button class="btn-all" pButton label="ALL"
                            (click)="menuSvc.selectAllMenuTagOptions()"></button>
                        <div class="name">
                        </div>
                    </div>
                    <div *ngFor="let tag of menuSvc.menuTagOptions" class="tag" [ngClass]="{'active': tag.selected}"
                        (click)="menuSvc.selectMenuTagOption(tag)">
                        <div>
                            <img [src]="tag.imageS3 | s3URL | async">
                        </div>
                        <div class="name">
                            {{tag.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-8 p-0 tagged-menus" *ngIf="menuSvc.menuTagOptionsSelected">
                <app-tagged-menus [orderLink]="orderSvc.orderLink" [tags]="menuSvc.menuTagOptionsSelected">
                </app-tagged-menus>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderMode === orderModeOnlineOrder">
    <a [href]="orderSvc.orderLink.footerURL" target="_blank">
        <img class="footer-image" *ngIf="footerImage" [src]="footerImage" style="margin-bottom: -4px;">
    </a>

    <app-footer style="margin-top: -5px;"></app-footer>
</ng-container>