import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetMenuItemQuery, GetVenueQuery, Menu } from 'src/app/services/API.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-menu-item-search',
  templateUrl: './menu-item-search.component.html',
  styleUrls: ['./menu-item-search.component.scss']
})
export class MenuItemSearchComponent implements OnInit {
  @Input() menus: Menu[]
  items: {
    label: string,
    menuID: string,
    items: {
      name: string,
      venue: GetVenueQuery,
      menuItem: GetMenuItemQuery,
      menuID: string,
      menuName: string,
      priceTypeID?: string,
      imageS3?: string
    }[]
  }[] = []
  @Output() onSelect: EventEmitter<{ menuItemID: string, venueID: string }> = new EventEmitter()
  @ViewChild('op') op: OverlayPanel
  searchValue: string
  searchValueChanged: Subject<string> = new Subject<string>();

  @ViewChild('target') target

  constructor(
    private menuSvc: MenuService, 
    public orderSvc: OrderService) {
    this.searchValueChanged
      .pipe(debounceTime(500),
        distinctUntilChanged()
      ).subscribe(model => {
        this.searchValue = model;
        this.search(this.searchValue)
      })
  }

  ngOnInit(): void {
  }

  reset() {
    this.op.hide()
    setTimeout(() => {
      this.items = []
      this.searchValue = null
    }, 250);
  }

  async search(value: string) {
    this.items = []
    if (value) {
      value = value.toLowerCase()
      const menus = this.menus.filter(m => m.active)
      for (let menu of menus) {
        if (menu.name.toLowerCase().includes(value)) {
          const group = this.items.find(g => g.menuID === menu.id)
          if (!group) {
            this.items.push({
              label: menu.name,
              menuID: menu.id,
              items: []
            })
          }
        }
        for (let category of menu.categories.items.map(i => i.menuCategory).filter(c => c.active)) {
          for (let item of (category as any).menuItems.items.map(i => i.menuItem)) {
            if (item.name.toLowerCase().includes(value)) {
              this.items = this.items || []
              const group = this.items.find(g => g.menuID === menu.id)
              if (group) {
                group.items = group.items || []
                const existing = group.items.find(i => i.menuItem.id === item.id && i.priceTypeID === category.priceTypeID)
                if (!existing) {
                  group.items.push({
                    name: item.name,
                    venue: menu.venue,
                    menuItem: item,
                    menuID: menu.id,
                    menuName: menu.name,
                    priceTypeID: category.priceTypeID,
                    imageS3: item.imageS3
                  })
                }
              } else {
                this.items.push({
                  label: menu.name,
                  menuID: menu.id,
                  items: [{
                    name: item.name,
                    venue: menu.venue,
                    menuItem: item,
                    menuID: menu.id,
                    menuName: menu.name,
                    priceTypeID: category.priceTypeID,
                    imageS3: item.imageS3
                  }]
                })
              }
            }
          }
        }
      }
    }

    if (this.items.length > 0) {
      this.op.show(null, this.target.nativeElement)
    } else {
      this.op.hide()
    }

    // console.log(this.items)
    return this.items
  }

  selectItem($event) {
    this.onSelect.emit({ menuItemID: $event.value.menuItem.id, venueID: $event.value.venue.id })
    this.menuSvc.selectMenuItem($event.value.menuItem.id, $event.value.venue.id, $event.value.menuID, $event.value.priceTypeID)
    this.reset()
  }

  selectMenuOption(option) {
    this.menuSvc.selectMenu(option.menuID)
  }
}