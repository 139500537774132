<div class="grid items-top">
    <div class="col">
        <div class="item" (click)="onClickItem(items[0])" *ngIf="items[0]">
            <div>
                <img [src]="items[0].imageS3 | s3URL | async">
            </div>
            <div class="item-name">
                {{items[0].name}}
            </div>
            <div class="item-details">
            </div>
        </div>
    </div>
    <div class="col">
        <div class="item" (click)="onClickItem(items[1])" *ngIf="items[1]">
            <div>
                <img [src]="items[1].imageS3 | s3URL | async">
            </div>
            <div class="item-name">
                {{items[1].name}}
            </div>
            <div class="item-details">
            </div>
        </div>
    </div>
</div>

<div class="items">
    <div class="item" *ngFor="let item of items2" (click)="onClickItem(item)">
        <div>
            <img [src]="item.imageS3 | s3URL | async">
        </div>
        <div class="item-name">
            {{item.name}}
        </div>
        <div class="item-details">
        </div>
    </div>
</div>
