<div class="top-bar">
    <div class="left">
        <span class="business-name">
            {{orderSvc.orderLink?.originName}}
        </span>
    </div>
    <div class="right">
        <fa-icon (click)="customerSvc.showCustomerAccount = false" size="lg" [icon]="['far', 'times-circle']"
            style="cursor: pointer;"></fa-icon>
    </div>
</div>

<div class="main">

    <div class="nav">
        <div class="customer-details">
            <div class="initials">
                {{customerSvc.customerInitials}}
            </div>
            <div class="flex flex-column">
                <div>
                    Welcome Back,
                </div>
                <div class="customer-name">
                    {{customerSvc.customer?.firstName}} {{customerSvc.customer?.lastName}}
                </div>
            </div>
        </div>

        <hr>

        <ul>
            <li class="back" (click)="customerSvc.showCustomerAccount = false">
                <fa-icon size="lg" [icon]="['far', 'arrow-left']" class="mr-3"></fa-icon>
                Back to Order
            </li>
            <hr>
            <li [ngClass]="{'active' : activeNav === 'orders'}" (click)="activeNav = 'orders'">
                My Orders
            </li>
            <li [ngClass]="{'active' : activeNav === 'wallet'}" (click)="activeNav = 'wallet'">
                My Wallet
            </li>
            <li [ngClass]="{'active' : activeNav === 'address'}" (click)="activeNav = 'address'">
                My Address Book
            </li>
            <li [ngClass]="{'active' : activeNav === 'account'}" (click)="activeNav = 'account'">
                My Account
            </li>
            <li (click)="signOut()">
                Sign Out
            </li>
        </ul>
    </div>

    <div class="content">
        <ng-container *ngIf="activeNav === 'orders'">
            <div class="section">
                <div class="heading">Active Orders</div>
                <hr>
                <app-customer-orders-list [checks]="customerSvc.checksActive" [id]="'active'"></app-customer-orders-list>
            </div>
            <div class="section mt-3">
                <div class="heading">Past Orders</div>
                <hr>
                <app-customer-orders-list [checks]="customerSvc.checksPast" [id]="'past'"></app-customer-orders-list>
            </div>
        </ng-container>

        <ng-container *ngIf="activeNav === 'wallet'">
            <div class="section">
                <div class="heading mb-3">My Wallet</div>
                <app-customer-wallet [paymentMethods]="customerSvc.paymentMethods"></app-customer-wallet>
            </div>
        </ng-container>

        <ng-container *ngIf="activeNav === 'address'">
            <div class="section">
                <div class="heading mb-3">Address Book</div>
                <app-customer-address-book [addresses]="customerSvc.customer?.addresses"></app-customer-address-book>
            </div>
        </ng-container>

        <ng-container *ngIf="activeNav === 'account'">
            <div class="section">
                <div class="heading mb-3">My Account</div>
                <app-customer-account-details></app-customer-account-details>
            </div>
        </ng-container>
    </div>

</div>